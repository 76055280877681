import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { convertToArrayOfObjectsForPieChart, NumberFormatter } from "../../../../../../scripts/helpers";
import AnalyticsPageHeader from "../Common/AnalyticsPageHeader";
import CardComponent from "../../../../../../components/Events/Registrations/CardComponent";
import MuiChip from "../../../../../../common/FormComponents/MuiChip";
import { convertObjectToArrays, generateOpenOpportunitiesGraphOption } from "./barChartGenerator.helper";
import { GraphComponent } from "../Common/GraphComponent";
import { IRevenueGraphReady, IRevenueOverview } from "./revenueAnalytics.interface";
import { constructObjectForPieChart } from "../../../../../../common/ECharts/helpers/charts.helper";
import { useTablePagination } from "../../../../../../contexts/TablePaginationContext";
import { EventAnalyticsTableData } from "../../../../interfaces/event-analytics_interface";
import { getEventAnalyticsTable } from "../../../../../../scripts/apis/events";
import { EventAnalyticsType } from "../../../../enum";
import { createColumnHelper } from "@tanstack/react-table";
import TanstackTable from "../../../../../../common/TanstackTable/TanstackTable";

import './styles.scss';

interface OpportunitiesProps {
    opportunitiesOverview: IRevenueOverview;
    graphReady: IRevenueGraphReady;
    opportunitiesTableCount: number;
    eventId: string | number;
}

const Opportunities: React.FC<OpportunitiesProps> = (props): React.JSX.Element =>
{

    const { opportunitiesOverview, graphReady, eventId, opportunitiesTableCount } = props;

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const [opportunitiesTableData, setopportunitiesTableData] = useState<EventAnalyticsTableData[]>([]);

    const opportunitiesOverviewData = [
        {
            name: 'Open Opportunities',
            value: opportunitiesOverview?.opportunitiesOverview?.openOpportunities && Number(opportunitiesOverview?.opportunitiesOverview?.openOpportunities) > 0 ? `$${NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.openOpportunities)}` : '-',
            chipLabel: opportunitiesOverview?.opportunitiesOverview?.openOpportunitiesCount && NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.openOpportunitiesCount) || '-',
            chipColor: 'grey'
        },
        {
            name: 'New Opportunities',
            value: opportunitiesOverview?.opportunitiesOverview?.newOpportunities && Number(opportunitiesOverview?.opportunitiesOverview?.newOpportunities) > 0 ? `$${NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.newOpportunities)}` : '-',
            chipLabel: opportunitiesOverview?.opportunitiesOverview?.newOpportunitiesCount && NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.newOpportunitiesCount) || '-',
            chipColor: 'grey'
        },
        {
            name: 'Influenced Opportunities',
            value: opportunitiesOverview?.opportunitiesOverview?.influencedOpportunities && Number(opportunitiesOverview?.opportunitiesOverview?.influencedOpportunities) > 0 ? `$${NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.influencedOpportunities)}` : '-',
            chipLabel: opportunitiesOverview?.opportunitiesOverview?.influencedOpportunitiesCount && NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.influencedOpportunitiesCount) || '-',
            chipColor: 'grey'
        },
        {
            name: 'Projected Revenue',
            value: opportunitiesOverview?.opportunitiesOverview?.projectedRevenue && Number(opportunitiesOverview?.opportunitiesOverview?.projectedRevenue) > 0 ? `$${NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.projectedRevenue)}` : '-',
            chipLabel: opportunitiesOverview?.opportunitiesOverview?.projectedRevenueCount && NumberFormatter(opportunitiesOverview?.opportunitiesOverview?.projectedRevenueCount) || '-',
            chipColor: 'grey'
        }
    ];

    const opportunitiesStageData = convertObjectToArrays(opportunitiesOverview?.opportunitiesStages);
    const opportunitiesStageOption = generateOpenOpportunitiesGraphOption(opportunitiesStageData as any);

    const icpGraphData = convertToArrayOfObjectsForPieChart(opportunitiesOverview?.opportunitiesICP as any);
    const icpGraphOption = constructObjectForPieChart(icpGraphData, ['50%', '72%'], undefined, undefined);

    const bpGraphData = convertToArrayOfObjectsForPieChart(opportunitiesOverview?.opportunitesBuyerPersona as any);
    const bpGraphOption = constructObjectForPieChart(bpGraphData, ['50%', '72%'], undefined, undefined);

    const columnHelper = createColumnHelper<EventAnalyticsTableData>();
    const columns = [
        columnHelper.accessor('dealName' as any, {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Opportunities',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'dealName'
        }),
        columnHelper.accessor('accountName', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Account',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'accountName'
        }),
        columnHelper.accessor('touchpoint', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Touch Point',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'touchPoint'
        }),
        columnHelper.accessor('amount', {
            cell: (row) => {
                return (<p className="cellContent">{`${row.getValue() && row?.getValue() !== '' && Number(row?.getValue()) !== 0 ? '$' + NumberFormatter(row?.getValue()) : '-'}`}</p>)
            },
            header: 'Amount',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'amount'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Type',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'type'
        }),
        columnHelper.accessor('closedOn', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue() ? row.getValue() : '-'}</p>)
            },
            header: 'Closed On',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'closedOn'
        }),
        columnHelper.accessor('timeline', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue() ? row.getValue() : '-'}</p>)
            },
            header: 'Timeline',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'timeline'
        })
    ];

    const fetchOpportunitiesTableData = async (): Promise<void> =>
    {
        try 
        {
            const opportunitiesTableData = await getEventAnalyticsTable(eventId, EventAnalyticsType.OPPORTUNITIES, pageSize || 25, currentPage - 1);
            if (opportunitiesTableData) 
            {
                setopportunitiesTableData(opportunitiesTableData);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchOpportunitiesTableData();
    }, [pageSize, currentPage]);

    return (
        <div id="revenueOpportunitiesComponent">
            <AnalyticsPageHeader 
                header="Opportunities"
                description="Understand your deal performance and win rates"
            />

            <div className="content-div">

                {/* Row to render the cards related to Oppotunities overview */}
                <Row className="custom-row">
                    {
                        opportunitiesOverviewData.map((info, index) => 
                        {
                            return (
                                <Col className="px-0" key={index}>
                                    <CardComponent 
                                        header={info.name}
                                        footerLeft={info.value}
                                        footerStringBoldLeft
                                        footerRight={info.chipLabel && <MuiChip circleIcon label={info.chipLabel} chipColor={info.chipColor as 'grey'} />}
                                        cardMinHeight="82px"
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>

                {/* Row to render graphs related to opportunities stage and icp / bp breakdown */}
                <Row className="custom-row">
                    <Col className="px-0">
                        <GraphComponent 
                            title="Opportunities Stage"
                            chartOption={opportunitiesStageOption}
                            height='240px'
                            graphReady={graphReady?.opportunitiesStage}
                            isDataAvailable={opportunitiesStageData && opportunitiesStageData[1]?.length > 0 && opportunitiesStageData[1]?.some((item: number | string) => item !== 0)}
                        />
                    </Col>
                    <Col className="px-0">
                        <Row className="custom-row">
                            <Col className="px-0">
                                <GraphComponent 
                                    title="ICP Breakdown"
                                    chartOption={icpGraphOption}
                                    graphReady={graphReady?.opportunitiesICP}
                                    isDataAvailable={icpGraphData && icpGraphData?.length > 0}
                                    height='240px'
                                />
                            </Col>
                            <Col className="px-0">
                                <GraphComponent 
                                    title="Buyer Persona Breakdown"
                                    chartOption={bpGraphOption}
                                    graphReady={graphReady?.opportunitiesBuyerPersona}
                                    isDataAvailable={bpGraphData && bpGraphData?.length > 0}
                                    height='240px'
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Opportunities table */}
                <Row className="custom-row">
                    <Col className="px-0">
                        <div className="event-analytics-pagination-tables">
                            <TanstackTable 
                                data={opportunitiesTableData}
                                initialColumns={columns}
                                rowCount={opportunitiesTableCount}
                                showSpinner={false}
                                currentPage={currentPage - 1}
                                pageSize={pageSize}
                                showNoRowsOverlay
                                updateCurrentPage={updateCurrentPage as any}
                                updatePageSize={updatePageSize}
                                hidePagination={opportunitiesTableCount == 0}
                                showBottomPadding={false}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default Opportunities;