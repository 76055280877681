import { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import TanstackTable from "../../../common/TanstackTable/TanstackTable";
import { Audience } from "../../../pages/Audience/interfaces";
import { buildInitialsForPicture } from "../../../scripts/helpers";
import { Avatar } from "@mui/material";
import { getAllEventModerators, removeModerator } from "../../../scripts/apis/events";
import { useParams } from "react-router-dom";
import { TableActions } from "../../../common/TableActions";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import AddModerator from "./AddModerator";
import MuiChip from "../../../common/FormComponents/MuiChip";
import DeletePopup from "../../../common/DeletePopup";
import toast from "react-hot-toast";

const EventModeratorSettings: React.FC = (): React.JSX.Element =>
{
    const { eventId } = useParams();

    const [moderators, setModerators] = useState<Audience[]>([]);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedModerator, setSelectedModerator] = useState<Audience | null>();

    const columnHelper = createColumnHelper<Audience>();
    const columns = 
    [
        columnHelper.accessor('image' as any, {
            cell: (row) => {
                const clearbitFName = row?.row?.original?.clearbitData?.givenName;
                const clearbitLName = row?.row?.original?.clearbitData?.familyName;

                const name = clearbitFName && clearbitLName ? buildInitialsForPicture('', clearbitFName, clearbitLName) : buildInitialsForPicture(row?.row?.original?.email, row?.row?.original?.firstName, row?.row?.original?.lastName || undefined);
                return (
                  <div className="avatar-container">
                    <Avatar className="logo-avatar-img" src={row.row.original?.clearbitData?.companyLogo}>
                      {!row.row.original?.clearbitData?.companyLogo ? name : ''}
                    </Avatar>
                  </div>
                );
            },
            header: '',
            size: 40,
            enableSorting: false,
            id: 'image'
        }),
        columnHelper.accessor('firstName', {
            cell: (row) => {
                return (
                    <div>
                        <h3 className="bolded-cellContent">{`${row.row.original?.firstName}${row.row.original?.lastName || ''}`}</h3>
                        <p className="cellContent">{`${row.row.original?.email}`}</p>
                    </div>
                )
            },
            header: 'Name',
            size: 150,
            id: 'name'
        }),
        columnHelper.accessor('status', {
            cell: (row) => {
                return (
                    <MuiChip label={'Moderator'} chipColor="green" />
                )
            },
            header: 'Status',
            size: 120,
            id: 'status',
            enableSorting: false,
        }),
        columnHelper.accessor('actions' as any, {
            cell: ({ row }) => (
                <TableActions 
                        actions={[
                            { 
                                title: 'Delete', 
                                onClick: () => 
                                {
                                    setSelectedModerator(row.original);
                                    setShowDeletePopup(true);
                                } 
                            }
                        ]}
                    />
            ),
            header: '',
            id: 'actions',
            size: 40,
            enableSorting: false,
        })
    ];
    
    const handleAddModerator = (): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Add Moderator',
            component: <AddModerator eventId={eventId as string} setRefresh={setRefresh} existingModerators={moderators} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const handleRemoveModerator = async (): Promise<void> =>
    {
        try 
        {
            const moderatorRemoved = await removeModerator(eventId as string, selectedModerator?.audienceUserId as string);
            if(moderatorRemoved)
            {
                toast.success(`${selectedModerator?.firstName} has been removed successfully`);
                setSelectedModerator(null);
                setShowDeletePopup(false);
                setRefresh(true);
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message || 'Error removing moderator');
        }
    };

    const fetchModerators = async (): Promise<void> =>
    {
        try 
        {
            const moderators = await getAllEventModerators(eventId as string);
            if(moderators)
            {
                setModerators(moderators);
            }
        } 
        catch (error) 
        {
            console.log(error);   
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    useEffect(() => 
    {
        fetchModerators();
    }, []);

    useEffect(() =>
    {
        if(refresh)
        {
            fetchModerators();
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <div id="eventModeratorSettings">
            <div className="event-moderator-settings">
                <div className="title-description-container">
                    <h3 className="title">{'Manage Members'}</h3>
                    <p className="description">{''}</p>
                </div>
                <div className="add-member-container">
                    <CustomButton 
                        name="+ Add Members"
                        btnType="secondary"
                        onClick={handleAddModerator}
                    />
                    <div className="moderator-table-container">
                        <TanstackTable 
                            initialColumns={columns}
                            data={moderators}
                            hidePagination
                            showNoRowsOverlay
                            rowCount={moderators?.length}
                            showSpinner={showSpinner}
                            // height="362px"
                            rightPinnedColumns={['actions']}
                            showBottomPadding={false}
                        />
                    </div>
                </div>
            </div>

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Remove' 
                        acceptClick={handleRemoveModerator} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                        }} 
                        modalContent={`Are you sure you want to remove ${selectedModerator?.firstName} from moderators ?`}
                        modalTitle='Remove Moderator'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
            }
        </div>
    );
};

export default EventModeratorSettings;