import { ITransactionalMarketingPayload, MarketingPayload } from '../../components/Marketing/IMarketingComponent';
import { EventMarketing, EventMarketingAnalytics, EventMarketingEmailBody } from '../../pages/Events/interfaces';
import { EventMarketingType } from '../../pages/Events/enum';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';
import { ActivityLogResponse } from '../../interfaces/activity-log_interface';
import { EventRegistrantActivityObj } from '../../pages/Events/interfaces/event-registrant_interface';

// GET methods

export const getAllEventMarketingCount = async (eventId: string | number, type?: EventMarketingType, queryParams: [string, string][] = []): Promise<number> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/count?type='+type + '&' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getAllEventMarketing = async (pageSize: number, offset: number, eventId: string | number, type?: number, queryParams: [string, any][] = []): Promise<EventMarketing[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        type,
        ...filteredParams
    })
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventMarketingById = async (eventId: string | number, marketingId: string | number): Promise<EventMarketing> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/' + marketingId;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventMarketingByType = async (eventId: string | number, emailType: EventMarketingType): Promise<EventMarketing> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '?&pageSize=' + 10 + '&offset=' + 0 +'&type=' + emailType;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventMarketingActivityLogs = async (eventId: string | number, marketingId: string | number): Promise<ActivityLogResponse[]> =>
{
    const query = convertJSONToGETParams({
        marketingId
    })

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/activity-logs' + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventMarketingAnalytics = async (marketingId: string | number): Promise<EventMarketingAnalytics> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + marketingId + '/analytics'; 
    return triggerApi(API_URL, 'GET', '');
};

export const getEventMarketingRegistrantActivityCount = async (marketingId: string | number, email?: string): Promise<number> =>
{
    const query = convertJSONToGETParams({
        email
    })

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + marketingId + '/registrant-activity/count?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventMarketingRegistrantActivity = async (marketingId: string | number, pageSize: number, offset: number, email?: string): Promise<EventRegistrantActivityObj[]> =>
{
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        email
    })

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + marketingId + '/registrant-activity?' + query;
    return triggerApi(API_URL, 'GET', '');
};

// POST methods

export const testMarketingEmail = async (eventId: string | number, marketingMail: EventMarketingEmailBody, csrfToken: string): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/marketing/test';
    return triggerApi(API_URL, 'POST', marketingMail, csrfToken);
};

export const createMarketing = async (data: any, eventId: string | number, csrfToken: string): Promise<EventMarketing> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/marketing';
    return triggerApi(API_URL, 'POST', data, csrfToken);
};

// PUT methods

export const updateMarketing = async (data: any, eventId: string | number, marketingId: string | number): Promise<EventMarketing> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/' + marketingId + '/marketing';
    return triggerApi(API_URL, 'PUT', data);
};

export const updateTransactionalMarketing = async (data: ITransactionalMarketingPayload, eventId: string | number, marketingId: string | number): Promise<EventMarketing> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/' + marketingId + '/transactional';
    return triggerApi(API_URL, 'PUT', data);
};

// DELETE methods

export const deleteMarketing = async (marketingId: string | number, eventId: string | number): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-marketing/' + eventId + '/' + marketingId + '/marketing';
    return triggerApi(API_URL, 'DELETE', '');
};
