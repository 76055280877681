import { DomainAuth } from "../../pages/Settings/interface/domain_interface";
import APP_CONSTANTS from "../constants";
import { triggerApi } from "../helpers";

// GET methods

export const getDnsRecords = async (): Promise<DomainAuth> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/email-integration/dns-records';
    return triggerApi(API_URL, 'GET', '');
};

export const validateDomain = async (): Promise<DomainAuth> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/email-integration/validate-domain';
    return triggerApi(API_URL, 'GET', '');
};

// POST methods

export const createEmailIntegration = async (data: any, csrfTokenData: string): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/email-integration';
    return triggerApi(API_URL, 'POST', data, csrfTokenData);
};

// DELETE methods

export const deleteEmailIntegration = async (): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/email-integration';
    return triggerApi(API_URL, 'DELETE', '');
};
