import { useEffect, useState } from "react";
import { EventRegistrantActivityObj } from "../../../../pages/Events/interfaces/event-registrant_interface";
import { createColumnHelper } from "@tanstack/react-table";
import { useTablePagination } from "../../../../contexts/TablePaginationContext";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import { MailWebhookRequest } from "../../../../pages/Events/interfaces/mail-webhook_interface";
import moment from "moment";
import _, { debounce } from "lodash";
import { getEventMarketingRegistrantActivity, getEventMarketingRegistrantActivityCount } from "../../../../scripts/apis/eventMarketing";
import toast from "react-hot-toast";
import FormTabs from "../../../../common/FormTabs";
import { FormControlComponent } from "../../../../common/FormComponents/ReusableFormComponents";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";

import './styles.scss';

const EmailRecipients: React.FC<{ marketingId: string | number }> = ({ marketingId }) => {

    const [activityCount, setActivityCount] = useState<number>(0);
    const [searchInput, setSearchInput] = useState<string>('');
    const [registrantActivty, setRegistrantActivity] = useState<EventRegistrantActivityObj[]>([]);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const columnHelper = createColumnHelper<EventRegistrantActivityObj>();

    const registrantMarketingActivityCols = [
        columnHelper.accessor("email", {
            header: "Recipients",
            id: "recipients",
            cell: (info) => {
                return (
                    <>
                        {info.row.depth == 0 &&
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                {info?.row?.subRows?.length > 0 && info.row.getCanExpand() && (
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        info.row.getToggleExpandedHandler()();
                                    }}>

                                        {info.row.getIsExpanded() ?
                                            (
                                                <CustomButton
                                                    startIcon={<FontAwesomeIcon style={{ height: '14px', width: '14px' }} icon={['fal', 'chevron-down']} />}
                                                    name=''
                                                    btnType='tertiary'
                                                />
                                            ) : (
                                                <CustomButton
                                                    startIcon={<FontAwesomeIcon style={{ height: '14px', width: '14px' }} icon={['fal', 'chevron-right']} />}
                                                    name=''
                                                    btnType='tertiary'
                                                />
                                            )
                                        }
                                    </div>
                                )}
                                <p style={{
                                    paddingLeft: info?.row?.subRows?.length > 0 ? '' : '28px'
                                }} className="bolded-cellContent">{`${info.row.original.email}`}{info?.row?.subRows?.length > 0 ? <MuiChip rounded chipColor="grey" label={info?.row?.subRows?.length} /> : ''}</p>
                            </div>
                        }
                    </>
                );
            },
            minSize: 300,
            meta: {
                isExpander: true
            }
        }),
        columnHelper.accessor("action" as any, {
            header: "Action",
            id: "action",
            minSize: 150,
            cell: (info) => {
                const isSubRow = info.row.depth > 0;
                if (isSubRow) {
                    const rowData = info.row.original as unknown as MailWebhookRequest;
                    return (
                        <p className="cellContent">{_.capitalize(rowData?.event)}</p>
                    )
                }
            }
        }),
        columnHelper.accessor("date" as any, {
            header: "Date",
            id: "date",
            minSize: 200,
            cell: (info) => {
                const isSubRow = info.row.depth > 0;
                if (isSubRow) {
                    const rowData = info.row.original as unknown as MailWebhookRequest;
                    return (
                        <p className="cellContent">{moment.unix(rowData.timestamp)?.format('MMMM DD, YYYY hh:mm A')}</p>
                    )
                }
            }
        }),
    ];

    const delayDebounceRegistrantSearch = debounce(async (newValue: string): Promise<void> => {
        if (newValue?.length > 2) {
            fetchCount(newValue);
            fetchRegistrantMarketingActivity(newValue);
        }
    }, 300);

    const handleActivitySearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;

        setSearchInput(value);

        if (value === '') {
            fetchCount();
            fetchRegistrantMarketingActivity();
        }
        else {
            delayDebounceRegistrantSearch(value);
        }
    };

    const fetchCount = async (email?: string) => {
        try {
            const registrantActivityCount = await getEventMarketingRegistrantActivityCount(marketingId, email);
            if (registrantActivityCount) {
                setActivityCount(registrantActivityCount);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const fetchRegistrantMarketingActivity = async (email?: string) => {

        // setting the spinner to true only if the email is not present as triggering it everytime during search is causing a flicker
        if(!email)
        {
            setShowSpinner(true);
        }

        try {
            const registrantsMarketingActivity = await getEventMarketingRegistrantActivity(marketingId, pageSize, currentPage - 1, email);
            if (registrantsMarketingActivity) {
                setRegistrantActivity(registrantsMarketingActivity);
            }
        }
        catch (error) {
            console.log(error);
            toast.error((error as Error)?.message || 'Error in fetching activities');
        }
        finally {
            setShowSpinner(false);
        }
    };

    useEffect(() => {
        fetchCount();
        fetchRegistrantMarketingActivity();
    }, []);

    return (
        <div id="emailRecipients">
            <div className="marketing-registrant-activity">
                <h3 className="heading">{'Email Recipients'}</h3>

                <div className="registrant-activity-table-container">
                    <div className="activity-tabs-search-input-container">
                        <FormTabs
                            tabs={[
                                {
                                    tabName: 'Email',
                                    selectedTab: true,
                                    count: activityCount,
                                    navigation: () => { }
                                }
                            ]}
                        />
                        <div className="activity-search-input-container">
                            <FormControlComponent
                                type="email"
                                placeholder="Search with an email"
                                value={searchInput}
                                onChange={(event) => handleActivitySearch(event)}
                                clearable
                                startIcon='search'
                            />
                        </div>
                    </div>
                    <div className="marketing-activity-table">
                        <TanstackTable
                            data={registrantActivty}
                            initialColumns={registrantMarketingActivityCols}
                            subRowKey="activity"
                            rowCount={activityCount}
                            pageSize={pageSize}
                            currentPage={currentPage - 1}
                            updatePageSize={updatePageSize}
                            updateCurrentPage={updateCurrentPage as any}
                            showSpinner={showSpinner}
                            showBottomPadding={false}
                            height="400px"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EmailRecipients;