import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import { CheckoutStep } from '../../../../common/Stepper';
import TablePopupTabs from '../../../../common/TablePopup/TablePopupTabs';
import { EventCheckInNotificationsSource } from '../../../Events/enum/event-check-in-notifications.enum';
import NotificationStepper from '../../../Events/EventDetails/Accesscontrol/Components/Notifications/NotificationsStepper';
import MessageNotification from '../../../Events/EventDetails/Accesscontrol/Components/Notifications/StepperComponents/MessageNotification';
import { EventCheckInNotifications } from '../../../Events/interfaces/event-check-in-notifications.interface';
import { EventRegistrant } from '../../../Events/interfaces/event-registrant_interface';
import { BuyerPersona } from '../../../Settings/interface/buyer-persona_interface';
import { ICP } from '../../../Settings/interface/icp_interface';
import { addSponsorPortalNotifications, deleteSponsorPortalNotifications, updateSponsorPortalNotifications } from '../../../../scripts/apis/sponsorPortal/sponsorPortal';
import PortalOptionNotifications from '../../Components/Notifications/PortalOptionNotifications';
import PortalTriggerNotification from '../../Components/Notifications/PortalTriggerNotifications';
import PortalDeliveryNotifications from '../../Components/Notifications/PortalDeliveryNotifications';
import { AudienceUser } from '../../../Audience/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeletePopup from '../../../../common/DeletePopup';

interface AddCheckInNotificationsProps {
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  checkInNotifications: EventCheckInNotifications | undefined;
  handleDrawerClose: () => void;
}

const AddNotifications: React.FC<AddCheckInNotificationsProps> = ({ setRefresh, checkInNotifications, handleDrawerClose }): JSX.Element => {
  
  const [notificationSource, setNotificationSource] = useState<EventCheckInNotificationsSource>(EventCheckInNotificationsSource.TAREGET_LIST);
  const [icpData, setIcpData] = useState<ICP[]>([]);
  const [bpData, setBpData] = useState<BuyerPersona[]>([]);
  const [viewMode, setViewMode] = useState<'tabs' | 'stepper'>(checkInNotifications ? 'tabs' : 'stepper')

  const [registrants, setRegistrants] = useState<EventRegistrant[]>([]);
  const [sponsorPortalRegistrants, setSponsorPortalRegistrants] = useState<EventRegistrant[] | AudienceUser[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);

  const [messageTitle, setMessageTitle] = useState<string>('');
  const [messageContent, setMessageContent] = useState<string>('');

  const [currentStep, setCurrentStep] = useState(1);
  const [isComplete, setIsComplete] = useState(false);

  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

  const validateMessage = async () => 
  {
    if (messageTitle === '') {
      toast.error('Please enter a message title');
      return false;
    }
    if (messageContent === '') {
      toast.error('Please enter a message content');
      return false;
    }
    return true;
  } 

  const validateNotificationTo = async () => 
  {
    if (userIds.length === 0) {
      toast.error('Please select at least one user');
      return false;
    }
    return true;
  }

  const submitCheckInNotification = async () => {
    try {
      const checkInNotification: EventCheckInNotifications = {
        title: messageTitle,
        messageContent: messageContent,
        source: notificationSource,
        registrantIds: registrants.map((registrant) => registrant.id),
        sponsorPortalRegistrantIds: userIds,
        filters: 
        {
          icp: icpData.map((icp) => icp.id.toString()),
          bp: bpData.map((bp) => bp.id.toString()),
        },
      };

      if(checkInNotifications?.id)
      {
        await updateSponsorPortalNotifications(Number(checkInNotifications.id), checkInNotification);
      }
      else
      {
        await addSponsorPortalNotifications(checkInNotification);
      }
      handleDrawerClose();
      setRefresh(true);

    } catch (error) {
      console.error('Error submitting Check In Notification:', error);
      throw error;
    }
  };


  const updateCheckInNotification = async () => {
    try {

      if(checkInNotifications)
      {
        const checkInNotification: EventCheckInNotifications = {
          title: messageTitle ? messageTitle : checkInNotifications?.title,
          messageContent: messageContent ? messageContent : checkInNotifications?.messageContent,
          source: notificationSource ? notificationSource : checkInNotifications?.source,
          registrantIds: registrants ? registrants.map((registrant) => registrant.id) : checkInNotifications?.registrantIds,
          userIds: userIds ? userIds : checkInNotifications?.userIds,
          filters: {
            icp: icpData.length > 0 ? icpData.map((icp) => icp.id.toString()) : checkInNotifications?.filters?.icp,
            bp: bpData.length > 0 ? bpData.map((bp) => bp.id.toString()) : checkInNotifications?.filters?.bp,
          },
        };

        await updateSponsorPortalNotifications(Number(checkInNotifications.id), checkInNotification);
        handleDrawerClose();
        setRefresh(true);
     }
    } catch (error) {
      console.error('Error submitting Check In Notification:', error);
      throw error;
    }
  };

  const handleDeleteCheckInNotification = async (): Promise<void> =>
    {
        try 
        {
            if(checkInNotifications && checkInNotifications.id)
            {
              const checkInNotificationDeleted = await deleteSponsorPortalNotifications(Number(checkInNotifications?.id));
              if(checkInNotificationDeleted)
              {
                  setRefresh(true);
                  handleDrawerClose();
                  toast.success('Check In Notifcation Deleted Successfully');
              }
            }
        } 
        catch (error) 
        {
            console.error(error);
        }
    };
  
  const CHECKOUT_STEPS: CheckoutStep[] = [
    {
      name: "Filters",
      Component:
      <PortalOptionNotifications
          icpData={icpData}
          setIcpData={setIcpData}
          bpData={bpData}
          setBpData={setBpData}
          notificationSource={notificationSource}
          setNotificationSource={setNotificationSource}
          checkInNotifications={checkInNotifications}
        />
    },
    {
      name: "Trigger",
      Component:
        <PortalTriggerNotification
          notificationSource={notificationSource}
          icpData={icpData}
          bpData={bpData}
          registrants={registrants}
          setRegistrants={setRegistrants}
          viewMode={viewMode}
        />
      
    },
    {
      name: "Notification To",
      Component: <PortalDeliveryNotifications checkInNotifications={checkInNotifications} setSponsorPortalRegistrants={setSponsorPortalRegistrants} sponsorPortalRegistrants={sponsorPortalRegistrants} userIds={userIds} setUserIds={setUserIds}/>,
      onComplete: async() => { validateNotificationTo();}
    },
    {
      name: "Message",
      Component: <MessageNotification checkInNotifications={checkInNotifications} messageTitle={messageTitle} setMessageTitle={setMessageTitle}  messageContent={messageContent} setMessageContent={setMessageContent} />,
      onComplete: async () => { submitCheckInNotification(); },
    },
  ];

  const tablePopupTabData = [
    {
      title: 'Trigger',
      data: <PortalTriggerNotification onTriggerButtonClick={() => setViewMode('stepper')} checkInNotifications={checkInNotifications} registrants={registrants} setRegistrants={setRegistrants} viewMode={viewMode} />
    },
    {
      title: 'Notification To',
      data: <PortalDeliveryNotifications checkInNotifications={checkInNotifications} setSponsorPortalRegistrants={setSponsorPortalRegistrants} sponsorPortalRegistrants={sponsorPortalRegistrants} userIds={userIds} setUserIds={setUserIds} viewMode={viewMode} />
    },
    {
      title: 'Message',
      data: <MessageNotification checkInNotifications={checkInNotifications} messageTitle={messageTitle} setMessageTitle={setMessageTitle}  messageContent={messageContent} setMessageContent={setMessageContent} />,
    }
  ];

  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
    setIsComplete(false);
  };

  const handleNext = async () => 
  {

    if(currentStep === 3)
    {
        const res = await validateNotificationTo();
        if(!res)
        {
            return ;
        }
    }
    if(currentStep === 4)
    {
        const res = await validateMessage();
        if(!res)
        {
            return ;
        }
    }

    if (currentStep === CHECKOUT_STEPS.length) {
        submitCheckInNotification();
        setIsComplete(true);
    } else {
        setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  return (
    <div id='addPortalNotification' style={{ paddingTop: '24px' }}>
      {viewMode === 'tabs' ? (
        <div>
          <div className='side-drawer-notification'>
           <TablePopupTabs tabData={tablePopupTabData} onPopupClose={() => { }} />

           <div className="delete-tab-container">
              <div className="button-group">
                  <CustomButton
                      name="Cancel"
                      btnType={"secondary"}
                      onClick={handleDrawerClose}
                      style={{ marginRight: "10px" }}
                  />
                  <CustomButton
                      name={"Save"}
                      btnType={"primary"}
                      onClick={updateCheckInNotification}
                  />
              </div>
              <FontAwesomeIcon className="sidebar-delete-icon" icon={['fal', 'trash']} onClick={() => setShowDeletePopup(true)} />
          </div>
          </div>
          {showDeletePopup && (
                <DeletePopup
                    acceptBtn="Delete"
                    acceptClick={() =>
                      handleDeleteCheckInNotification()
                    }
                    cancelClick={() => {
                        setShowDeletePopup(false);
                    }}
                    modalContent={`Are you sure you want to delete ${checkInNotifications?.title}?`}
                    modalTitle="Delete Check In Notification"
                    show={showDeletePopup}
                    rejectBtn="Cancel"
                    modalHeaderIcon='trash'
                />
            )}
        </div>
      ) : (
        <>
        <NotificationStepper
            props={CHECKOUT_STEPS}
            buttonPosition='bottom'
            currentStep={currentStep}
            isComplete={isComplete}
        />
        {!isComplete && (
            <div className={`button-tab-container`}> 
                <CustomButton
                    name="Back"
                    btnType={"secondary"}
                    onClick={handleBack}
                    disabled={currentStep === 1}
                    style={{ marginRight: "auto" }}
                />
                <CustomButton
                    name={currentStep === CHECKOUT_STEPS.length ? "Finish" : "Next"}
                    btnType={"primary"}
                    onClick={handleNext}
                />
            </div>
        )}
      </>
      )}
    </div>
  );
}

export default AddNotifications;