import { ActivityLogResponse } from '../../interfaces/activity-log_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const getAllUtmCount = async (eventId: string | number, queryParams: [string, string][] = []) => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-utm/' + eventId + '/' + 'count' + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getAllUtm = async (pageSize: number, offset: number, eventId: string | number, queryParams: [string, any][] = []) => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-utm/' + eventId + '?&pageSize=' + pageSize + '&offset=' + offset + '&' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getUtmById = async (utmId: number, eventId: string | number) => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-utm/' + eventId + '/' + utmId;
    return triggerApi(API_URL, 'GET', '');
};

export const getUtmActivityLogs = async (eventId: string | number, utmId: string | number): Promise<ActivityLogResponse[]> =>
{
    const query = convertJSONToGETParams({ utmId });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-utm/' + eventId + '/activity-logs?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const createUtm = async (UTM_DATA: any, eventId: string | number, csrfToken: string) => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-utm/' + eventId;
    return triggerApi(API_URL, 'POST', UTM_DATA, csrfToken, '');
};

export const updateUtm = async (UTM_DATA: any, UTM_ID: number, eventId: string | number) => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-utm/' + eventId + '/' + UTM_ID;
    return triggerApi(API_URL, 'PUT', UTM_DATA);
};

export const deleteUtm = async (UTM_ID: number, eventId: string | number) => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-utm/' + eventId + '/' + UTM_ID;
    return triggerApi(API_URL, 'DELETE', '');
};
