import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useTablePagination = ():{pageSize: number;
  updatePageSize: (value: number|string) => void;
  currentPage: number;
  updateCurrentPage: (value: number|string) => void;} => 
{
    const [pageSize, setPageSize] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRefresh, setPageRefresh] = useState(false);

    const currentLocation = useLocation();

    useEffect(():void => 
    {
        const searchParams = new URLSearchParams(currentLocation.search);
        if (Array.from(searchParams).length > 0) {
            setCurrentPage(1);
        }
        setPageRefresh(true);
    }, [currentLocation]);

    useEffect(():void => 
    {
        if (pageRefresh) 
        {
            setCurrentPage(1);
            setPageSize(pageSize);
            setPageRefresh(false);
        }
    }, [pageRefresh]);

    const updatePageSize = (value):void => 
    {
        setPageSize(value);
    };

    const updateCurrentPage = (value):void => 
    {
        setCurrentPage(value);
    };

    return {
        pageSize, updatePageSize, currentPage, updateCurrentPage 
    };
};

