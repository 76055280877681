import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import toast from "react-hot-toast";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

import './styles.scss';

import mailIcon from '../../../../assets/icons/envelope-light.svg';
import twitterIcon from '../../../../assets/icons/twitter-icon.svg';
import linkedInIcon from '../../../../assets/icons/linkedin-solid.svg';

import { EventSession } from "../../../Events/interfaces";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
// import FeedbackTable from "../PortalFeedbackTable";
import SessionMaterialList from "./SessionMaterials/SessionMaterialsList";

interface PortalAgendaDetailedProps { 
    sessionData: EventSession;
    setViewType: React.Dispatch<React.SetStateAction<'agenda' | 'detailed'>>;
}

const PortalAgendaDetailed: React.FC<PortalAgendaDetailedProps> = (props): React.JSX.Element =>
{

    const { sessionData, setViewType } = props;

    const SessionCard = (): React.JSX.Element =>
    {
        return (
            <div className="session-card">
                <div className="session-image">
                    <img 
                        src={sessionData?.image || ''}
                        alt={sessionData?.imageAlternateText || 'session-image'}
                    />
                </div>
                <div className="session-details-container">
                    <div className="session-stage-timing-container">
                        <h3 className="session-stage-title">{sessionData?.stage?.title || 'Main Stage'}</h3>
                        <li className="session-timing">{`${moment.unix(Number(sessionData?.startDateTime)).format('hh:mm A')} - ${moment.unix(Number(sessionData?.endDateTime)).format('hh:mm A')}`}</li>
                        <li className="session-date">{`${moment.unix(Number(sessionData?.startDateTime)).format('DD MMM YYYY')}`}</li>
                    </div>
                    <h3 className="session-title">{sessionData?.title}</h3>
                    <p className="session-description">{sessionData?.description}</p>
                </div>
            </div>
        )
    };

    const SpeakersCard = (): React.JSX.Element =>
    {
        return (
            <div className="speakers-card-container">
                <h2 className="title">{'Speakers'}</h2>
                <Row>
                    {
                        sessionData?.speakers?.map((speaker) => {

                            let speakerSocialsArr: { icon: string, link: string }[] = [];
                            speakerSocialsArr?.unshift({
                                icon: mailIcon,
                                link: speaker?.email
                            })

                            if (speaker?.additionalInfo?.social?.twitter) {
                                speakerSocialsArr.push({
                                    icon: twitterIcon,
                                    link: speaker?.additionalInfo?.social?.twitter
                                });
                            }
                            if (speaker?.additionalInfo?.social?.linkedIn) {
                                speakerSocialsArr.push({
                                    icon: linkedInIcon,
                                    link: speaker?.additionalInfo?.social?.linkedIn
                                });
                            }

                            const speakerSocials = 
                            (
                                <div className="speaker-socials-icons">{speakerSocialsArr.map((social, index) => {
                                        return <Box component={'img'} key={index} src={social.icon} height={'1rem'} width={'1rem'} onClick={(event) => {
                                            if (social.link !== speaker?.email) {
                                                window.open(social.link, '_blank');
                                                event.stopPropagation();
                                            }
                                            else {
                                                navigator.clipboard.writeText(`${speaker.email}`);
                                                toast.success('Email copied to clipboard');
                                                event.stopPropagation();
                                            }

                                            }} />
                                        })}
                                </div>
                            );

                            return (
                                <Col style={{ marginBottom: '1rem' }} xl={4} lg={4} md={4} sm={6} xs={6} key={speaker.id}>
                                    <div className="speaker-card">
                                        <div className="speaker-image">
                                            {
                                                speaker?.additionalInfo?.image ? 
                                                    <img 
                                                        src={speaker?.additionalInfo?.image}
                                                    />
                                                    :
                                                    <div className="default-speaker-avatar">
                                                        {speaker?.name?.charAt(0)}
                                                    </div>
                                            }
                                        </div>
                                        <div className="speaker-info-container">
                                            <h3 className="speaker-name">{speaker?.name}</h3>
                                            <h3 className="speaker-headline-info">{`${speaker?.additionalInfo?.headline} ${speaker?.clearbitData && speaker?.clearbitData?.company ? ', ' + speaker?.clearbitData?.company : ''}`}</h3>
                                            <div className="speaker-socials">
                                                {speakerSocials}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        )
    };

    return (
        <div id="portalAgendaDetailedView">
            <div className="portal-agenda-session-container">
                <div className="session-back-button" onClick={() => setViewType('agenda')}>
                    <CustomButton 
                        btnType="tertiary"
                        name="Back"
                        startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />}
                    />
                </div>

                <SessionCard />
            </div>

            {
                sessionData?.speakerIds && sessionData?.speakerIds?.length > 0 && <SpeakersCard />
            }

            <SessionMaterialList sessionId={sessionData?.id} />
            {/* <FeedbackTable /> */}
        </div>
    );
};

export default PortalAgendaDetailed;