import React, { useEffect, useRef, useState } from 'react';
import FormTabs from '../FormTabs';
import TrendsComponent, { ITrendsBlock } from '../TrendsComponent';
import './styles.scss';

interface IMinimalViewComponentProps { 
    title: string | React.ReactNode;
    description?: string | React.ReactNode;
    titleBarActions?: React.ReactNode[];
    tabData?: { tabName: string, count: string | number, navigation: () => void, selectedTab: boolean }[];
    actions?: React.ReactNode[];
    showCharts?: boolean;
    trendData?: ITrendsBlock[];
    component?: React.ReactNode;
    componentType?: 'table' | 'list';
    headerActions?: React.ReactNode;
    componentOverflow?: 'scroll' | 'hidden' | 'auto';
    titleBarStyle?: React.CSSProperties;
}

export const MinimalViewComponent: React.FC<IMinimalViewComponentProps> = (props): React.JSX.Element => { 

    const { title, titleBarActions, tabData, actions, showCharts, trendData, componentType = 'table', headerActions, componentOverflow, titleBarStyle } = props;

    const titleBarRef = useRef<HTMLDivElement>(null);
    const chartsContainerHeight = showCharts ? 108 : 0;
    const tabsActionsRef = useRef<HTMLDivElement>(null);
    const eventNavbarHeight = 60;

    const isEventsModule = window.location.pathname.includes('events') && !window.location.pathname.endsWith('events');
    const isSettingsModule = window.location.pathname.includes('settings');

    const componentHeight = (isEventsModule || isSettingsModule) ? eventNavbarHeight + Number(titleBarRef?.current?.clientHeight) + chartsContainerHeight + Number(tabsActionsRef?.current?.clientHeight) + 16 : Number(titleBarRef?.current?.clientHeight) + chartsContainerHeight + Number(tabsActionsRef?.current?.clientHeight) + 16;

    const [isComponentMounted, setIsComponentMounted] = useState<boolean>(false);

    useEffect(() => {
        setIsComponentMounted(true);
    }, []);

    return (
        <div id="minimalViewComponent">
            <div className="minimal-view-component-container">
                {/* title bar */}
                <div style={titleBarStyle} className="title-bar-container" ref={titleBarRef}>
                    {headerActions && (
                        <div className="header-actions">
                            {headerActions}
                        </div>
                    )}
                    <div className="title-bar">
                        {/* title and description */}
                        <div className="title-description">
                            {typeof(title) === 'string' ? <h1 className="title">{title}</h1> : title}
                        </div>
                        {/* title bar actions */}
                        <div className="title-bar-actions">
                            {titleBarActions}
                        </div>
                    </div>
                </div>

                <div style={{ visibility: showCharts ? 'visible' : 'hidden' }} className={`charts-container ${showCharts ? 'show' : ''}`}>
                    <TrendsComponent
                        Trends={trendData || []}
                        view={'horizontal'}
                    />
                </div>

                {/* tabs, search, filter and other actions */}
                {(actions || tabData) && (
                    <div ref={tabsActionsRef} className="minimal-view-actions" style={{ top: titleBarRef.current?.clientHeight || 0, borderBottom: componentType === 'list' ? '1px solid #F2F4F7' : '' }}>
                        {/* tabs */}
                        {tabData && <FormTabs tabs={tabData || []} />}

                        {/* action buttons */}
                        {actions && (
                            <div className="action-buttons">
                                {actions}
                            </div>
                        )}
                    </div>
                )}

                {/* component */}
                {
                    isComponentMounted &&
                    <div style={{ overflow: componentOverflow || '', height: `calc(100vh - ${componentHeight}px)` }} className="component">
                        {props.component}
                    </div>
                }
            </div>
        </div>
    );
};