export enum EventAccessControlStatus
{
    ACTIVE = 1,
    DELETED = 2
}

export enum EnableSelfCheckin
{
    ENABLED = 1,
    DISABLED = 2
}



