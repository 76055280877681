import { useEffect, useRef, useState } from 'react';
import './styles.scss';
import EmailEditor, { EditorRef } from 'react-email-editor';
import { EmailMarketingFormData } from '../../../pages/Events/interfaces';
import { FormikErrors } from 'formik';

interface EmailDesignProps {
    formValues: EmailMarketingFormData;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<EmailMarketingFormData>>;
}

const EmailMarketingDesign: React.FC<EmailDesignProps> = (props): React.JSX.Element => {

    const { formValues, setFieldValue } = props;

    const emailEditorRef = useRef<EditorRef>(null);
    const [isReady, setReady] = useState(false);

    const mergeTags = {
        org_name: {
            name: 'Organisation Name',
            value: "{{orgName}}"
        },
        event_name: {
            name: 'Event Name',
            value: "{{eventName}}"
        },
        first_name: {
            name: 'First Name',
            value: "{{firstName}}"
        },
    };

    const convertStringBooleansToActualBooleans = (obj: any): any => {
        if (typeof obj !== 'object' || obj === null) {
          return obj;
        }
      
        for (const key in obj) {
          if (typeof obj[key] === 'string') {
            if (obj[key].toLowerCase() === 'true') {
              obj[key] = true;
            } else if (obj[key].toLowerCase() === 'false') {
              obj[key] = false;
            }
          } else if (typeof obj[key] === 'object') {
            convertStringBooleansToActualBooleans(obj[key]);
          }
        }
      
        return obj;
    };

    const onReady = () => {
        if (!isReady) {
            setReady(true);
            if (
                formValues?.marketingJson && Object.keys(formValues?.marketingJson)?.length &&
                emailEditorRef?.current?.editor
            ) {
                emailEditorRef?.current?.editor?.loadDesign(typeof formValues?.marketingJson === 'string' ? convertStringBooleansToActualBooleans(JSON.parse(formValues?.marketingJson)) : formValues?.marketingJson);
            }
        }
    };

    const updateUnlayerContent = async () =>
    {
        if (isReady && emailEditorRef?.current) {
            emailEditorRef.current?.editor?.addEventListener(
                "design:updated",
                function (data: any) {
                    emailEditorRef.current?.editor?.exportHtml((data: any) => {
                        const { design, html } = data;
                        setFieldValue("marketingJson", design);
                    });
                }
            );

            const unlayer = emailEditorRef.current?.editor;
            const result = await new Promise<{ design: any, html: string }>((resolve) => {
                unlayer?.saveDesign((design: any) => {
                    unlayer?.exportHtml((data) => {
                    const { design: htmlDesign, html } = data;
            
                    resolve({
                        design,
                        html,
                    });
                    });
                });
            });
            setFieldValue("emailContent", result?.html);
        }
    };

    useEffect(() => {
        updateUnlayerContent();
    }, [emailEditorRef, isReady, formValues]);

    return (
        <div id="emailDesign">
            <div className="email-design-container">
                <div className="email-design-header">
                    <h3 className="heading">{'Email Design'}</h3>
                </div>
                <div className="editor-container">
                    <EmailEditor ref={emailEditorRef} onReady={onReady} onLoad={onReady} options={{ mergeTags }} />
                </div>
            </div>
        </div>
    );
};

export default EmailMarketingDesign;