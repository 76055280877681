import { EventAccessControl, RefreshOtp } from '../../pages/Events/interfaces/event-access-control_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const getAllEventAccessControlCount = async (eventId: string | number, queryParams: [string, string][] = []): Promise<number> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-access-control/' + eventId + '/count?'+query;
    return triggerApi(API_URL, 'GET', '');
};

export const getAllEventAccessControl = async (pageSize: number, offset: number, eventId: string | number, queryParams: [string, string][]): Promise<EventAccessControl[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-access-control/' + eventId + '?&pageSize=' + pageSize + '&offset=' + offset + '&' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventAccessControlById = async (eventId: string | number, accessControlId: string | number): Promise<EventAccessControl> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-access-control/' + eventId + '/' + accessControlId;
    return triggerApi(API_URL, 'GET', '');
};

export const createEventAccessControl = async (data, eventId: string | number, csrfToken: string): Promise<EventAccessControl> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-access-control/' + eventId;
    return triggerApi(API_URL, 'POST', data, csrfToken);
};

export const updateEventAccessControl = async (data, eventId: string | number, accessControlId: string | number): Promise<EventAccessControl> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-access-control/' + eventId + '/' + accessControlId;
    return triggerApi(API_URL, 'PUT', data);
};

export const deleteEventAccessControl = async (eventId: string | number, accessControlId: number): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-access-control/' + eventId + '/' + accessControlId;
    return triggerApi(API_URL, 'DELETE', '');
};

export const refreshAccessControlPin = async (eventId: string | number, accessControlId: string | number): Promise<RefreshOtp> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-access-control/' + eventId + '/' + accessControlId + '/refresh-otp';
    return triggerApi(API_URL, 'GET', '');
};


export const disableAccess = async (eventId: string | number, accessControlId: string | number): Promise<Boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-access-control/' + eventId + '/' + accessControlId + '/disable';
    return triggerApi(API_URL, 'PUT', '');
};

export const createBadgeSettings = async (eventId: string | number, data: any): Promise<Boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-access-control/' + eventId + '/badge-setting';
    return triggerApi(API_URL, 'PUT', data);
}

export const updateVCardSetting = async (eventId: string | number, vCardSetting: any): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-access-control/' + eventId + '/vcard-setting';
    return triggerApi(API_URL, 'PUT', vCardSetting);
}
