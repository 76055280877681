import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SectionCardProps {
    title: string;
    onCloseButtonClick?: () => void;
    cardContent: React.ReactNode;
}

const SectionCard: React.FC<SectionCardProps> = (props): React.JSX.Element =>
{

    const { title, onCloseButtonClick, cardContent } = props;

    return (
        <div id="sectionCard">
            <div className="section-card-container">
                <div className="section-card-header">
                    <h3 className="section-card-title">{title}</h3>
                    {
                        onCloseButtonClick &&
                        <button className="card-close-button" onClick={() => onCloseButtonClick()}>
                            <FontAwesomeIcon icon={['fal', 'xmark']} />
                        </button>
                    }
                </div>
                <div className="section-card-content">
                    {cardContent}
                </div>
            </div>
        </div>
    )
};  

export default SectionCard;