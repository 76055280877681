import React from 'react';
import './styles.scss';

interface NestedDrawerProps {
    open: boolean;
    children?: React.ReactNode;
    right?: string;
}

const NestedDrawer: React.FC<NestedDrawerProps> = ({ open, children, right }) => {
    if (!open) return null;

    return (
        <div id="nestedSideDrawer">
            <div
                className="nested-drawer-container"
                style={{
                    right
                }}
            >
                {/* Content */}
                <div
                    style={{
                        flex: 1,
                        overflowY: 'auto',
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default NestedDrawer;
