import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, ColumnResizeMode, ColumnResizeDirection, getSortedRowModel, getPaginationRowModel, Column, getExpandedRowModel, ExpandedState } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomButton } from '../FormComponents/Buttons';
import CustomSpinner from '../CustomSpinner';
import _ from 'lodash';
import { BpCheckbox } from '../CheckboxWrapper';

import './styles.scss';

interface TanstackTableProps {
    data: any[];
    initialColumns: any[];
    rowCount: number;
    pageSize?: number;
    currentPage?: number;
    updateCurrentPage?: (page: number | null) => void;
    updatePageSize?: (pageSize: number) => void;
    leftPinnedColumns?: string[];
    rightPinnedColumns?: string[];
    showSpinner: boolean;
    checkboxSelection?: boolean;
    height?: string;
    hidePagination?: boolean;
    fullWidth?: boolean;
    showNoRowsOverlay?: boolean;
    onRowClick?: (row: any) => void;
    selectedRows?: string[];
    setSelectedRows?: React.Dispatch<React.SetStateAction<string[]>>;
    onCheckBoxClick?: (event: React.ChangeEvent<HTMLInputElement>, row: any) => void;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>, row: any[]) => void;
    checkBoxSelectedIds?: string[];
    subRowKey?: string;
    hideTableHeader?: boolean;
    showPageSizeOptions?: boolean;
    selectedRow?: any;
    showBottomPadding?: boolean;
}

const getCommonPinningStyles = (column: Column<any>): React.CSSProperties => {
    const isPinned = column.getIsPinned()
    // const isLastLeftPinnedColumn =
    //   isPinned === 'left' && column.getIsLastColumn('left')
    // const isFirstRightPinnedColumn =
    //   isPinned === 'right' && column.getIsFirstColumn('right')
  
    return {
        // boxShadow: isLastLeftPinnedColumn
        //     ? '-4px 0 4px -4px lightgray inset'
        //     : isFirstRightPinnedColumn
        //     ? '4px 0 4px -4px lightgray inset'
        //     : undefined,
        // borderRight: isLastLeftPinnedColumn ? '1px solid #e0e0e0' : undefined,
        // borderLeft: isFirstRightPinnedColumn ? '1px solid #e0e0e0' : undefined,
        left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
        right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
        opacity: isPinned ? 0.95 : 1,
        position: isPinned ? 'sticky' : 'relative',
        width: column.getSize(),
        zIndex: isPinned ? 1 : 0,
        // borderBottom: '1px solid #EAECF0',
        // backgroundColor: 'white',
    }
}

const TanstackTable: React.FC<TanstackTableProps> = (props) => 
{

    const { 
        initialColumns, 
        rowCount, 
        currentPage, 
        pageSize, 
        leftPinnedColumns, 
        rightPinnedColumns, 
        showSpinner, 
        checkboxSelection, 
        updateCurrentPage, 
        updatePageSize, 
        hidePagination, 
        fullWidth,
        showNoRowsOverlay,
        onRowClick,
        selectedRows,
        setSelectedRows,
        onCheckBoxClick,
        onSelectAllClick,
        checkBoxSelectedIds, 
        subRowKey,
        hideTableHeader = false, 
        showPageSizeOptions = true,
        selectedRow,
        showBottomPadding = true
    } = props;

    const paginationContainerRef = useRef<HTMLDivElement>(null);

    const columns = useMemo(() => [...initialColumns], [initialColumns]);
    const data = useMemo(() => props?.data, [props?.data]);
    const [columnResizeMode, setColumnResizeMode] = useState<ColumnResizeMode>('onChange');
    const [columnResizeDirection, setColumnResizeDirection] = useState<ColumnResizeDirection>('ltr');
    const [columnPinning, setColumnPinning] = React.useState({
        left: leftPinnedColumns || [],
        right: rightPinnedColumns || [],
    });

    const unClickableColumns = ['actions', 'copyLink'];
    
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        columnResizeMode,
        columnResizeDirection,
        manualPagination: true,
        rowCount,
        enableRowSelection: true,
        getSubRows: (row: any) => row[subRowKey || ''],
        getRowCanExpand: () => true,
        initialState: {
            pagination: {
                pageIndex: currentPage,
                pageSize: pageSize,
            },
            columnPinning,
        },
        getExpandedRowModel: getExpandedRowModel(),
        onColumnPinningChange: (leftPinnedColumns || rightPinnedColumns) ? setColumnPinning : undefined,
    });

    useEffect((): void => 
    {
        if(props.updatePageSize)
        {
            props.updatePageSize(25);
        }
        
    }, []);

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, row: any): void =>
    {
        if (selectedRows && setSelectedRows)
        {
            if(event.target.checked)
            {
                setSelectedRows([...selectedRows, row?.id]);
            }
            else
            {
                setSelectedRows(selectedRows.filter(id => id !== row?.id));
            }
        }
    };

    const CustomPagination = (): React.JSX.Element => 
    {
        const page = currentPage;
        const paginationContainerRef = useRef(null);
        const paginationCount = Math.ceil(rowCount / (pageSize || 25));
        const selectedPage = (page || 0) + 1;

        const paginationButtons: React.JSX.Element[] = [];

        if (typeof paginationCount === 'number' && paginationCount < 10000000) 
        {
            let startPage = selectedPage;
            let endPage = startPage + 5;
        
            if (endPage > paginationCount) {
                endPage = paginationCount;
                startPage = endPage - 5;
                if (startPage < 1) startPage = 1;
            }
        
            for (let i = startPage; i <= endPage; i++) 
            {
                paginationButtons.push(
                    <div key={i} className={selectedPage === i ? 'selected-pagination-button' : 'pagination-button'} onClick={(): void => 
                    {
                        updateCurrentPage ? updateCurrentPage(i) : undefined;
                        table.setPageIndex(i);
                    }}>{i}</div>
                );
            }
        }
    
        return (
            <div className="tanstack-pagination-container" ref={paginationContainerRef}>
                {/* <div className="page-indicator-component">
                    <p>{`Page ${(page || 0)+1} of ${Math.ceil(rowCount / (pageSize || 25))}`}</p>
                </div> */}
                <div className="pagination-buttons-container">
                    <CustomButton btnType='tertiary' disabled={selectedPage === 1} onClick={(): void => 
                    {
                        if (selectedPage !== 1) 
                        {
                            updateCurrentPage ? updateCurrentPage(selectedPage !== 1 ? selectedPage - 1 : null) : undefined;
                            table.setPageIndex(selectedPage - 1);
                        }
                    }} name='Prev' startIcon={<FontAwesomeIcon style={{ marginRight: '8px' }} icon={['fal', 'arrow-left']} className="navigation-icon" />} />

                    <div style={{ flexBasis: '200px', maxWidth: '200px', overflow: 'hidden' }} >
                        <div className="pagination-numbers" ref={paginationContainerRef}>
                            {paginationButtons}
                        </div>

                    </div>

                    <CustomButton disabled={selectedPage === paginationCount} btnType='tertiary' name='Next' onClick={(): void => 
                    {
                        if (selectedPage !== paginationCount) 
                        {
                            updateCurrentPage ? updateCurrentPage(selectedPage !== paginationCount ? selectedPage + 1 : null) : undefined;
                            table.setPageIndex(selectedPage + 1);
                        }
                    }} endIcon={<FontAwesomeIcon icon={['fal', 'arrow-right']} className="navigation-icon" />} />

                    {showPageSizeOptions && 
                        <select
                            className="tanstack-pagination-select"
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                                table.setPageSize(Number(e.target.value));
                                updatePageSize ? updatePageSize(Number(e.target.value)) : undefined;
                                updateCurrentPage ? updateCurrentPage(1) : undefined;
                            }}>
                            {[25, 50, 75, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize} Per Page
                                </option>
                            ))}
                        </select>
                    }
                </div>
                
            </div>
        );
    };

    const NoRowsOverlay = () => 
    {
        return (
            <div style={{ height: '100%', width: '100%', alignContent: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: '#d0d5dd' }}>
                No rows to display
            </div>
        );
    };

    return (
        <div id="tanstackTable">
            <div className="tanstack-table-container" style={{ paddingBottom: showBottomPadding ? `${paginationContainerRef?.current?.clientHeight}px` : '0' }}>
                <div style={{ direction: table.options.columnResizeDirection, overflow: 'auto', height: showSpinner ? '100%' : props?.height ? props?.height : '' }}>
                    {showSpinner ? <CustomSpinner height="100%" /> : <table 
                        className="table"
                        {...{
                            style: {
                                width: !fullWidth ? table.getCenterTotalSize() : '',
                                height: showNoRowsOverlay ? '100%' : ''
                            },
                        }}
                    >
                        {!hideTableHeader && <thead className="table-header">
                            {table.getHeaderGroups()?.map((headerGroup, index) => (
                                <tr key={index} className="table-header-row">
                                    {headerGroup.headers.map((header, idx) => (
                                        <th
                                            {...{
                                                key: header.id,
                                                colSpan: header.colSpan,
                                                style: {
                                                    width: header.getSize(),
                                                    ...getCommonPinningStyles(header.column),
                                                },
                                            }} 
                                            className="table-header-head"
                                            
                                        >
                                            <p className="column-title">
                                                {header.isPlaceholder
                                                    ? <FontAwesomeIcon icon={['fal', 'arrow-up']} className="sort-icon" />
                                                    : 
                                                    <div onClick={header.column.getToggleSortingHandler()} style={{ padding: checkboxSelection ? '2px 24px' : '10px 24px' }} className="header-wrapper">
                                                        {
                                                            (idx === 0 && checkboxSelection) &&
                                                                <BpCheckbox 
                                                                    checked={(onSelectAllClick && checkBoxSelectedIds)
                                                                        ? table?.getPrePaginationRowModel().rows.every(row => checkBoxSelectedIds.includes(row.original.id)) : (table?.getPrePaginationRowModel().rows?.length > 0 && selectedRows && selectedRows?.length === table?.getPrePaginationRowModel().rows?.length)}
                                                                    indeterminate={(onSelectAllClick && checkBoxSelectedIds)
                                                                        ? checkBoxSelectedIds.length > 0 &&
                                                                        !table?.getPrePaginationRowModel().rows.every(row => checkBoxSelectedIds.includes(row.original.id)) : selectedRows && selectedRows?.length > 0 && selectedRows?.length < table?.getPrePaginationRowModel().rows?.length}
                                                                    onChange={(event) => {
                                                                        if (setSelectedRows)
                                                                        {
                                                                            if(event.target.checked)
                                                                            {
                                                                                setSelectedRows(_.map(data, 'id'));
                                                                                return;
                                                                            }
                                                                            setSelectedRows([]);
                                                                        }
                                                                        else if (onSelectAllClick)
                                                                        {
                                                                            const originalRows = _.map(table?.getPrePaginationRowModel().rows, 'original');
                                                                            onSelectAllClick(event, originalRows);                                                                
                                                                        }
                                                                    }}
                                                                />
                                                        }
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {{
                                                            asc: <FontAwesomeIcon icon={['fal', 'arrow-up']} className="sort-icon" />,
                                                            desc: <FontAwesomeIcon icon={['fal', 'arrow-down']} className="sort-icon" />,
                                                        }[header.column.getIsSorted() as string] ?? null}
                                                    </div>
                                                }   
                                            </p>
                                            {(idx !== headerGroup?.headers?.length - 1) && 
                                                <FontAwesomeIcon icon={['fal', 'pipe']}
                                                    {...{
                                                        onDoubleClick: () => header.column.resetSize(),
                                                        onMouseDown: header.getResizeHandler(),
                                                        onTouchStart: header.getResizeHandler(),
                                                        className: `resizer ${
                                                            table.options.columnResizeDirection
                                                        } ${
                                                            header.column.getIsResizing() ? 'isResizing' : ''
                                                        }`,
                                                        style: {
                                                            transform:
                                                            columnResizeMode === 'onEnd' &&
                                                            header.column.getIsResizing()
                                                                ? `translateX(${
                                                                    (table.options.columnResizeDirection ===
                                                                    'rtl'
                                                                    ? -1
                                                                    : 1) *
                                                                    (table.getState().columnSizingInfo
                                                                    .deltaOffset ?? 0)
                                                                }px)`
                                                                : '',
                                                        },
                                                    }}
                                                />
                                            }
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>}
                        {data?.length > 0 ? 
                            <tbody className="table-body">
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id} className="table-body-row" style={{ cursor: onRowClick ? 'pointer' : 'default' }}>
                                    {row.getVisibleCells().map((cell, index) => {
                                        return (
                                            <td
                                                onClick={() => {
                                                    if((index === 0 && checkboxSelection) || unClickableColumns?.includes(cell.column.id) || (cell.column?.columnDef?.meta as { isExpander?: boolean })?.isExpander)
                                                    {
                                                        return;
                                                    } 
                                                    if (onRowClick && subRowKey) 
                                                    {
                                                        onRowClick(cell.getContext());
                                                    }
                                                    else if (onRowClick)
                                                    {
                                                        onRowClick(row.original)
                                                    }
                                                }}  
                                                key={cell.id} 
                                                className={`${cell.column?.id !== 'selection' && "table-body-data"} ${onRowClick && 'clickable'} ${onRowClick && selectedRow && selectedRow?.id === row.original?.id && 'selected-row'}`}
                                                style={{ 
                                                    ...getCommonPinningStyles(cell.column), 
                                                    width: cell.column.getSize(),
                                                }}
                                            >
                                                <div className={checkboxSelection ? 'table-body-cell-checkbox-wrapper' : ''}>
                                                    {
                                                        (index === 0 && checkboxSelection) &&
                                                            <BpCheckbox 
                                                                checked={(checkBoxSelectedIds) ? checkBoxSelectedIds.includes(table.getRow(row?.id).original?.id) : selectedRows?.includes(table.getRow(row?.id).original?.id)} 
                                                                onChange={(event) => (onCheckBoxClick) ? onCheckBoxClick(event, row?.original) : handleCheckBoxChange(event, row?.original)}
                                                            />
                                                    }
                                                    {<p style={{ maxWidth: cell.column.getSize() }}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</p>}
                                                </div>
                                            </td>
                                        )
                                    })}
                                </tr>
                                ))}
                            </tbody>
                            :
                            ((!showSpinner && showNoRowsOverlay) && <tbody className="table-body">
                                <tr className="table-body-row">
                                    <td colSpan={table.getAllColumns().length} className="table-body-data">
                                        <div className="no-rows-div">
                                            <NoRowsOverlay />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>)
                        }
                        <tfoot>
                            
                        </tfoot>
                    </table>}
                </div>
                {!showSpinner && !hidePagination && <CustomPagination />}
            </div>
        </div>
    );
};

export default TanstackTable;