import './styles.scss';

interface BreadcrumbsProps {
    origin: Array<string>;
    active: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props): React.JSX.Element =>
{

    const { origin, active } = props;

    return (
        <div id="breadcrumbsComponent">
            <ul className="breadcrumbs">
                {origin.map((component, index) => {
                    return <li key={index} className="origin">{`${component} / `}</li>
                })}
                <li className="active">{active}</li>
            </ul>
        </div>
    )
};

export default Breadcrumbs;