import { Audience } from '../../pages/Audience/interfaces';
import { EventTargetList, UpdateEventTargetList } from '../../pages/Events/interfaces/event-target-list-interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const eventTargetListCount = async (EVENT_ID: number | string, queryParams: [string, string][] = []): Promise<number> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-target-list/' + EVENT_ID + '/count' + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventTargetLists = async (EVENT_ID: number | string, pageSize: number, offset: number, queryParams: [string, any][] = []): Promise<EventTargetList[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-target-list/' + EVENT_ID + '?' + '&pageSize=' + pageSize + '&offset=' + offset + '&' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventTargetListById = async (EVENT_ID: number | string, TARGET_LIST_ID: number | string): Promise<EventTargetList> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-target-list/' + EVENT_ID + '/' + TARGET_LIST_ID;
    return triggerApi(API_URL, 'GET', '');
};

export const creatTargetListByEventId = async (EVENT_ID: number | string, TARGET_LIST_DATA: EventTargetList, csrfToken: string): Promise<EventTargetList> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-target-list/' + EVENT_ID;
    return triggerApi(API_URL, 'POST', TARGET_LIST_DATA, csrfToken, '');
};

export const updateEventTargetList = async (EVENT_ID: number | string, TARGET_LIST_ID: number | string, TARGET_LIST_DATA: UpdateEventTargetList): Promise<EventTargetList> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-target-list/' + EVENT_ID + '/' + TARGET_LIST_ID;
    return triggerApi(API_URL, 'PUT', TARGET_LIST_DATA);
};
    
export const deleteEventTargetList = async (EVENT_ID: number | string, TARGET_LIST_ID: number | string): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-target-list/' + EVENT_ID + '/' + TARGET_LIST_ID;
    return triggerApi(API_URL, 'DELETE', '');
};

export const getEventTargetAudiencesByListId = async (EVENT_ID: number | string, TARGET_LIST_ID: number | string, pageSize: number, offset: number, queryParams: [string, any][] = [] ): Promise<Audience[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-target-list/' + EVENT_ID + '/' + TARGET_LIST_ID + '/audience?'+ '&pageSize=' + pageSize + '&offset=' + offset + '&' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventTargetAudiencesCountByListId = async (EVENT_ID: number | string, TARGET_LIST_ID: number | string, queryParams: [string, any][] = [] ): Promise<number> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-target-list/' + EVENT_ID + '/' + TARGET_LIST_ID + '/audience/count?'+ '&' + query;;
    return triggerApi(API_URL, 'GET', '');
};
