import React, { useEffect, useState } from "react";
import { FormControlComponent, FormLabelComponent } from "../../common/FormComponents/ReusableFormComponents";
import NotificationStepper from "../../pages/Events/EventDetails/Accesscontrol/Components/Notifications/NotificationsStepper";
import { CustomButton } from "../../common/FormComponents/Buttons";
import DeletePopup from "../../common/DeletePopup";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from "react-bootstrap";
import { integrateEmail } from "../../scripts/apis/integration";
import { DNSRecord, DNSRecordObj, DomainAuth } from "../../pages/Settings/interface/domain_interface";
import { createEmailIntegration, getDnsRecords, validateDomain } from "../../scripts/apis/emailIntegration";
import { createColumnHelper } from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TanstackTable from "../../common/TanstackTable/TanstackTable";
import CustomSpinner from "../../common/CustomSpinner";

import './styles.scss';

enum EmailIntegrationSteps {
    FILTERS = 1,
    VERIFICATION = 2,
    EMAIL = 3
}

interface EmailIntegrationFormValues {
    domain: string;
    fromEmail: string;
    fromName: string;
}

const EmailIntegrationComponent: React.FC<{ handleClosePopup: () => void }> = ({ handleClosePopup }): React.JSX.Element => {

    const csrfTokenData = useSelector((state: any) => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const [loading, setLoading] = useState<boolean>(true);
    const [currentStep, setCurrentStep] = useState<EmailIntegrationSteps>(EmailIntegrationSteps.FILTERS);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);
    const [dnsDetails, setDnsDetails] = useState<DomainAuth>();
    const [isEditFlow, setIsEditFlow] = useState<boolean>(false);

    const disableBackNavigation = ((currentStep === EmailIntegrationSteps.FILTERS) || (currentStep === EmailIntegrationSteps.VERIFICATION && dnsDetails && Object?.keys(dnsDetails)?.length > 0));
    const disableNextNavigation = (currentStep === EmailIntegrationSteps.VERIFICATION && dnsDetails && !dnsDetails?.validationSuccess);

    const validationSchema = Yup.object().shape({
        domain: Yup.string().required('Domain name is required'),
        fromEmail: Yup.string()
            .email('Invalid email')
            .required('Email is required')
            .test('fromEmail', 'Email should match the domain name provided', (value: string | undefined): boolean => {
                if (value) {
                    const domain = value.split('@')[1];
                    return domain === dnsDetails?.domain;
                }
                return false;
            }),
        fromName: Yup.string().required('Name is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        enableReinitialize: true,
        initialValues: {
            domain: dnsDetails?.domain || '',
            fromEmail: dnsDetails?.email || '',
            fromName: dnsDetails?.fromName || ''
        },
        onSubmit: () => {
            handleSubmit();
        },
    });

    const handleBack = () => {
        if (disableBackNavigation) {
            return;
        }
        setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
        setIsComplete(false);
    };

    const handleNext = async () => {
        if (currentStep === emailIntegrationSteps.length) {
            formik.validateForm();
            formik.setTouched({
                domain: true,
                fromEmail: true,
                fromName: true
            });
            formik.handleSubmit();
        }
        else {
            if (currentStep == 1) {
                if (formik.values.domain === '') {
                    toast.error('Please enter domain name');
                    formik.setTouched({ domain: true });
                    return;
                }
                setShowConfirmationPopup(true);
                return;
            }
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };

    const handleEdit = () =>
    {
        formik.validateForm();
        formik.setTouched({
            domain: true,
            fromEmail: true,
            fromName: true
        });
        formik.handleSubmit();
    };

    const handleConfirmationClick = (): void => {
        handleEmailIntegration();
        setShowConfirmationPopup(false);
    };

    const handleEmailIntegration = async (): Promise<void> => {

        const data = {
            domain: formik.values.domain
        }

        try {
            const isEmailIntegrationSuccess = await integrateEmail(data, csrfTokenData);
            if (isEmailIntegrationSuccess) {
                toast.success('Email Integration created successfully');
                setCurrentStep((prevStep) => prevStep + 1);
                setDnsDetails(isEmailIntegrationSuccess);
            }
        }
        catch (error) {
            console.log(error);
            toast.error((error as Error)?.message || 'Something went wrong. Please try again later');
        }
    };

    const onDomainValidationSuccess = (): void => {
        setCurrentStep((prevStep) => prevStep + 1);
        fetchDnsRecords();
    };

    const handleSubmit = async (): Promise<void> => {
        const data = {
            email: formik.values.fromEmail,
            name: formik.values.fromName
        };

        try {
            const isEmailIntegrationSuccess = await createEmailIntegration(data, csrfTokenData);
            if (isEmailIntegrationSuccess) {
                toast.success('Email Integration created successfully');
                setIsComplete(true);
                handleClosePopup();
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const emailIntegrationSteps = [
        {
            name: "Domain Details",
            Component: <DomainDetails formik={formik} />,
        },
        {
            name: "Verification",
            Component: <VerificationDetails dnsDetails={dnsDetails} onValidationSuccess={onDomainValidationSuccess} />
        },
        {
            name: "Email",
            Component: <EmailDetails formik={formik} />
        }
    ];

    const renderStepperFlowForEmailIntegration = (): React.JSX.Element => {
        return (
            <div className="email-integration-component">
                <NotificationStepper
                    props={emailIntegrationSteps}
                    buttonPosition='bottom'
                    currentStep={currentStep}
                    isComplete={isComplete}
                />

                {!isComplete && (
                    <div className={`email-integration-button-container`}>
                        <CustomButton
                            name="Back"
                            btnType={"secondary"}
                            onClick={handleBack}
                            disabled={disableBackNavigation}
                        />
                        <CustomButton
                            name={currentStep === emailIntegrationSteps.length ? "Finish" : "Next"}
                            btnType={"primary"}
                            onClick={handleNext}
                            disabled={disableNextNavigation}
                        />
                    </div>
                )}

                {
                    showConfirmationPopup && (
                        <DeletePopup
                            modalTitle="Confirmation"
                            modalContent={`Are you sure you want to proceed with the domain name ${formik.values.domain}?.    You will not be able to change the domain name once you proceed.`}
                            show={showConfirmationPopup}
                            acceptBtn="Proceed"
                            rejectBtn="Cancel"
                            acceptClick={handleConfirmationClick}
                            cancelClick={() => setShowConfirmationPopup(false)}
                        />
                    )
                }
            </div>
        );
    };

    const renderEditFlowForEmailIntegration = (): React.JSX.Element => { 
        return (
            <div className="email-integration-component">
                <EmailDetails formik={formik} isEditFlow={isEditFlow} />
                <div className="email-integration-button-container">
                    <CustomButton
                        name="Cancel"
                        btnType={"secondary"}
                        onClick={handleClosePopup}
                    />
                    <CustomButton
                        name="Save"
                        btnType={"primary"}
                        onClick={handleEdit}
                    />
                </div>
            </div>
        )
    };

    const renderFormContent = () => {
        if(loading)
        {
            return <CustomSpinner height="100%" width="100%" />
        }
        else if(!loading)
        {
            if (dnsDetails && dnsDetails?.validationSuccess && isEditFlow)
            {
                return renderEditFlowForEmailIntegration();
            }
            else
            {
                return renderStepperFlowForEmailIntegration();
            }
        }
    };

    const fetchDnsRecords = async () => {
        try {
            const dnsRecords = await getDnsRecords();
            if (dnsRecords) {
                setDnsDetails(dnsRecords);
                if(dnsRecords?.validationSuccess)
                {
                    if(dnsRecords?.fromName && dnsRecords?.email)
                    {
                        setCurrentStep(EmailIntegrationSteps.EMAIL);
                        setIsEditFlow(true);
                    }
                    else
                    {
                        setCurrentStep(EmailIntegrationSteps.EMAIL);
                        setIsEditFlow(false);
                    }
                }
                else
                {
                    setCurrentStep(EmailIntegrationSteps.VERIFICATION);
                    setIsEditFlow(false);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDnsRecords();
    }, []);

    return (
        <div id="emailIntegrationComponent">
            <Form className="h-100" onSubmit={formik.handleSubmit}>
                {renderFormContent()}
            </Form>
        </div>
    )
};

const DomainDetails = ({ formik }: { formik: FormikProps<EmailIntegrationFormValues> }) => {
    return (
        <div className="email-integration-form-container">
            <FormLabelComponent label='Domain Name' required />
            <FormControlComponent
                type='text'
                value={formik.values.domain}
                onChange={(e) => formik.setFieldValue('domain', e.target.value)}
                placeholder='Enter Domain Name'
                required
                isInvalid={formik.touched.domain && !!formik.errors.domain}
            />
        </div>
    )
};

const VerificationDetails = ({ dnsDetails, onValidationSuccess }: { dnsDetails?: DomainAuth, onValidationSuccess: () => void }) => {

    const [validationSpinner, setValidationSpinner] = useState<boolean>(false);

    const prepareRowsForTable = () => {
        let rows: DNSRecord[] = [];
        if (dnsDetails && Object.keys(dnsDetails)?.length === 0) {
            rows = [];
        }
        else {
            Object.entries(dnsDetails?.dns as DNSRecordObj).forEach(([key, value]) => {
                rows.push({
                    host: value.host,
                    type: value.type,
                    data: value.data,
                    valid: value.valid
                });
            });
        }
        return rows;
    };

    const columnHelper = createColumnHelper<DNSRecord>();
    const columns = [
        columnHelper.accessor('type', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()?.toUpperCase()}</p>)
            },
            header: 'DNS Type',
            size: 80,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'type'
        }),
        columnHelper.accessor('host', {
            cell: (row) => {
                return (
                    <div className="dns-records-table-cell">
                        <p className="cellContent dns-name">{row.getValue()}</p>
                        <CustomButton 
                            btnType="tertiary"
                            name=""
                            startIcon={<FontAwesomeIcon icon={['fal', 'copy']} />}
                            style={{
                                background: 'transparent',
                            }}
                            onClick={() =>
                            {
                                navigator.clipboard.writeText(row.getValue());
                                toast.success('Copied to clipboard');
                            }}
                        />
                    </div>
                )
            },
            header: 'DNS Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'host'
        }),
        columnHelper.accessor('data', {
            cell: (row) => {
                return (
                    <div className="dns-records-table-cell">
                        <p className="cellContent dns-content">{row.getValue()}</p>
                        <CustomButton 
                            btnType="tertiary"
                            name=""
                            startIcon={<FontAwesomeIcon icon={['fal', 'copy']} />}
                            style={{
                                background: 'transparent',
                            }}
                            onClick={() =>
                            {
                                navigator.clipboard.writeText(row.getValue());
                                toast.success('Copied to clipboard');
                            }}
                        />
                    </div>
                )
            },
            header: 'DNS Content',
            size: 350,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'data'
        }),
        columnHelper.accessor('valid', {
            cell: (row) => {
                return (
                    <div className={`${row?.getValue() ? 'valid-record' : 'invalid-record'}`}>
                        <FontAwesomeIcon icon={['fal', row?.getValue() ? 'check' : 'xmark']} />
                    </div>
                )
            },
            header: 'Status',
            size: 50,
            id: 'valid',
            enableSorting: false
        })
    ];

    const handleDomainValidation = async () => {
        setValidationSpinner(true);
        try {
            const domainValidated = await validateDomain();
            if (domainValidated) {
                onValidationSuccess();
                toast.success('Domain validated successfully');
            }
        }
        catch (error) {
            console.log(error);
            toast.error((error as Error)?.message || 'Something went wrong. Please try again later');
        }
        finally {
            setValidationSpinner(false);
        }
    };

    return (
        <div className="email-integration-verification-details">
            <div className="dns-records-table">
                <TanstackTable
                    initialColumns={columns}
                    data={prepareRowsForTable()}
                    hidePagination
                    rowCount={prepareRowsForTable().length}
                    showSpinner={false}
                />
            </div>

            {
                !dnsDetails?.validationSuccess &&
                <div className="validate-domain-button">
                    <CustomButton
                        name="Validate Domain"
                        btnType="primary"
                        onClick={handleDomainValidation}
                        disabled={validationSpinner}
                        loading={validationSpinner}
                    />
                </div>
            }
        </div>
    )
};

const EmailDetails = ({ formik, isEditFlow }: { formik: FormikProps<EmailIntegrationFormValues>, isEditFlow?: boolean }) => {
    return (
        <div className="email-details-component">
            {
                isEditFlow && (
                    <div className="w-100">
                        <FormLabelComponent label='Domain name' />
                        <FormControlComponent
                            type='email'
                            value={formik.values.domain}
                            required
                            disabled
                        />
                    </div>
                )
            }

            <div className="w-100">
                <FormLabelComponent label='From Email' required />
                <FormControlComponent
                    type='email'
                    placeholder='Enter From Email'
                    value={formik.values.fromEmail}
                    onChange={(e) => formik.setFieldValue('fromEmail', e.target.value)}
                    required
                    isInvalid={formik.touched.fromEmail && !!formik.errors.fromEmail}
                />
                {
                    formik?.errors?.fromEmail && <div className="error-msg">{formik.errors.fromEmail}</div>
                }
            </div>

            <div className="w-100">
                <FormLabelComponent label='From Name' required />
                <FormControlComponent
                    type='text'
                    placeholder='Enter From Name'
                    value={formik.values.fromName}
                    onChange={(e) => formik.setFieldValue('fromName', e.target.value)}
                    required
                    isInvalid={formik.touched.fromName && !!formik.errors.fromName}
                />
                {
                    formik?.errors?.fromName && <div className="error-msg">{formik.errors.fromName}</div>
                }
            </div>
        </div>
    )
};

export default EmailIntegrationComponent;