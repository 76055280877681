import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActivityLogComponentProps } from './interface/activity-log-component_interface';
import './styles.scss';

const ActivityLogComponent: React.FC<ActivityLogComponentProps> = (props): React.JSX.Element => {

    const { activityLog, componentHeight } = props;

    return (
        <div style={{ height: componentHeight ? componentHeight : '100%' }} id="activityLogComponent">
            <div className="activity-component-container">
                {
                    activityLog && activityLog?.length > 0 ? activityLog?.map((log, index) => 
                    {
                        return (
                            <div key={index} className="log-card">
                                <div className="log-icon-container">
                                    <div className="log-icon">
                                        <FontAwesomeIcon icon={['fal', `${log.icon}`]} />
                                    </div>
                                </div>
                                <div className="log-info-container">
                                    <div 
                                        className="log-title"
                                        dangerouslySetInnerHTML={{
                                            __html: log?.title as string
                                        }} 
                                    />
                                    {log?.subtitle && <p className="log-sub-title">{log?.subtitle}</p>}
                                    <p className="log-timing">{log?.modifiedTime}</p>
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className="log-empty">
                        No activity logs found
                    </div>
                }
            </div>
        </div>
    );
};

export default ActivityLogComponent;