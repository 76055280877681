import { LinearProgress, linearProgressClasses, LinearProgressProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

interface BorderLinearProgressProps extends LinearProgressProps {
  progressColor?: string;
}

export const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'progressColor',
})<BorderLinearProgressProps>(({ theme, progressColor }: { theme: Theme, progressColor?: string }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: progressColor || (theme.palette.mode === 'light' ? '#38C793' : '#38C793'),
  },
}));