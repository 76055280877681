import { BuyerPersona } from '../../pages/Settings/interface/buyer-persona_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const buyerPersonaCount = async (queryParams: [string, string][] = []):Promise<number> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/buyer-persona/count' + '?' + query;;
    return triggerApi(API_URL, 'GET', '');
};

export const getAllBuyerPersona = async (pageSize: number, offset: number, queryParams: [string, any][] = []):Promise<BuyerPersona[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        ...filteredParams,
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/buyer-persona?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getBuyerPersonaById = async (BUYER_PERSONA_ID):Promise<BuyerPersona> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/buyer-persona/' + BUYER_PERSONA_ID;
    return triggerApi(API_URL, 'GET', '');
};

export const createBuyerPersona = async (BUYER_PERSONA_DATA, csrfToken: string):Promise<BuyerPersona> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/buyer-persona';
    return triggerApi(API_URL, 'POST', BUYER_PERSONA_DATA, csrfToken);
};

export const updateBuyerPersona = async (BUYER_PERSONA_ID, BUYER_PERSONA_DATA):Promise<BuyerPersona> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/buyer-persona/' + BUYER_PERSONA_ID;
    return triggerApi(API_URL, 'PUT', BUYER_PERSONA_DATA);
};

export const deleteBuyerPersona = async (BUYER_PERSONA_ID):Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/buyer-persona/' + BUYER_PERSONA_ID;
    return triggerApi(API_URL, 'DELETE', '');
};