import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import eventBus from '../../../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../../../scripts/constants';
import './styles.scss';
import EventsCardHeaderComponent from '../../../../../../components/Events/EventComponents/EventsCardHeaderComponent';
import CardComponent from '../../../../../../components/Events/Registrations/CardComponent';
import { EventCheckInNotifications } from '../../../../interfaces/event-check-in-notifications.interface';
import MuiChip from '../../../../../../common/FormComponents/MuiChip';
import AddCheckInNotifications from './AddCheckInNotifications';
import CustomTooltip from '../../../../../../common/Tooltip';
import { CustomButton } from '../../../../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface NotificationsCardComponentProps {
    eventCheckInNotificationsData: EventCheckInNotifications[];
    eventCheckInNotificationsCount: number;
    eventId: number | string;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    minimalView: boolean;
}

const NotificationsCardComponent: React.FC<NotificationsCardComponentProps> = ({
    eventCheckInNotificationsData,
    eventCheckInNotificationsCount,
    eventId,
    setRefresh,
    minimalView,
}): React.JSX.Element =>
{

    const navigate = useNavigate();

    const openCheckInNotificationsSidedrawer = (checkInNotifications?: EventCheckInNotifications) =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: checkInNotifications ? `${checkInNotifications.title}` : 'Check In Notifications',
            hideCloseButton: true,
            component: (
                <AddCheckInNotifications
                    eventId={eventId}
                    setRefresh={setRefresh}
                    checkInNotifications={checkInNotifications}
                />
            ),
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '75vw !important',
        });
    };

    const leftNodes = [
        <CustomTooltip title='Table View' key={0}>
        <div>
            <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />} onClick={() => navigate('/events/' + eventId + '/check-in/notifications')} />
        </div>
        </CustomTooltip>,
    ];


    const notificationsCardView = () => 
    {
        return (
            <div id='notificationsCardComponent'>
            <div className='notifications-container'>
                <EventsCardHeaderComponent
                    heading='CheckIn Notifications'
                    count={eventCheckInNotificationsCount}
                    leftNodes={leftNodes}
                />
                <Grid container spacing={2}>
                    {eventCheckInNotificationsData?.map((notification: EventCheckInNotifications, index: number) =>
                    {
                        const deviceNameComponent = (
                            <div className='device-name-container'>
                                <p>{notification.title}</p>
                            </div>
                        );

                        let filterNames = [];
                        if (notification?.targetListNames && notification.targetListNames.length > 0)
                        {
                            filterNames.push(...notification.targetListNames.filter(name => name !== null));
                        }
                        if (notification?.icpNames && notification.icpNames.length > 0)
                        {
                            filterNames.push(...notification.icpNames.filter(name => name !== null));
                        }
                        if (notification?.bpNames && notification.bpNames.length > 0)
                        {
                            filterNames.push(...notification.bpNames.filter(name => name !== null));
                        }
                        if (notification?.ticketNames && notification.ticketNames.length > 0)
                        {
                            filterNames.push(...notification.ticketNames.filter(name => name !== null));
                        }
                        if (notification?.filters?.guestType && notification.filters.guestType.length > 0)
                        {
                            filterNames.push(...notification.filters.guestType.filter(name => name !== null));
                        }

                        const displayedValues = filterNames.slice(0, 3); // Adjust this number 
                        const remainingValues = filterNames.slice(3);
                        const remainingCount = filterNames.length - displayedValues.length;

                        const NotificationsCardComponent = (
                            <div className='chip-container'>
                                {displayedValues.map((value, index) => (
                                    <MuiChip key={index} label={value} chipColor='grey' />
                                ))}
                                {remainingCount > 0 && (
                                    <CustomTooltip title={remainingValues.map((value) => value).join(', ')}>
                                         <div>
                                            <MuiChip key="more" label={`+${remainingCount}`} chipColor="grey" />
                                         </div>
                                    </CustomTooltip>
                                )}
                            </div>
                        );

                        return (
                            <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <CardComponent
                                    header={deviceNameComponent}
                                    headerBold
                                    contentHeading={NotificationsCardComponent}
                                    key={index}
                                    cardMinHeight='82px' 
                                    onClick={() =>
                                    {
                                        openCheckInNotificationsSidedrawer(notification);
                                    }}
                                />
                            </Grid>
                        );
                    })}

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <CardComponent
                            emptyText='+ Add Notification'
                            onClick={() =>
                            {
                                openCheckInNotificationsSidedrawer();
                            }}
                            emptyCardHeight='52px' 
                            cardMinHeight='52px'          
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
        )
    }

    const notificationsCardMinimalView = () =>
    {
        return (
            <div id='notificationsCardMinimalComponent'>
            <div className='notifications-container'>
                <EventsCardHeaderComponent
                    heading='CheckIn Notifications'
                    count={eventCheckInNotificationsCount}
                    leftNodes={leftNodes}
                />
                <Grid container spacing={2}>
                    {eventCheckInNotificationsData?.map((notification: EventCheckInNotifications, index: number) =>
                    {
                        const deviceNameComponent = (
                            <div className='device-name-container'>
                                <p>{notification.title}</p>
                            </div>
                        );

                        let filterNames = [];
                        if (notification?.targetListNames && notification.targetListNames.length > 0)
                        {
                            filterNames.push(...notification.targetListNames.filter(name => name !== null));
                        }
                        if (notification?.icpNames && notification.icpNames.length > 0)
                        {
                            filterNames.push(...notification.icpNames.filter(name => name !== null));
                        }
                        if (notification?.bpNames && notification.bpNames.length > 0)
                        {
                            filterNames.push(...notification.bpNames.filter(name => name !== null));
                        }
                        if (notification?.ticketNames && notification.ticketNames.length > 0)
                        {
                            filterNames.push(...notification.ticketNames.filter(name => name !== null));
                        }
                        if (notification?.filters?.guestType && notification.filters.guestType.length > 0)
                        {
                            filterNames.push(...notification.filters.guestType.filter(name => name !== null));
                        }

                        const displayedValues = filterNames.slice(0, 2); // Adjust this number 
                        const remainingValues = filterNames.slice(2);
                        const remainingCount = filterNames.length - displayedValues.length;

                        const NotificationsCardComponent = (
                            <div className='chip-container'>
                                {displayedValues.map((value, index) => (
                                    <MuiChip key={index} label={value} chipColor='grey' />
                                ))}
                                {remainingCount > 0 && (
                                    <CustomTooltip title={remainingValues.map((value) => value).join(', ')}>
                                         <div>
                                            <MuiChip key="more" label={`+${remainingCount}`} chipColor="grey" />
                                         </div>
                                    </CustomTooltip>
                                )}
                            </div>
                        );

                        return (
                            <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <CardComponent
                                    header={deviceNameComponent}
                                    headerBold
                                    contentHeading={NotificationsCardComponent}
                                    key={index}
                                    cardMinHeight='82px' 
                                    onClick={() =>
                                    {
                                        openCheckInNotificationsSidedrawer(notification);
                                    }}
                                />
                            </Grid>
                        );
                    })}

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <CardComponent
                            emptyText='+ Add Notification'
                            onClick={() =>
                            {
                                openCheckInNotificationsSidedrawer();
                            }}
                            emptyCardHeight='52px' 
                            cardMinHeight='52px'          
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
        )
    }

    return minimalView ? notificationsCardMinimalView() : notificationsCardView();
};

export default NotificationsCardComponent;