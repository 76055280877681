import { ActivityLogResponse } from '../../interfaces/activity-log_interface';
import { Vendor } from '../../pages/Vendors/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

// GET methods

export const vendorsCount = async (queryParams: [string, string][] = []):Promise<number> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/vendor/count' + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getAllVendors = async (pageSize: number, offset: number, queryParams: [string, any][] = []):Promise<Vendor[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/vendor?' + '&pageSize=' + pageSize + '&offset=' + offset + '&' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getVendorById = async (vendorId: number):Promise<Vendor> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/vendor/' + vendorId;
    return triggerApi(API_URL, 'GET', '');
};

export const getVendorsActivityLog = async (vendorId?: string | number): Promise<ActivityLogResponse[]> =>
{
    const query = convertJSONToGETParams({
        vendorId: vendorId
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/vendor/activity-logs?' + query;
    return triggerApi(API_URL, 'GET', '');
};

// POST methods

export const createVendor = async (VENDOR_DATA, csrfToken: string):Promise<Vendor> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/vendor';
    return triggerApi(API_URL, 'POST', VENDOR_DATA, csrfToken, '');
};

// PUT methods

export const updateVendor = async (VENDOR_DATA, VENDOR_ID: number):Promise<Vendor> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/vendor/' + VENDOR_ID;
    return triggerApi(API_URL, 'PUT', VENDOR_DATA);
};

// DELETE methods

export const deleteVendor = async (VENDOR_ID: string | number):Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/vendor/' + VENDOR_ID;
    return triggerApi(API_URL, 'DELETE', '');
};
