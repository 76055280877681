import React, { createContext, useEffect, useState } from "react";
import { useTablePagination } from "../TablePaginationContext";
import { deleteEventAccessControl, getAllEventAccessControl, getAllEventAccessControlCount } from "../../scripts/apis/eventAccessControl";
import { EventAccessControl } from "../../pages/Events/interfaces/event-access-control_interface";
import { getAllStage, getStageCount } from "../../scripts/apis/eventStage";
import { Event, EventStage } from "../../pages/Events/interfaces";
import { getEventById } from "../../scripts/apis/events";
import toast from "react-hot-toast";
import { getEncodedParams } from "../../scripts/helpers";
import { useLocation } from "react-router-dom";
import { getAllCheckInNotifications, getCheckInNotificationsCount } from "../../scripts/apis/eventCheckInNotifications";
import { EventCheckInNotifications } from "../../pages/Events/interfaces/event-check-in-notifications.interface";

interface IEventAccessControlContext { 
    rows: EventAccessControl[];
    eventAccessControlData: EventAccessControl[];
    eventCheckInNotificationsData: EventCheckInNotifications[];
    isEmpty: boolean;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setRows: React.Dispatch<React.SetStateAction<EventAccessControl[]>>;
    showSpinner: boolean;
    eventId: string | number;
    pageSize: number;
    currentPage: number;
    updateCurrentPage?: (page: number) => void;
    updatePageSize?: (size: number) => void;
    eventAccessControlCount: number;
    eventCheckInNotificationsCount: number;
    eventStages: EventStage[];
    deleteDeviceFromAccessControl: (id: string | number) => Promise<void>;
    disableAndEnableAccessForDevice: (id: string | number, status: number) => Promise<void>;
    event?: Event;
    currentUrl: string;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const EventAccessControlContext = createContext<IEventAccessControlContext>({
    rows: [],
    eventAccessControlData: [],
    eventCheckInNotificationsData: [],
    isEmpty: true,
    setRefresh: () => {},
    setRows: () => {},
    showSpinner: false,
    eventId: '',
    pageSize: 25,
    currentPage: 1,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    eventAccessControlCount: 0,
    eventCheckInNotificationsCount: 0,
    eventStages: [],
    deleteDeviceFromAccessControl: async () => {},
    disableAndEnableAccessForDevice: async () => {},
    event: undefined,
    currentUrl: '',
    setCurrentUrl: () => {},
});

const EventAccessControlProvider: React.FC<{children: React.ReactNode, eventId: string | number}> = ({ children, eventId }) => 
{ 

    const [rows, setRows] = useState<EventAccessControl[]>([]);
    const [eventAccessControlData, setEventAccessControlData] = useState<EventAccessControl[]>([]);
    const [eventCheckInNotificationsData, setEventCheckInNotificationsData] = useState<EventCheckInNotifications[]>([]); 
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [isNotificationsEmpty, setIsNotificationsEmpty] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [eventAccessControlCount, setEventAccessControlCount] = useState<number>(0);
    const [eventCheckInNotificationsCount, setEventCheckInNotificationsCount] = useState<number>(0);
    const [eventStages, setEventStages] = useState<EventStage[]>([]);
    const [event, setEvent] = useState<Event>();
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const currentpath = useLocation().pathname;
    
    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchCheckInNotificationsCount = async (): Promise<void> =>
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await getCheckInNotificationsCount(eventId, encodedParams);
            if (count) 
            {
                setEventCheckInNotificationsCount(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    }

    const fetchCheckInNotificationsData = async () =>
    {
        setShowSpinner(true);
        try 
        {
            if(eventCheckInNotificationsCount)
            {
                const encodedParams = getEncodedParams(currentUrl);
                const checkInNotifications = await getAllCheckInNotifications(pageSize, currentPage - 1, eventId, encodedParams);
                
                if(checkInNotifications)
                {
                    setEventCheckInNotificationsData(checkInNotifications);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching Access control data', error);
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    const fetchTotalAccessControlCount = async (): Promise<void> =>
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await getAllEventAccessControlCount(eventId, encodedParams);
            if (count) 
            {
                setEventAccessControlCount(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    }
    const fetchAccessControlData = async() =>
    {
        setShowSpinner(true);
        try 
        {
            if(eventAccessControlCount)
            {
                const encodedParams = getEncodedParams(currentUrl);
                const accessControlData = await getAllEventAccessControl(pageSize, currentPage - 1, eventId, encodedParams);
                
                if(accessControlData)
                {
                    setRows(accessControlData);
                    setEventAccessControlData(accessControlData);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching Access control data', error);
        }
    };

    const fetchStageData = async ():Promise<void> => 
    {
        try 
        {
            const count = await getStageCount(eventId);
            if(count)
            {
                const eventStageData = await getAllStage(eventId, count, 0);
                if(eventStageData)
                {
                    setEventStages(eventStageData);
                }
            }
        } catch (error) 
        {
            console.log('Error in fetching Stage data', error);
        }
    };

    const deleteDeviceFromAccessControl = async (id: string | number): Promise<void> => 
    {
        if (rows?.length > 0) 
        {
            let deleteSuccess = true;
            if (deleteSuccess) 
            {
                if (id) 
                {
                    try 
                    {
                        const eventDeleted = await deleteEventAccessControl(eventId, Number(id));
                        if (eventDeleted) 
                        {
                            setRefresh(true);
                            toast.success('Device Deleted Successfully');
                            deleteSuccess = false;
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                    }

                }
            }
        }
    };

    const disableAndEnableAccessForDevice = async (id: string | number): Promise<void> =>
    {        
        if(rows?.length > 0)
        {
            if(id)
            {
                // try 
                // {
                //     const disableAccess = await updateEventAccessControl({status: EventAccessControlStatus.DISABLED}, eventId, id);
                //     if(disableAccess)
                //     {
                //         setRefresh(true);
                //         eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, true);
                //     }
                // } 
                // catch (error) 
                // {
                    
                // }
            }
        }
    };
    
    const fetchEventData = async (): Promise<void> => {
        try {
            const event = await getEventById(eventId.toString());
            if(event){
                setEvent(event);
            }    
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    };

    useEffect((): void => 
    {
        if(currentpath.endsWith('devices'))
        {
            fetchEventData();
            fetchAccessControlData();
            fetchStageData();
        }
        else if(currentpath.endsWith('notifications'))
        {
            fetchCheckInNotificationsData();
        }
        else 
        {
            fetchEventData();
            fetchAccessControlData();
            fetchStageData();
            fetchCheckInNotificationsData();
        }
        
    }, [currentPage, pageSize, eventAccessControlCount, eventCheckInNotificationsCount, currentUrl]);

    useEffect(() =>
    {
        if(refresh)
        {
            if(currentpath.endsWith('devices'))
            {
                fetchTotalAccessControlCount();
                fetchEventData();
                fetchAccessControlData();
                fetchStageData();
            }
            else if(currentpath.endsWith('notifications'))
            {
                fetchCheckInNotificationsCount();
                fetchCheckInNotificationsData();
            }
            else 
            {
                fetchTotalAccessControlCount();
                fetchEventData();
                fetchAccessControlData();
                fetchStageData();
                fetchCheckInNotificationsCount();
                fetchCheckInNotificationsData();
            }
        }
    }, [refresh]);

    useEffect(() => 
    {
        if(currentpath.endsWith('devices'))
        {
            fetchTotalAccessControlCount();
        }
        else if(currentpath.endsWith('notifications'))
        {
           fetchCheckInNotificationsCount();
        }
        else 
        {
            fetchTotalAccessControlCount();
            fetchCheckInNotificationsCount();
        }
    }, [currentUrl]);

    return (
        <EventAccessControlContext.Provider value={{
                rows,
                eventAccessControlData, 
                setRows,
                isEmpty, 
                setRefresh, 
                showSpinner, 
                eventId, 
                eventAccessControlCount, 
                pageSize, 
                currentPage,
                updateCurrentPage, 
                updatePageSize, 
                eventStages, 
                deleteDeviceFromAccessControl, 
                disableAndEnableAccessForDevice, 
                event,
                currentUrl,
                setCurrentUrl,
                eventCheckInNotificationsData,
                eventCheckInNotificationsCount,
          }}>
            {children}
        </EventAccessControlContext.Provider>
    );
};

export default EventAccessControlProvider;

