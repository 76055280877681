import { IEventGoal } from '../../pages/Events/interfaces';
import APP_CONSTANTS from '../constants';
import { triggerApi } from '../helpers';

export const getEventsgoals = async (eventId: string):Promise<IEventGoal[]> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/goals/'  + eventId;
    return triggerApi(API_URL, 'GET', '');
};

export const createEventgoals = async (eventId: string,goalData, csrfToken: string):Promise<IEventGoal>  => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/goals/' + eventId;
    return triggerApi(API_URL, 'POST', goalData, csrfToken);
};

export const updateEventgoals = async (eventId: string, goalData):Promise<boolean>  => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/goals/' + eventId;
    return triggerApi(API_URL, 'PUT', goalData);
};

export const deleteEventsgoals = async (eventId: string): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-goal/' + eventId;
    return triggerApi(API_URL, 'DELETE', '');
};
