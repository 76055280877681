import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { EventAccessControl } from '../../../../interfaces/event-access-control_interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomButton } from '../../../../../../common/FormComponents/Buttons';
import eventBus from '../../../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../../../scripts/constants';
import './styles.scss';
import EventsCardHeaderComponent from '../../../../../../components/Events/EventComponents/EventsCardHeaderComponent';
import CardComponent from '../../../../../../components/Events/Registrations/CardComponent';
import { EventAccessControlStatus } from '../../../../enum/event-access-control.enum';
import AddEventAccessControl from '../../../../../../components/Events/AccessControl/AddEventAccessControl';
import AccessPinActionsComponent from './AccessPinActionsComponent'; // Import the new component
import AddEventBadgeSettings from '../../../../../../components/Events/AccessControl/AddEventBadgeSettings';
import AddCardSettings from '../../../../../../components/Events/CheckinApp/AddCardSettings';
import { useSelector } from 'react-redux';
import { IEventReduxValues, IEventsDispatch } from '../../../../../../components/Events/interfaces/create-events_interface';
import CustomTooltip from '../../../../../../common/Tooltip';

interface CheckInDevicesCardComponentProps {
    eventAccessControlData: EventAccessControl[];
    eventAccessControlCount: number;
    eventId: number | string;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    event: any;
    minimalView: boolean;
}

const CheckInDevicesCardComponent: React.FC<CheckInDevicesCardComponentProps> = ({
    eventAccessControlData,
    eventAccessControlCount,
    eventId,
    setRefresh,
    event,
    minimalView = false,
}): React.JSX.Element =>
{
    const navigate = useNavigate();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });
    
    const leftNodes = [
        <CustomTooltip title='Table View' key={0}>
            <div>
                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />} onClick={() => navigate('/events/' + eventId + '/check-in/devices')} />
            </div>
        </CustomTooltip>,
    ];

    const viewPin = (device: EventAccessControl): React.ReactElement =>
    {
        const viewPinHeaderClass: React.CSSProperties =
        {
            padding: '12px',
            borderRadius: 'var(--radius-lg, 10px)',
            border: '1px solid var(--Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border, #EAECF0)',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            height: '48px',
            width: '48px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };

        return (
            <div>
                {device?.status === EventAccessControlStatus.ACTIVE && (
                    <CustomButton
                        btnType='infoBlue'
                        name='View PIN'
                        type='button'
                        style={{
                            padding: '0px',
                            background: 'unset',
                            lineHeight: '18px',
                            fontWeight: 600,
                        }}
                        onClick={(event) =>
                        {
                            event.stopPropagation();
                            setTimeout(() =>
                            {
                                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                                    heading: (
                                        <Box sx={viewPinHeaderClass}>
                                            <FontAwesomeIcon icon={['fal', 'lock']} />
                                        </Box>
                                    ),
                                    component: (
                                        <AccessPinActionsComponent
                                            eventId={Number(eventId)}
                                            data={device}
                                            componentType={'viewPin'}
                                        />
                                    ),
                                    componentWidth: '512px',
                                    componentHeight: '262px',
                                });

                                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                                    open: true,
                                });
                            });
                        }}
                    />
                )}
            </div>
        );
    };

    const openEventAccessControlSidedrawer = (accessControlData?: EventAccessControl) =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: accessControlData ? 'Update Device' : 'Create Device',
            hideCloseButton: true,
            component: (
                <AddEventAccessControl
                    eventId={eventId}
                    setRefresh={setRefresh}
                    accessControlData={accessControlData}
                />
            ),
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const openEventBadgeSettingsSidedrawer = () =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Badge Settings',
            hideCloseButton: true,
            component: <AddEventBadgeSettings eventId={eventId} setRefresh={setRefresh} eventData={event} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };
    
    const openEventCardSettings = () => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'VCF Card Settings',
            hideCloseButton: true,
            component: <AddCardSettings eventId={eventId} setRefresh={setRefresh} formId={eventReduxData?.registrationFormId as string} existingVCardSettings={eventReduxData?.vcardSettings} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            // width: '50vw !important'
        });
    };

    const checkInDevicesCardView = () => 
    {
        return (
            <div
                id='eventDevicesCardComponent'
            >
                <div className='devices-container'>
                    <EventsCardHeaderComponent
                        heading='Devices'
                        count={eventAccessControlCount}
                        leftNodes={leftNodes}
                    />
    
                    <Grid container spacing={2}>
                        {eventAccessControlData?.map((device: EventAccessControl, index: number) =>
                        {
                            const deviceNameComponent = (
                                <div className='device-name-container'>
                                    <p>{device.deviceName}</p>
                                </div>
                            );
                            const viewPinComponent = viewPin(device);
    
                            return (
                                <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <CardComponent
                                        header={deviceNameComponent}
                                        headerBold
                                        cardMinHeight='82px'
                                        footerLeft={viewPinComponent}
                                        key={index}
                                        onClick={() =>
                                        {
                                            openEventAccessControlSidedrawer(device);
                                        }}
                                    />
                                </Grid>
                            );
                        })}
    
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <CardComponent
                                emptyText='+ Add Device'
                                onClick={() =>
                                {
                                    openEventAccessControlSidedrawer();
                                }}
                                emptyCardHeight='49px' 
                                cardMinHeight='49px'
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }


    const checkInDevicesCardMinimalView = () => 
    {
        return (
            <div
                id='eventDevicesCardMinimalComponent'
            >
                <div className='devices-container'>
                    <EventsCardHeaderComponent
                        heading='Devices'
                        count={eventAccessControlCount}
                        leftNodes={leftNodes}
                    />
    
                    <Grid container spacing={2}>
                        {eventAccessControlData?.map((device: EventAccessControl, index: number) =>
                        {
                            const deviceNameComponent = (
                                <div className='device-name-container'>
                                    <p>{device.deviceName}</p>
                                </div>
                            );
                            const viewPinComponent = viewPin(device);
    
                            return (
                                <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <CardComponent
                                        header={deviceNameComponent}
                                        headerBold
                                        footerLeft={viewPinComponent}
                                        key={index}
                                        cardMinHeight='82px'
                                        onClick={() =>
                                        {
                                            openEventAccessControlSidedrawer(device);
                                        }}
                                    />
                                </Grid>
                            );
                        })}
    
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <CardComponent
                                emptyText='+ Add Device'
                                onClick={() =>
                                {
                                    openEventAccessControlSidedrawer();
                                }}
                                emptyCardHeight='49px' 
                                cardMinHeight='49px'    
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

    return minimalView ? checkInDevicesCardMinimalView() : checkInDevicesCardView();
};

export default CheckInDevicesCardComponent;