import { useState } from "react";
import FormTabs from "../../../../common/FormTabs";
import EventMarketingPerformance from "./EventMarketingPerformance";
import { EventMarketing } from "../../../../pages/Events/interfaces";
import EventMarketingPreview from "./EventMarketingPreview";
import { CloseIconComponent } from "../../../../common/FormComponents/ReusableFormComponents";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";

import './styles.scss';

interface EventMarketingAnalyticsPreviewProps {
    marketingData: EventMarketing;
}

const EventMarketingAnalyticsPreview: React.FC<EventMarketingAnalyticsPreviewProps> = (props): React.JSX.Element => 
{
    const { marketingData } = props;

    const [selectedTab, setSelectedTab] = useState<'performance' | 'preview'>('performance');

    const handleDrawerClose = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const tabs = [
        {
            tabName: 'Performance',
            selectedTab: selectedTab === 'performance',
            navigation: () => setSelectedTab('performance')
        },
        {
            tabName: 'Preview',
            selectedTab: selectedTab === 'preview',
            navigation: () => setSelectedTab('preview')
        }
    ];

    return (
        <div id="eventMarketingAnalyticsPreview">
            <CloseIconComponent onClick={handleDrawerClose} />
            <div className="event-marketing-analytics-component">
                <FormTabs 
                    tabs={tabs}
                />
                {
                    selectedTab === 'performance' && <EventMarketingPerformance marketingId={marketingData.id} />
                }
                {
                    selectedTab === 'preview' && <EventMarketingPreview marketingData={marketingData} />
                }
            </div>
        </div>
    )
};

export default EventMarketingAnalyticsPreview;