import { useEffect, useRef, useState } from "react";
import { FormControlComponent, FormLabelComponent } from "../../../../common/FormComponents/ReusableFormComponents";
import { EventMarketing } from "../../../../pages/Events/interfaces";
import { DomainAuth } from "../../../../pages/Settings/interface/domain_interface";
import { getDnsRecords } from "../../../../scripts/apis/emailIntegration";
import moment from "moment";
import { EventMarketingNotificationType } from "../../../../pages/Events/enum";

import './styles.scss';
import APP_CONSTANTS from "../../../../scripts/constants";

interface EventMarketingPreviewProps {
    marketingData: EventMarketing;
}

const EventMarketingPreview: React.FC<EventMarketingPreviewProps> = (props): React.JSX.Element =>
{

    const { marketingData } = props;

    const [emailIntegrationInfo, setEmailIntegrationInfo] = useState<DomainAuth>();

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const fetchEmailIntegrationInfo = async (): Promise<void> =>
    {
        try 
        {
            const emailIntegrationInfo = await getDnsRecords();
            if(emailIntegrationInfo)
            {
                setEmailIntegrationInfo(emailIntegrationInfo);
            }
        } 
        catch (error) 
        {
            console.log('Error fetching email integration info', error);
        }
    };

    useEffect(() =>
    {
        fetchEmailIntegrationInfo();
    }, []);

    useEffect(() => {
        if (iframeRef.current) {
            const doc = iframeRef.current.contentDocument || iframeRef.current.contentWindow?.document;
            if (doc) {
                doc.open();
                doc.write(marketingData?.marketingMail?.emailContent);
                doc.close();
            }
        }
    }, [marketingData]);

    return (
        <div id="eventMarketingPreview">
            <div className="event-marketing-preview-component">
                <div className="email-details-container">
                    <h3 className="heading">{'Email Details'}</h3>
                    <div className="email-details-form-container">
                        <div className="email-details">
                            <div className="w-100">
                                <FormLabelComponent label='From Name' />
                                <FormControlComponent 
                                    type="text"
                                    value={emailIntegrationInfo?.fromName || APP_CONSTANTS.MARKETING_DEFAULT_FROM_NAME}
                                    disabled
                                />
                            </div>
                            <div className="w-100">
                                <FormLabelComponent label='From Email' />
                                <FormControlComponent 
                                    type="email"
                                    value={emailIntegrationInfo?.email || APP_CONSTANTS.MARKETING_DEFAULT_FROM_EMAIL}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="email-details">
                            <div className="w-100">
                                <FormLabelComponent label='Subject' />
                                <FormControlComponent 
                                    type="text"
                                    value={marketingData?.marketingMail?.subject || ''}
                                    disabled
                                />
                            </div>
                            <div className="w-100">
                                <FormLabelComponent label='Trigger Date & Time' />
                                <FormControlComponent 
                                    type="text"
                                    value={String(marketingData?.triggerTime) !== '0' && (moment.unix(Number(marketingData?.triggerTime))?.format('DD/MM/YYYY hh:mm A')) || ''}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="email-preview-container">
                    <h3 className="heading">{'Email Preview'}</h3>
                    <div className="email-preview">
                        {
                            marketingData?.notificationType === EventMarketingNotificationType.EMAIL &&
                            <iframe
                                ref={iframeRef}
                                style={{ width: '100%', height: '100%', border: 'none' }}
                                title={`${marketingData?.name} Email content`}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}; 

export default EventMarketingPreview;