import { useEffect, useState } from 'react';
import { getEventById } from '../scripts/apis/events';
import _ from 'lodash';
import { uniqueTimezoneArr } from '../components/Events/timezoneGenerateFunction';
import eventBus from '../scripts/event-bus';
import { getAllEventCategories } from '../scripts/apis/eventCategory';
import { Event } from '../pages/Events/interfaces';
import { CustomFieldLocations, customFieldCountByLocation, customFieldsByLocation } from '../pages/Settings/CustomField/CustomField.helper';                                 
import { Integrations, IntegrationsState } from '../pages/Settings/interface/integration_interface';
import { useSelector } from 'react-redux';

const useEventOverviewData = (props: {eventId: number}): [Event , boolean, string, string,{[key:string]:string|number|string[]|number[]|boolean|{[key:string]:string|number|string[]|number[]|boolean}}[], Integrations[], React.Dispatch<React.SetStateAction<boolean>>] => 
{

    const integrations = useSelector((state: IntegrationsState): Integrations[] =>
    {
        return state.integrations.value;
    });

    const [eventDetails, setEventDetails] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const [timezone, setTimezone] = useState('');
    const [eventCategory, setEventCategory] = useState('');
    // const [integrations, setIntegrations] = useState<Integrations[]>([]);
    const [refreshEventData, setRefreshEventData] = useState<boolean>(false);
    const [customFields, setCustomFields] = useState([]);

    const fetchEventData = async (): Promise<void> =>
    {
        try 
        {
            const eventData: Event = await getEventById(String(props.eventId));
            if (eventData) 
            {
                setEventDetails(eventData);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            const eventData: Event = await getEventById(String(props.eventId));
            if (eventData) 
            {

                setEventDetails(eventData);

                try 
                {
                    const eventCategoryData = await getAllEventCategories();
                    if (eventCategoryData) 
                    {
                        const defaultCategory = _.filter(eventCategoryData, function (elem): boolean 
                        {
                            return elem.id === eventData?.eventCategoryId;
                        });
                        if (defaultCategory && defaultCategory[0]?.name !== '') 
                        {
                            setEventCategory(defaultCategory[0]?.name);
                        }
                        const cfcount = await customFieldCountByLocation(CustomFieldLocations.EVENT);
                        if(cfcount)
                        {
                            const customFields = await customFieldsByLocation(cfcount,0,CustomFieldLocations.EVENT);
                            if(customFields)
                            {
                                setCustomFields(customFields);
                            }
                        }
                       
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }

                const defaultTimezone = _.filter(uniqueTimezoneArr, function (elem): boolean 
                {
                    return elem.value === eventData.timezone;
                });
                if (defaultTimezone) 
                {
                    setTimezone(defaultTimezone[0]?.name ? defaultTimezone[0]?.name : '');
                }

                setSpinner(false);
                setRefresh(false);

            }

        }
        catch (error) 
        {
            console.log(error);
        }
    };

    // const fetchIntegrations = async (): Promise<void> =>
    // {
    //     try 
    //     {
    //         const integrationsData = await getIntegrationsData();
    //         if(integrationsData)
    //         {
    //             setIntegrations(integrationsData);
    //         }
    //     } 
    //     catch (error) 
    //     {
    //         console.log('Error fetching integrations', error)
    //     }
    // };

    useEffect((): void => 
    {
        fetchData();
        // fetchIntegrations();

        eventBus.on('event-overview-refreshed', (data): void => 
        {
            if (data.refresh) 
            {
                setRefresh(data.refresh);
            }
        });
    }, []);

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchData();
        }

    }, [refresh]);

    useEffect(() =>
    {
        if(refreshEventData)
        {   
            fetchEventData();
            setRefreshEventData(false);
        }
    }, [refreshEventData]);

    return [eventDetails,  spinner, timezone, eventCategory, customFields, integrations, setRefreshEventData];
};

export default useEventOverviewData;
