import React, { useState } from "react";
import { CloseIconComponent, FormControlComponent, FormLabelComponent } from "../../../common/FormComponents/ReusableFormComponents";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { EventAccessControl } from "../../../pages/Events/interfaces/event-access-control_interface";
import _ from "lodash";
import { createEventAccessControl, updateEventAccessControl } from "../../../scripts/apis/eventAccessControl";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { CONTENT } from "../../../scripts/i18n";
import { Form } from "react-bootstrap";
import { BpCheckbox } from "../../../common/CheckboxWrapper";
import { EnableSelfCheckin } from "../../../pages/Events/enum/event-access-control.enum";

interface IAddEventAccessControlProps 
{ 
    eventId: string | number;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    accessControlData?: EventAccessControl;
}

const AddEventAccessControl: React.FC<IAddEventAccessControlProps> = (props): React.JSX.Element => 
{  

    const { setRefresh, eventId, accessControlData } = props;
    const [spinner, setSpinner] = useState(false);

    const csrfTokenData = useSelector((state): string => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const handleDrawerClose = (): void => {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
    });
    
    const formik = useFormik({
        initialValues: {
            name: accessControlData?.deviceName || '',
            selfCheckin: accessControlData?.selfCheckin == EnableSelfCheckin.ENABLED ? EnableSelfCheckin.ENABLED : EnableSelfCheckin.DISABLED,
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            setSpinner(true);

            const accessControlFormData = {
                deviceName: values.name,
                selfCheckin: values.selfCheckin,
            };

            if(accessControlData)
            {
                // Update

                try 
                {
                    const accessControlUpdated = await updateEventAccessControl(accessControlFormData, eventId, accessControlData?.id);
                    if(accessControlUpdated)
                    {
                        setSpinner(false);
                        setRefresh(true);
                        handleDrawerClose();
                    }
                } 
                catch (error) 
                {
                    console.log('Error in updating Access Control', error);
                }
            }
            else
            {
                // Create
                
                try 
                {
                    const accessControlCreated = await createEventAccessControl(accessControlFormData, eventId, csrfTokenData);
                    if(accessControlCreated)
                    {
                        setSpinner(false);
                        setRefresh(true);
                        handleDrawerClose();
                    }
                } 
                catch (error) 
                {
                    console.log('Error in creating Access Control', error);
                }
            }
        },
    });

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('selfCheckin', event.target.checked ? EnableSelfCheckin.ENABLED : EnableSelfCheckin.DISABLED);
    };

    return(
        <div id="addEventAccessControl">
            <CloseIconComponent onClick={handleDrawerClose} />

            <Form noValidate autoComplete='off' onSubmit={(values: React.FormEvent<HTMLFormElement>): void => 
            {
                return formik.handleSubmit(values);
            }}>
                {/* Name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label="Name" required />
                    <FormControlComponent 
                        name="name" 
                        value={formik.values.name} 
                        placeholder="Master Device"
                        type="text"
                        required
                        onChange={formik.handleChange} 
                        error={formik.touched.name && !!formik.errors.name} 
                    />
                    {formik.touched.name && formik.errors.name && <p className="error-msg">{formik.errors.name}</p>}
                </Box>

                {/* Enable Self check In CheckBox */}
                <Box className="sidebar-container-spacing checkbox-container">
                    <BpCheckbox 
                         checked={formik.values.selfCheckin === EnableSelfCheckin.ENABLED}  
                         onChange={handleCheckBoxChange}
                    />
                    <p className="checkbox-label">Enable Self check In</p>
                </Box>

                {/* Submit button */}
                <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton onClick={handleDrawerClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton type="submit" disabled={spinner} loading={spinner} name={'Save'} btnType={'primary'} />
                    </Stack>
                </Box>
            </Form>
        </div>
    )
};

export default AddEventAccessControl;