import { Col, Row } from "react-bootstrap";
import AnalyticsPageHeader from "../Common/AnalyticsPageHeader";
import { IRevenueGraphReady, IRevenueOverview } from "./revenueAnalytics.interface";
import { GraphComponent } from "../Common/GraphComponent";
import { convertObjectToArrays, generateOpenOpportunitiesGraphOption } from "./barChartGenerator.helper";
import { convertToArrayOfObjectsForPieChart, NumberFormatter } from "../../../../../../scripts/helpers";
import { constructObjectForPieChart } from "../../../../../../common/ECharts/helpers/charts.helper";
import { useTablePagination } from "../../../../../../contexts/TablePaginationContext";
import { useEffect, useState } from "react";
import { getEventAnalyticsTable } from "../../../../../../scripts/apis/events";
import { EventAnalyticsType } from "../../../../enum";
import { EventAnalyticsTableData } from "../../../../interfaces/event-analytics_interface";
import TanstackTable from "../../../../../../common/TanstackTable/TanstackTable";
import { createColumnHelper } from "@tanstack/react-table";

import './styles.scss';

interface LeadsRevenueProps {
    leadsRevenueData: IRevenueOverview;
    graphReady: IRevenueGraphReady;
    leadsTableCount: number;
    eventId: string | number;
}

const LeadsRevenue: React.FC<LeadsRevenueProps> = (props): React.JSX.Element =>
{

    const { leadsRevenueData, graphReady, eventId, leadsTableCount } = props;

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const [leadsTableData, setLeadsTableData] = useState<EventAnalyticsTableData[]>([]);

    const leadStagesData = convertObjectToArrays(leadsRevenueData?.leadStages);
    const leadStageGraphOption = generateOpenOpportunitiesGraphOption(leadStagesData as any);

    const icpGraphData = convertToArrayOfObjectsForPieChart(leadsRevenueData?.leadsICP as any);
    const icpGraphOption = constructObjectForPieChart(icpGraphData, ['50%', '72%'], undefined, undefined);

    const bpGraphData = convertToArrayOfObjectsForPieChart(leadsRevenueData?.leadsBuyerPersona as any);
    const bpGraphOption = constructObjectForPieChart(bpGraphData, ['50%', '72%'], undefined, undefined);

    const columnHelper = createColumnHelper<EventAnalyticsTableData>();
    const columns = [
        columnHelper.accessor('dealName' as any, {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Leads',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'dealName'
        }),
        columnHelper.accessor('email', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Email',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'email'
        }),
        columnHelper.accessor('touchpoint', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Touch Point',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'touchPoint'
        }),
        columnHelper.accessor('amount', {
            cell: (row) => {
                return (<p className="cellContent">{`${row.getValue() && row?.getValue() !== '' && Number(row?.getValue()) !== 0 ? '$' + NumberFormatter(row?.getValue()) : '-'}`}</p>)
            },
            header: 'Amount',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'amount'
        }),
        columnHelper.accessor('status', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Status',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'status'
        }),
        columnHelper.accessor('created', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Created On',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'created'
        }),
    ];

    const fetchLeadsTableData = async (): Promise<void> =>
    {
        try 
        {
            const leadsTableData = await getEventAnalyticsTable(eventId, EventAnalyticsType.LEADS, pageSize || 25, currentPage - 1);
            if (leadsTableData) 
            {
                setLeadsTableData(leadsTableData);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchLeadsTableData();
    }, [pageSize, currentPage]);

    return (
        <div id="leadsRevenue">
            <AnalyticsPageHeader 
                header="Leads"
                description="Understand your deal performance and win rates"
            />

            <div className="content-div">
                {/* Row which renders lead stages, lead icp and bp */}
                <Row className="custom-row">
                    <Col className="px-0">
                        <GraphComponent 
                            chartOption={leadStageGraphOption}
                            height="240px"
                            title="Lead Stages"
                            graphReady={graphReady?.leadStages}
                            isDataAvailable={leadStagesData[1]?.length > 0 && leadStagesData[1]?.some((item: number | string) => item !== 0)}
                        />
                    </Col>

                    <Col className="px-0">
                        <Row className="custom-row">
                            <Col className="px-0">
                                <GraphComponent 
                                    title="ICP Breakdown"
                                    chartOption={icpGraphOption}
                                    height="240px"
                                    graphReady={graphReady?.leadsICP}
                                    isDataAvailable={icpGraphData.length > 0}
                                />
                            </Col>

                            <Col className="px-0">
                                <GraphComponent 
                                    title="Buyer Persona Breakdown"
                                    chartOption={bpGraphOption}
                                    height="240px"
                                    graphReady={graphReady?.leadsBuyerPersona}
                                    isDataAvailable={bpGraphData.length > 0}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Leads table */}
                <Row className="custom-row">
                    <Col className="px-0">
                        <div className="event-analytics-pagination-tables">
                            <TanstackTable 
                                data={leadsTableData}
                                initialColumns={columns}
                                rowCount={leadsTableCount}
                                showSpinner={false}
                                currentPage={currentPage - 1}
                                pageSize={pageSize}
                                showNoRowsOverlay
                                updateCurrentPage={updateCurrentPage as any}
                                updatePageSize={updatePageSize}
                                hidePagination={leadsTableCount == 0}
                                showBottomPadding={false}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default LeadsRevenue;