export enum EventCheckInNotificationsSource
{
    TAREGET_LIST = 1,
    REGISTRANTS = 2,
}

export enum EventCheckInNotificationsStatus
{
    ACTIVE = 1,
    DELETED = 2,
}

export enum EventRegistrantGuestType
{
    REGISTRANT = 1,
    SPEAKER = 2,
    SPONSOR = 3,
}

export enum EventCheckInNotificationsType
{
    HOST = 1,
    SPONSOR = 2,
}
