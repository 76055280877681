import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import _ from "lodash";
import './styles.scss';
import { UserContext } from "../../../../contexts/UserContext";
import { UserViewType } from "../../../Settings/enum/users.enum";
import { MinimalViewComponent } from "../../../../common/MinimalView/MinimalViewComponent";
import HeaderBar from "../../../../common/Headerbar";
import CheckInDevicesCardComponent from "./Components/Devices/CheckInDevicesCardComponent";
import NotificationsCardComponent from "./Components/Notifications/NotificationsCardComponent";
import { EventAccessControlContext } from "../../../../contexts/EventAccessControl/EventAccessControlContext";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";
import AddEventBadgeSettings from "../../../../components/Events/AccessControl/AddEventBadgeSettings";
import AddCardSettings from "../../../../components/Events/CheckinApp/AddCardSettings";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../../../components/Events/interfaces/create-events_interface";
import CustomTooltip from "../../../../common/Tooltip";

const EventAccessControlPage: React.FC = (): React.JSX.Element => { 

    const { userDetails } = useContext(UserContext);
    const minimalView = userDetails?.viewType === UserViewType.MINIMAL;

    const {
        eventAccessControlData,
        eventAccessControlCount,
        eventId,
        setRefresh,
        event,
        eventCheckInNotificationsData,
        eventCheckInNotificationsCount,
        updatePageSize,
    } = useContext(EventAccessControlContext);

    useEffect(() => {
        if(updatePageSize) updatePageSize(8);
    }, []);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const openEventBadgeSettingsSidedrawer = () =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Badge Settings',
            hideCloseButton: true,
            component: <AddEventBadgeSettings eventId={eventId} setRefresh={setRefresh} eventData={event} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };
    
    const openEventCardSettings = () => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'VCF Card Settings',
            hideCloseButton: true,
            component: <AddCardSettings eventId={eventId} setRefresh={setRefresh} formId={eventReduxData?.registrationFormId as string} existingVCardSettings={eventReduxData?.vcardSettings} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            // width: '50vw !important'
        });
    };

    const eventAccessControlView = () =>
    {
        return(
            <div id="eventAccessControlPage">
                <HeaderBar
                    title={'CheckIn App'}
                    buttons={[
                        <CustomTooltip title='VCF Card Settings' key={0}>
                            <div>
                                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'id-badge']} />} onClick={() => openEventCardSettings()} />
                            </div>
                        </CustomTooltip>,
                        <CustomTooltip title='Badge Settings' key={1}>
                            <div>
                                <CustomButton name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'qrcode']} />} onClick={(): void => {
                                    openEventBadgeSettingsSidedrawer();
                                }} />
                            </div>
                        </CustomTooltip>
                    ]}
                />

                <Box sx={{
                    flex: 1,
                    height: 'calc(100% - 60px)', 
                }}>
                    <CheckInDevicesCardComponent 
                        eventAccessControlData={eventAccessControlData}
                        eventAccessControlCount={eventAccessControlCount}
                        eventId={eventId}
                        setRefresh={setRefresh}
                        event={event}
                        minimalView={minimalView}
                    />
                    <NotificationsCardComponent 
                        eventCheckInNotificationsData={eventCheckInNotificationsData}
                        eventCheckInNotificationsCount={eventCheckInNotificationsCount}
                        eventId={eventId}
                        setRefresh={setRefresh}
                        minimalView={minimalView}
                    />
                </Box>

            </div>
        )
    };

    const eventAccessControlMinimalView = () =>
    {
        return(
            <div id="eventAccessControlPage">
                <MinimalViewComponent
                    title={'CheckIn App'}
                    description={'Manage your check in devices and notifications'}
                    titleBarActions={[
                        <CustomTooltip title='VCF Card Settings' key={0}>
                            <div>
                                <CustomButton
                                    key={0}
                                    btnType='tertiary'
                                    name=''
                                    startIcon={ <FontAwesomeIcon icon={ ['fal', 'id-badge'] } /> }
                                    onClick={() => openEventCardSettings()}
                                />
                            </div>
                        </CustomTooltip>,
                        <CustomTooltip title='Badge Settings' key={0}>
                            <div>
                                <CustomButton
                                    key={1}
                                    btnType='tertiary'
                                    name=''
                                    type='button'
                                    startIcon={ <FontAwesomeIcon icon={ ['fal', 'qrcode'] } /> }
                                    onClick={(): void => {
                                        openEventBadgeSettingsSidedrawer();
                                    }}
                                />
                            </div>
                        </CustomTooltip>,
                    ]}
                    component={
                        <div>
                            <CheckInDevicesCardComponent 
                                eventAccessControlData={eventAccessControlData}
                                eventAccessControlCount={eventAccessControlCount}
                                eventId={eventId}
                                setRefresh={setRefresh}
                                event={event}
                                minimalView={minimalView}
                            />
                             <NotificationsCardComponent 
                                eventCheckInNotificationsData={eventCheckInNotificationsData}
                                eventCheckInNotificationsCount={eventCheckInNotificationsCount}
                                eventId={eventId}
                                setRefresh={setRefresh}
                                minimalView={minimalView}
                            />
                        </div>
                    }
                />  
            </div>
        )
    };

    return minimalView ? eventAccessControlMinimalView() : eventAccessControlView();

};

export default EventAccessControlPage;