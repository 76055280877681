// Needed for future use

// import React, { useEffect, useState } from 'react';
// import { CustomField } from '../../../pages/Registration/interfaces/custom-field_interface';
// import { getCustomField, getCustomFieldCount } from '../../../scripts/apis/customField';
// import { CustomFieldLocations } from '../../../pages/Registration/enum/custom-field.enum';
// import { stripHtmlTags } from '../../../scripts/helpers';
// import { CloseIconComponent } from '../../../common/FormComponents/ReusableFormComponents';
// import { Autocomplete, Stack, TextField } from '@mui/material';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import MuiChip from '../../../common/FormComponents/MuiChip';
// import { CustomButton } from '../../../common/FormComponents/Buttons';
// import eventBus from '../../../scripts/event-bus';
// import APP_CONSTANTS from '../../../scripts/constants';
// import { CONTENT } from '../../../scripts/i18n';
// import _ from 'lodash';
// import toast from 'react-hot-toast';
// import { updateVCardSetting } from '../../../scripts/apis/events';
// import { EventVcardSetting } from '../../../pages/Events/interfaces';
// import { useDispatch } from 'react-redux';
// import { addEvent } from '../../../redux/events/Events';

// import './styles.scss';
// import { getRegistrationFormById } from '../../../scripts/apis/registrationForm';

// const AddCardSettings: React.FC<{ 
//     eventId: string | number, 
//     setRefresh: React.Dispatch<React.SetStateAction<boolean>> ,
//     formId: string;
//     existingVCardSettings?: EventVcardSetting;
// }> = ({ eventId, setRefresh, formId, existingVCardSettings }): React.JSX.Element => 
// {
//     const cardProperties = [
//         { name: "phonenumber", multiple: false },
//         // { name: "nickname", multiple: false },
//         { name: "role", multiple: false },
//         // { name: "social", multiple: true },
//         // { name: "birthday", multiple: false },
//         // { name: "url", multiple: true },
//         { name: "company", multiple: false },
//         // { name: "note", multiple: false },
//     ];
    
//     const dispatch = useDispatch();
//     const [customFields, setCustomFields] = useState<CustomField[]>([]);
//     const [spinner, setSpinner] = useState<boolean>(false);
//     const [mappings, setMappings] = useState<{ [key: string]: CustomField[] }>({});

//     const handleDrawerClose = (): void => {
//         eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
//             open: false,
//         });
//     };

//     const handleSelectChange = (property: string, newValue: CustomField | CustomField[]) => {
//         const propertyConfig = cardProperties.find(prop => prop.name === property);
//         const valueArray = propertyConfig?.multiple ? newValue : [newValue];

//         setMappings(prevMappings => ({
//             ...prevMappings,
//             [property]: Array.isArray(valueArray) ? valueArray as CustomField[] : []
//         }));
//     };

//     const handleSave = async () => 
//     {
//         if (Object.keys(mappings)?.length === 0) {
//             return toast.error('Please select at least one property');
//         }

//         const data = transformMappings(mappings);
//         try 
//         {
//             const vcardDetailsUpdated = await updateVCardSetting(eventId, data);
//             if (vcardDetailsUpdated)
//             {
//                 dispatch(addEvent({
//                     vcardSettings: data
//                 }))
//                 toast.success('VCF Card settings updated');
//                 setRefresh(true);
//                 handleDrawerClose();
//             }
//         } 
//         catch (error) 
//         {
//             console.log(error);
//             toast.error('Failed to update VCard settings');
//         }
//         finally
//         {
//             setSpinner(false);
//         }
        
//     };

//     const fetchData = async (): Promise<void> => {
//         try {
//             const customFieldData = await getRegistrationFormById(formId);
//             if (customFieldData) {
//                 const filteredCustomFields = customFieldData?.formFields?.filter((field: CustomField) => !field.default);
//                 if (filteredCustomFields) {
//                     setCustomFields(filteredCustomFields);
//                 }

//                 if (existingVCardSettings)
//                 {
//                     const mappedFields = {};
//                     Object.keys(existingVCardSettings).forEach(key => {
//                         if (existingVCardSettings[key] && existingVCardSettings[key] !== '')
//                         {
//                             mappedFields[key] = filteredCustomFields?.filter((field: CustomField) => field.propertyKey === existingVCardSettings[key]);
//                         }
//                         // if ((key === 'social' || key === 'url') && existingVCardSettings[key]?.length > 0) {
//                         //     mappedFields[key] = filteredCustomFields?.filter((field: CustomField) => existingVCardSettings[key].includes(field.propertyKey));
//                         // } else if (existingVCardSettings[key] && existingVCardSettings[key] !== '') {
//                         //     mappedFields[key] = filteredCustomFields?.filter((field: CustomField) => field.propertyKey === existingVCardSettings[key]);
//                         // }
//                     });
//                     setMappings(mappedFields);
//                 }
//             }
//         }
//         catch (error) {
//             console.log(error);
//         }
//     };

//     useEffect(() => {
//         fetchData();
//     }, []);

//     return (
//         <div id="addCardSettings">
//             <CloseIconComponent onClick={handleDrawerClose} />
//             <div className="card-settings-div">
//                 {cardProperties?.map((property, index) => (
//                     <div key={index} className="property-row">
//                         <h3 className="property-name">{_.startCase(property.name)}</h3>
//                         <div className="property-select-div">
//                             <Autocomplete
//                                 value={mappings[property.name]?.find(item => item.propertyKey === existingVCardSettings?.[property.name]) || []}
//                                 className="autocomplete-with-tags"
//                                 popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
//                                 multiple={property.multiple}
//                                 renderTags={(): null => {
//                                     return null;
//                                 }}
//                                 onChange={(event: React.SyntheticEvent<Element, Event>, newValue: CustomField | CustomField[] | null, reason: any): void => {
//                                     handleSelectChange(property.name, newValue as CustomField | CustomField[]);
//                                 }}
//                                 options={customFields}
//                                 renderInput={(params): React.JSX.Element => {
//                                     return <TextField className="autocomplete-placeholder" placeholder={`Choose a property for ${_.startCase(property?.name)}`} {...params} />;
//                                 }}
//                                 getOptionLabel={(option: CustomField): string => {
//                                     return stripHtmlTags(option?.fieldName);
//                                 }}
//                                 // selectOnFocus
//                                 // clearOnBlur
//                                 handleHomeEndKeys
//                                 renderOption={(props, option: string | number | any, { selected }): React.JSX.Element => {
//                                     return (
//                                         <li {...props}>
//                                             <p>{stripHtmlTags(option?.fieldName)}</p>
//                                             {selected && <FontAwesomeIcon className="check-icon" icon={['fal', 'check']} />}
//                                         </li>
//                                     );
//                                 }}
//                                 slotProps={{
//                                     paper: {
//                                         sx: {
//                                             borderRadius: '8px',
//                                             background: '#fff',
//                                             display: 'flex',
//                                             flexDirection: 'column',
//                                             alignItems: 'flex-start',
//                                             gap: '4px',
//                                             marginTop: '14px',
//                                             zIndex: 3,
//                                             width: '100%',
//                                             padding: '4px',
//                                             boxShadow: '0px 8px 24px -4px rgba(0, 0, 0, 0.25)',
//                                             '& .MuiAutocomplete-listbox': {
//                                                 width: '100% !important'
//                                             },
//                                             '& .MuiAutocomplete-input': {
//                                                 maxWidth: '150px',
//                                             },
//                                             '& .MuiAutocomplete-option': {
//                                                 color: 'var(--colors-text-text-primary-900, #101828)',
//                                                 fontSize: '16px',
//                                                 fontWeight: 500,
//                                                 fontStyle: 'normal',
//                                                 lineHeight: '24px',
//                                                 borderRadius: '8px',
//                                             },
//                                             '& .MuiAutocomplete-option.Mui-focused': {
//                                                 color: 'var(--colors-text-text-primary-900, #101828)',
//                                                 borderRadius: 'var(--spacing-sm, 6px)',
//                                                 backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
//                                             },
//                                             '& .MuiAutocomplete-option[aria-selected=true]': {
//                                                 color: 'var(--colors-text-text-primary-900, #101828)',
//                                                 borderRadius: 'var(--spacing-sm, 6px)',
//                                                 backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
//                                                 display: 'flex',
//                                                 justifyContent: 'space-between',
//                                             },
//                                             '& .check-icon': {
//                                                 color: '#1570EF'
//                                             }
//                                         }
//                                     }
//                                 }}
//                             />
//                             {/* {(mappings[property.name] && mappings[property.name]?.length > 0 && (property.multiple)) && <div className="autocomplete-selected-box-container">
//                                 {
//                                     mappings[property.name] && mappings[property.name]?.length > 0 && mappings[property.name]?.map((element, index) => {
//                                         const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];
//                                         return (
//                                             <>
//                                                 {
//                                                     element?.fieldName && element?.fieldName !== '' && 
//                                                     <MuiChip
//                                                         key={index}
//                                                         chipColor={chipColors[index % chipColors.length]}
//                                                         label={<Stack direction={'row'} spacing={1} key={index} className="autocomplete-selected-label">
//                                                             <p>{stripHtmlTags(element?.fieldName)}</p>
//                                                             <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-btn" onClick={(): void => {
//                                                                 setMappings(prevMappings => {
//                                                                     const updatedMappings = { ...prevMappings };
//                                                                     updatedMappings[property.name] = updatedMappings[property.name]?.filter((item: CustomField) => item?.propertyKey !== element?.propertyKey);
//                                                                     return updatedMappings;
//                                                                 });
//                                                             }} />
//                                                         </Stack>}
//                                                     />
//                                                 }
//                                             </>
//                                         );
//                                     })
//                                 }
//                             </div>} */}
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <div style={{ width: '50vw' }} className="submit-btn-container">
//                 <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
//                     <CustomButton onClick={handleDrawerClose} btnType='secondary' name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
//                     <CustomButton loading={spinner} disabled={spinner} onClick={handleSave} name={'Save'} btnType='primary' />
//                 </Stack>
//             </div>
//         </div>
//     )
// };

// export default AddCardSettings;

// const transformMappings = (mappings: { [key: string]: CustomField[] }): { [key: string]: string | string[] } => {
//     const transformed: { [key: string]: string | string[] } = {};

//     Object.keys(mappings).forEach(key => {
//         if (key === 'social' || key === 'url') {
//             transformed[key] = mappings[key].map(item => item.propertyKey);
//         } else {
//             transformed[key] = mappings[key][0].propertyKey;
//         }
//     });

//     return transformed;
// };

import React, { useEffect, useState } from 'react';
import { CustomField } from '../../../pages/Registration/interfaces/custom-field_interface';
import { stripHtmlTags } from '../../../scripts/helpers';
import { CloseIconComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { CONTENT } from '../../../scripts/i18n';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { EventVcardSetting } from '../../../pages/Events/interfaces';
import { useDispatch } from 'react-redux';
import { addEvent } from '../../../redux/events/Events';
import { getRegistrationFormById } from '../../../scripts/apis/registrationForm';

import './styles.scss';
import { updateVCardSetting } from '../../../scripts/apis/eventAccessControl';

const AddCardSettings: React.FC<{ 
    eventId: string | number, 
    setRefresh: React.Dispatch<React.SetStateAction<boolean>> ,
    formId: string;
    existingVCardSettings?: EventVcardSetting;
}> = ({ eventId, setRefresh, formId, existingVCardSettings }): React.JSX.Element => 
{
    const cardProperties = [
        { name: "phonenumber" },
        { name: "role" },
        // { name: "company" },
    ];

    const dispatch = useDispatch();
    const [customFields, setCustomFields] = useState<CustomField[]>([]);
    const [spinner, setSpinner] = useState<boolean>(false);
    const [mappings, setMappings] = useState<{ [key: string]: CustomField[] }>({});

    const handleDrawerClose = (): void => {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const handleSelectChange = (property: string, newValue: CustomField | null) => {
        setMappings(prevMappings => ({
            ...prevMappings,
            [property]: newValue ? [newValue] : []
        }));
    };

    const handleSave = async () => 
    {
        if (Object.keys(mappings)?.length === 0) {
            return toast.error('Please select at least one property');
        }

        const data = {
            phonenumber: mappings['phonenumber']?.[0]?.propertyKey || '',
            role: mappings['role']?.[0]?.propertyKey || '',
            company: mappings['company']?.[0]?.propertyKey || ''
        };

        try 
        {
            const vcardDetailsUpdated = await updateVCardSetting(eventId, data);
            if (vcardDetailsUpdated)
            {
                dispatch(addEvent({
                    vcardSettings: data
                }))
                toast.success('VCF Card settings updated');
                setRefresh(true);
                handleDrawerClose();
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error('Failed to update VCF Card settings');
        }
        finally
        {
            setSpinner(false);
        }
        
    };

    const fetchData = async (): Promise<void> => {
        try {
            const customFieldData = await getRegistrationFormById(formId);
            if (customFieldData) {
                const filteredCustomFields = customFieldData?.formFields?.filter((field: CustomField) => !field.default);
                if (filteredCustomFields) {
                    setCustomFields(filteredCustomFields);
                }

                if (existingVCardSettings)
                {
                    const mappedFields = {};
                    Object.keys(existingVCardSettings).forEach(key => {
                        if (existingVCardSettings[key] && existingVCardSettings[key] !== '')
                        {
                            mappedFields[key] = filteredCustomFields?.filter((field: CustomField) => field.propertyKey === existingVCardSettings[key]);
                        }
                    });
                    setMappings(mappedFields);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div id="addCardSettings">
            <CloseIconComponent onClick={handleDrawerClose} />
            <div className="card-settings-div">
                {cardProperties?.map((property, index) => (
                    <div key={index} className="property-row">
                        <h3 className="property-name">{_.startCase(property.name)}</h3>
                        <div className="property-select-div">
                            <Autocomplete
                                value={mappings[property.name]?.[0] || null}
                                className="autocomplete-with-tags"
                                popupIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
                                onChange={(event: React.SyntheticEvent<Element, Event>, newValue: CustomField | null): void => {
                                    handleSelectChange(property.name, newValue);
                                }}
                                options={customFields}
                                renderInput={(params): React.JSX.Element => {
                                    return <TextField className="autocomplete-placeholder" placeholder={`Choose a property for ${_.startCase(property?.name)}`} {...params} />;
                                }}
                                getOptionLabel={(option: CustomField): string => {
                                    return stripHtmlTags(option?.fieldName);
                                }}
                                handleHomeEndKeys
                                renderOption={(props, option: CustomField, { selected }): React.JSX.Element => {
                                    return (
                                        <li {...props}>
                                            <p>{stripHtmlTags(option?.fieldName)}</p>
                                            {selected && <FontAwesomeIcon className="check-icon" icon={['fal', 'check']} />}
                                        </li>
                                    );
                                }}
                                slotProps={{
                                    paper: {
                                        sx: {
                                            borderRadius: '8px',
                                            background: '#fff',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '4px',
                                            marginTop: '14px',
                                            zIndex: 3,
                                            width: '100%',
                                            padding: '4px',
                                            boxShadow: '0px 8px 24px -4px rgba(0, 0, 0, 0.25)',
                                            '& .MuiAutocomplete-listbox': {
                                                width: '100% !important'
                                            },
                                            '& .MuiAutocomplete-input': {
                                                maxWidth: '150px',
                                            },
                                            '& .MuiAutocomplete-option': {
                                                color: 'var(--colors-text-text-primary-900, #101828)',
                                                fontSize: '16px',
                                                fontWeight: 500,
                                                fontStyle: 'normal',
                                                lineHeight: '24px',
                                                borderRadius: '8px',
                                            },
                                            '& .MuiAutocomplete-option.Mui-focused': {
                                                color: 'var(--colors-text-text-primary-900, #101828)',
                                                borderRadius: 'var(--spacing-sm, 6px)',
                                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                                            },
                                            '& .MuiAutocomplete-option[aria-selected=true]': {
                                                color: 'var(--colors-text-text-primary-900, #101828)',
                                                borderRadius: 'var(--spacing-sm, 6px)',
                                                backgroundColor: 'var(--Colors-Background-bg-active, #F9FAFB) !important',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            },
                                            '& .check-icon': {
                                                color: '#1570EF'
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="submit-btn-container">
                <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                    <CustomButton onClick={handleDrawerClose} btnType='secondary' name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                    <CustomButton loading={spinner} disabled={spinner} onClick={handleSave} name={'Save'} btnType='primary' />
                </Stack>
            </div>
        </div>
    )
};

export default AddCardSettings;

const transformMappings = (mappings: { [key: string]: CustomField[] }): { [key: string]: string } => {
    const transformed: { [key: string]: string } = {};

    Object.keys(mappings).forEach(key => {
        transformed[key] = mappings[key][0].propertyKey;
    });

    return transformed;
};