import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import APP_CONSTANTS from '../../../../../scripts/constants';
import { CONTENT } from '../../../../../scripts/i18n';
import eventBus from '../../../../../scripts/event-bus';
import { deleteUtm, getAllUtm, getAllUtmCount } from '../../../../../scripts/apis/utm';
import { useTablePagination } from '../../../../../contexts/TablePaginationContext';
import TableEmptyComponent from '../../../../../common/TableEmptyComponent';
import CreateUtm from '../../../../../components/Events/EventShareLink/CreateUTM';
import { EventUtm } from '../../../interfaces';
import { ActionsDropdown } from '../../../EventBudget/EventBudgetDataGrid';
import utmEmptyImg from '../../../../../assets/icons/empty-state/ICPEmptyState.svg';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { useSelector } from 'react-redux';
import { IEventReduxValues, IEventsDispatch } from '../../../../../components/Events/interfaces/create-events_interface';
import CardComponent from '../../../../../components/Events/Registrations/CardComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import TanstackTable from '../../../../../common/TanstackTable/TanstackTable';
import HeaderBar from '../../../../../common/Headerbar';
import DeletePopup from '../../../../../common/DeletePopup';
import toast from 'react-hot-toast';
import EventsCardHeaderComponent from '../../../../../components/Events/EventComponents/EventsCardHeaderComponent';
import TanstackToolbarWithServerFilter from '../../../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { UserContext } from '../../../../../contexts/UserContext';
import { UserViewType } from '../../../../Settings/enum/users.enum';
import { MinimalViewComponent } from '../../../../../common/MinimalView/MinimalViewComponent';
import ColumnVisibilityDropdown from '../../../../../common/TanstackTable/ColumnVisibilityDropdown';
import { getEncodedParams } from '../../../../../scripts/helpers';
import Breadcrumbs from '../../../../../common/Breadcrumbs';

import './styles.scss';
import CustomTooltip from '../../../../../common/Tooltip';

const UtmComponent: React.FC<{eventId: string | number}> = (props: {eventId: string | number}): React.JSX.Element => 
{
    const navigate = useNavigate();

    const {
        userDetails,
        orgDetails
    } = useContext(UserContext);

    const searchParams = new URLSearchParams();
    const currentpath = useLocation().pathname;
    const isTableView = currentpath.includes('share-links');

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const eventLink = eventReduxData?.link;

    const [Utmrows, setUtmRows] = useState<EventUtm[]>([]);
    const [utmData, setUtmData] = useState<EventUtm[]>([]);
    const [UtmCount, setUtmCount] = useState<number>(0);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [refersh, setRefresh] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedUtm, setSelectedUtm] = useState<EventUtm | null>(null);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const { pageSize, currentPage, updatePageSize, updateCurrentPage } = useTablePagination();

    const leftNodes = [
        <CustomTooltip title='Table View' key={0}>
            <div>
                <CustomButton name='' startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />} btnType='tertiary' onClick={() => navigate('/events/' + props?.eventId + '/share-links')} />
            </div>
        </CustomTooltip>
    ];
    
    useEffect((): void => 
    {
        if(!isTableView)
        {
            fetchUtmData(pageSize, currentPage - 1);
        }
        // fetchOrgData();

        eventBus.on('utm_data_refresh', (data: any): void => 
        {
            setRefresh(data.refresh);
        });

    }, []);

    useEffect((): void => 
    {
        if (refersh) 
        {
            // fetchOrgData();
            fetchUtmData(pageSize, currentPage - 1);
        }
    }, [refersh]);

    useEffect((): void => 
    {
        if (currentPage > 0 && pageSize) 
        {
            fetchUtmData(pageSize, currentPage - 1);
        }
    }, [pageSize, currentPage, UtmCount]);

    useEffect((): void => 
    {
        fetchTotalUtmCount();
    }, [currentUrl]); 


    // const fetchOrgData = async (): Promise<void> =>
    // {
    //     try 
    //     {
    //         if(orgLinkLocalStorage)
    //         {
    //             setOrgLink(orgLinkLocalStorage);
    //         }
    //         else
    //         {
    //             const orgData = await orgMe();
    //             if(orgData)
    //             {
    //                 setOrgLink(orgData.link);
    //             }
    //         }
    //     } 
    //     catch (error) 
    //     {
    //         console.log(error);
    //     }
    // };

    const fetchTotalUtmCount = async (): Promise<void> =>
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await getAllUtmCount(props.eventId, encodedParams);
            if (count) 
            {
                setUtmCount(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    }

    const fetchUtmData = async (pageSizeValue?: number, offsetValue?: number): Promise<void> => 
    {
        try 
        {
            if(isTableView)
            {
                const encodedParams = getEncodedParams(currentUrl);
                const utmData = await getAllUtm(pageSizeValue || 0, offsetValue || 0, props.eventId, encodedParams);
                if (utmData) 
                {
                    setUtmRows(utmData);
                    setUtmData(utmData);
                    setRefresh(false);
                }
            }
            else
            {
                const utmData = await getAllUtm(6, 0, props.eventId);
                if (utmData) 
                {
                    setUtmRows(utmData);
                    setRefresh(false);
                }
            }

        }
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    const columnHelper = createColumnHelper<EventUtm>();

    const generateColumns = () => [
        columnHelper.accessor('name', {
            cell: (row) => <p className="bolded-cellContent">{row.getValue()}</p>,
            header: 'Name',
            size: 300,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('utmLink', {
            cell: (row) => <p className="cellContent">{row.getValue()}</p>,
            header: 'Share Link',
            size: 250,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'utmLink'
        }),
        columnHelper.accessor('submissionCount', {
            cell: (row) => <p className="cellContent">{row.getValue()}</p>,
            header: 'Count',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'submissionCount'
        }),
        columnHelper.accessor('copyLink' as any, {
            cell: (row) => utmLinkCopy(row.row.original),
            header: '',
            size: 50,
            enableResizing: false,
            enableSorting: false,
            id: 'copyLink'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => utmRowActions(row.row.original),
            header: '',
            size: 50,
            enableResizing: false,
            enableSorting: false,
            id: 'actions'
        })
    ];

    useEffect(() => {
        setAllColumns(generateColumns);
    }, [orgDetails?.link, eventReduxData?.link]);

    const [allColumns, setAllColumns] = useState(generateColumns());

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Link',
            id: 'utmLink',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Count',
            id: 'submissionCount',
            meta: {
                type: 'string'
            }
        },
    ];

    const handleCopyLink = (utmLink: string, couponName: string): void => 
    {
        let link = `${APP_CONSTANTS.PROTOCOL}://${orgDetails?.link}.${APP_CONSTANTS.APP_MAIN_DOMAIN}/${eventReduxData?.link}?utm_source=${utmLink}`;
        if (couponName) {
            link += `&coupon=${couponName}`;
        }
        navigator.clipboard.writeText(link);
        toast.success(CONTENT.EVENTS_MODULE.OVERVIEW.ALERT_CONTENT.SUCCESS);
    };

    const utmLinkCopy = (utmInfo: EventUtm): React.ReactElement => 
    {
        return (
            <Box className="copyUtmLink" onClick={(): void => 
            {
                handleCopyLink(utmInfo.utmLink, utmInfo.couponName);
            }} >
                <FontAwesomeIcon icon={['fal', 'copy']} className="copy-utm-text" />
            </Box>
        );
    };

    const utmRowActions = (utmInfo: EventUtm): React.ReactElement => 
    {
        const editClick = (): void => 
        {
            editUtmData(utmInfo);
        };
    
        const deleteClick = (): void => 
        {
            setSelectedUtm(utmInfo);
            setShowDeletePopup(true);
        };
    
        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const editUtmData = (utmInfo: EventUtm): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Update UTM',
            component: <CreateUtm setRefresh={setRefresh} isTableView={isTableView} utmData={utmInfo} eventId={Number(props.eventId)} createUtm={false} updateUtm={true} utmId={Number(utmInfo.id)} orgLink={orgDetails?.link as string} eventLink={eventLink} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const createUtmData = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Create UTM',
            component: <CreateUtm isTableView={isTableView} eventId={Number(props.eventId)} createUtm={true} updateUtm={false} orgLink={orgDetails?.link as string} eventLink={eventLink}  />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    // const handleDelete = (utmInfo: EventUtm): void => 
    // {
    //     const utmName = utmInfo.name;
    //     deleteUtmFromTable();
    // };

    const deleteUtmFromTable = async (utmInfo: EventUtm): Promise<void> => 
    {
        try 
        {
            const utmDeleted = await deleteUtm(Number(utmInfo?.id), props.eventId);
            if (utmDeleted) 
            {
                setShowDeletePopup(false);
                setSelectedUtm(null);
                toast.success('Share Link Deleted Successfully');
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }           
    };

    const renderHeader = () =>
    {
        return (
            <div className="utm-header">
                <CustomButton 
                    startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} 
                    name=''
                    btnType='tertiary'
                    onClick={() => navigate('/events/' + props?.eventId + '/registrations')}
                />
                <Breadcrumbs 
                    origin={['Registrations']}
                    active='Share Links'
                />
            </div>
        )
    }

    const renderNormalViewForUtm = (): React.ReactElement => {
        return (
            <div id="utmContainer" style={{ height: isTableView ? 'calc(100vh - 60px)' : '', overflow: isTableView ? 'hidden' : '' }}>
                {renderHeader()}
                
                <Box className="utmTableContent" height={`${APP_CONSTANTS.EVENTS_MODULE_STANDARD_COMPONENT_HEIGHT}`}>
                    {isEmpty ? (<TableEmptyComponent emptyImg={utmEmptyImg} openDrawer={createUtmData} infoText={'No Existing Share Links yet'} subInfoText={'Create your first Share Link'} buttonName={'Add Share Link'} />) : 
                    (
                        <div className="h-100">
                            {/* <TanstackToolbar 
                                columns={generateColumns()}
                                rows={Utmrows}
                                setColumns={setAllColumns}
                                setRows={setUtmData}
                                tabs={[
                                    {
                                        tabName: 'All',
                                        count: UtmCount as number,
                                        navigation: () => {},
                                        selectedTab: true
                                    }
                                ]}
                            /> */}
                            <TanstackToolbarWithServerFilter 
                                columns={generateColumns()}
                                filterColumns={filterColumns}
                                setColumns={setAllColumns}
                                setCurrentUrl={setCurrentUrl}
                                tabs={[{
                                    tabName: 'All',
                                    count: UtmCount as number,
                                    navigation: () => {},
                                    selectedTab: true
                                }]}
                                handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                rightCornerButton={
                                    <CustomButton 
                                        startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                                        name=''
                                        btnType='primary'
                                        onClick={() => createUtmData()}
                                    />
                                }
                            />
                            <TanstackTable 
                                initialColumns={allColumns}
                                data={utmData}
                                rowCount={UtmCount}
                                showNoRowsOverlay={UtmCount == 0}
                                pageSize={pageSize}
                                currentPage={currentPage - 1}
                                updatePageSize={updatePageSize}
                                updateCurrentPage={updateCurrentPage as any}
                                showSpinner={showSpinner}
                                // height='calc(100% - 124px)'
                                onRowClick={(row) => editUtmData(row)}
                                rightPinnedColumns={['actions']}
                            />
                        </div>
                    )}

                </Box>
            </div>
        );
    };

    const renderCardViewForUtm = (): React.ReactElement => { 

        const gridItemProps = userDetails?.viewType === UserViewType.NORMAL ? { xl: 4, lg: 4, md: 6, sm: 12, xs: 12 } : { xl: 4, lg: 4, md: 8, sm: 12, xs: 12 };

        return (
            <div id="utmContainer">
                <div className="utm-container">

                    <EventsCardHeaderComponent heading='Share Links' count={UtmCount} leftNodes={leftNodes} />

                    <Grid container spacing={2}>
                        {
                            Utmrows?.map((utm: EventUtm, index: number) => {

                                const header = (
                                    <CustomButton 
                                        name={utm.name}
                                        btnType='linkBtn'
                                        onClick={(event) => {
                                            event?.stopPropagation(); 
                                            handleCopyLink(utm.utmLink, utm.couponName);
                                        }}
                                    />
                                )
                                const ticketNames = utm?.tickets?.map((ticket) => ticket.name).join(', ');
                                const footerLeft = `Registrants: ${utm?.submissionCount}`;

                                return (
                                    <Grid key={index} item {...gridItemProps}>
                                        <CardComponent 
                                            header={header}
                                            headerBold={true}
                                            contentHeading={ticketNames}
                                            onClick={(): void => editUtmData(utm)}
                                            footerLeft={footerLeft}
                                            key={index}
                                        />
                                    </Grid>
                                )
                            })
                        }
                        <Grid item {...gridItemProps}>
                            <CardComponent 
                                emptyText='+ Add Share Link'
                                onClick={createUtmData}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    };

    const renderMinimalViewForUtm = (): React.ReactElement => { 
        return (
            <div id="utmContainerMinimalView">
                <MinimalViewComponent
                    title={renderHeader()}
                    titleBarStyle={{ padding: '0', borderBottom: '1px solid #F2F4F7' }}
                    tabData={[
                        {
                            tabName: 'All',
                            count: UtmCount as number,
                            navigation: () => { },
                            selectedTab: true
                        }
                    ]}
                    actions={[
                        !isEmpty && <TanstackToolbarWithServerFilter
                            key={1}
                            columns={generateColumns()}
                            filterColumns={filterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        !isEmpty && <ColumnVisibilityDropdown 
                            key={2}
                            columns={generateColumns()}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomButton
                            key={2}
                            name=''
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() => createUtmData()}
                        />
                    ]}
                    component={
                        <div className="minimalView-table-container">
                            {
                                isEmpty ?
                                    <TableEmptyComponent
                                        emptyImg={''}
                                        openDrawer={createUtmData}
                                        infoText={'No Share Links'}
                                        subInfoText={'Create your first Share Link'}
                                    />
                                    :
                                    <TanstackTable
                                        initialColumns={allColumns}
                                        data={utmData}
                                        rowCount={UtmCount}
                                        showNoRowsOverlay={UtmCount == 0}
                                        pageSize={pageSize}
                                        currentPage={currentPage - 1}
                                        updatePageSize={updatePageSize}
                                        updateCurrentPage={updateCurrentPage as any}
                                        showSpinner={showSpinner}
                                        // height='calc(100vh - 300px)'
                                        onRowClick={(row) => editUtmData(row)}
                                        rightPinnedColumns={['actions']}
                                        showBottomPadding={false}
                                    />
                            }
                        </div>
                    }
                    componentType='table'
                />
            </div>
        );
    };

    return (
        <>

            {
                isTableView && userDetails?.viewType === UserViewType.NORMAL && renderNormalViewForUtm()
            }

            {
                !isTableView && renderCardViewForUtm()
            }

            {
                isTableView && userDetails?.viewType === UserViewType.MINIMAL && renderMinimalViewForUtm()
            }

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() =>deleteUtmFromTable(selectedUtm as EventUtm)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedUtm(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedUtm?.name}?`}
                        modalTitle='Delete Share Link'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
            }

        </>
    );
};

export default UtmComponent;