import React, { useContext, useEffect, useState } from "react";
import { CustomButton } from "../../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableActions } from "../../../../../common/TableActions";
import { EventSpeakerSessionMaterial } from "../../../../Events/interfaces";
import PortalSidedrawer from "../../Common/PortalSidedrawer";
import AddSessionMaterial from "./AddSessionMaterial";
import { PortalContext } from "../../../../../contexts/Portal/PortalContext";
import { deleteSessionMaterial, getAllSessionMaterials } from "../../../../../scripts/apis/sponsorPortal/sponsorPortal";
import DeletePopup from "../../../../../common/DeletePopup";
import toast from "react-hot-toast";

interface SessionMaterialListProps {
    sessionId: string;
}

const SessionMaterialList: React.FC<SessionMaterialListProps> = (props): React.JSX.Element =>
{
    const {
        registrantData
    } = useContext(PortalContext);

    const { sessionId } = props;

    const [openSessionMaterialSidebar, setOpenSessionMaterialSidebar] = useState<boolean>(false);
    const [selectedPresentation, setSelectedPresentation] = useState<EventSpeakerSessionMaterial | null>(null);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [sessionMaterials, setSessionMaterials] = useState<EventSpeakerSessionMaterial[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);

    const handleOpenSidebar = (sessionMaterial?: EventSpeakerSessionMaterial): void =>
    {
        if(sessionMaterial)
        {
            setSelectedPresentation(sessionMaterial);
        }
        setOpenSessionMaterialSidebar(true);
    };

    const handleCloseSidebar = (): void =>
    {
        setOpenSessionMaterialSidebar(false);
        setSelectedPresentation(null);
    };

    const handleDelete = async (): Promise<void> =>
    {
        try 
        {
            const isDeleted = await deleteSessionMaterial(sessionId, selectedPresentation?.blobName as string);  
            if(isDeleted)
            {
                toast.success('Session Material deleted successfully');
                setSelectedPresentation(null);
                setShowDeletePopup(false);
                setRefresh(true);
            } 
        } 
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message || 'Failed to delete Session Material');
        }
    };

    const fetchSessionMaterials = async (): Promise<void> =>
    {
        try 
        {
            const sessionMaterials = await getAllSessionMaterials(sessionId);
            if(sessionMaterials)
            {
                setSessionMaterials(sessionMaterials);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(() =>
    {
        fetchSessionMaterials();
    }, []);

    useEffect(() =>
    {
        if(refresh)
        {
            fetchSessionMaterials();
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <div className="session-material">
            <div className="heading-div">
                <div className="heading-wrapper">
                    <h2 className="heading">{'Session Material'}</h2>
                </div>
                <CustomButton startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-from-bracket']} />} name={"Upload"} btnType="secondary" onClick={() => handleOpenSidebar()} />
            </div>

            <div className="session-material-container">
                {
                    sessionMaterials && sessionMaterials?.length > 0 && sessionMaterials?.map((material, index) => { 
                        const fileType = material?.fileName?.split('.').pop();
                        return (
                            <div className="session-material-table" key={index}>
                                <div className="session-material-name">
                                    <div className="material-icon-div">
                                        <FontAwesomeIcon icon={['fal', fileType === 'pdf' ? 'file' : 'file-ppt']} />
                                    </div>
                                    <h3>{material.fileName}</h3>
                                </div>
                                {
                                    registrantData?.eventSpeakerIdMap === material?.speakerId &&
                                    <div className="session-material-actions">
                                        <TableActions 
                                            actions={
                                                [
                                                    {
                                                        title: "Edit",
                                                        onClick: () => handleOpenSidebar(material)
                                                    },
                                                    {
                                                        title: "Delete",
                                                        onClick: () => {
                                                            setSelectedPresentation(material);
                                                            setShowDeletePopup(true);
                                                        }
                                                    }
                                                ]
                                            }
                                        />
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>

            {
                openSessionMaterialSidebar &&
                <PortalSidedrawer 
                    open={openSessionMaterialSidebar}
                    component={<AddSessionMaterial setRefresh={setRefresh} sessionId={sessionId} handleClose={handleCloseSidebar} existingMaterialInfo={selectedPresentation} />}
                    handleClose={handleCloseSidebar}
                    heading={selectedPresentation ? "Edit Presentation" :  "Add Presentation"}
                />
            }

            {
                showDeletePopup &&
                <DeletePopup 
                    modalTitle="Delete Session Material"
                    modalContent="Are you sure you want to delete this Session Material?"
                    acceptBtn="Delete"
                    rejectBtn="Cancel"
                    acceptClick={handleDelete}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                        setSelectedPresentation(null);
                    }}
                    show={showDeletePopup}
                />
            }
        </div>
    )
};

export default SessionMaterialList;