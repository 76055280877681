import React, { useContext, useEffect, useState } from "react";

import './styles.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Avatar, AvatarGroup, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import { EventSession } from "../../../Events/interfaces";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import PortalAgendaDetailed from "./PortalAgendaDetailed";
import { getAllEventAgenda } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import YourPhotosComponent from "../../Pages/Photos/YourPhotos";
import { PortalContext } from "../../../../contexts/Portal/PortalContext";

const PortalAgenda: React.FC = (): React.JSX.Element => 
{ 

    const {
        registrantData
    } = useContext(PortalContext);

    const [sessions, setSessions] = useState<EventSession[]>([]);
    const [viewType, setViewType] = useState<'agenda' | 'detailed'>('agenda');
    const [selectedSession, setSelectedSession] = useState<EventSession | null>(null);

    const SessionCard = (): React.JSX.Element =>
    {
        return (
            <div className="session-card-container">
                {
                    sessions?.map((session) => {

                        const speakerImage = session?.speakerIds && session?.speakerIds?.length > 0 ? session.speakerIds.map((item) => 
                        {
                            const ids = _.find(session?.speakers, ['id', String(item)]);
                            if (ids) 
                            {
                                return ids?.additionalInfo?.image;
                            }
                        }) : [];
                
                        const speakerName = session?.speakerIds && session?.speakerIds?.length > 0 ? session.speakerIds.map((item) => 
                        {
                            const ids = _.find(session?.speakers, ['id', String(item)]);
                            if (ids) 
                            {
                                return ids?.name;
                            }
                        }) : [];

                        const handleSessionClick = () =>
                        {
                            setSelectedSession(session);
                            setViewType('detailed');
                        }

                        return (
                            <div className="session-card" key={session.id} onClick={handleSessionClick}>
                                <div className="session-img">
                                    <img 
                                        src={session?.image || ''}
                                        alt={session?.imageAlternateText || "session-image"}
                                    />
                                </div>
                                <div className="session-details-container">
                                    <div className="session-details">
                                        <h3 className="session-title">{session?.title}</h3>
                                        <div className="session-stage-info">
                                            <FontAwesomeIcon icon={['fal', 'location-dot']} />
                                            <p className="stage-title">{session?.stage?.title || 'Main Stage'}</p>
                                        </div>
                                        <div className="session-timing">
                                            <FontAwesomeIcon icon={['fal', 'clock']} />
                                            <p className="session-timing-info">
                                                {
                                                    `${moment.unix(Number(session?.startDateTime)).format('hh:mm A')} - ${moment.unix(Number(session?.endDateTime)).format('hh:mm A')}`
                                                }
                                            </p>
                                        </div>
                                        <div className="session-date">
                                            <FontAwesomeIcon icon={['fal', 'calendar']} />
                                            <p className="session-date-info">{moment.unix(Number(session?.startDateTime)).format('DD, MMM YYYY')}</p>
                                        </div>
                                    </div>
                                    <div className="session-speaker-presentation-container">
                                        {
                                            session?.sessionMaterials && session?.sessionMaterials?.length === 0 &&
                                            <MuiChip 
                                                label="Presentation Missing"
                                                chipColor="red"
                                            />
                                        }
                                        <AvatarGroup>
                                        {speakerImage?.length > 0 && speakerImage.map((item, index) => 
                                            {
                                                return (
                                                    <Tooltip key={index} arrow placement="bottom" title={<Typography variant="subtitle2">{speakerName[index]}</Typography>}>
                                                        <Avatar className="speaker-avatar" key={index} alt="speaker-image" src={item}>
                                                            {item ? null : speakerName[index]?.charAt(0)}
                                                        </Avatar>
                                                    </Tooltip>
                                                )
                                            }
                                        )}
                                        </AvatarGroup>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const fetchSessions = async (): Promise<void> =>
    {
        try 
        {
            const agendaData = await getAllEventAgenda();
            if(agendaData)
            {
                setSessions(agendaData);
            }
        }
        catch (error) 
        {
          console.log('Error fetching sessions', error);
        }
    };

    useEffect(() => {
        fetchSessions();
    }, []);

    return (
        <div id="portalAgenda">
            {
                viewType === 'agenda' ?
                <>
                    <div className="agenda-title-container">
                        <h3 className="title">{'Agenda'}</h3>
                        <MuiChip label={sessions?.length} chipColor="grey" rounded />
                    </div>

                    <div>
                        <SessionCard />
                    </div>

                    {
                        registrantData?.eventSpeakerIdMap && !registrantData?.eventSponsorIdMap &&   
                        <YourPhotosComponent />
                    }
                </>
                :
                <PortalAgendaDetailed sessionData={selectedSession as EventSession} setViewType={setViewType} />
            }
        </div>
    );
};

export default PortalAgenda;