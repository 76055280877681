import { useSelector } from "react-redux";
import { Integrations, IntegrationsState } from "../../../pages/Settings/interface/integration_interface";
import { IntegrationTypes } from "../../../pages/Settings/enum/integrations.enum";
import { CustomSwitch } from "../../../common/StyledComponents/Switch.styled";
import { useEffect, useState } from "react";
import { FormControlLabel } from "@mui/material";
import { IEventReduxValues, IEventsDispatch } from "../interfaces/create-events_interface";
import { SyncCrm } from "../../../pages/Events/enum";
import { updateEvent } from "../../../scripts/apis/events";
import { useParams } from "react-router-dom";

import './styles.scss';
import toast from "react-hot-toast";

const IntegrationSync: React.FC = (): React.JSX.Element =>
{
    const { eventId } = useParams();

    const integrations = useSelector((state: IntegrationsState): Integrations[] =>
    {
        return state.integrations.value;
    });

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const isHubspotIntegrated = integrations?.some((integration) => integration.type === IntegrationTypes.HUBSPOT);

    const [isSyncEnabled, setIsSyncEnabled] = useState<boolean>(false);

    const handleSyncCrm = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> =>
    {
        setIsSyncEnabled(!isSyncEnabled);
        try 
        {
            const data= {
                syncCrm: event.target.checked ? SyncCrm.ENABLE : SyncCrm.DISABLE
            }
            const updateSyncCrm = await updateEvent(eventId as string, data);
            if(updateSyncCrm)
            {
                toast.success('Sync status updated successfully');
            }
        } 
        catch (error) 
        {
            toast.error('Failed to update Sync status');
        }
    }

    useEffect(() =>
    {
        setIsSyncEnabled(eventReduxData.syncCrm === SyncCrm.ENABLE);
    }, [eventReduxData?.syncCrm]);

    return (
        <>  
            {
                isHubspotIntegrated &&
                <div className="integration-sync-component">
                    <h3 className="title">{'Sync Registrants to Hubspot'}</h3>
                    <div className="switch-container">
                        <FormControlLabel
                            checked={isSyncEnabled}
                            control={<CustomSwitch
                                onChange={(event) => handleSyncCrm(event)}
                            />}
                            label={''}
                        />
                    </div>
                </div>
            }
        </>
    )
};

export default IntegrationSync;