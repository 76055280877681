/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useContext, useEffect, useState } from 'react';
import IntegrationsPage from './IntegrationsPage';
import { IntegrationTypes } from '../enum/integrations.enum';
import NavigationTabs from '../../../common/NavigationTabs';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { UserContext } from '../../../contexts/UserContext';
import { UserViewType } from '../enum/users.enum';
import { MinimalViewComponent } from '../../../common/MinimalView/MinimalViewComponent';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import HeaderBar from '../../../common/Headerbar';
import toast from 'react-hot-toast';

enum IntegrationGroups {
    CRM = 'CRM',
    PAYMENTS = 'Payments',
    MARKETING = 'Marketing',
    EVENT_REGISTRATION = 'Event Registration',
    CSM = 'CSM',
    ENRICHMENT = 'Enrichment',
    OTHERS = 'Others',
}

const IntegrationsTabPage = (): React.JSX.Element => {
    // const tab = props.subTab;

    const {
        userDetails    
    } = useContext(UserContext);

    const currentPath = useLocation().pathname;

    const allIntegrations = [
        {
            name: 'Email',
            description: 'Integrate your email and send emails using your custom domain',
            icon: 'envelope' as IconName,
            type: IntegrationTypes.EMAIL,
        },
        {
            name: 'HubSpot',
            description:
                'Connect data, teams and customers on one AI-powered customer platform. ',
            type: IntegrationTypes.HUBSPOT,
            group: IntegrationGroups.CRM,
            website: 'https://www.hubspot.com/',
        },
        {
            name: 'Razorpay',
            type: IntegrationTypes.RAZORPAY,
            comingSoon: false,
            group: IntegrationGroups.PAYMENTS,
            website: 'https://razorpay.com/',
            description: 'Automate transfers, collect payments  and manage your marketplace.'
        },
        {
            name: 'Luma',
            description: 'Set up an event page, invite friends and sell tickets.',
            type: IntegrationTypes.LUMA,
            comingSoon: false,
            noRedirect: true,
            group: IntegrationGroups.EVENT_REGISTRATION,
            website: 'https://lu.ma/',
        },
        {
            name: 'Google Calendar',
            type: IntegrationTypes.GOOGLE_CALENDAR,
            group: IntegrationGroups.OTHERS,
            comingSoon: false,
            website: 'https://calendar.google.com/',
            description: 'Organize your schedule and share events with others.',
        },
        {
            name: 'Zoom',
            type: IntegrationTypes.ZOOM,
            group: IntegrationGroups.EVENT_REGISTRATION,
            website: 'https://zoom.us/',
            description: 'Streamline communication, increase employee engagement and productivity.',
        },
        {
            name: 'Stripe',
            type: IntegrationTypes.STRIPE,
            group: IntegrationGroups.PAYMENTS,
            website: 'https://stripe.com/',
            description: 'Collect payments, manage subscriptions and verify identities.'
        },
        {
            name: 'Eventbrite',
            description:
                'Allow users to browse, create, and promote local events.',
            type: IntegrationTypes.EVENTBRITE,
            group: IntegrationGroups.EVENT_REGISTRATION,
            website: 'https://www.eventbrite.com/',
        },
        {
            name: 'Salesforce',
            description:
                'Cloud-based software which helps businesses connect to their customers.',
            type: IntegrationTypes.SALES_FORCE,
            comingSoon: true,
            group: IntegrationGroups.CRM,
            website: 'https://www.salesforce.com/',
        },
        {
            name: 'Pipedrive',
            description:
                'Manage leads, track communications, automate and integrate.',
            type: IntegrationTypes.PIPEDRIVE,
            comingSoon: true,
            group: IntegrationGroups.CRM,
            website: 'https://www.pipedrive.com/',
        },
        {
            name: 'Gainsight',
            description:
                'Enables businesses to capture, analyse, and act on customer data.',
            type: IntegrationTypes.GAINSIGHT,
            comingSoon: true,
            group: IntegrationGroups.CSM,
            website: 'https://www.gainsight.com/',
        },
        {
            name: 'Totango',
            description:
                'Prevents churn, increase revenue growth, and boost team productivity.',
            type: IntegrationTypes.TOTANGO,
            comingSoon: true,
            group: IntegrationGroups.CSM,
            website: 'https://www.totango.com/',
        },
        {
            name: 'Apollo.io',
            description:
                'Find buyers, close deals, and provides end-to-end sales solution.',
            type: IntegrationTypes.APOLLO_IO,
            comingSoon: true,
            group: IntegrationGroups.ENRICHMENT,
            website: 'https://www.apollo.io/',
        },
        {
            name: 'Zoominfo',
            description:
                'Enrich your records, score, route, and reveal buying intent from your visitors.',
            type: IntegrationTypes.ZOOMINFO,
            comingSoon: true,
            group: IntegrationGroups.ENRICHMENT,
            website: 'https://www.zoominfo.com/',
        },
        {
            name: 'Goldcast',
            description:
                'Drive revenue with engaging videos, webinars and events.',
            type: IntegrationTypes.GOLDCAST,
            comingSoon: true,
            group: IntegrationGroups.EVENT_REGISTRATION,
            website: 'https://www.goldcast.io/',
        },
        {
            name: 'Splashthat',
            description:
                'Create, manage and market events that grow your business.',
            type: IntegrationTypes.SPLASHTHAT,
            comingSoon: true,
            group: IntegrationGroups.EVENT_REGISTRATION,
            website: 'https://splashthat.com/',
        },
        {
            name: 'Marketo',
            description:
                'Attract and nurture customers where each touchpoint is tracked.',
            type: IntegrationTypes.MARKETO,
            comingSoon: true,
            group: IntegrationGroups.MARKETING,
            website: 'https://www.marketo.com/',
        },
        {
            name: 'Clearbit',
            description:
                'Enrich your records, score, route, and reveal buying intent from your visitors.',
            type: IntegrationTypes.CLEARBIT,
            comingSoon: true,
            group: IntegrationGroups.ENRICHMENT,
            website: 'https://clearbit.com/',
        },
    ];

    const [allIntegrationsData, setAllIntegrationsData] = useState(allIntegrations);

    const tabData = [
        {
            value: '1',
            title: 'All Integrations',
            to: '/settings/integrations',
            data: <IntegrationsPage integrationsArr={allIntegrationsData} />,
            selected: currentPath === '/settings/integrations',
        },
        {
            value: '2',
            title: 'CRM',
            to: '/settings/integrations/crm',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.CRM;
            }).true} />,
            selected: currentPath === '/settings/integrations/crm',
        },
        {
            value: '3',
            title: 'CSM Tools',
            to: '/settings/integrations/csm-tools',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.CSM;
            }).true} />,
            selected: currentPath === '/settings/integrations/csm-tools',
        },
        {
            value: '4',
            title: 'Enrichment',
            to: '/settings/integrations/enrichment',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.ENRICHMENT;
            }).true} />,
            selected: currentPath === '/settings/integrations/enrichment',
        },
        {
            value: '5',
            title: 'Event Registration Tools',
            to: '/settings/integrations/registration-tools',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.EVENT_REGISTRATION;
            }).true} />,
            selected: currentPath === '/settings/integrations/registration-tools',
        },
        {
            value: '6',
            title: 'Marketing Tools',
            to: '/settings/integrations/marketing-tools',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.MARKETING;
            }).true} />,
            selected: currentPath === '/settings/integrations/marketing-tools',
        },
        {
            value: '7',
            title: 'Payments',
            to: '/settings/integrations/payments',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.PAYMENTS;
            }).true} />,
            selected: currentPath === '/settings/integrations/payments',
        },
        {
            value: '8',
            title: 'Others',
            to: '/settings/integrations/others',
            data: <IntegrationsPage integrationsArr={_.groupBy(allIntegrationsData, (item) => {
                return item.group === IntegrationGroups.OTHERS;
            }).true} />,
            selected: currentPath === '/settings/integrations/others',
        }
    ];

    const renderIntegrations = (): React.JSX.Element => {
        return (
            <Box padding={userDetails?.viewType === UserViewType.MINIMAL ? '16px 0' : ''}>
                {userDetails?.viewType === UserViewType.NORMAL && 
                    <HeaderBar title={'Integrations'} showBorder />
                }
                <div className={userDetails?.viewType === UserViewType.NORMAL ? "integration-tab-container" : ""}>
                    <NavigationTabs borderBottom={'1px solid #F1F1F1'} tabData={tabData} />
                </div>
            </Box>
        );
    };

    const renderMinimalViewForIntegrations = (): React.JSX.Element => { 
        return (
            <div>
                <MinimalViewComponent 
                    title="Integrations"
                    description="Manage your integrations here."
                    component={renderIntegrations()}
                    componentType="list"
                />
            </div>
        )
    };

    useEffect(() => {
        if (window.location.search) {
            const searchParams = new URLSearchParams(window.location.search);

            if (searchParams.get('response') === 'account-exists') {
                const errorMsg = 'Account you are trying to integrate is connected to a different instance';

                setTimeout(() => {
                    toast.error(errorMsg || 'Something went wrong, please try again.');
                }, 0);
            }
        }
    }, []);
    
    return (
        <Box id="integrationsTabPage">
            {userDetails?.viewType === UserViewType.MINIMAL ? renderMinimalViewForIntegrations() : renderIntegrations()}
        </Box>
    );
};

export default IntegrationsTabPage;