/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import eventBus from '../../../scripts/event-bus';
import { createRegistrationFormFields, updateRegistrationFormFields } from '../../../scripts/apis/registrationForm';
import { useDispatch } from 'react-redux';
import { resetFormField } from '../../../redux/registrationsForms/FormComponentDisplayField';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import LexicalEditor from '../../../lexical';
import { CustomFieldDisabled, CustomFieldMandatory, CustomFieldType } from '../enum/custom-field.enum';
import { RegistrationForm } from '../interfaces/registration-form_interface';
import { createLeadCaptureField, updateLeadCaptureField } from '../../../scripts/apis/sponsorPortal/sponsorPortal';
import toast from 'react-hot-toast';

interface IStructuralSideDrawerProps {
    drawerTitle: string;
    formId: string | number;
    csrfTokenData: string;
    setRefresh: (refresh: boolean) => void;
    componentType: number;
    isHeader?: boolean;
    existingComponentData?: any;
    RegistrationFormData: RegistrationForm;
    contentCount?: {
        heading: number;
        paragraph: number;
    };
    leadCaptureFormId?: string | number;
}

const StructuralSideDrawer = ({ drawerTitle, formId, csrfTokenData, setRefresh, componentType, isHeader, existingComponentData, contentCount, leadCaptureFormId }: IStructuralSideDrawerProps): React.JSX.Element => 
{
    const dispatch = useDispatch();
    const [backBtnClicked, setBackBtnClicked] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        [isHeader ? 'header' : 'paragraph']: Yup.string().trim().required('This field is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            [isHeader ? 'header' : 'paragraph']: existingComponentData?.[isHeader ? 'heading' : 'paragraph'] ? existingComponentData[isHeader ? 'heading' : 'paragraph'] : '',
        },
        onSubmit: async (values): Promise<void> => 
        {
            let label = '';
            const currentTimestamp = Math.floor(new Date().getTime());
            if(componentType  === CustomFieldType.HEADER)
            {
                label = `form_${formId}_header_${currentTimestamp}`;
            }
            else
            {
                label = `form_${formId}_paragraph_${currentTimestamp}`;
            }

            const componentDetails = {
                type: componentType,
                label,
                placeholder: '',
                fieldMandatory: CustomFieldMandatory.NOT_MANDATORY,
                [isHeader ? 'heading' : 'paragraph']: values[isHeader ? 'header' : 'paragraph'],
                fieldDisabled: CustomFieldDisabled.NOT_DISABLED,
                fieldLocations: existingComponentData?.fieldLocations?.length > 0 ? existingComponentData?.fieldLocations : [5],
                fieldName: existingComponentData?.fieldName ? existingComponentData.fieldName : values.label,
            };

            // lead capture form api handlers
            if(leadCaptureFormId)
            {
                return handleSubmissionLeadCaptureForms(componentDetails);
            }

            if (existingComponentData) 
            {
                setSpinner(true);
                updateFormComponent(componentDetails);
            }
            else
            {
                setSpinner(true);
                createFormComponent(componentDetails);
            }
        }
    });

    const createFormComponent = async (componentDetails): Promise<void> => 
    {
        try 
        {
            const componentUpdated = await createRegistrationFormFields(Number(formId), componentDetails, csrfTokenData);
            if (componentUpdated) 
            {
                setSpinner(false);
                toast.success('Component successfully saved');
                setBackBtnClicked(true);
                setRefresh(true);
                dispatch(resetFormField());
                eventBus.dispatch('event-update-form-component', true);
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
        }
    };

    const updateFormComponent = async (componentDetails): Promise<void> => 
    {
        try 
        {
            const componentUpdated = await updateRegistrationFormFields(Number(formId), existingComponentData.id, componentDetails);

            if (componentUpdated) 
            {
                setSpinner(false);
                toast.success('Component successfully updated');
                setBackBtnClicked(true);
                setRefresh(true);
                dispatch(resetFormField());
                eventBus.dispatch('event-update-form-component', true);
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
        }
    };

    const handleSubmissionLeadCaptureForms = async (data: any): Promise<void> =>
    {
        setSpinner(true);

        const createLeadCaptureFormField = async (): Promise<void> =>
        {
            try 
            {
                const fieldCreated = await createLeadCaptureField(leadCaptureFormId, data);
                if (fieldCreated) 
                {
                    setSpinner(false);
                    toast.success('Field created successfully');
                    setBackBtnClicked(true);
                    setRefresh(true);
                    eventBus.dispatch('event-update-form-component', true);
                }
            } 
            catch (error) 
            {
                console.log(error);
                toast.error('Error in creating field');
            }
            finally
            {
                setSpinner(false);
            }
        };

        const updateLeadCaptureFormField = async (): Promise<void> =>
        {
            try 
            {
                const fieldUpdated = await updateLeadCaptureField(leadCaptureFormId, existingComponentData?.id, data);
                if (fieldUpdated) 
                {
                    setSpinner(false);
                    toast.success('Field updated successfully');
                    setBackBtnClicked(true);
                    setRefresh(true);
                    eventBus.dispatch('event-update-form-component', true);
                }
            } 
            catch (error) 
            {
                console.log(error);
                toast.error('Error in updating field');
            }
            finally
            {
                setSpinner(false);
            }
        };

        if (existingComponentData) 
        {
            updateLeadCaptureFormField();
        }
        else
        {
            createLeadCaptureFormField();
        }
    };

    useEffect((): void => 
    {
        if (backBtnClicked) 
        {
            eventBus.dispatch('event-open-form-Component-drawer', {
                componentType: 0
            });
            setBackBtnClicked(false);
            dispatch(resetFormField());
            eventBus.dispatch('event-update-form-component', true);
        }

    }, [backBtnClicked]);


    return (
        <Box id='textInputSideDrawer'>
            <Box padding={leadCaptureFormId ? '15px 24px' : '13px 24px'} className="drawer-header">
                <Box className="drawer-inner-cont" onClick={():void => 
                    {
                        setBackBtnClicked(true);
                        
                    }}>
                    <FontAwesomeIcon icon={['fal', 'arrow-left']} className='back-icon' />
                    <Typography className='component-type-name'>{drawerTitle}</Typography>
                </Box>
            </Box>
            <Box className="drawer-content">
                <Box className="editor-block">
                    <LexicalEditor value={isHeader ? formik.values.header : formik.values.paragraph} toolBarOptions={isHeader ? ['Bold', 'Italic', 'Underline', 'Link', 'BlockFormat'] : ['Bold', 'Italic', 'Underline', 'Link', 'BlockFormat']} handleChange={(html, json) => {
                        if (isHeader) 
                        {
                            formik.setFieldValue('header', html);
                        }
                        else 
                        {
                            formik.setFieldValue('paragraph', html);
                        }
                    }} />
                </Box>
                {(formik.touched['header'] || formik.touched['paragraph']) && (formik.errors['header'] || formik.errors['paragraph']) && <div className="error-msg">{formik.errors['header'] ? formik.errors['header'] : formik.values['paragraph'] }</div>}
            </Box>
            <Box className="bottom-btn-block">
                <CustomButton btnType='primary' name='Save' loading={spinner} type='submit' onClick={() => formik.handleSubmit()} />
            </Box>
        </Box>
    );
};

export default StructuralSideDrawer;