import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AutocompletewithKeyTags, FormLabelComponent, RadioGroupComponent } from '../../../../../../../common/FormComponents/ReusableFormComponents';
import { useFormik } from 'formik';
import { EventTargetList } from '../../../../../interfaces/event-target-list-interface';
import './styles.scss';
import { EventTicket } from '../../../../../interfaces';
import { eventTargetListCount, getEventTargetLists } from '../../../../../../../scripts/apis/eventTargetList';
import { EventCheckInNotificationsSource } from '../../../../../enum/event-check-in-notifications.enum';
import { getAlleventTickets, ticketsCount } from '../../../../../../../scripts/apis/eventTickets';
import { getAllICP, icpCount } from '../../../../../../../scripts/apis/icp';
import { buyerPersonaCount, getAllBuyerPersona } from '../../../../../../../scripts/apis/buyerPersona';
import { ICP } from '../../../../../../Settings/interface/icp_interface';
import { BuyerPersona } from '../../../../../../Settings/interface/buyer-persona_interface';
import { EventCheckInNotifications } from '../../../../../interfaces/event-check-in-notifications.interface';

interface SelectOptionNotificationProps
{
    eventId: string | number;
    targetListData: EventTargetList[];
    setTargetListData: React.Dispatch<React.SetStateAction<EventTargetList[]>>;
    guestTypeData: any[];
    setGuestTypeData: React.Dispatch<React.SetStateAction<any[]>>;
    ticketsData: EventTicket[];
    setTicketsData: React.Dispatch<React.SetStateAction<EventTicket[]>>;
    icpData: ICP[];
    setIcpData: React.Dispatch<React.SetStateAction<ICP[]>>;
    bpData: BuyerPersona[];
    setBpData: React.Dispatch<React.SetStateAction<BuyerPersona[]>>;
    notificationSource: EventCheckInNotificationsSource;
    setNotificationSource: React.Dispatch<React.SetStateAction<EventCheckInNotificationsSource>>;
    checkInNotifications: EventCheckInNotifications | undefined;
}

const SelectOptionNotification: React.FC<SelectOptionNotificationProps> = (
    {
        eventId,
        targetListData,
        setTargetListData,
        guestTypeData,
        setGuestTypeData,
        ticketsData,
        setTicketsData,
        icpData,
        setIcpData,
        bpData,
        setBpData,
        notificationSource,
        setNotificationSource,
        checkInNotifications
    }
) =>
{

    const [targetListFromDb, setTargetListFromDb] = useState<EventTargetList[]>([]);
    const [ticketsFromDb, setTicketsFromDb] = useState<EventTicket[]>([]);
    const [icpDataFromDb, setIcpDataFromDb] = useState<ICP[]>([]);
    const [bpDataFromDb, setBpDataFromDb] = useState<BuyerPersona[]>([]);

    const eventLocationOptions = [
        {
            name: 'Select Target Lists', value: 1, id: 1
        },
        {
            name: 'Select Filters', value: 2, id: 2
        },
    ];

    const eventGuestOptions = [
        {
            name: 'Registrants', value: 1, id: 1
        },
        {
            name: 'Speakers', value: 2, id: 2
        },
        {
            name: 'Sponsors', value: 3, id: 3
        }
    ]

    const formik = useFormik(
        {
            initialValues:
            {
                type: checkInNotifications?.source?.toString() || '1',
                targetList: checkInNotifications?.filters?.targetList || [],
                filters: checkInNotifications?.filters || [],
            },
            onSubmit: (): void =>
            {
                // onSubmit();
            }
        }
    );

    const handleNotificationSource = (event: React.ChangeEvent<HTMLInputElement | HTMLLIElement>): void =>
    {
        formik.setFieldValue('type', event.target.value);
        setNotificationSource(Number(event.target.value));
        if (Number(event.target.value) === EventCheckInNotificationsSource.TAREGET_LIST)
        {
            setGuestTypeData([]);
            setTicketsData([]);
            setIcpData([]);
            setBpData([]);
        }
        else if (Number(event.target.value) === EventCheckInNotificationsSource.REGISTRANTS)
        {
            setTargetListData([]);
        }
    };

    const handleTargetList = (event: ChangeEvent<HTMLInputElement>, newValue: any[]): void =>
    {
        setTargetListData(newValue);
    };

    const handleTargetListDelete = (index: number): void =>
    {
        const newSelectedTargetList = targetListData.filter((_, i) => i !== index);
        setTargetListData(newSelectedTargetList);
    };

    const handleGuestType = (event: ChangeEvent<HTMLInputElement>, newValue: any[]): void =>
    {
        setGuestTypeData(newValue);
    };

    const handleGuestTypeDelete = (index: number): void =>
    {
        const newSelectedGuestType = guestTypeData.filter((_, i) => i !== index);
        setGuestTypeData(newSelectedGuestType);
    };

    const handleTickets = (event: ChangeEvent<HTMLInputElement>, newValue: any[]): void =>
    {
        setTicketsData(newValue);
    };

    const handleTicketsDelete = (index: number): void =>
    {
        const newSelectedTickets = ticketsData.filter((_, i) => i !== index);
        setTicketsData(newSelectedTickets);
    };

    const handleIcp = (event: ChangeEvent<HTMLInputElement>, newValue: any[]): void =>
    {
        setIcpData(newValue);
    };

    const handleIcpDelete = (index: number): void =>
    {
        const newSelectedIcp = icpData.filter((_, i) => i !== index);
        setIcpData(newSelectedIcp);
    };

    const handleBp = (event: ChangeEvent<HTMLInputElement>, newValue: any[]): void =>
    {
        setBpData(newValue);
    };

    const handleBpDelete = (index: number): void =>
    {
        const newSelectedBp = bpData.filter((_, i) => i !== index);
        setBpData(newSelectedBp);
    };

    const fetchData = async () => 
    {
        try {
            const count = await eventTargetListCount(eventId);
            const targetListData = await getEventTargetLists(eventId, count, 0);
            setTargetListFromDb(targetListData);

            const ticketCount = await ticketsCount(eventId);
            const ticketsData = await getAlleventTickets(ticketCount, 0, eventId);
            setTicketsFromDb(ticketsData);

            const icpCountData = await icpCount();
            const icpData = await getAllICP(icpCountData, 0);
            setIcpDataFromDb(icpData);

            const bpCount = await buyerPersonaCount();
            const bpData = await getAllBuyerPersona(bpCount, 0);
            setBpDataFromDb(bpData);
        } catch (error) {
            console.error('Error fetching target list data:', error);
            throw error;
        }
    };

    useEffect(() => {   
        fetchData();
    }, [eventId]);

    useEffect(() => {
        if (checkInNotifications) {
            setNotificationSource(checkInNotifications.source);

            const targetList = checkInNotifications.filters?.targetList?.map(id => targetListFromDb.find(item => Number(item.id) === Number(id))).filter((item): item is EventTargetList => item !== undefined) || [];
            setTargetListData(targetList);

            const guestType = checkInNotifications.filters?.guestType
            ?.map(name => eventGuestOptions.find(option => option.name === name))
            .filter((item): item is typeof eventGuestOptions[0] => item !== undefined) || [];
            setGuestTypeData(guestType);

            const tickets = checkInNotifications.filters?.tickets?.map(id => ticketsFromDb.find(item => Number(item.id) === Number(id))).filter((item): item is EventTicket => item !== undefined) || [];
            setTicketsData(tickets);

            const icp = checkInNotifications.filters?.icp?.map(id => icpDataFromDb.find(item => Number(item.id) === Number(id))).filter((item): item is ICP => item !== undefined) || [];
            setIcpData(icp);

            const bp = checkInNotifications.filters?.bp?.map(id => bpDataFromDb.find(item => Number(item.id) === Number(id))).filter((item): item is BuyerPersona => item !== undefined) || [];
            setBpData(bp);
        }
    }, [checkInNotifications, targetListFromDb, ticketsFromDb, icpDataFromDb, bpDataFromDb]);

    return (
        <div id='SelectOptionContainer'>
            <Form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
                <div className='sidebar-container-spacing'>
                    <FormLabelComponent label={'Select Options'} required={true} />
                    <RadioGroupComponent
                        row
                        options={eventLocationOptions}
                        value={notificationSource}
                        onChange={handleNotificationSource}
                    />
                    {
                        formik.errors.type && formik.touched.type ? (
                            <p className='error-msg'>{formik.errors.type}</p>
                        ) : null
                    }
                </div>

                {
                    notificationSource === EventCheckInNotificationsSource.TAREGET_LIST &&  (
                        <div className='sidebar-container-spacing'>
                            <FormLabelComponent label={'Select Lists'} required={true} />
                            <div className='event-category-container'>
                                <div className='w-100'>
                                    <AutocompletewithKeyTags
                                        value={targetListData}
                                        defaultValue={targetListData}
                                        keyToStore="id"
                                        keyToShow="name"
                                        onChange={(event, newValue) => handleTargetList(event, newValue)}
                                        onRemoveClick={handleTargetListDelete}
                                        options={targetListFromDb}
                                        placeholder='Select Target List'
                                    />
                                </div>
                            </div>
                            {
                                formik.errors.targetList && formik.touched.targetList ? (
                                    <p className='error-msg'>{formik.errors.targetList}</p>
                                ) : null
                            }
                        </div>
                    )
                }

                {
                    notificationSource === EventCheckInNotificationsSource.REGISTRANTS && (
                        <>
                            <div className='sidebar-container-spacing'>
                                <FormLabelComponent label={'Select Guest Type'} required={true} />
                                <div className='event-category-container'>
                                    <div className='w-100'>
                                        <AutocompletewithKeyTags
                                            value={guestTypeData}
                                            defaultValue={guestTypeData}
                                            keyToStore="id"
                                            keyToShow="name"
                                            onChange={(event, newValue) => handleGuestType(event, newValue)}
                                            onRemoveClick={handleGuestTypeDelete}
                                            options={eventGuestOptions}
                                            placeholder='Select Guest Type'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='sidebar-container-spacing'>
                                <FormLabelComponent label={'Select Tickets'} />
                                <div className='event-category-container'>
                                    <div className='w-100'>
                                        <AutocompletewithKeyTags
                                            value={ticketsData}
                                            defaultValue={ticketsData}
                                            keyToStore="id"
                                            keyToShow="name"
                                            onChange={(event, newValue) => handleTickets(event, newValue)}
                                            onRemoveClick={handleTicketsDelete}
                                            options={ticketsFromDb}
                                            placeholder='Select Tickets'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='sidebar-container-spacing'>
                                <FormLabelComponent label={'Select ICP'} />
                                <div className='event-category-container'>
                                    <div className='w-100'>
                                        <AutocompletewithKeyTags
                                            value={icpData}
                                            defaultValue={icpData}
                                            keyToStore="id"
                                            keyToShow="name"
                                            onChange={(event, newValue) => handleIcp(event, newValue)}
                                            onRemoveClick={handleIcpDelete}
                                            options={icpDataFromDb}
                                            placeholder='Select ICP'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='sidebar-container-spacing'>
                                <FormLabelComponent label={'Select Buyer Persona'} />
                                <div className='event-category-container'>
                                    <div className='w-100'>
                                        <AutocompletewithKeyTags
                                            value={bpData}
                                            defaultValue={bpData}
                                            keyToStore="id"
                                            keyToShow="name"
                                            onChange={(event, newValue) => handleBp(event, newValue)}
                                            onRemoveClick={handleBpDelete}
                                            options={bpDataFromDb}
                                            placeholder='Select BP'
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                formik.errors.filters && formik.touched.filters ? (
                                    <p className='error-msg'>{formik.errors.filters}</p>
                                ) : null
                            }
                        </>
                    )
                }
            </Form>
        </div>
    );
};

export default SelectOptionNotification;