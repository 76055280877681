import { Col, Row } from "react-bootstrap";
import AnalyticsPageHeader from "../Common/AnalyticsPageHeader";
import { NumberFormatter } from "../../../../../../scripts/helpers";
import CardComponent from "../../../../../../components/Events/Registrations/CardComponent";
import MuiChip from "../../../../../../common/FormComponents/MuiChip";
import { convertObjectToArrays, generateOpenOpportunitiesGraphOption } from "./barChartGenerator.helper";
import { GraphComponent } from "../Common/GraphComponent";
import { IRevenueGraphReady, IRevenueOverview } from "./revenueAnalytics.interface";
import { useTablePagination } from "../../../../../../contexts/TablePaginationContext";
import { getEventAnalyticsTable } from "../../../../../../scripts/apis/events";
import { EventAnalyticsType } from "../../../../enum";
import { useEffect, useState } from "react";
import { EventAnalyticsTableData } from "../../../../interfaces/event-analytics_interface";
import TanstackTable from "../../../../../../common/TanstackTable/TanstackTable";
import { createColumnHelper } from "@tanstack/react-table";

import './styles.scss';

interface ClosedWonProps {
    closedWonOverviewData: IRevenueOverview;
    graphReady: IRevenueGraphReady;
    closedWonTableCount: number;
    eventId: string | number;
}

const ClosedWon: React.FC<ClosedWonProps> = (props): React.JSX.Element =>
{

    const { closedWonOverviewData, graphReady, closedWonTableCount, eventId } = props;

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const [closedWonTableData, setClosedWonTableData] = useState<EventAnalyticsTableData[]>([]);

    const closedWonCardData =
    [
        {
            name: 'Closed Won',
            value: closedWonOverviewData?.closedWonOverview?.closedWon && Number(closedWonOverviewData?.closedWonOverview?.closedWon) > 0 ? `$${NumberFormatter(closedWonOverviewData?.closedWonOverview?.closedWon)}` : '-',
            chipLabel: closedWonOverviewData?.closedWonOverview?.closedWonCount && NumberFormatter(closedWonOverviewData?.closedWonOverview?.closedWonCount) || '-',
            chipColor: 'green'
        },
        {
            name: 'New Revenue',
            value: closedWonOverviewData?.closedWonOverview?.newRevenue && Number(closedWonOverviewData?.closedWonOverview?.newRevenue) > 0 ? `$${NumberFormatter(closedWonOverviewData?.closedWonOverview?.newRevenue)}` : '-',
            chipLabel: closedWonOverviewData?.closedWonOverview?.newRevenueCount && NumberFormatter(closedWonOverviewData?.closedWonOverview?.newRevenueCount) || '-',
            chipColor: 'grey'
        },
        {
            name: 'Influenced Revenue',
            value: closedWonOverviewData?.closedWonOverview?.influencedRevenue && Number(closedWonOverviewData?.closedWonOverview?.influencedRevenue) > 0 ? `$${NumberFormatter(closedWonOverviewData?.closedWonOverview?.influencedRevenue)}` : '-',
            chipLabel: closedWonOverviewData?.closedWonOverview?.influencedRevenueCount && NumberFormatter(closedWonOverviewData?.closedWonOverview?.influencedRevenueCount) || '-',
            chipColor: 'grey'
        },
        {
            name: 'Closed Lost',
            value: closedWonOverviewData?.closedWonOverview?.closedLost && Number(closedWonOverviewData?.closedWonOverview?.closedLost) > 0 ? `$${NumberFormatter(closedWonOverviewData?.closedWonOverview?.closedLost)}` : '-',
            chipLabel: closedWonOverviewData?.closedWonOverview?.closedLostCount && NumberFormatter(closedWonOverviewData?.closedWonOverview?.closedLostCount) || '-',
            chipColor: 'grey'
        }
    ];

    const closedWonIcpGraphData = convertObjectToArrays(closedWonOverviewData?.closedWonICP);
    const closedWonBpGraphData = convertObjectToArrays(closedWonOverviewData?.closedWonBuyerPersona);

    const closedWonIcpGraphOption = generateOpenOpportunitiesGraphOption(closedWonIcpGraphData as any);
    const closedWonBpGraphOption = generateOpenOpportunitiesGraphOption(closedWonBpGraphData as any);

    const columnHelper = createColumnHelper<EventAnalyticsTableData>();
    const columns = [
        columnHelper.accessor('dealName', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Deal Name',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'dealName'
        }),
        columnHelper.accessor('accountName', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Account Name',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'accountName'
        }),
        columnHelper.accessor('touchpoint', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Touch Point',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'touchPoint'
        }),
        columnHelper.accessor('amount', {
            cell: (row) => {
                return (<p className="cellContent">{`${row.getValue() && row?.getValue() !== '' && Number(row?.getValue()) !== 0 ? '$' + NumberFormatter(row?.getValue()) : '-'}`}</p>)
            },
            header: 'Amount',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'amount'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Type',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'type'
        }),
        columnHelper.accessor('closedOn', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue() ? row.getValue() : '-'}</p>)
            },
            header: 'Closed On',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'closedOn'
        }),
        columnHelper.accessor('timeline', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue() ? row.getValue() : '-'}</p>)
            },
            header: 'Timeline',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'timeline'
        })
    ];

    const fetchClosedWonTableData = async (): Promise<void> =>
    {
        try 
        {
            const closedWonTableData = await getEventAnalyticsTable(eventId, EventAnalyticsType.CLOSEDWON, pageSize || 25, currentPage - 1);
            if (closedWonTableData) 
            {
                setClosedWonTableData(closedWonTableData);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchClosedWonTableData();
    }, [pageSize, currentPage]);

    return (
        <div id="closedWon">
            <AnalyticsPageHeader 
                header='Closed Won'
                description='Understand your deal performance and win rates'
            />

            <div className="content-div">
                {/* Cards containing closed won, new revenue, influenced revenue and closed lost */}
                <Row className="custom-row">
                    {
                        closedWonCardData?.map((info, index) => {
                            return (
                                <Col className="px-0" key={index}>
                                    <CardComponent 
                                        header={info.name}
                                        footerLeft={info.value}
                                        footerStringBoldLeft
                                        footerRight={info.chipLabel && <MuiChip circleIcon label={info.chipLabel} chipColor={info.chipColor as 'grey' | 'green'} />}
                                        cardMinHeight="82px"
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>

                {/* Closed Won icp and bp graphs */}
                <Row className="custom-row">
                    <Col className="px-0">
                        <GraphComponent 
                            title="Closed Won ICP"
                            chartOption={closedWonIcpGraphOption}
                            height="240px"
                            graphReady={graphReady?.closedWonICP}
                            isDataAvailable={closedWonIcpGraphData && closedWonIcpGraphData[1]?.length > 0 && closedWonIcpGraphData[1]?.some((item: number | string) => item !== 0)}
                        />
                    </Col>

                    <Col className="px-0">
                        <GraphComponent 
                            title="Closed Won Buyer Persona"
                            chartOption={closedWonBpGraphOption}
                            height="240px"
                            graphReady={graphReady?.closedWonBuyerPersona}
                            isDataAvailable={closedWonBpGraphData && closedWonBpGraphData[1]?.length > 0 && closedWonBpGraphData[1]?.some((item: number | string) => item !== 0)}
                        />
                    </Col>
                </Row>

                {/* Closed Won table */}
                <Row className="custom-row">
                    <Col className="px-0">
                        <div className="event-analytics-pagination-tables">
                            <TanstackTable 
                                data={closedWonTableData}
                                initialColumns={columns}
                                rowCount={closedWonTableCount}
                                showSpinner={false}
                                currentPage={currentPage - 1}
                                pageSize={pageSize}
                                showNoRowsOverlay
                                updateCurrentPage={updateCurrentPage as any}
                                updatePageSize={updatePageSize}
                                hidePagination={closedWonTableCount == 0}
                                showBottomPadding={false}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default ClosedWon;