import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import { Form } from 'react-bootstrap';
import { createUtm, deleteUtm, getUtmActivityLogs, updateUtm } from '../../../scripts/apis/utm';
import { useSelector } from 'react-redux';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import { AutocompleteComponent, AutocompletewithTags, CloseIconComponent, FormControlComponent, FormLabelComponent, ToggleButtonGroupComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { IEventReduxValues, IEventsDispatch } from '../interfaces/create-events_interface';
import { getAlleventTickets, ticketsCount } from '../../../scripts/apis/eventTickets';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { CONTENT } from '../../../scripts/i18n';
import { EnableApprovalStatus, EventRegistrationTypes } from '../../../pages/Events/enum';
import _ from 'lodash';
import { EventTicket, EventUtm } from '../../../pages/Events/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transformTitleToLink } from '../../../scripts/helpers';
import DeletePopup from '../../../common/DeletePopup';
import toast from 'react-hot-toast';
import { getCouponCount, getCoupons } from '../../../scripts/apis/eventTicketCoupons';
import { EventCoupon } from '../../../pages/Events/interfaces/event-coupon_interface';
import { ActivityLogResponse } from '../../../interfaces/activity-log_interface';

import './styles.scss';
import ActivityLogViewer from '../../../common/ActivityLog/ActivityLogViewer';
import ActivityLogComponent from '../../../common/ActivityLog/ActivityLogComponent';
import { eventUtmActivityLog } from '../../../scripts/activityLogGenerator/activitylog-generator';

import './styles.scss';

interface ICreateUtmProps {
    eventId: number;
    updateUtm: boolean;
    createUtm: boolean;
    orgLink:string;
    eventLink: string;
    utmId?: number;
    utmData?: EventUtm;
    isTableView?: boolean;
    deleteUtmFromTable?: (utmInfo: EventUtm) => void;
    setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
}
interface IUtmPayload {
    name: string;
    utmLink: string;
    eventId: number;
}

const CreateUtm: React.FC<ICreateUtmProps> = (props: ICreateUtmProps): React.JSX.Element => 
{
    const [spinner, setSpinner] = useState(false);
    const defaultDetailsObj = {
        name: '', utmLink: '', type: '', eventTicketId: [], eventCouponId: ''
    };

    const utmSourceOptions = 
    [
        {
            name: 'Ticket', value: 1, icon: <FontAwesomeIcon icon={['fal', 'ticket-perforated']} />
        },
        {  
            name: 'Coupons', value: 2, icon: <FontAwesomeIcon icon={['fal', 'percent']} />
        }
    ];
    const [existingUtmInfo, setExistingUtmInfo] = useState(defaultDetailsObj);
    const [eventTickets, setEventTickets] = useState<EventTicket[]>([]);
    const [ticketsAvailable, setTicketsAvailable] = useState(0);
    const [isAllTicketsAreOpen, setAllTicketsAreOpen] = useState(false);
    const [expiredTickets, setExpiredTickets] = useState<string[]>([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [couponData, setCouponData] = useState<EventCoupon[]>([]);
    const [utmSource, setUtmSource] = useState<number>(1);
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    // Fetches all the event tickets, checks for the approval required tickets, if approval required tickets are present then it sets the tickets for the ticket select component
    const fetchEventTicketsAndProps = async (): Promise<void> => 
    {
        let eventTickets: EventTicket[] = [];
        let coupons: EventCoupon[] = [];
        try 
        {
            const count = await ticketsCount(props.eventId);
            if (count) 
            {
                eventTickets = await getAlleventTickets(count, 0, props.eventId);
                if (eventTickets && eventTickets.length > 0) 
                {
                    setEventTickets(eventTickets);

                    const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
                    const expiredTickets = eventTickets?.filter((ticket) => ticket?.saleCloseDateTime < currentTimestamp)?.map((ticket) => ticket?.name) || [];
                    setExpiredTickets(expiredTickets);
    
                    const isTicketHasApprovalRequired = eventTickets.filter((item) => 
                    {
                        return item?.enableApproval === EnableApprovalStatus.ENABLE; 
                    })?.length > 0;
                    if (!isTicketHasApprovalRequired) 
                    {
                        setAllTicketsAreOpen(true);
                    }
                }
            }

            const couponCount = await getCouponCount(props.eventId);
            if (couponCount) 
            {
                coupons = await getCoupons(props.eventId, couponCount, 0);
                if (coupons && coupons.length > 0) 
                {
                    setCouponData(coupons);
                }
            }

            const utmData = props?.utmData;
            if (utmData) 
            {
                const ticketIds = eventTickets?.length > 0 && utmData?.eventTicketIds?.length > 0
                    ? utmData?.eventTicketIds?.map((item) => 
                    {
                        const ids = _.find(eventTickets, ['id', String(item)]);
                        return ids ? ids.name : null;
                    }).filter(Boolean) || []
                    : [];
                
                const couponName = utmData?.eventCouponId ? _.find(coupons, ['id', utmData?.eventCouponId])?.name : '';
                if(utmData?.eventCouponId)
                {
                    setUtmSource(2);
                }
                setExistingUtmInfo({
                    name: utmData.name,
                    utmLink: utmData.utmLink,
                    type: String(utmData.type),
                    eventTicketId: ticketIds,
                    eventCouponId: couponName ? couponName : '',
                });
            }       
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const postUtmData = async (utm_data_payload: IUtmPayload): Promise<void> => 
    {
        try 
        {
            const postUtmData = await createUtm(utm_data_payload, props.eventId, csrfTokenData);
            if (postUtmData) 
            {
                handleDrawerClose();
                eventBus.dispatch('utm_data_refresh', {
                    refresh: true 
                });
                eventBus.dispatch('Refresh-events-top-bar', {
                    refresh: true 
                });
                setSpinner(false);

                toast.success('Share Link Created Successfully');
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
            eventBus.dispatch('utm_data_refresh', {
                refresh: true 
            });
            if (error.statusCode === 400) 
            {
                toast.error((error as Error)?.message);
            }
        }
    };

    const updateUtmData = async (utm_data_payload: IUtmPayload, utm_id: number): Promise<void> => 
    {
        try 
        {
            const updateUtmData = await updateUtm(utm_data_payload, utm_id, props.eventId);
            if (updateUtmData) 
            {
                handleDrawerClose();
                eventBus.dispatch('utm_data_refresh', {
                    refresh: true 
                });
                setSpinner(false);
                toast.success('Share Link Updated Successfully');
            }
        }
        catch (error) 
        {
            console.log(error);
            setSpinner(false);
            eventBus.dispatch('utm_data_refresh', {
                refresh: true 
            });
            if (error.statusCode === 400) 
            {
                toast.error((error as Error)?.message);
            }
        }
    };

    const deleteUtmFromTable = async (utmInfo: EventUtm): Promise<void> => 
    {
        try 
        {
            const utmDeleted = await deleteUtm(Number(utmInfo?.id), props.eventId);
            if (utmDeleted) 
            {
                setShowDeletePopup(false);
                handleDrawerClose();
                toast.success('Share Link Deleted Successfully');
                props?.setRefresh && props.setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }           
    };

    const fetchActivityLogs = async (): Promise<void> =>
    {
        try 
        {
            const activityLogs = await getUtmActivityLogs(props?.eventId, String(props?.utmData?.id));
            if(activityLogs)
            {
                setActivityLogs(activityLogs);
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching activity logs');
        }
    };

    useEffect((): void => 
    {
        fetchEventTicketsAndProps();
        
        if(props?.utmData)
        {
            fetchActivityLogs();
        }
    }, []);    

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        type: Yup.string(),
        utmLink: Yup.string()
            .matches(/^[a-zA-Z0-9_-]+$/, 'Enter valid UTM Link')
            .required('UTM Link is required'),
        eventTicketId: Yup.array().of(Yup.string()).required('Event Ticket ID is required'),
    });
    
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: existingUtmInfo.name,
            type: eventReduxData.registrationType === 2 ? existingUtmInfo.type : 1,
            utmLink: existingUtmInfo.utmLink,
            eventTicketId: existingUtmInfo?.eventTicketId,
            eventCouponId: existingUtmInfo?.eventCouponId
        },
        initialTouched: {
            name: false,
            type: true,
            utmLink: false,
        },
        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);

            const allEventTicketIds = eventTickets.map((item): number => 
            {
                return item.id;
            });

            const convertTicketNameToId = _.filter(eventTickets, (item): boolean => 
            {
                return _.includes(formik.values.eventTicketId, item.name); 
            }).map((item) => 
            {
                return item.id; 
            });

            const couponId = _.find(couponData, ['name', formik.values.eventCouponId])?.id;

            const validPayload = {
                name: formik.values.name,
                type: formik.values.type ? formik.values.type : 2,
                utmLink: formik.values.utmLink,
                eventId: props?.eventId,
                eventTicketIds: Number(utmSource) === 1 && eventTickets?.length > 0 ? convertTicketNameToId : [],
                ...(Number(utmSource) === 2 && couponId && { eventCouponId: couponId })
            };

            if (props.createUtm) 
            {
                postUtmData(validPayload);
            }
            else 
            {
                if (props.utmId) 
                {
                    updateUtmData(validPayload, props.utmId);
                }
            }
        }
    });

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.CLOSE_EVENT, {
            open: false
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);
    };

    const handleNestedSidebarOpen = (): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.UPDATE_EVENT, {
            component: <ActivityLogViewer><ActivityLogComponent activityLog={eventUtmActivityLog(activityLogs, props?.utmData, eventTickets, couponData)} /></ActivityLogViewer>
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.OPEN_EVENT, {
            open: true,
            right: '441'
        });
    };

    const handleTicketChange = (event, newValue): void => 
    {
        formik.setFieldValue('eventTicketId', newValue);
    };

    const handleTicketRemove = (index): void => 
    {
        const updatedTickets = formik.values?.eventTicketId?.filter((_, i): boolean => 
        {
            return i !== index; 
        });
        formik.setFieldValue('eventTicketId', updatedTickets);
    };

    const handleCouponChange = (event, newValue): void => 
    {
        formik.setFieldValue('eventCouponId', newValue);
    };

    const handleUtmSource = (event, value): void =>
    {
        setUtmSource(value);
        if(Number(value) === 1)
        {
            formik.setFieldValue('eventCouponId', '');
        }
        else
        {
            formik.setFieldValue('eventTicketId', []);
        }
    }

    const handleDelete = (): void => 
    {
        setShowDeletePopup(true);
    };

    useEffect((): void => 
    {   
        if(formik.values.eventTicketId)
        {
            const ticket = eventTickets.find((item): boolean => 
            {
                return item.id === Number(formik.values.eventTicketId);
            });
            if(ticket)
            {
                setTicketsAvailable(ticket.totalTicketsAvailable);
            }
        }
    }, [formik.values.eventTicketId]);
    
    return (
        <Box id="createUtmContainer">
            <CloseIconComponent onClick={handleDrawerClose} />

            {
                props?.utmData &&
                <div className="utm-activity-log-button">
                    <CustomButton 
                        name=""
                        btnType="secondary"
                        startIcon={<FontAwesomeIcon icon={['fal', 'wave-pulse']} />}
                        onClick={handleNestedSidebarOpen}
                    />
                </div>
            }

            <Form noValidate onSubmit={(values): void => 
            {
                return formik.handleSubmit(values);
            }} autoComplete="off" className="utm-form">

                {/* Utm Name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Name'} required />
                    <FormControlComponent
                        type="text"
                        placeholder={'Godard'}
                        value={formik.values.name}
                        onChange={(event): void => 
                        {
                            formik.setFieldValue('name', event.target.value);
                            const link = transformTitleToLink(event.target.value);
                            formik.setFieldValue('utmLink', link);
                        }}
                        name={'name'}
                        required
                    />
                    {formik.touched.name && formik.errors.name ? <Typography className="error-msg">{formik.errors.name}</Typography> : null}
                </Box>

                {/* Utm Link */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'UTM Link'} required />
                    <FormControlComponent
                        type="text"
                        placeholder={'Customers'}
                        value={formik.values.utmLink}
                        onChange={(event): void => 
                        {
                            formik.setFieldValue('utmLink', event.target.value);
                        }}
                        name={'utmLink'}
                        required
                    />
                    <Typography className="utm-link-example">{`https://${props?.orgLink}.eventhq.com/${props?.eventLink}?utm_source=${formik.values.utmLink}`}</Typography>
                    {formik.touched.utmLink && formik.errors.utmLink ? <Typography className="error-msg">{formik.errors.utmLink}</Typography> : null}
                </Box>

                {/* Registrants Approval */}
                {   
                    eventReduxData?.registrationType === EventRegistrationTypes.INVITE &&
                    <Box className="sidebar-container-spacing">
                        <Box className="auto-approval-toggle">
                            <Box className="componentLabel"><FontAwesomeIcon icon={['fal', 'user-check']} className='auto-approve-icon'/><Typography className="label">{'Auto Approve Registrants'}</Typography></Box>
                            <FormControlLabel
                                checked={formik.values.type === '1'}
                                className="auto-approval-check"
                                control={<CustomSwitch
                                    // defaultChecked={eventReduxData.registrationType === EventRegistrationTypes.OPEN}
                                    // disabled={eventReduxData.registrationType === EventRegistrationTypes.OPEN}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('type', event.target.checked ? '1' : '2');
                                    }}
                                />}
                                label={null}
                            />
                        </Box>
                    </Box>
                }

                {/* {eventReduxData.registrationType === EventRegistrationTypes.OPEN && <Typography className="auto-approve-disabled-info-text">{'(Registrants will be auto approved as the registration type is open)'}</Typography>} */}

                <Box className='sidebar-container-spacing'>
                    <FormLabelComponent label={Number(utmSource) === 1 ? 'Select Ticket' : 'Select Coupon'} required/>
                    <ToggleButtonGroupComponent
                        value={Number(utmSource)}
                        onChange={(event, value): void => {
                            handleUtmSource(event, value);
                        }}
                        options={utmSourceOptions}
                    />
                </Box>

                {Number(utmSource) === 1 && <Box className="sidebar-container-spacing">
                    {/* <FormLabelComponent label='Select Ticket' required /> */}
                    <AutocompletewithTags 
                        defaultValue={formik.values.eventTicketId} 
                        value={formik.values.eventTicketId} 
                        options={eventTickets} 
                        keyToShow='name' 
                        onChange={handleTicketChange}
                        disabledOptions={expiredTickets} 
                        placeholder='Select Ticket' 
                        onRemoveClick={(index): void => 
                        {
                            handleTicketRemove(index);
                        }} />
                </Box>}

                {Number(utmSource) === 2 && <Box className="sidebar-container-spacing">
                    <AutocompleteComponent
                            value={formik.values.eventCouponId || ''}
                            defaultValue={formik.values.eventCouponId || ''}
                            onChange={handleCouponChange}
                            keyToShow='name'
                            optionsArr={couponData}
                            placeholder='Select Coupon'
                    />
                </Box>}

                <Box className="submit-btn-container">
                    {(!props?.utmData) ? <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton btnType='primary' loading={spinner} name={'Save'} type='submit' />
                    </Stack>
                    : (props?.utmData) &&
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                        <Stack direction={'row'} spacing={1} display={'flex'}>
                            <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                            <CustomButton btnType='primary' loading={spinner} name={'Save'} type='submit' />
                        </Stack>
                        {!props?.isTableView && <FontAwesomeIcon className="sidebar-delete-icon" icon={['fal', 'trash']} onClick={handleDelete} />}
                    </Stack>
                    }
                </Box>

            </Form>

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteUtmFromTable(props?.utmData as EventUtm)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                        }} 
                        modalContent={`Are you sure you want to delete ${props?.utmData?.name}?`}
                        modalTitle='Delete Share Link'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
            }
        </Box>
    );
};

export default CreateUtm;