import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../interfaces/create-events_interface";
import { deleteEvent } from "../../../scripts/apis/events";
import toast from "react-hot-toast";
import { UserViewType } from "../../../pages/Settings/enum/users.enum";
import { EventPhase } from "../../../pages/Events/enum";
import DeletePopup from "../../../common/DeletePopup";
import { CustomButton } from "../../../common/FormComponents/Buttons";

const DeleteEventComponent: React.FC = (): React.JSX.Element =>
{

    const { eventId } = useParams();
    const navigate = useNavigate();

    const {
        userDetails
    } = useContext(UserContext);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const [showEventDeletePopup, setShowEventDeletePopup] = useState<boolean>(false);

    const handleDeleteEvent = async (): Promise<void> => 
    {
        try 
        {
            const isEventDeleted = await deleteEvent(eventId as string);
            if(isEventDeleted)
            {
                toast.success('Event Deleted Successfully');
                navigate(userDetails?.viewType === UserViewType.MINIMAL ? `/events?eventPhase=${EventPhase.UPCOMING}` : `/events`);
            }
        } 
        catch (error) 
        {
            
        }
    };

    return (
        <div className="delete-event-component">
            <div className="event-delete-help-text-container">
                <h3 className="title">{'Delete Event'}</h3>
                <p className="description">{'This action will permanently delete the event and cannot be undone. If there are any registered guests, they will be notified that the event has been cancelled.'}</p>
            </div>
            <CustomButton 
                name="Delete Event"
                btnType="danger"
                onClick={() => setShowEventDeletePopup(true)}
            />

            {
                showEventDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => handleDeleteEvent()} 
                        cancelClick={() => { 
                            setShowEventDeletePopup(false);
                        }} 
                        modalContent={`Are you sure you want to delete ${eventReduxData?.title} ?`}
                        modalTitle='Delete Event'
                        show={showEventDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
            }
        </div>
    )
};

export default DeleteEventComponent;