import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import './styles.scss';
import { EventCheckInNotificationsSource } from '../../../Events/enum/event-check-in-notifications.enum';
import { EventCheckInNotifications } from '../../../Events/interfaces/event-check-in-notifications.interface';
import { BuyerPersona } from '../../../Settings/interface/buyer-persona_interface';
import { ICP } from '../../../Settings/interface/icp_interface';
import { AutocompletewithKeyTags, FormLabelComponent, RadioGroupComponent } from '../../../../common/FormComponents/ReusableFormComponents';
import { EventRegistrant } from '../../../Events/interfaces/event-registrant_interface';
import { getAllBuyerPersonaSponsorPortal, getAllIcpSponsorPortal, getAllTargetList, getbuyerPersonaSponsorPortalCount, getIcpSponsorPortalCount } from '../../../../scripts/apis/sponsorPortal/sponsorPortal';


interface PortalOptionNotificationsProps
{
    icpData: ICP[];
    setIcpData: React.Dispatch<React.SetStateAction<ICP[]>>;
    bpData: BuyerPersona[];
    setBpData: React.Dispatch<React.SetStateAction<BuyerPersona[]>>;
    notificationSource: EventCheckInNotificationsSource;
    setNotificationSource: React.Dispatch<React.SetStateAction<EventCheckInNotificationsSource>>;
    checkInNotifications: EventCheckInNotifications | undefined;
}

const PortalOptionNotifications: React.FC<PortalOptionNotificationsProps> = (
    {
        icpData,
        setIcpData,
        bpData,
        setBpData,
        notificationSource,
        setNotificationSource,
        checkInNotifications
    }
) =>
{

    const [icpDataFromDb, setIcpDataFromDb] = useState<ICP[]>([]);
    const [bpDataFromDb, setBpDataFromDb] = useState<BuyerPersona[]>([]);

    const eventLocationOptions = [
        {
            name: 'Select Target Lists', value: 1, id: 1
        },
        {
            name: 'Select Filters', value: 2, id: 2
        },
    ];

    const formik = useFormik(
        {
            initialValues:
            {
                source: checkInNotifications?.source?.toString() || '1',
                targetList: checkInNotifications?.filters?.targetList || [],
                filters: checkInNotifications?.filters || [],
            },
            onSubmit: (): void =>
            {
                // onSubmit();
            }
        }
    );

    const handleNotificationSource = (event: React.ChangeEvent<HTMLInputElement | HTMLLIElement>): void =>
    {
        formik.setFieldValue('source', event.target.value);
        setNotificationSource(Number(event.target.value));
        if (Number(event.target.value) === EventCheckInNotificationsSource.TAREGET_LIST)
        {
            setIcpData([]);
            setBpData([]);
        }
    };

    const handleIcp = (event: ChangeEvent<HTMLInputElement>, newValue: any[]): void =>
    {
        setIcpData(newValue);
    };

    const handleIcpDelete = (index: number): void =>
    {
        const newSelectedIcp = icpData.filter((_, i) => i !== index);
        setIcpData(newSelectedIcp);
    };

    const handleBp = (event: ChangeEvent<HTMLInputElement>, newValue: any[]): void =>
    {
        setBpData(newValue);
    };

    const handleBpDelete = (index: number): void =>
    {
        const newSelectedBp = bpData.filter((_, i) => i !== index);
        setBpData(newSelectedBp);
    };

    const fetchData = async () => 
    {
        try {
            const icpCountData = await getIcpSponsorPortalCount();
            const icpData = await getAllIcpSponsorPortal(icpCountData, 0);
            setIcpDataFromDb(icpData);

            const bpCount = await getbuyerPersonaSponsorPortalCount();
            const bpData = await getAllBuyerPersonaSponsorPortal(bpCount, 0);
            setBpDataFromDb(bpData);
        } catch (error) {
            console.error('Error fetching target list data:', error);
            throw error;
        }
    };

    useEffect(() => {   
        fetchData();
    }, []);

    useEffect(() => {
        if (checkInNotifications) {
            setNotificationSource(checkInNotifications.source);

            const icp = checkInNotifications.filters?.icp?.map(id => icpDataFromDb.find(item => Number(item.id) === Number(id))).filter((item): item is ICP => item !== undefined) || [];
            setIcpData(icp);

            const bp = checkInNotifications.filters?.bp?.map(id => bpDataFromDb.find(item => Number(item.id) === Number(id))).filter((item): item is BuyerPersona => item !== undefined) || [];
            setBpData(bp);
        }
    }, [checkInNotifications, icpDataFromDb, bpDataFromDb]);

    return (
        <div id='PortalOptionContainer'>
            <Form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
                <div className='sidebar-container-spacing'>
                    <FormLabelComponent label={'Select Options'} required={true} />
                    <RadioGroupComponent
                        row
                        options={eventLocationOptions}
                        value={notificationSource}
                        onChange={handleNotificationSource}
                    />
                    {
                        formik.errors.source && formik.touched.source ? (
                            <p className='error-msg'>{formik.errors.source}</p>
                        ) : null
                    }
                </div>

                {
                    notificationSource === EventCheckInNotificationsSource.TAREGET_LIST &&  (
                        <></>
                    )
                }

                {
                    notificationSource === EventCheckInNotificationsSource.REGISTRANTS && (
                        <>
                            <div className='sidebar-container-spacing'>
                                <FormLabelComponent label={'Select ICP'} />
                                <div className='event-category-container'>
                                    <div className='w-100'>
                                        <AutocompletewithKeyTags
                                            value={icpData}
                                            defaultValue={icpData}
                                            keyToStore="id"
                                            keyToShow="name"
                                            onChange={(event, newValue) => handleIcp(event, newValue)}
                                            onRemoveClick={handleIcpDelete}
                                            options={icpDataFromDb}
                                            placeholder='Select ICP'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='sidebar-container-spacing'>
                                <FormLabelComponent label={'Select Buyer Persona'} />
                                <div className='event-category-container'>
                                    <div className='w-100'>
                                        <AutocompletewithKeyTags
                                            value={bpData}
                                            defaultValue={bpData}
                                            keyToStore="id"
                                            keyToShow="name"
                                            onChange={(event, newValue) => handleBp(event, newValue)}
                                            onRemoveClick={handleBpDelete}
                                            options={bpDataFromDb}
                                            placeholder='Select BP'
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </Form>
        </div>
    );
};

export default PortalOptionNotifications;