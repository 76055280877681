import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { EventCheckInNotifications } from '../../../../../interfaces/event-check-in-notifications.interface';
import PlaceholderAutoCompleteInput from '../../../../../../../common/FormComponents/PlaceholderInput';

interface MessageNotificationProps 
{
  checkInNotifications: EventCheckInNotifications | undefined;
  messageTitle: string;
  setMessageTitle: React.Dispatch<React.SetStateAction<string>>;
  messageContent: string;
  setMessageContent: React.Dispatch<React.SetStateAction<string>>;
}
const MessageNotification: React.FC<MessageNotificationProps> = (
  {
    checkInNotifications,
    messageTitle,
    setMessageTitle,
    messageContent,
    setMessageContent
  }
) => {

  const validationSchema = Yup.object().shape({
          title: Yup.string()
              .required('Title is required')
              .max(100, 'Event Title length exceeded')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            title: '',
            content: ''
        },
        onSubmit: (): void => 
        {
            
        }
    });

    useEffect(() => {
        if (checkInNotifications) 
        {
            setMessageTitle(checkInNotifications.title);
            setMessageContent(checkInNotifications.messageContent);
        }
    }, [checkInNotifications]);

  return (
      <div id='MessageNotification'>
          <div className='message-container'>
              <Form noValidate autoComplete='off'>

                  <div className='form-group'>
                    <PlaceholderAutoCompleteInput options={['Event Name', 'Organization Name', 'First Name', 'Last Name']} initialInputValue={messageTitle} onChange={(value) => setMessageTitle(value)} label='Message Title' placeholder='Type your message Title and use {{ to insert placeholders' rows={1} />
                      <p className='help-text'>
                          This title will be push notification title for the guest.
                      </p>
                  </div>
                  <div className='form-group'>
                      <PlaceholderAutoCompleteInput options={ ['Event Name', 'Organization Name', 'First Name', 'Last Name'] } initialInputValue={ messageContent } onChange={ (value) => setMessageContent(value) } label='Message Content' placeholder='Type your message Content and use {{ to insert placeholders' rows={6} />
                  </div>
              </Form>
          </div>
      </div>
  );
}

export default MessageNotification