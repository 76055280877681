import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntegrationTypes } from '../enum/integrations.enum';
import './styles.scss';
import { CustomButton } from '../../../common/FormComponents/Buttons';

interface IntegrationsPopupProps { 
    component: React.ReactNode;
    heading: string | React.ReactNode;
    closeFunction: () => void;
    type: IntegrationTypes;
}

const IntegrationsPopup: React.FC<IntegrationsPopupProps> = (props) => 
{

    const { component, heading, closeFunction, type } = props;

    return (
        <div id="integrationsPopup">
            <div className="integrations-popup">
                <div className="integrations-popup-header">
                    <h2 className="heading">{heading}</h2>
                    <CustomButton 
                        startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />}
                        onClick={closeFunction}
                        btnType='tertiary'
                        name=''
                    />
                </div>
                <div className="integrations-popup-body">
                    {component}
                </div>
            </div>
        </div>
    )
};

export default IntegrationsPopup;