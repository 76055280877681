import React, { useState } from "react";
import { Form, Formik } from "formik";
import { FormControlComponent, FormLabelComponent } from "../../../../../common/FormComponents/ReusableFormComponents";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomButton } from "../../../../../common/FormComponents/Buttons";
import { CONTENT } from "../../../../../scripts/i18n";
import toast from "react-hot-toast";
import { updateSessionMaterial, uploadSessionMaterials } from "../../../../../scripts/apis/sponsorPortal/sponsorPortal";
import { EventSpeakerSessionMaterial } from "../../../../Events/interfaces";

import './styles.scss';

interface AddSessionMaterialProps {
    handleClose: () => void;
    sessionId: string;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    existingMaterialInfo?: EventSpeakerSessionMaterial | null;
}

const AddSessionMaterial: React.FC<AddSessionMaterialProps> = (props): React.JSX.Element => 
{ 
    const { handleClose, sessionId, setRefresh, existingMaterialInfo } = props;

    const [spinner, setSpinner] = useState<boolean>(false);

    const handleSubmit = async (values: any) =>
    {
        if(existingMaterialInfo)
        {
            editSessionMaterial(values);
        }
        else
        {
            createSessionMaterial(values);
        }
    };

    const createSessionMaterial = async (values: any) =>
    {
        try 
        {
            const formData = new FormData();
            formData.append('file', values?.presentationFile);

            uploadSessionMaterials(formData, sessionId)
                .then((response) => {
                    if (response) {
                        const data = {
                            note: values?.note
                        };
                        updateSessionMaterial(data, sessionId, response)
                            .then((response) => {
                                if(response)
                                {
                                    toast.success('Session Material uploaded successfully');
                                    setRefresh(true);
                                    setSpinner(false);
                                    handleClose();
                                }
                                else
                                {
                                    toast.error('Session Material upload failed');
                                }
                            })
                            .catch(() => {
                                toast.error('Session Material upload failed');
                            });
                    } else {
                        toast.error('File upload failed');
                    }
                })
                .catch(() => {
                    toast.error('File upload failed');
                });
        }   
        catch (error) 
        {
            
        }
    };

    const editSessionMaterial = async (values: any) =>
    {
        const data = {
            note: values?.note
        };

        try 
        {
            const isNotesUpdated = await updateSessionMaterial(data, sessionId, existingMaterialInfo?.blobName as string);
            if(isNotesUpdated)
            {
                toast.success('Session Material updated successfully');
                setRefresh(true);
                setSpinner(false);
                handleClose();
            }
            else
            {
                toast.error('Session Material update failed');
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    return (
        <div id="addSessionMaterial">
            <Formik
                initialValues={{
                    note: existingMaterialInfo?.note || '',
                    presentationFile: undefined,
                    fileName: existingMaterialInfo?.fileName || '',
                }}
                enableReinitialize={true}
                onSubmit={(values) => {
                    if(existingMaterialInfo)
                    {
                        if(values?.note === existingMaterialInfo?.note)
                        {
                            return;
                        }
                        else
                        {
                            setSpinner(true);
                            handleSubmit(values);
                        }
                    }
                    else
                    {
                        if(values?.fileName === '' || !values?.presentationFile)
                        {
                            toast.error('Please upload a file');
                            return;
                        }
                        setSpinner(true);
                        handleSubmit(values);
                    }
                }}
            >
                {({ values, setFieldValue }) => {
                    return (
                        <Form noValidate autoComplete="off">
                            <div className="sidebar-container-spacing">
                                {
                                    !existingMaterialInfo &&
                                    <>
                                        <FormLabelComponent label={"Upload Presentation"} required />
                                        <Dropzone 
                                            multiple={false}
                                            disabled={values?.fileName !== ''} 
                                            accept={{
                                                'application/pdf': [],
                                                'application/ppt': [],
                                                'application/pptx': [],
                                            }}
                                            onDrop={async (acceptedFiles) => {
                                                const file = acceptedFiles[0];
                                                setFieldValue('presentationFile', file);
                                                setFieldValue('fileName', file.name);
                                            }}
                                        >
                                            {({ getRootProps, getInputProps }) => ( 
                                                <div {...getRootProps()} className="session-materials-dropzone">
                                                    <div className="upload-icon-div">
                                                        <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} />
                                                    </div>
                                                    <div className="dropzone-upload-content">
                                                        <h2 className="btn-text">
                                                            <input {...getInputProps()} />
                                                            {'Browse File'}
                                                        </h2>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </>
                                }
                                {
                                    values?.fileName &&
                                    <div className="sidebar-container-spacing"> 
                                        <div className="images-container">
                                            <div className="file-name-container">
                                                <FontAwesomeIcon icon={['fal', 'file']} />
                                                <h3 className="file-name">{values?.fileName}</h3>
                                            </div>
                                            {
                                                !existingMaterialInfo ?
                                                <FontAwesomeIcon 
                                                    className="remove-icon" 
                                                    onClick={async () => {
                                                        setFieldValue('presentationFile', undefined);
                                                        setFieldValue('fileName', '');
                                                    }} 
                                                    icon={['fal', 'trash-can']} 
                                                />
                                                :
                                                <div />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="sidebar-container-spacing">
                                <FormLabelComponent label={"Note"} />
                                <FormControlComponent 
                                    value={values?.note}
                                    onChange={(e) => setFieldValue('note', e.target.value)}
                                    placeholder={"Description"}
                                    as="textarea"
                                    type="text"
                                    rows={3}
                                />
                            </div>

                            <div className="submission-container">
                                <CustomButton onClick={handleClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                                <CustomButton type="submit" loading={spinner} disabled={spinner} name={"Save"} btnType={"primary"} />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
};

export default AddSessionMaterial;