interface TicketCardProps {
    leftSection?: JSX.Element;
    rightSection?: JSX.Element;
}

const TicketCard: React.FC<TicketCardProps> = (props): React.JSX.Element =>
{
    return (
        <div id="ticketCard">
            <div className="ticket-card-component">
                <div className="card-left-section">
                    {props.leftSection}
                </div>
                <div className="card-right-section">
                    {props.rightSection}
                    <div className="card-right-edge"></div>
                </div>
            </div>
        </div>
    )
};

export default TicketCard;