import { useContext, useEffect } from "react";
import { LocalStorage } from "../scripts/LocalStorage";
import { Toaster } from "react-hot-toast";
import { PortalContext } from "../contexts/Portal/PortalContext";
import { Container } from "react-bootstrap";
import PortalHeader from "../pages/Portal/Components/Common/PortalHeader";
import PortalTabs from "../pages/Portal/Components/Common/PortalTabs";
import PortalAudiencePage from "../pages/Portal/Pages/Audience/PortalAudiencePage";
import ICPAndBPPage from "../pages/Portal/Pages/ICP/ICPAndBPPage";
import PortalDashboard from "../pages/Portal/Pages/Dashboard/PortalDashboard";
import PhotosPage from "../pages/Portal/Pages/Photos/PhotosPage";
import PortalAuthPage from "../pages/Portal/Pages/Auth/PortalAuthPage";
import LeadCaptureFormPage from "../pages/Portal/Pages/Form/LeadCaptureFormPage";
import PortalAgenda from "../pages/Portal/Components/Agenda/PortalAgenda";
import YourPhotosComponent from "../pages/Portal/Pages/Photos/YourPhotos";
import NotificationsPage from "../pages/Portal/Pages/Notifications/NotificationsPage";

const SponsorPortalProtectedRoute: React.FC = (): React.JSX.Element =>
{
    const sponsorDetails = LocalStorage.get('@SponsorDetails');

    const { selectedModule, handleModuleChange, registrantData } = useContext(PortalContext);

    let component: React.JSX.Element;

    const tabData =
    [
        {
            title: 'Dashboard',
            data: <PortalDashboard />,
            selected: selectedModule === 'dashboard',
            onClick: () => handleModuleChange('dashboard')
        },
        ...(registrantData && registrantData?.eventSpeakerIdMap && registrantData?.eventSponsorIdMap ? [{
            title: 'Agenda',
            data: <PortalAgenda />,
            selected: selectedModule === 'agenda',
            onClick: () => handleModuleChange('agenda')
        }] : []),
        {
            title: 'Form',
            data: <LeadCaptureFormPage />,
            selected: selectedModule === 'form',
            onClick: () => handleModuleChange('form')
        },
        {
            title: 'Audience',
            data: <PortalAudiencePage />,
            selected: selectedModule === 'audience',
            onClick: () => handleModuleChange('audience')
        },
        {
            title: 'ICP & BP',
            data: <ICPAndBPPage />,
            selected: selectedModule === 'icp',
            onClick: () => handleModuleChange('icp')
        },
        {
            title: 'Photos',
            data: <PhotosPage />,
            selected: selectedModule === 'photos',
            onClick: () => handleModuleChange('photos')
        },
        {
            title: 'Notifications',
            data: <NotificationsPage />,
            selected: selectedModule === 'notifications',
            onClick: () => handleModuleChange('notifications')
        }
    ];

    const renderFormFieldEdit = () =>
    {
        return (
            <div className="w-100">
                <LeadCaptureFormPage />
            </div>
        )
    };

    const renderPortalTabs = () =>
    {
        return (
            <div className="portal-tabs-page">
                <Container>
                    <PortalHeader />
                    <div className="portal-content">
                        <PortalTabs 
                            tabData={tabData}
                        />
                    </div>
                </Container>
            </div>
        )
    };

    if(sponsorDetails)
    {
        if(sponsorDetails?.speakerId && !sponsorDetails?.sponsorId)
        {
            component = (
                <div className="sponsor-portal-layout-wrapper">
                    <Container>
                        <PortalHeader/>
                        <PortalAgenda />
                    </Container>
                </div>
            );
        }
        else
        {
            component = (
                <div className="sponsor-portal-layout-wrapper">  
                    {
                        selectedModule === 'formFieldEdit' ? renderFormFieldEdit() :
                        renderPortalTabs()
                    }
                    <Toaster />
                </div>
            );
        }
    }
    else
    {
        component = <PortalAuthPage />
    }

    const components = (
        <div className="h-100 w-100">
            {component}
        </div>
    );

    return components;
};

export default SponsorPortalProtectedRoute;