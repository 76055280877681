import React, { useContext, useEffect, useState } from 'react';
import useEventOverviewData from '../../../../hooks/eventOverviewData';
import EventOverviewBar from './EventOverviewBar';
import './styles.scss';
import CustomSpinner from '../../../../common/CustomSpinner';
import GoalsProvider from '../../../../contexts/GoalsContext';
import { useCountdown } from '../../events.helpers';
import LandingPagePreview from './LandingPagePreview';
import RegistrationsGraph from './RegistrationsGraph';
import GoalsCard from './Goals/GoalsCard';
import { useSelector } from 'react-redux';
import { IBillingInfo } from '../../../../redux/billing/billing-redux_interface';
import SpeakersProvider from '../../../../contexts/SpeakersPageContext';
import SpeakersPage from '../../../Speakers/SpeakersPage';
import SponsorsPageProvider from '../../../../contexts/SponsorsPageContext';
import SponsorsPage from '../../../Sponsors/SponsorsPage';
import VendorsProvider from '../../../../contexts/VendorsPageContext';
import VendorsPage from '../../../Vendors/VendorsPage';
import { UserContext } from '../../../../contexts/UserContext';
import { UserViewType } from '../../../Settings/enum/users.enum';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import { CONTENT } from '../../../../scripts/i18n';
import CreateEvent from '../../../../components/Events/EventComponents/CreateEvent';
import { IntegrationTypes } from '../../../Settings/enum/integrations.enum';

const Overview: React.FC<{eventId: number}> = (props: {eventId: number}): React.JSX.Element => 
{
    const eventId = props?.eventId;
    const [isFutureEvent, setIsFutureEvent] = useState<boolean>(false);
    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const [eventDetails, spinner, timezone, eventCategory,customFields, integrations] = useEventOverviewData({
        eventId: eventId 
    });

    const isLumaIntegrated = integrations?.some((integration): boolean => {
        return integration?.type === IntegrationTypes.LUMA;
    });

    const {
        userDetails
    } = useContext(UserContext);

    const countdown = useCountdown(Number(eventDetails?.eventStartDateTime * 1000));
    let countdownDisplay = '';
    if(countdown)
    {
        countdownDisplay = `${String(countdown?.days).padStart(2, '0')}D ${String(countdown?.hours).padStart(2, '0')}H ${String(countdown?.minutes).padStart(2, '0')}M ${String(countdown?.seconds).padStart(2, '0')}S`;
    }

    const handleEditEvent = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.EDIT.HEADING,
            component: <CreateEvent eventData={eventDetails} routeFromId={false} fromOverview={true} customFields={customFields} isLumaIntegrated={isLumaIntegrated} />,
            hideCloseButton: true
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '500px !important'
        });
    };

    const renderHeader = () => {
        return (
            <div className="overview-header-container">
                <h3>{'Overview'}</h3>
                <CustomButton 
                    name={'Edit Event'}
                    onClick={handleEditEvent}
                    btnType='secondary'
                />
            </div>
        )
    };

    const renderOverview = () => 
    {
        return (
            <>  
                <div className="overview-content">
                    {renderHeader()}
                    <EventOverviewBar eventDetails={eventDetails} customFields={customFields} integrations={integrations} countDown={countdownDisplay !== 'undefinedD undefinedH undefinedM undefinedS' && isFutureEvent ? countdownDisplay : '-'} />  
                </div>
                <RegistrationsGraph eventId={eventId} />
                <GoalsProvider eventId={eventId}><GoalsCard /></GoalsProvider>
                <LandingPagePreview event={eventDetails} />
                <SpeakersProvider eventId={eventId} cardView={true}><SpeakersPage eventId={eventId} /></SpeakersProvider>
                {orgBillingInfo?.billingModules?.sponsor && <SponsorsPageProvider eventId={eventId} cardView={true} ><SponsorsPage eventId={eventId} /></SponsorsPageProvider>}
                {orgBillingInfo?.billingModules?.vendor && <VendorsProvider eventId={eventId} cardView={true} ><VendorsPage eventId={eventId} /></VendorsProvider>}
            </>
        )
    };

    const renderMinimalViewForOverview = () => 
    {
        return (
            <div id="eventOverviewMinimalContainer">
                <div id="overview">
                    {renderOverview()}
                </div>
            </div>
        )
    };

    const renderNormalViewForOverview = () => 
    {
        return (
            <div id="overview">
                {renderOverview()}
            </div>
        )
    };

    useEffect((): void =>
    {
        if(eventDetails?.eventStartDateTime > Math.floor(Date.now() / 1000))
        {
            setIsFutureEvent(true);
        }
    }, [eventDetails?.eventStartDateTime]);
    
    return (
        <div style={{ backgroundColor: '#FCFCFD', width: '100%' }}>
            {spinner ?
                <CustomSpinner height={'100lvh'} /> :
                userDetails?.viewType === UserViewType.MINIMAL ? renderMinimalViewForOverview() : renderNormalViewForOverview()   
            }
        </div>
    );
};

export default Overview;

