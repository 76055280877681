import { EventCheckInNotifications } from '../../pages/Events/interfaces/event-check-in-notifications.interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const createCheckInNotifications = async (data: EventCheckInNotifications, EVENT_ID: string | number, csrfToken: string): Promise<EventCheckInNotifications> =>
{
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-check-in-notifications/${EVENT_ID}/`;
    return triggerApi(API_URL, 'POST', data, csrfToken);
};

export const getCheckInNotificationsCount = async (EVENT_ID: string | number, queryParams: [string, string][] = []): Promise<number> =>
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-check-in-notifications/${EVENT_ID}/count?${query}`;
    return triggerApi(API_URL, 'GET', '');
};

export const getAllCheckInNotifications = async (pageSize: number, offset: number, EVENT_ID: string | number, queryParams: [string, string][]): Promise<EventCheckInNotifications[]> =>
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-check-in-notifications/${EVENT_ID}/?pageSize=${pageSize}&offset=${offset}&${query}`;
    return triggerApi(API_URL, 'GET', '');
};

export const getCheckInNotificationsById = async (EVENT_ID: string | number, NOTIFICATION_ID: string | number): Promise<EventCheckInNotifications> =>
{
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-check-in-notifications/${EVENT_ID}/${NOTIFICATION_ID}`;
    return triggerApi(API_URL, 'GET', '');
};

export const updateCheckInNotifications = async (data, EVENT_ID: string | number, NOTIFICATION_ID: string | number): Promise<EventCheckInNotifications> =>
{
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-check-in-notifications/${EVENT_ID}/${NOTIFICATION_ID}`;
    return triggerApi(API_URL, 'PUT', data);
};

export const deleteCheckInNotifications = async (EVENT_ID: string | number, NOTIFICATION_ID: string | number): Promise<boolean> =>
{
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-check-in-notifications/${EVENT_ID}/${NOTIFICATION_ID}`;
    return triggerApi(API_URL, 'DELETE', '');
};