import MuiChip from "../../../common/FormComponents/MuiChip";
import { ReactNode } from "react";

interface IEventsCardHeaderComponent 
{
    heading: string;
    count?: number;
    leftNodes?: ReactNode[];
    rightNodes?: ReactNode[];
}

const EventsCardHeaderComponent = (props: IEventsCardHeaderComponent) => 
{

    const { heading, count, leftNodes, rightNodes} = props;

    return (
        <div className="events-card-header-component">
            <div className="heading-div">
                <div className="heading-wrapper">
                    <div className="count-container">
                        <h2 className="heading">{heading}</h2>
                        {count && count > 0 && <MuiChip chipColor='grey' label={count || '-'} />}
                    </div>
                    <div className="left-node-container">
                        {leftNodes && leftNodes.map((node) => (node))}
                    </div>
                </div>
                <div className="right-node-container">
                    {rightNodes && rightNodes.map((node) => (node))}
                </div>
            </div>
        </div>
    )
}

export default EventsCardHeaderComponent;