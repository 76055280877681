import { CloseIconComponent, DebouncedAutocomplete } from "../../../common/FormComponents/ReusableFormComponents";
import { useEffect, useState } from "react";
import { EventRegistrant } from "../../../pages/Events/interfaces/event-registrant_interface";
import { getAllEventRegistrants, searchEventRegistrant } from "../../../scripts/apis/eventRegistrants";
import { EventRegistrantStatus } from "../../../pages/Events/enum/event-registrant.enum";
import _, { debounce } from "lodash";
import { Form } from "react-bootstrap";
import MuiChip from "../../../common/FormComponents/MuiChip";
import { Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import { addModerator } from "../../../scripts/apis/events";
import { useSelector } from "react-redux";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import toast from "react-hot-toast";
import { Audience } from "../../../pages/Audience/interfaces";

interface AddModeratorProps {
    eventId: string | number;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    existingModerators?: Audience[];
}

const AddModerator: React.FC<AddModeratorProps> = ({ eventId, setRefresh, existingModerators }): React.JSX.Element =>
{

    const csrfTokenData = useSelector((state: any): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const [selectedRegistrant, setSelectedRegistrant] = useState<EventRegistrant[]>([]);
    const [registrantInputValue, setRegistrantInputValue] = useState('');
    const [registrants, setRegistrants] = useState<EventRegistrant[]>([]);

    // onCHange handler to search the registrants in the autocomplete
    const handleRegistrantSearch = (event: React.SyntheticEvent<Element, Event>, newValue: string):void => 
    {
        setRegistrantInputValue(newValue);

        if(newValue === '')
        {
            const mergedRegistrants = [...registrants];
            for (const selectedOption of selectedRegistrant) {
                if (!mergedRegistrants.some(registrant => registrant.id === selectedOption.id)) {
                    mergedRegistrants.push(selectedOption);
                }
            }
            setRegistrants(mergedRegistrants);
        }
        else
        {
            delayDebounceRegistrantSearch(newValue);
        }
    };

    // function to emit the eventBus actions to close the side drawer
    const handleDrawerClose = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    // submit handler to submit the form using selected registrants
    const handleSubmit = async (event: any): Promise<void> =>
    {
        event.preventDefault();
        try 
        {
            const data = {
                registrantIds: _.map(selectedRegistrant, 'id')
            }
            
            const moderatorsAdded = await addModerator(eventId, data, csrfTokenData);
            if(moderatorsAdded)
            {
                toast.success('Event Moderators added successfully');
                setRefresh(true);
                handleDrawerClose();
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message || 'Error in adding Event Moderators'); 
        }
    };

    // debounced function using lodash debounce method to search the registrants
    const delayDebounceRegistrantSearch = debounce(async(newValue: string): Promise<void> =>
    {
        if (newValue?.length > 2) 
        {
            try 
            {

                const params = {
                    email: newValue,
                };

                const registrantData = await searchEventRegistrant(
                    eventId,
                    params?.email ? params.email : undefined,
                    undefined,
                    undefined,
                    EventRegistrantStatus.CONFIRMED,
                );

                if (registrantData) {

                    // if there are moderators selected already then filter them out based on email
                    if(existingModerators)
                    {
                        const filteredRegistrants = registrantData?.filter((elem) => !existingModerators.find(({ email }) => elem.email === email));
                        if(filteredRegistrants && filteredRegistrants?.length > 0)
                        {
                            const mergedRegistrants = [...registrants];
                            for (const data of filteredRegistrants) {
                                if (!mergedRegistrants.some(registrant => registrant.id === data.id)) {
                                    mergedRegistrants.push(data);
                                }
                            }
                            setRegistrants(mergedRegistrants);
                        }
                    }
                    // else merge the registrants fetched from the initial fetch request along with the search request data
                    else
                    {
                        const mergedRegistrants = [...registrants];
                        for (const data of registrantData) {
                            if (!mergedRegistrants.some(registrant => registrant.id === data.id)) {
                                mergedRegistrants.push(data);
                            }
                        }
                        setRegistrants(mergedRegistrants);
                    }
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    }, 300);

    // fetch initial confirmed registrants to populate the dropdown
    const fetchInitialRegistrants = async (): Promise<void> =>
    {
        try 
        {
            const registrants = await getAllEventRegistrants(eventId, 25, 0, ['status', EventRegistrantStatus.CONFIRMED]);
            if(registrants)
            {
                // if moderators were selected already then filter them out from the initial data
                if(existingModerators && existingModerators?.length > 0)
                {
                    const filteredRegistrants = registrants?.filter((elem) => !existingModerators.find(({ email }) => elem.email === email));
                    if(filteredRegistrants && filteredRegistrants?.length > 0)
                    {
                        return setRegistrants(filteredRegistrants);
                    }
                }
                // if there are no moderators selected already then store the registrant data fetched from the api
                setRegistrants(registrants);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    // useEffect - calls the initial fetch request of registrants
    useEffect(() =>
    {
        fetchInitialRegistrants();
    },[]);

    return (
        <div id="addModerator">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Form onSubmit={handleSubmit} noValidate>
                <div className="sidebar-container-spacing">
                    <div>
                        <DebouncedAutocomplete 
                            defaultValue={selectedRegistrant}
                            value={selectedRegistrant}
                            onChange={(event, newValue): void => {
                                setSelectedRegistrant([...newValue]);
                            }}
                            noOptionsText="No confirmed attendee"
                            inputValue={registrantInputValue}
                            onInputChange={handleRegistrantSearch}
                            options={registrants}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    {option.email}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value): boolean => option.email === value.email}
                            getOptionLabel={(option): string => option?.email}
                            placeholder="Search for an Attendee"
                            className="registrant-select"
                        />
                        {
                            selectedRegistrant && selectedRegistrant?.length > 0 && 
                            <div className="registrant-selected-container">
                                {
                                    selectedRegistrant?.map((element: EventRegistrant): React.JSX.Element => 
                                    {
                                        const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];
                                        return (
                                            <MuiChip
                                                circleIcon 
                                                chipColor={chipColors[Number(element.id) % chipColors.length] as 'red' | 'green' | 'blue'}
                                                label={<Stack direction={'row'} spacing={1} key={element.id} className="registrant-selected-label">
                                                {element?.firstName}
                                                <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-btn" onClick={(): void => 
                                                {
                                                    const filteredRegistrants = selectedRegistrant.filter((registrant): boolean => registrant.id !== element.id);
                                                    setSelectedRegistrant([...filteredRegistrants]);
                                                }} />
                                            </Stack>}
                                            />
                                            
                                        );
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>

                <div className="submission-container">
                    <CustomButton
                        name="Cancel"
                        btnType="secondary"
                        onClick={handleDrawerClose}
                    />
                    <CustomButton 
                        type="submit"
                        btnType="primary"
                        name="Save"
                    />
                </div>
            </Form>
        </div>
    )
};

export default AddModerator;