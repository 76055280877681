import { ActivityLogResponse } from '../../interfaces/activity-log_interface';
import { EventTicket, EventTicketGroup, UpdateEventTicket } from '../../pages/Events/interfaces';
import { EventRegistrantTicketTrend } from '../../pages/Events/interfaces/event-registrant_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

// GET methods

export const ticketsCount = async (eventId: number | string, queryParams: [string, string][] = []): Promise<number> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + eventId + '/count' + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getAlleventTickets = async (pageSize: number, offset: number, eventId: number | string, privateTicket?: number, queryParams: [string, any][] = []): Promise<EventTicket[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        privateTicket,
        ...filteredParams
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + eventId + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventTicketById = async (eventId: number, ticketId: number): Promise<EventTicket> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + eventId + '/' + ticketId;
    return triggerApi(API_URL, 'GET', '');
};

export const ticketGroupCount = async (eventId: number | string): Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + eventId + '/group/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAllTicketGroups = async (pageSize: number, offset: number, eventId: number | string, status?: number, ticketGroupId?: number): Promise<EventTicketGroup[]> => 
{
    const query = convertJSONToGETParams({
        pageSize,
        status,
        ticketGroupId,
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + eventId + '/group?' + query + '&offset=0';
    return triggerApi(API_URL, 'GET', '');
};

export const getEventTicketActivityLogs = async (eventId: string | number, ticketId?: string | number): Promise<ActivityLogResponse[]> =>
{
    const query = convertJSONToGETParams({
        ticketId
    });
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-ticket/${eventId}/activity-logs?${query}`;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventTicketTrend = async (eventId: string | number): Promise<EventRegistrantTicketTrend[]> =>
{
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-ticket/${eventId}/trend`;
    return triggerApi(API_URL, 'GET', '');
} 

// POST methods

export const createEventTicket = async (EVENT_ID: number, ticketData: EventTicket, csrfToken: string): Promise<EventTicket> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + EVENT_ID;
    return triggerApi(API_URL, 'POST', ticketData, csrfToken);
};

export const createTicketGroup = async (EVENT_ID: number, TICKET_GROUP_DATA: EventTicketGroup, csrfToken: string): Promise<EventTicketGroup> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + EVENT_ID + '/group';
    return triggerApi(API_URL, 'POST', TICKET_GROUP_DATA, csrfToken);
};

// PUT methods

export const updateEventTicket = async (EVENT_ID: number, TICKET_ID: number, TICKET_DATA: UpdateEventTicket): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + EVENT_ID + '/' + TICKET_ID;
    return triggerApi(API_URL, 'PUT', TICKET_DATA);
};

// DELETE methods

export const deleteTicket = async (EVENT_ID: string | number, TICKET_ID: string | number): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-ticket/' + EVENT_ID + '/' + TICKET_ID;
    return triggerApi(API_URL, 'DELETE', '');
};

