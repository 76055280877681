import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/index.ts';
import 'reset-css';
import 'normalize.css';
import './fontawesomeIcons';
import DemoAppBar from './common/DemoAppBar.tsx';

const Main = () => 
{
    useEffect(() => 
    {
        const spinner = document.getElementById('loading-spinner');
        if (spinner) 
        {
            spinner.remove();
        }
    }, []);

    return (
        <Provider store={store}>
            <BrowserRouter>
                {
                    import.meta.env.VITE_NODE_ENV === 'demo' && <DemoAppBar />
                }
                <div id={import.meta.env.VITE_NODE_ENV === 'demo' ? "main-content" : ""}>
                    <App />
                </div>
            </BrowserRouter>
        </Provider>
    );
};

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Main />
    </React.StrictMode>,
);
