import axios from 'axios';
import { Event, EventSession, EventSpeakerSessionMaterial } from '../../../pages/Events/interfaces';
import { EventLeadCapture } from '../../../pages/Events/interfaces/event-lead-capture_interface';
import { EventRegistrant } from '../../../pages/Events/interfaces/event-registrant_interface';
import { SponsorPortalTrend } from '../../../pages/Portal/interfaces/portal_interface';
import { CustomField } from '../../../pages/Registration/interfaces/custom-field_interface';
import { RegistrationForm } from '../../../pages/Registration/interfaces/registration-form_interface';
import { BuyerPersona } from '../../../pages/Settings/interface/buyer-persona_interface';
import { ICP } from '../../../pages/Settings/interface/icp_interface';
import { Sponsor } from '../../../pages/Sponsors/interfaces';
import APP_CONSTANTS from '../../constants';
import { convertJSONToGETParams, triggerApi } from '../../helpers';
import { EventCheckInNotifications, UpdateEventCheckInNotifications } from '../../../pages/Events/interfaces/event-check-in-notifications.interface';
import { AudienceUser } from '../../../pages/Audience/interfaces';

// GET API's

export const registrantMe = async (orgLink: string, eventLink: string): Promise<EventRegistrant> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/' + orgLink + '/' + eventLink + '/registrant/me';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getSponsorPortalRegistrantsCount = async (...queryParams: [string, any][]): Promise<number> =>
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/registrants/count' + (query ? '?' + query : '');
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getSponsorPortalLeadsCount = async (...queryParams: [string, any][]): Promise<number> =>
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/leads/count' + (query ? '?' + query : '');
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllRegistrantsInSponsorPortal = async (pageSize: number, offset: number, ...queryParams: [string, any][]): Promise<EventRegistrant[]> =>
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        ...filteredParams
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/registrants?' + query;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllLeadsInSponsorPortal = async (pageSize: number, offset: number, status?: string, sponsorId?: string | number, accessToken?: string, ...queryParams: [string, any][]): Promise<EventLeadCapture[]> =>
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));

    const query = convertJSONToGETParams({
        pageSize,
        offset,
        status,
        accessToken,
        sponsorId,
        ...filteredParams
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/leads?' + query;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getIcpSponsorPortalCount = async (): Promise<number> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/icp/count';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllIcpSponsorPortal = async (pageSize: number, offset: number): Promise<ICP[]> =>
{
    const query = convertJSONToGETParams({
        pageSize,
        offset,
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/icp?' + query;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getIcpSponsorPortalById = async (id: string | number): Promise<ICP> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/icp-sponsor-portal/' + id;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getbuyerPersonaSponsorPortalCount = async (): Promise<number> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona/count';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllBuyerPersonaSponsorPortal = async (pageSize: number, offset: number): Promise<BuyerPersona[]> =>
{
    const query = convertJSONToGETParams({
        pageSize,
        offset,
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona?' + query;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getBuyerPersonaSponsorPortalById = async (id: string | number): Promise<BuyerPersona> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona' + id;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getLeadCaptureForm = async (): Promise<RegistrationForm> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/lead-capture-form';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllTargetList = async (registrant?: boolean, ...queryParams: [string, any][]): Promise<EventRegistrant[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        registrant,
        ...filteredParams
    })

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/target-list?' + query;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const sponsorPortalRegistrants = async (email?: string, firstName?: string, lastName?: string): Promise<EventRegistrant[]> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/search?' + convertJSONToGETParams({ email, firstName, lastName });
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
}; 

export const getSponsorPortalTrends = async (): Promise<SponsorPortalTrend> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/trend';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getSponsorInfo = async (): Promise<Sponsor> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/sponsor-info';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getTeamMembers = async (): Promise<EventRegistrant[]> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/team';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getSponsorPortalEventData = async (): Promise<Event> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/event';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllEventAgenda = async (): Promise<EventSession[]> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/agenda';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllSessionMaterials = async (sessionId: string): Promise<EventSpeakerSessionMaterial[]> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/session-material/' + sessionId;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllSpeakerPhotos = async () =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/speaker-photos';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const downloadAllSpeakerPhotos = async () =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/speaker-photos/zip';
    return axios.get(API_URL, {
        responseType: 'blob',
        withCredentials: true,
    });
};

export const getSponsorPortalNotifications = async (): Promise<EventCheckInNotifications[]> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/check-in-notifications';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
}

export const getSponsorPortalNotificationsById = async (notificationId: string | number): Promise<EventCheckInNotifications> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/check-in-notifications/' + notificationId;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
}

export const getSponsorPortalNotificationsCount = async (): Promise<number> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/check-in-notifications/count';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
}

export const getSponsorPortalNotificationsUsers = async (notificationId: string | number): Promise<AudienceUser[]> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/check-in-notifications/' + notificationId + '/users';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
}

// POST API's

export const accessSponsor = async (data: any): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/access';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const authorizeSponsor = async (data: any): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/authorize';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const createIcpSponsorPortal = async (data: ICP): Promise<ICP> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/icp';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const createBuyerPersonaSponsorPortal = async (data: BuyerPersona): Promise<BuyerPersona> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const createLeadCaptureField = async (formId: string | number | undefined, data: any): Promise<CustomField> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/' + formId + '/fields';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const unauthorizeSponsor = async (): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/unauthorize';
    return triggerApi(API_URL, 'POST', null, undefined);
};

export const addToTargetList = async (data: { registrantIds: string[] | number[] | null }): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/registrants/target-list';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const addSponsorInfoImages = async (image: FormData, imageName: string): Promise<string> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/upload-images/' + imageName;
    return triggerApi(API_URL, 'POST', image, '', false, undefined, true);
};

export const addTeamMember = async (data: any): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/team';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const uploadSessionMaterials = async (data: FormData, sessionId: string): Promise<string> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/session-material/' + sessionId;
    return triggerApi(API_URL, 'POST', data, '', false, undefined, true);
};

export const addSponsorPortalNotifications = async (data: any): Promise<EventCheckInNotifications> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/check-in-notifications';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
}

// PUT API's

export const updateIcpSponsorPortal = async (id: string | number, data: ICP): Promise<ICP> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/icp/' + id;
    return triggerApi(API_URL, 'PUT', data, undefined, undefined, undefined, true);
};

export const updateBuyerPersonaSponsorPortal = async (id: string | number, data: BuyerPersona): Promise<BuyerPersona> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona/' + id;
    return triggerApi(API_URL, 'PUT', data, undefined, undefined, undefined, true);
};

export const updateLeadCaptureField = async (formId: string | number | undefined, fieldId: string | number | undefined, data: any): Promise<CustomField> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/' + formId + '/' + fieldId + '/fields';
    return triggerApi(API_URL, 'PUT', data, undefined, undefined, undefined, true);
};

export const updateLeadCaptureForm = async (formId: string | number, data: any): Promise<RegistrationForm> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/lead-capture-form/' + formId;
    return triggerApi(API_URL, 'PUT', data, undefined, undefined, undefined, true);
};

export const updateSponsorInfo = async (data: any): Promise<Sponsor> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/sponsor-info';
    return triggerApi(API_URL, 'PUT', data, undefined, undefined, undefined, true);
};

export const updateSessionMaterial = async (data: any, sessionId: string, blobName: string): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/session-material/' + sessionId + '?blobName=' + blobName;
    return triggerApi(API_URL, 'PUT', data, undefined, undefined, undefined, true);
};

export const updateSponsorPortalNotifications = async ( notificationId: string | number,data: any): Promise<UpdateEventCheckInNotifications> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/check-in-notifications/' + notificationId;
    return triggerApi(API_URL, 'PUT', data, undefined, undefined, undefined, true);
}

// DELETE API's

export const deleteIcpSponsorPortal = async (id: string | number): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/icp/' + id;
    return triggerApi(API_URL, 'DELETE', '', undefined, undefined, undefined, true);
};

export const deleteBuyerPersonaSponsorPortal = async (id: string | number): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona/' + id;
    return triggerApi(API_URL, 'DELETE', '', undefined, undefined, undefined, true);
};

export const deleteLeadCaptureField = async (formId: string | number | undefined, componentId: string | number | undefined): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/lead-capture-form/' + formId + '/components/' + componentId;
    return triggerApi(API_URL, 'DELETE', '', undefined, undefined, undefined, true);
};

export const removeTargetsFromTargetList = async (data: { registrantIds: string[] | number[] | null }): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/registrants/target-list';
    return triggerApi(API_URL, 'DELETE', data, undefined, undefined, undefined, true);
};

export const deleteSponsorInfoImages = async (imageName: string, url: string): Promise<boolean> =>
{

    const query = convertJSONToGETParams({
        url
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/images/' + imageName + '?' + query;
    return triggerApi(API_URL, 'DELETE', '', undefined, undefined, undefined, true);
};

export const removeTeamMember = async (id: string | number): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/team/' + id;
    return triggerApi(API_URL, 'DELETE', '', undefined, undefined, undefined, true);
};

export const deleteSessionMaterial = async (sessionId: string, blobName: string): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/session-material/' + sessionId + '?blobName=' + blobName;
    return triggerApi(API_URL, 'DELETE', '', undefined, undefined, undefined, true);
};

export const deleteSponsorPortalNotifications = async (notificationId: string | number): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/check-in-notifications/' + notificationId;
    return triggerApi(API_URL, 'DELETE', '', undefined, undefined, undefined, true);
}