import { useEffect, useState } from 'react';
import { AutocompletewithTags, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { getAllICP, icpCount } from '../../../scripts/apis/icp';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import ICP from '../../ICP/AddICP';
import { IICP } from '../../../pages/Settings/ICP/interfaces';
import { updateEvent } from '../../../scripts/apis/events';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import useEventOverviewData from '../../../hooks/eventOverviewData';
import _ from 'lodash';

import './styles.scss';

const EventICPSettings: React.FC = (): React.JSX.Element =>
{
    const { eventId } = useParams();

    const [eventDetails, , , , , , setRefreshEventData] = useEventOverviewData({
        eventId: Number(eventId)
    });

    const [icpNames, setIcpNames] = useState<string[]>([]);
    const [icpIds, setIcpIds] = useState<string[]>(eventDetails?.icps || []);
    const [icpDetails, setIcpDetails] = useState<IICP[]>([]);

    const handleIcpChange = (event: any, newValue: string[]): void =>
    {
        setIcpNames(newValue);
        const ids = icpDetails.filter((icp: IICP) => newValue.includes(icp.name)).map((icp: IICP) => icp.id);
        if(ids)
        {
            setIcpIds(ids);
        }
    };

    const handleRemoveIcp = (index: number): void =>
    {
        const newIcpNames = icpNames.filter((name: string, i: number) => i !== index);
        setIcpNames(newIcpNames);
        const ids = icpDetails.filter((icp: IICP) => newIcpNames.includes(icp.name)).map((icp: IICP) => icp.id);
        if(ids)
        {
            setIcpIds(ids);
        }
    }

    const onIcpCreate = (icpData: IICP): void => {
        const updatedIcpDetails = [icpData, ...icpDetails];
        const updatedIcpNames = [...icpNames, icpData.name];
    
        setIcpDetails(updatedIcpDetails);
        setIcpNames(updatedIcpNames);
    
        const ids = updatedIcpDetails
            .filter((icp: IICP) => updatedIcpNames.includes(icp.name))
            .map((icp: IICP) => icp.id);
    
        if (ids.length > 0) {
            setIcpIds(ids);
        }
    };

    const handleIcpSidebarOpen = (): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Create ICP',
            component: <ICP eventICP onICPCreate={onIcpCreate} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const handleSubmit = async (): Promise<void> =>
    {
        try 
        {
            const icpIds = icpDetails.filter((icp: IICP) => icpNames.includes(icp.name)).map((icp: IICP) => icp.id);
            const data = {
                icps: icpIds?.length > 0 ? icpIds : null
            }

            const icpDataSaved = await updateEvent(eventId as string, data);
            if(icpDataSaved)
            {
                toast.success('ICP Mapping saved successfully');
                setRefreshEventData(true);
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }
    };

    const handleCancelClick = (): void =>
    {
        setIcpNames(eventDetails?.icps?.map((icp: string) => icpDetails.find((icpData: IICP) => icpData.id === icp)?.name).filter((name): name is string => name !== undefined) || []);
        setIcpIds(eventDetails?.icps || []);
    };

    const fetchIcpData = async (): Promise<void> =>
    {
        try 
        {
            const count = await icpCount();
            if(count)
            {
                const icpDetails = await getAllICP(count, 0);
                if(icpDetails)
                {
                    setIcpDetails(icpDetails);
                }
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(() =>
    {
        fetchIcpData();
    }, []);

    useEffect(() =>
    {
        if(eventDetails && eventDetails?.icps && eventDetails?.icps?.length > 0)
        {
            setIcpIds(eventDetails?.icps);
            const names = icpDetails.filter((icp: IICP) => eventDetails?.icps && eventDetails?.icps.includes(icp.id)).map((icp: IICP) => icp.name);
            if(names)
            {
                setIcpNames(names);
            }
        }
    }, [eventDetails]);

    return (
        <div id="eventIcpSettings">
            <div className="event-icp-settings-container">
                <div className="title-description-container">
                    <h3 className="title">{'ICP Mapping'}</h3>
                    <p className="description">{''}</p>
                </div>
                <div className="icp-mapping-container">
                    <div className="selection-container">
                        <FormLabelComponent label="Select ICP" />
                        <AutocompletewithTags 
                            value={icpNames}
                            onChange={handleIcpChange}
                            options={icpDetails.map((icp: IICP) => icp.name)}
                            placeholder="Select ICP"
                            onRemoveClick={(index: number) => handleRemoveIcp(index)}
                        />
                    </div>
                    <div className="button-container">
                        {
                            eventDetails?.icps && (!_.isEqual(eventDetails?.icps, icpIds)) &&
                            <>
                                <CustomButton
                                    name="Cancel"
                                    onClick={handleCancelClick}
                                    btnType="secondary"
                                />
                                <CustomButton 
                                    btnType="primary"
                                    name="Save"
                                    onClick={handleSubmit}
                                />
                            </>
                        }
                        <CustomButton 
                            btnType="secondary"
                            name="+ Create ICP"
                            onClick={handleIcpSidebarOpen}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventICPSettings;