import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import APP_CONSTANTS from "../scripts/constants";

interface CustomTooltipProps { 
    title: string | React.ReactNode; 
    children: React.ReactElement<any, any>; 
    placement?: 'top' | 'bottom' | 'left' | 'right';
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#0C111D',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#0C111D',
      padding: '8px 12px',
      borderRadius: '8px',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '18px',
      fontStyle: 'normal',
    },
}));

const CustomTooltip: React.FC<CustomTooltipProps> = (props): React.JSX.Element =>
{

    const { title, children, placement } = props;

    return (
        <BootstrapTooltip title={title} arrow placement={placement || APP_CONSTANTS.TOOLTIP_PLACEMENT}>
            {children}
        </BootstrapTooltip>
    )
};

export default CustomTooltip;