import { FormikErrors } from 'formik';
import { AutocompletewithTags, DebouncedAutocomplete, FormLabelComponent, RadioGroupComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { BorderLinearProgress } from '../../../common/StyledComponents/linearProgress.styled';
import { EmailMarketingFormData, EventMarketing, EventMarketingFilterObj, EventTicket } from '../../../pages/Events/interfaces';
import './styles.scss';
import DatePicker from '../../../common/FormComponents/DatePicker';
import TimePicker from '../../../common/FormComponents/TimePicker';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { getAlleventTickets, getEventTicketTrend, ticketsCount } from '../../../scripts/apis/eventTickets';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SectionCard from '../../../common/SectionCard';
import { EventRegistrantAttendedStatus, EventRegistrantStatusFilter } from '../../../pages/Events/enum/event-registrant.enum';
import { EnableTicketWaitlistStatus, EventMarketingTriggerType, TicketType } from '../../../pages/Events/enum';
import { EventRegistrant, EventRegistrantTicketTrend } from '../../../pages/Events/interfaces/event-registrant_interface';
import { getAllEventRegistrants, getEventRegistrantCount, searchEventRegistrant } from '../../../scripts/apis/eventRegistrants';
import _, { debounce } from 'lodash';
import MuiChip from '../../../common/FormComponents/MuiChip';
import { Stack } from '@mui/material';

interface EmailMarketingScheduleProps {
    formValues: EmailMarketingFormData;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<EmailMarketingFormData>>;
    existingMarketingData?: EventMarketing;
}

const EmailMarketingSchedule: React.FC<EmailMarketingScheduleProps> = (props): React.JSX.Element =>
{
    const { eventId } = useParams();
    const { formValues, setFieldValue, existingMarketingData } = props;

    const [eventTickets, setEventTickets] = useState<EventTicket[]>([]);
    const [eventTicketTrend, setEventTicketTrend] = useState<EventRegistrantTicketTrend[]>([]);
    const [estimatedRegistrants, setEstimatedRegistrants] = useState<number>(0);
    const [eventRegistrantCount, setEventRegistrantCount] = useState<number>(0);
    const [registrants, setRegistrants] = useState<EventRegistrant[]>([]);
    const [ignoreRegistrantInputValue, setIgnoreRegistrantInputValue] = useState<string>('');

    const getAvailableTickets = (index: number) => 
    {
        const selectedTickets = formValues?.filter?.slice(0, index).flatMap((filter: EventMarketingFilterObj) => filter.ticketNames);
        return eventTickets.filter(ticket => !selectedTickets.includes(ticket.name));
    };

    const sendOptions = [
        { 
            value: EventMarketingTriggerType.IMMEDIATE, name: "Send now" 
        },
        { 
            value: EventMarketingTriggerType.SCHEDULED, name: "Schedule for later" 
        },
    ];

    const handleRegistrantSearch = (event: React.SyntheticEvent<Element, Event>, newValue: string):void => 
    {
        setIgnoreRegistrantInputValue(newValue);

        if(newValue === '')
        {
            const mergedRegistrants = [...registrants];
            if(formValues?.ignoredRegistrants && formValues?.ignoredRegistrants?.length > 0)
            {
                for (const selectedOption of formValues?.ignoredRegistrants) {
                    if (!mergedRegistrants.some(registrant => registrant.id === selectedOption.id)) {
                        mergedRegistrants.push(selectedOption);
                    }
                }
                setRegistrants(mergedRegistrants);
            }
        }
        else
        {
            delayDebounceRegistrantSearch(newValue);
        }
    };

    const handleAddRecipient = (): void =>
    {
        const filters = formValues?.filter;
        if(getAvailableTickets(filters.length).length === 0)
        {
            return;
        }
        filters.push({
            ticketNames: [],
            ticketIds: [],
            statuses: [],
            attendeeStatus: undefined
        });
        setFieldValue('filter', filters);
    }

    const FilterCard = ({ filter, index }: { filter: EventMarketingFilterObj, index: number }): React.ReactNode =>
    {
        const availableTickets = getAvailableTickets(index);

        const generateStatusOptions = () => 
        {
            type StatusOptions = {name: string, id: EventRegistrantStatusFilter};
            const baseOptions: StatusOptions[] = [
                {
                    name: 'In Review',
                    id: EventRegistrantStatusFilter.INVITE,
                },
                {
                    name: 'Rejected',
                    id: EventRegistrantStatusFilter.REJECTED,
                },
                {
                    name: 'Confirmed',
                    id: EventRegistrantStatusFilter.CONFIRMED,
                },
            ];

            if(availableTickets?.length > 0 && availableTickets?.some((ticket) => ticket.type === TicketType.PAID)) 
            {
                baseOptions.splice(1, 0, {
                    name: 'Approved',
                    id: EventRegistrantStatusFilter.APPROVED,
                });
            }

            if(availableTickets?.length > 0 && availableTickets?.some((ticket) => ticket.enableWaitlistStatus === EnableTicketWaitlistStatus.ENABLE))
            {
                baseOptions.splice(3, 0, {
                    name: 'Waitlist',
                    id: EventRegistrantStatusFilter.WAITLIST,
                });
            }

            if (filter?.statuses?.includes('Confirmed')) {
                if (!filter?.statuses?.includes('Not Attended')) {
                    baseOptions.push({
                        name: 'Attended',
                        id: EventRegistrantStatusFilter.ATTENDED,
                    });
                }
                if (!filter?.statuses?.includes('Attended')) {
                    baseOptions.push({
                        name: 'Not Attended',
                        id: EventRegistrantStatusFilter.NOT_ATTENDED,
                    });
                }
            }

            return baseOptions;
        };

        return (
            <>
                {
                    eventTickets && eventTickets?.length > 0 &&
                    <div>
                        <FormLabelComponent label='Tickets' />
                        <AutocompletewithTags 
                            value={filter?.ticketNames as string[]}
                            options={availableTickets}
                            onChange={(event, newValue: string[]) => {
                                if (newValue.includes('All')) {
                                    const allTickets = availableTickets.map(ticket => ticket.name);
                                    setFieldValue(`filter[${index}].ticketNames`, allTickets);
                                    setFieldValue(`filter[${index}].ticketIds`, allTickets.map(ticketName => eventTickets.find(ticket => ticket.name === ticketName)?.id));
                                } else {
                                    setFieldValue(`filter[${index}].ticketNames`, newValue);
                                    setFieldValue(`filter[${index}].ticketIds`, newValue.map(ticketName => eventTickets.find(ticket => ticket.name === ticketName)?.id));
                                }
                            }}
                            onRemoveClick={(removeIndex) => {
                                const tickets = filter?.ticketNames && filter?.ticketNames?.filter((_, i) => i !== removeIndex);
                                setFieldValue(`filter[${index}].ticketNames`, tickets);
                                if (tickets) {
                                    setFieldValue(`filter[${index}].ticketIds`, tickets.map(ticketName => eventTickets.find(ticket => ticket.name === ticketName)?.id));
                                }
                            }}
                            keyToShow='name'
                            showAll
                        />
                    </div>
                }
                <div>
                    <FormLabelComponent label='Status' />
                    <AutocompletewithTags 
                        value={filter.statuses as any[]}
                        options={generateStatusOptions()}
                        onChange={(event, newValue: string[]) => {
                            const updatedStatus = newValue;
                            setFieldValue(`filter[${index}].statuses`, updatedStatus);
                        }}
                        onRemoveClick={(removeIndex) => {
                            const status = filter.statuses && filter.statuses.filter((_, i) => i !== removeIndex);
                            setFieldValue(`filter[${index}].statuses`, status);
                        }}
                        keyToShow='name'
                    />
                </div>
            </>
        )
    };

    const calculateEstimatedRegistrants = () => {
        let totalCount = 0;

        const statusMapping = {
            [EventRegistrantStatusFilter.INVITE]: 'In Review',
            [EventRegistrantStatusFilter.APPROVED]: 'Approved',
            [EventRegistrantStatusFilter.REJECTED]: 'Rejected',
            [EventRegistrantStatusFilter.WAITLIST]: 'Waitlist',
            [EventRegistrantStatusFilter.DELETED]: 'Deleted',
            [EventRegistrantStatusFilter.CONFIRMED]: 'Confirmed',
            [EventRegistrantStatusFilter.INVITED]: 'Invited',
            [EventRegistrantStatusFilter.ATTENDED]: 'Attended',
            [EventRegistrantStatusFilter.NOT_ATTENDED]: 'Not Attended',
        };
    
        const peopleRegisteredWithoutTicket = eventTicketTrend.find(trend => trend.ticketName === null);

        formValues?.filter?.forEach((filter: EventMarketingFilterObj) => {

            if(filter?.statuses?.length === 0 && filter?.ticketNames?.length === 0)
            {
                totalCount = eventRegistrantCount;
            }

            filter?.ticketNames && filter.ticketNames?.length > 0 && filter.ticketNames.forEach((ticketName: string) => {
                const ticketTrend = eventTicketTrend.find(trend => trend.ticketName === ticketName);

                if (!filter.statuses?.length) {
                    if(ticketTrend)
                    {
                        totalCount += ticketTrend.inReview + ticketTrend.approved + ticketTrend.rejected + ticketTrend.waitlist + ticketTrend.confirmed;
                    }
                }

                if (ticketTrend) {
                    let countConfirmed = false;
                    let countAttended = false;
                    let countNotAttended = false;
    
                    filter.statuses?.forEach((status: string | any) => {
                        switch (status) {
                            case 'Confirmed' as any:
                                countConfirmed = true;
                                break;
                            case 'Attended' as any:
                                countAttended = true;
                                break;
                            case 'Not Attended' as any:
                                countNotAttended = true;
                                break;
                            default:
                                break;
                        }
                    });
    
                    filter.statuses?.forEach((status: string | any) => {
                        switch (status) {
                            case 'In Review' as any:
                                totalCount += ticketTrend.inReview;
                                break;
                            case 'Approved' as any:
                                totalCount += ticketTrend.approved;
                                break;
                            case 'Rejected' as any:
                                totalCount += ticketTrend.rejected;
                                break;
                            case 'Waitlist' as any:
                                totalCount += ticketTrend.waitlist;
                                break;
                            case 'Confirmed' as any:
                                if (!countAttended && !countNotAttended) {
                                    totalCount += ticketTrend.confirmed;
                                }
                                break;
                            case 'Attended' as any:
                                totalCount += ticketTrend.attended;
                                break;
                            case 'Not Attended' as any:
                                totalCount += ticketTrend.notAttended;
                                break;
                            default:
                                break;
                        }
                    });
                }

                // if (formValues?.ignoredRegistrants && formValues?.ignoredRegistrants?.length > 0)
                // {
                //     formValues?.ignoredRegistrants?.forEach((item) => {
                //         if (item.ticket?.name === ticketName) 
                //         {
                //             totalCount -= 1;
                //         }

                //         if(filter?.statuses && filter.statuses.length > 0)
                //         {
                //             for(const status of filter.statuses)
                //             {
                //                 if(statusMapping[item.status as EventRegistrantStatusFilter] === status)
                //                 {
                //                     totalCount -= 1;
                //                 }
                //             }
                //         }
                //     })
                // }
            });

            if(filter?.ticketNames?.length === 0) { 
                let countConfirmed = false;
                let countAttended = false;
                let countNotAttended = false;

                filter.statuses?.forEach((status: string | any) => {
                    switch (status) {
                        case 'Confirmed' as any:
                            countConfirmed = true;
                            break;
                        case 'Attended' as any:
                            countAttended = true;
                            break;
                        case 'Not Attended' as any:
                            countNotAttended = true;
                            break;
                        default:
                            break;
                    }
                });

                if (filter?.statuses && filter?.statuses?.length > 0)
                {
                    filter?.statuses?.forEach((status: string | any) => {
                        switch (status) {
                            case 'In Review' as any:
                                totalCount += _.sumBy(eventTicketTrend, 'inReview');
                                break;
                            case 'Approved' as any:
                                totalCount += _.sumBy(eventTicketTrend, 'approved');
                                break;
                            case 'Rejected' as any:
                                totalCount += _.sumBy(eventTicketTrend, 'rejected');
                                break;
                            case 'Waitlist' as any:
                                totalCount += _.sumBy(eventTicketTrend, 'waitlist');
                                break;
                            case 'Confirmed' as any:
                                if (!countAttended && !countNotAttended) {
                                    totalCount += _.sumBy(eventTicketTrend, 'confirmed');
                                }
                                break;
                            case 'Attended' as any:
                                totalCount += _.sumBy(eventTicketTrend, 'attended');
                                break;
                            case 'Not Attended' as any:
                                totalCount += _.sumBy(eventTicketTrend, 'notAttended');
                                break;
                            default:
                                break;
                        }
                    });
                }

                if (peopleRegisteredWithoutTicket && !filter?.statuses?.length) 
                {
                    filter.statuses?.forEach((status: string | any) => {
                        switch (status) {
                            case 'In Review' as any:
                                totalCount += peopleRegisteredWithoutTicket.inReview;
                                break;
                            case 'Approved' as any:
                                totalCount += peopleRegisteredWithoutTicket.approved;
                                break;
                            case 'Rejected' as any:
                                totalCount += peopleRegisteredWithoutTicket.rejected;
                                break;
                            case 'Waitlist' as any:
                                totalCount += peopleRegisteredWithoutTicket.waitlist;
                                break;
                            case 'Confirmed' as any:
                                if (!countAttended && !countNotAttended) {
                                    totalCount += peopleRegisteredWithoutTicket.confirmed;
                                }
                                break;
                            case 'Attended' as any:
                                totalCount += peopleRegisteredWithoutTicket.attended;
                                break;
                            case 'Not Attended' as any:
                                totalCount += peopleRegisteredWithoutTicket.notAttended;
                                break;
                            default:
                                break;
                        }
                    });
                }

                // if (formValues?.ignoredRegistrants && formValues?.ignoredRegistrants?.length > 0)
                // {
                //     formValues?.ignoredRegistrants?.forEach((item) => {
                //         if(filter?.statuses && filter.statuses.length > 0)
                //         {
                //             for(const status of filter?.statuses)
                //             {
                //                 if(statusMapping[item.status as EventRegistrantStatusFilter] === status)
                //                 {
                //                     totalCount -= 1;
                //                 }
                //             }
                //         }
                //         else
                //         {
                //             totalCount -= 1;
                //         }
                //     })
                // }
            }
        });

        if (formValues?.filter?.length === 0 && formValues?.ignoredRegistrants?.length === 0) {
            totalCount = eventRegistrantCount;
        }
        else if (formValues?.ignoredRegistrants && formValues?.ignoredRegistrants?.length > 0) {
            totalCount -= formValues?.ignoredRegistrants?.length;
        }

        return totalCount < 0 ? 0 : totalCount;
    };

    const delayDebounceRegistrantSearch = debounce(async(newValue: string): Promise<void> =>
    {
        if (newValue?.length > 2) 
        {
            try 
            {

                const params = {
                    email: newValue,
                };

                const registrantData = await searchEventRegistrant(
                    eventId as string,
                    params?.email ? params.email : undefined,
                    undefined,
                    undefined,
                );

                if (registrantData) {

                    // if there are moderators selected already then filter them out based on email
                    // if(existingModerators)
                    // {
                    //     const filteredRegistrants = registrantData?.filter((elem) => !existingModerators.find(({ email }) => elem.email === email));
                    //     if(filteredRegistrants && filteredRegistrants?.length > 0)
                    //     {
                    //         const mergedRegistrants = [...registrants];
                    //         for (const data of filteredRegistrants) {
                    //             if (!mergedRegistrants.some(registrant => registrant.id === data.id)) {
                    //                 mergedRegistrants.push(data);
                    //             }
                    //         }
                    //         setRegistrants(mergedRegistrants);
                    //     }
                    // }
                    // else merge the registrants fetched from the initial fetch request along with the search request data
                    // else
                    // {
                        const mergedRegistrants = [...registrants];
                        for (const data of registrantData) {
                            if (!mergedRegistrants.some(registrant => registrant.id === data.id)) {
                                mergedRegistrants.push(data);
                            }
                        }
                        setRegistrants(mergedRegistrants);
                    // }
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    }, 300);

    const fetchEventTickets = async (): Promise<void> =>
    {
        try 
        {
            const count = await ticketsCount(eventId as string);
            if(count)
            {
                const tickets = await getAlleventTickets(count, 0, eventId as string);
                if(tickets)
                {
                    setEventTickets(tickets);
                    if(existingMarketingData && existingMarketingData?.filter && existingMarketingData?.filter?.length > 0)
                    {
                        handleExistingDataMapping(tickets);
                    }
                }
            }
        } 
        catch (error) 
        {
            console.log('Error fetching event tickets: ', error);
        }
    }; 

    const fetchEventTicketTrend = async (): Promise<void> =>
    {
        try 
        {
            const trend = await getEventTicketTrend(eventId as string);
            if(trend)
            {
                setEventTicketTrend(trend);
            }
        } 
        catch (error) 
        {
            console.log('Error fetching event ticket trend: ', error);
        }
    }; 
    
    const fetchRegistrantsCount = async (): Promise<void> =>
    {
        try 
        {
            const count = await getEventRegistrantCount(eventId as string);
            if(count)
            {
                setEventRegistrantCount(count);
            }
        } 
        catch (error) 
        {
            console.log('Error fetching event registrants count: ', error);
        }
    };

    const fetchInitialRegistrants = async (): Promise<void> =>
    {
        try 
        {
            const registrants = await getAllEventRegistrants(eventId as string, 10, 0);
            if(registrants)
            {
                // if moderators were selected already then filter them out from the initial data
                // if(existingModerators && existingModerators?.length > 0)
                // {
                //     const filteredRegistrants = registrants?.filter((elem) => !existingModerators.find(({ email }) => elem.email === email));
                //     if(filteredRegistrants && filteredRegistrants?.length > 0)
                //     {
                //         return setRegistrants(filteredRegistrants);
                //     }
                // }
                // if there are no moderators selected already then store the registrant data fetched from the api
                setRegistrants(registrants);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchRegistrantsFromIds = async (): Promise<void> =>
    {
        if(existingMarketingData?.ignoredRegistrants)
        {
            try 
            {
                const registrants = await getAllEventRegistrants(eventId as string, existingMarketingData?.ignoredRegistrants?.length, 0, ['registrantIdsStr', existingMarketingData?.ignoredRegistrants?.join(',')]);
                if(registrants)
                {
                    setFieldValue('ignoredRegistrants', registrants);
                }
            } 
            catch (error) 
            {
                console.log(error, 'error');
            }
        }
    };

    const handleExistingDataMapping = async (tickets?: EventTicket[]): Promise<void> =>
    {
        if(existingMarketingData?.filter && existingMarketingData?.filter?.length > 0)
        {
            const updatedFilters = existingMarketingData?.filter.map((filter, index) => {
                let statusNames: string[] = [];
                if (filter?.statuses && filter?.statuses?.length > 0) {
                    const statusModifedToNumber = filter?.statuses?.map(Number);
                    statusNames = statusModifedToNumber?.map((status: EventRegistrantStatusFilter) => {
                        return status === EventRegistrantStatusFilter.INVITE ? 'In Review' :
                            status === EventRegistrantStatusFilter.APPROVED ? 'Approved' :
                            status === EventRegistrantStatusFilter.REJECTED ? 'Rejected' :
                            status === EventRegistrantStatusFilter.WAITLIST ? 'Waitlist' :
                            status === EventRegistrantStatusFilter.CONFIRMED ? 'Confirmed' : ''
                    });
                }
                if (filter?.attendeeStatus)
                {
                    if(String(filter?.attendeeStatus) === String(EventRegistrantAttendedStatus.YES))
                    {
                        statusNames.push('Attended');
                    }
                    if(String(filter?.attendeeStatus) === String(EventRegistrantAttendedStatus.NO))
                    {
                        statusNames.push('Not Attended');
                    }
                }
                return {
                    ...filter,
                    ticketIds: filter?.tickets || [],
                    ticketNames: filter?.tickets?.map((ticketId) => tickets && tickets?.find(ticket => String(ticket.id) === String(ticketId))?.name) || [],
                    statuses: statusNames
                }
            });
            setFieldValue('filter', updatedFilters);
        }
    }

    useEffect(() =>
    {
        fetchEventTickets();
        fetchEventTicketTrend();
        fetchRegistrantsCount();
        fetchInitialRegistrants();
        if (existingMarketingData?.ignoredRegistrants && existingMarketingData?.ignoredRegistrants?.length > 0) {
            fetchRegistrantsFromIds();
        }
    }, []);

    useEffect(() => {
        setEstimatedRegistrants(calculateEstimatedRegistrants());
    }, [formValues?.filter, formValues?.ignoredRegistrants, eventRegistrantCount]);

    return (
        <div id="emailMarketingSchedule">
            <div className="email-marketing-schedule-container">

                {/*  */}
                <h3 className="send-schedule-heading">{'Send Now or Schedule'}</h3>

                {/* Estimated recipients */}
                <div className="sidebar-container-spacing">
                    <div>
                        <FormLabelComponent label='Estimated recipients' />
                        <FormLabelComponent label={`${estimatedRegistrants}/${eventRegistrantCount}`} />
                    </div>
                    <BorderLinearProgress 
                        value={(estimatedRegistrants / eventRegistrantCount) * 100}
                        progressColor={'#1570EF'}
                        variant='determinate'
                    />
                </div>

                {/* Sending options */}
                <div className="sidebar-container-spacing send-options-container">
                    <FormLabelComponent label='Sending Options' />
                    <RadioGroupComponent 
                        row
                        options={sendOptions}
                        value={formValues.triggerType}
                        onChange={(event) => setFieldValue('triggerType', Number(event.target.value))}
                    />
                </div>

                {/* Trigger timing */}
                {
                    formValues.triggerType === EventMarketingTriggerType.SCHEDULED &&
                    <div className="sidebar-container-spacing">
                        <div className="trigger-timing-container">
                            <div className="w-100">
                                <FormLabelComponent label='Trigger Date' />
                                <CustomButton 
                                    btnType="secondary"
                                    name=""
                                    mainDivStyle={{ width: '100%' }}
                                    style={{ width: '100%', textAlign: 'left', justifyContent: 'flex-start', minHeight: '40px' }}
                                >
                                    <DatePicker 
                                        value={formValues.triggerDate}
                                        onChange={(date) => setFieldValue('triggerDate', date.unix())}
                                    />
                                </CustomButton>
                            </div>
                            <div className="w-100">
                                <FormLabelComponent label='Trigger Time' />
                                <CustomButton
                                    btnType="secondary"
                                    name=""
                                    mainDivStyle={{ width: '100%' }}
                                    style={{ width: '100%', textAlign: 'left', justifyContent: 'flex-start', minHeight: '40px' }}
                                >
                                    <TimePicker 
                                        value={formValues.triggerTime}
                                        onChange={(time) => setFieldValue('triggerTime', time)}
                                    />
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                }

                {/* Send to filters */}
                <div className="sidebar-container-spacing">
                    <div className="send-to-filters-container">
                        <div className="send-to-filters-header">
                            <FormLabelComponent label='Send to' />
                            <CustomButton 
                                name='Add Recipient'
                                btnType='tertiary'
                                startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                                disabled={getAvailableTickets(formValues?.filter?.length).length === 0}
                                onClick={() =>{
                                    handleAddRecipient();
                                }}
                            />
                        </div>

                        {
                            formValues?.filter?.map((filter, index) => {
                                return (
                                    <div className="w-100">
                                        <SectionCard 
                                            title={`Recipient ${index + 1}`}
                                            cardContent={<FilterCard filter={filter} index={index} />}
                                            onCloseButtonClick={
                                                index === 0 ? undefined : () => {
                                                    const filters = [...formValues?.filter];
                                                    filters.splice(index, 1);
                                                    setFieldValue('filter', filters);
                                                }
                                            }
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                {/* Don't send to list of registrants */}
                <div className="sidebar-container-spacing">
                    <div className="ignored-registrants-container">
                        <FormLabelComponent label="Don't Send to Recipient" />
                        <DebouncedAutocomplete 
                            defaultValue={formValues?.ignoredRegistrants}
                            value={formValues?.ignoredRegistrants}
                            onChange={(event, newValue): void => {
                                setFieldValue('ignoredRegistrants', newValue);
                            }}
                            noOptionsText="No Registrants"
                            inputValue={ignoreRegistrantInputValue}
                            onInputChange={handleRegistrantSearch}
                            options={registrants}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    {option.email}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value): boolean => option.email === value.email}
                            getOptionLabel={(option): string => option?.email}
                            placeholder="Search for a Registrant"
                            className="registrant-select"
                        />
                        {
                            formValues?.ignoredRegistrants && formValues?.ignoredRegistrants?.length > 0 && 
                            <div className="ignored-registrant-tags-container">
                                {
                                    formValues?.ignoredRegistrants?.map((element: EventRegistrant): React.JSX.Element => 
                                    {
                                        const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];
                                        return (
                                            <MuiChip
                                                chipColor={chipColors[Number(element.id) % chipColors.length] as 'red' | 'green' | 'blue'}
                                                label={<Stack direction={'row'} spacing={1} key={element.id} className="registrant-selected-label">
                                                {element?.firstName}
                                                <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-btn" onClick={(): void => 
                                                {
                                                    const filteredRegistrants = formValues?.ignoredRegistrants?.filter((registrant): boolean => (registrant as EventRegistrant).id !== element.id);
                                                    setFieldValue('ignoredRegistrants', filteredRegistrants);
                                                }} />
                                            </Stack>}
                                            />
                                            
                                        );
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EmailMarketingSchedule;