/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, ReactElement } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Stack, Typography } from '@mui/material';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { useDispatch, useSelector } from 'react-redux';
import { addSpeakers } from '../../redux/speakers/Speakers';
import { createSpeaker, getAllSpeakersActivityLogs, updateSpeaker, uploadSpeakerImage } from '../../scripts/apis/speakers';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { useNavigate } from 'react-router-dom';
import { addEventSpeaker, removeEventSpeaker } from '../../scripts/apis/eventSpeakers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
//import _ from 'lodash';
import './styles.scss';
import { CONTENT } from '../../scripts/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decompressImage } from '../../common/ImageProcessing';
import { isImageBuffer } from '../../scripts/helpers';
import { renderCheckBoxComponent, renderDateComponent, renderDropDownComponent, renderInputComponent, renderRadioGroupComponent } from '../../pages/Settings/CustomField/CustomTypeComponent';
import { CloseIconComponent, FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
import { ISpeakerValue, eventSpeakersDetails } from './ICreateSpeaker';
import { CustomFieldLocations, CustomFieldType, customFieldCountByLocation, customFieldsByLocation } from '../../pages/Settings/CustomField/CustomField.helper';
import CustomSpinner from '../../common/CustomSpinner';
import { CustomButton } from '../../common/FormComponents/Buttons';
import DeletePopup from '../../common/DeletePopup';
import toast from 'react-hot-toast';
import ActivityLogViewer from '../../common/ActivityLog/ActivityLogViewer';
import ActivityLogComponent from '../../common/ActivityLog/ActivityLogComponent';
import { ActivityLogResponse } from '../../interfaces/activity-log_interface';
import { speakersActivityLog } from '../../scripts/activityLogGenerator/activitylog-generator';

const CreateSpeaker = (props): React.JSX.Element =>
{
    const eventSpeakerData = props.speakerData;
    const disabled = props.disabled;
    const eventId = props['data-eventId'];
    const eventSpeaker = props['create-event-speaker'];
    const speakerId = props.speakerId;
    const [files, setFiles] = useState('');
    // const [speakerBioLength, setSpeakerBioLength] = useState(0);
    const [spinner, setSpinner] = useState(false);
    const [speakerImage, setSpeakerImage] = useState('');
    const [customFormState, setCustomFormState] = useState({
    });
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsSpinner, setCustomFieldsSpinner] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [activityLogs, setActivityLogs] = useState<ActivityLogResponse[]>([]);
    // const {
    //     showDeletePopup,
    //     setShowDeletePopup
    // } = useContext(SpeakersContext)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    interface speakers {
        speakers: {
            value: {
                name: string,
                email: string,
                additionalInfo: {
                    headline: string,
                    bio: string,
                    image: any,
                    social?: {
                        linkedIn?: string,
                        twitter?: string
                    }
                },
                additionalFieldData: object,
            }
        }

    }

    const fetchSpeakerCustomFieldData = async ():Promise<void> => 
    {
        try 
        {
            const cfcount = await customFieldCountByLocation(CustomFieldLocations.SPEAKER);
            if(cfcount)
            {
                setCustomFieldsSpinner(true);
                try 
                {
                    const customFields = await customFieldsByLocation(cfcount,0,CustomFieldLocations.SPEAKER);
                    if(customFields)
                    {
                        setCustomFields(customFields);
                        setCustomFieldsSpinner(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                    setCustomFieldsSpinner(false);
                }
            }
            
        }
        catch (error) 
        {
            console.log(error);
            setCustomFieldsSpinner(false);
        }
        
    };

    const speakersData = useSelector((state: speakers ):ISpeakerValue => 
    {
        return state.speakers.value;
    });

    const csrfTokenData = useSelector((state):string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
        },
        multiple: false,
        onDrop: (acceptedFiles): void => 
        {
            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = (): void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    dispatch(
                        addSpeakers({
                            additionalInfo: {
                                headline: speakersData?.additionalInfo?.headline,
                                bio: speakersData?.additionalInfo?.bio,
                                image: file,
                                social: {
                                    linkedIn: speakersData.additionalInfo?.social?.linkedIn,
                                    twitter: speakersData.additionalInfo?.social?.twitter
                                }
                            },
                        })
                    );
                    setFiles(URL.createObjectURL(file));
                }
                else 
                {
                    setFiles('');
                    toast.error(fileSizeInMB > 10 ? 'Image size should be less than 10 MB' : 'Please provide a valid image');
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        },
    });

    const getImageUrl = async (imageUrl: string): Promise<void> => 
    {
        const updatedImage = await decompressImage(imageUrl, 1500, 1500);
        if (updatedImage) 
        {
            setSpeakerImage(updatedImage);
        }
    };

    const Preview = (): ReactElement => 
    {
        return (
            <Box className="preview-container">
                <img
                    src={((speakerImage ? speakerImage : '') || (files ? files : ''))}
                />
            </Box>
        );
    };

    const handleNestedSidebarOpen = (): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.UPDATE_EVENT, {
            component: <ActivityLogViewer><ActivityLogComponent activityLog={speakersActivityLog(activityLogs, props?.speakerData)} /></ActivityLogViewer>
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.OPEN_EVENT, {
            open: true,
            right: '441'
        });
    };

    const handleDrawerClose: () => void = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.CLOSE_EVENT, {
            open: false
        });

        if (props?.routeFromId) 
        {
            navigate('/speakers');
        }
        if (props?.routeFromEventSpeaker && eventId) 
        {
            navigate('/events/' + eventId + '/people/speakers');
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[a-zA-Z ]*$/, 'Enter a valid Speaker Name')
            .required('Name is required'),
        email: Yup.string()
            .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, 'Enter valid Email')
            .required('Email is required'),
        headline: Yup.string()
            .required('Headline is required'),
        bio: Yup.string().test('isValidBio', 'Maximum words Exceeded', (value:string): boolean => 
        {
            if (value && value.trim()?.length > 0) 
            {
                const words = value.trim().split(/\s+/);
                const wordsLength = words?.length;
                // setSpeakerBioLength(wordsLength);
                if (wordsLength > 250) 
                {
                    return false;
                }
                else 
                {
                    return true;
                }
            }
            else 
            {
                // setSpeakerBioLength(0);
                return true;
            }
        }),
        linkedIn: Yup.string()
            .matches(/(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/, 'Please enter a valid LinkedIn URL'),
        twitter: Yup.string()
            .matches(/(https:\/\/x.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/, 'Please enter a valid Twitter URL')
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: speakersData.name ? speakersData.name : '',
            email: speakersData.email ? speakersData.email : '',
            headline: speakersData.additionalInfo.headline ? speakersData.additionalInfo.headline : '',
            bio: speakersData.additionalInfo.bio ? speakersData.additionalInfo.bio : '',
            linkedIn: speakersData.additionalInfo?.social?.linkedIn ? speakersData.additionalInfo?.social?.linkedIn : '',
            twitter: speakersData.additionalInfo?.social?.twitter ? speakersData.additionalInfo?.social?.twitter : '',
        },

        onSubmit: async (): Promise<void> => 
        {
            setSpinner(true);
            const speakerInfo = {
                name: formik.values.name,
                email: formik.values.email,
                additionalInfo: {
                    headline: formik.values?.headline,
                    bio: formik.values?.bio,
                    social: {
                        linkedIn: formik.values.linkedIn,
                        twitter: formik.values.twitter
                    },
                }
            };

            if (eventSpeaker) 
            {
                const newSpeakerInfo = {
                    ...speakerInfo,
                    additionalFieldData: customFormState ? {
                        ...customFormState 
                    } : null
                };

                try 
                {
                    const speakerUpdated = await createSpeaker(newSpeakerInfo, csrfTokenData);
                    if (speakerUpdated) 
                    {
                        const eventSpeakerDetails: eventSpeakersDetails= {
                            status: 1,
                            speakerId: speakerUpdated.id,
                            eventId: eventId,
                        };
                        try 
                        {
                            const eventSpeakerCreated = await addEventSpeaker(eventSpeakerDetails, eventId, csrfTokenData);
                            if (eventSpeakerCreated) 
                            {
                                if (speakersData?.additionalInfo.image) 
                                {
                                    const image = new FormData();
                                    image.append('file', speakersData?.additionalInfo.image);
                                    try 
                                    {
                                        const imageUploaded = await uploadSpeakerImage(speakerUpdated.id, image, csrfTokenData);
                                        if(!imageUploaded)
                                        {
                                            toast.error('Image upload failed.');
                                        }
                                    }
                                    catch (error) 
                                    {
                                        toast.error('Image upload failed.');
                                    }
                                }
                                props.setRefresh(true);
                                toast.success('Speaker created successfully');
                                handleDrawerClose();
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }
                        finally
                        {
                            setSpinner(false);
                        }
    
                    }
    
                }
                catch (error) 
                {
                    console.log(error);
                }
                finally
                {
                    setSpinner(false);
                }
                
            }
            else 
            {
                setSpinner(true);
                if (!(props.speakerEdit)) 
                {
                    const newSpeakerInfo = {
                        ...speakerInfo,
                        additionalFieldData: customFormState ? {
                            ...customFormState 
                        } : null
                    };

                    try 
                    {
                        const speakerCreated = await createSpeaker(newSpeakerInfo, csrfTokenData);
                        if (speakerCreated) 
                        {
                            if (speakersData?.additionalInfo.image !== '') 
                            {
                                const image = new FormData();
                                image.append('file', speakersData?.additionalInfo.image);
                                try 
                                {
                                    const imageUploaded = await uploadSpeakerImage(speakerCreated.id, image, csrfTokenData);
                                    if(imageUploaded)
                                    {
                                        toast.success('Image uploaded successfully.');
                                    }
                                        
                                }
                                catch (error) 
                                {
                                    toast.error('Image upload failed.');
                                }
                                    
                            }
                            props.setRefresh(true);
                            toast.success('Speaker created successfully');
                            handleDrawerClose();
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                    }
                    finally
                    {
                        setSpinner(false);
                    }
                    
                }
                else 
                {
                    let speakerImageUpdated;
                    let speakerDetailsUpdated;
                    if (eventSpeakerData?.additionalInfo.image !== speakersData?.additionalInfo.image) 
                    {
                        if (speakersData?.additionalInfo.image !== '') 
                        {
                            const image = new FormData();
                            image.append('file', speakersData.additionalInfo.image);
                            try 
                            {
                                speakerImageUpdated = await uploadSpeakerImage(Number(speakerId), image, csrfTokenData);
                            }
                            catch (error) 
                            {
                                toast.error('Image upload failed.');
                            } 
                            finally
                            {
                                setSpinner(false);
                            }
                        }
                    }
                    else 
                    {
                        const image = new FormData();
                        image.append('file', eventSpeakerData?.additionalInfo.image);
                    }
                    if (eventSpeakerData.name !== speakersData.name || eventSpeakerData.email !== speakersData.email || eventSpeakerData.email !== speakersData.email || eventSpeakerData?.additionalInfo?.bio !== speakersData?.additionalInfo?.bio || eventSpeakerData?.additionalInfo?.social?.linkedIn !== speakersData?.additionalInfo?.social?.linkedIn || eventSpeakerData?.additionalInfo?.social?.twitter !== speakersData?.additionalInfo?.social?.twitter || eventSpeakerData?.additionalInfo?.headline !== speakersData?.additionalInfo?.headline || eventSpeakerData?.additionalInfo?.image !== speakersData?.additionalInfo?.image || customFormState ) 
                    {
                        try 
                        {
                            const newSpeakerInfo = {
                                ...speakerInfo,
                                additionalFieldData: customFormState ? {
                                    ...customFormState 
                                } : null
                            };
                            speakerDetailsUpdated = await updateSpeaker(newSpeakerInfo, speakerId);
                        }
                        catch (error) 
                        {
                            console.log(error);
                        } 
                        finally
                        {
                            setSpinner(false);
                        }
                    }
                    if (speakerImageUpdated || speakerDetailsUpdated) 
                    {
                        props.setRefresh(true);
                        toast.success('Speaker details updated successfully');
                        handleDrawerClose();
                    }
                }
            }
        }
    });

    const deleteEventSpeaker = async ():Promise<void> => 
    {
        try 
        {
            const speakerRemoved = await removeEventSpeaker(eventId, eventSpeakerData?.id);
            if (speakerRemoved) 
            {
                setShowDeletePopup(true);
                handleDrawerClose();
                props?.setRefresh(true);
                toast.success('Speaker removed from the event.')
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error(error?.message);
        }
    };

    const handleInputChange = (event, id): void => 
    {
        const { name, value } = event.target;
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleDropDownInputChange = (name, value, id): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleBoxChange = (name, value, id): void => 
    {
        setCustomFormState((prevState): any => 
        {
            return {
                ...prevState,
                [name + ' ' + id]: value,
            }; 
        });
    };

    const handleCheckboxChange = (item, data): void => 
    {

        if(customFormState[data.label + ' ' + data.id])
        {
            const selectedIndex = customFormState[data.label + ' ' + data.id].indexOf(item.name);
            const updatedSelectedOptions = [...customFormState[data.label + ' ' + data.id]];

            if (selectedIndex === -1) 
            {
                updatedSelectedOptions.push(item.name);
            }
            else 
            {
                updatedSelectedOptions.splice(selectedIndex, 1);
            }

            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: updatedSelectedOptions,
                }; 
            });
        }
        else
        {
            setCustomFormState((prevState): any => 
            {
                return {
                    ...prevState,
                    [data.label + ' ' + data.id]: [item.name],
                }; 
            });
        }
        
    };

    const fetchActivityLogs = async (): Promise<void> =>
    {
        try 
        {
            const activityLogs = await getAllSpeakersActivityLogs(props?.speakerData?.id);
            if(activityLogs)
            {
                setActivityLogs(activityLogs);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(():void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, (): void => 
        {
            navigate(0);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data): void => 
        {
            setSpinner(data.spinner);
        });

        if (eventSpeakerData) 
        {
            setFiles(eventSpeakerData.additionalInfo && eventSpeakerData.additionalInfo.image && eventSpeakerData.additionalInfo.image !== null ? eventSpeakerData.additionalInfo.image : '');
            // setSpeakerBioLength(eventSpeakerData.additionalInfo?.bio?.length);
            dispatch(addSpeakers({
                name: eventSpeakerData.name,
                email: eventSpeakerData.email,
                additionalInfo: {
                    headline: eventSpeakerData?.additionalInfo.headline,
                    bio: eventSpeakerData?.additionalInfo.bio ? eventSpeakerData?.additionalInfo.bio : '',
                    image: eventSpeakerData?.additionalInfo && eventSpeakerData?.additionalInfo.image && eventSpeakerData?.additionalInfo.image !== null ? eventSpeakerData?.additionalInfo.image : '',
                    social: eventSpeakerData?.additionalInfo?.social ? eventSpeakerData?.additionalInfo?.social : ''
                }
            }));

            fetchActivityLogs();
        }
        if (eventSpeakerData?.additionalInfo.image) 
        {
            getImageUrl(eventSpeakerData.additionalInfo.image);
        }
        if (eventSpeakerData?.additionalFieldData) 
        {
            setCustomFormState({
                ...eventSpeakerData?.additionalFieldData 
            });
        }

        fetchSpeakerCustomFieldData();

    }, []);

    return (
        <Box id="createSpeaker">
            <CloseIconComponent onClick={handleDrawerClose} />
            {/* {!props.addEventSpecificSpeaker && <Stack className="required-icon-stack" direction={'row'}><Typography className="required-icon">*</Typography><Typography className="required-field-text">{CONTENT.SIDE_DRAWER.FORM_REQUIRED_TEXT}</Typography></Stack>} */}
            <Form noValidate onSubmit={(values: React.FormEvent<HTMLFormElement>): void => 
            {
                return formik.handleSubmit(values);
            }} autoComplete="off">

                {/* Image */}
                <Box className="sidebar-container-spacing">
                    <div className="image-activity-log-container">
                        <div>
                            <FormLabelComponent label='Select Photo' />
                            {
                                files !== '' || speakerImage !== '' ?
                                    <Box className="preview-upload-box"><Preview />
                                        {
                                            !props.addEventSpecificSpeaker ?
                                                <React.Fragment>
                                                    <Box className="icon-box">
                                                        <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-icon" onClick={(): void => 
                                                        {
                                                            setFiles('');
                                                            setSpeakerImage('');
                                                            dispatch(addSpeakers({
                                                                additionalInfo: {
                                                                    headline: speakersData.additionalInfo.headline,
                                                                    bio: speakersData.additionalInfo.bio,
                                                                    image: '',
                                                                    social: {
                                                                        linkedIn: speakersData?.additionalInfo?.social?.linkedIn,
                                                                        twitter: speakersData?.additionalInfo?.social?.twitter
                                                                    },
                                                                }
                                                            }));
                                                        }} />
                                                    </Box>
                                                </React.Fragment> : null
                                        }
                                    </Box>
                                    :
                                    <React.Fragment>
                                        {
                                            !props.addEventSpecificSpeaker ?
                                                <React.Fragment>
                                                    <Box {...getRootProps()} className="empty-upload-box">
                                                        <input {...getInputProps()} hidden accept="image/*" type="file" />
                                                        <FontAwesomeIcon className="upload-icon" icon={['fal', 'cloud-arrow-up']} />
                                                        <Typography>{'Browse File'}</Typography>
                                                    </Box>
                                                </React.Fragment>
                                                :
                                                null
                                        }
                                    </React.Fragment>
                            }
                        </div>
                        {
                            props?.speakerData &&
                            <CustomButton 
                                name=""
                                btnType="secondary"
                                startIcon={<FontAwesomeIcon icon={['fal', 'wave-pulse']} />}
                                style={{ width: '32px' }}
                                onClick={handleNestedSidebarOpen}
                            />
                        }
                    </div>
                </Box>

                {/* Name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.NAME} required />
                    <FormControlComponent
                        type="text"
                        placeholder={CONTENT.SPEAKERS_PAGE.FORMS.PLACEHOLDER.NAME}
                        disabled={disabled ? disabled : null}
                        value={formik.values.name}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('name', event.target.value);
                        }}
                        required
                        isInvalid={formik.errors.name && formik.touched.name}
                    />
                    {formik.errors.name && formik.touched.name ? <Typography className="error-msg">{formik.errors.name}</Typography> : null}
                </Box>

                {/* Email */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.EMAIL} required />
                    <FormControlComponent
                        value={formik.values.email}
                        placeholder={CONTENT.SPEAKERS_PAGE.FORMS.PLACEHOLDER.EMAIL}
                        disabled={disabled ? disabled : null}
                        required
                        type="email"
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('email', event.target.value?.toLowerCase());
                        }}
                        isInvalid={formik.errors.email && formik.touched.email}
                    />
                    {formik.errors.email && formik.touched.email ? <Typography className="error-msg">{formik.errors.email}</Typography> : null}
                </Box>

                {/* Headline */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.HEADLINE} required />
                    <FormControlComponent
                        value={formik.values.headline}
                        placeholder={CONTENT.SPEAKERS_PAGE.FORMS.PLACEHOLDER.HEADLINE}
                        disabled={disabled ? disabled : null}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => 
                        {
                            formik.setFieldValue('headline', event.target.value);
                        }}
                        required
                        isInvalid={formik.errors.name && formik.touched.name}
                        type="text"
                    />
                    {formik.errors.headline && formik.touched.headline ? <Typography className="error-msg">{formik.errors.headline}</Typography> : null}
                </Box>

                {/* Bio */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.BIO} />
                    <FormControlComponent
                        as="textarea"
                        type="text"
                        value={formik.values.bio}
                        rows={3}
                        placeholder='Bio'
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('bio', event.target.value);
                        }}
                        disabled={disabled ? disabled : null}
                    />
                    {formik.errors.bio && formik.touched.bio ? <Typography className="error-msg">{formik.errors.bio}</Typography> : null}
                </Box>

                {/* Social Links */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.SOCIAL_LINK.LINKEDIN} />
                    <FormControlComponent
                        type="text"
                        value={formik.values.linkedIn}
                        placeholder='URL'
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('linkedIn', event.target.value);
                        }}
                        disabled={disabled ? disabled : null}
                        isInvalid={formik.errors.linkedIn && formik.touched.linkedIn}
                    />
                    {formik.errors.linkedIn && formik.touched.linkedIn ? <Typography className="error-msg">{formik.errors.linkedIn}</Typography> : null}
                </Box>

                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SPEAKERS_PAGE.FORMS.LABEL.SOCIAL_LINK.TWITTER} />
                    <FormControlComponent
                        value={formik.values.twitter}
                        placeholder='URL'
                        type="text"
                        disabled={disabled ? disabled : null}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('twitter', event.target.value);
                        }}
                        isInvalid={formik.errors.twitter && formik.touched.twitter}
                    />
                    {formik.errors.twitter && formik.touched.twitter ? <Typography className="error-msg">{formik.errors.twitter}</Typography> : null}
                </Box>
                
                {/* Custom Fields */}
                {customFields.length > 0 &&
                 customFieldsSpinner ? <CustomSpinner height={'100%'}/> :
                    <Box className="custom-field-container">
                        {customFields.map((item): React.ReactElement => 
                        {
                            if (item.type === CustomFieldType.NUMBER) 
                            {
                                return renderInputComponent(item, 'number', handleInputChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.SINGLE_LINE) 
                            {
                                return renderInputComponent(item, 'text', handleInputChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.MULTI_LINE_TEXT) 
                            {
                                return renderInputComponent(item, 'textarea', handleInputChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.CHECKBOXES) 
                            {
                                return renderCheckBoxComponent(item, handleCheckboxChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.DROPDOWN) 
                            {
                                return renderDropDownComponent(item, handleDropDownInputChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.RADIO) 
                            {
                                return renderRadioGroupComponent(item, handleBoxChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                            else if (item.type === CustomFieldType.DATE) 
                            {
                                return renderDateComponent(item, handleInputChange, customFormState?.[item?.label + ' ' + item.id], null);
                            }
                        })
                        }
                    </Box>
                }
                
                {
                    ((!props.bottomBtn && props?.isTableView) || props['create-event-speaker'] || props?.createorUpdateGlobalSpeaker) ?
                        <Box className="submit-btn-container">
                            <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-start'}>
                                <CustomButton onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} btnType='secondary' type='button' />
                                <CustomButton onClick={formik.handleSubmit} type="submit" loading={spinner} name={CONTENT.SPEAKERS_PAGE.FORMS.BUTTON.SUBMIT_BTN} btnType='primary' />
                            </Stack>
                        </Box> : ((props?.speakerData) && (!props?.isTableView)) &&
                        
                        <Box className="submit-btn-container">
                            <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                                <Stack direction={'row'} spacing={1} display={'flex'}>
                                    <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                                    <CustomButton btnType='primary' loading={spinner} name={'Save'} type='submit' />
                                </Stack>
                                {!props?.isTableView && <FontAwesomeIcon className="sidebar-delete-icon" icon={['fal', 'trash']} onClick={() => setShowDeletePopup(true)} />}
                            </Stack>
                        </Box>
                }

            </Form>

            {
                showDeletePopup &&
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={deleteEventSpeaker}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                    }}
                    modalContent={`Are you sure you want to delete ${eventSpeakerData?.name}?`}
                    modalTitle='Delete Speaker'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='trash'
                />
            }
        </Box>
    );
};

export default CreateSpeaker;