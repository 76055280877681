import { FormikErrors, FormikTouched } from "formik";
import { EmailMarketingFormData } from "../../../pages/Events/interfaces";
import './styles.scss';
import { FormControlComponent, FormLabelComponent } from "../../../common/FormComponents/ReusableFormComponents";
import PlaceholderAutoCompleteInput from "../../../common/FormComponents/PlaceholderInput";

interface EmailMarketingSettingsProps {
    formValues: EmailMarketingFormData;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<EmailMarketingFormData>>;
    errors: FormikErrors<EmailMarketingFormData>;
    touched: FormikTouched<EmailMarketingFormData>;
}

const EmailMarketingSettings: React.FC<EmailMarketingSettingsProps> = (props): React.JSX.Element =>
{
    const { formValues, setFieldValue, touched, errors } = props;

    return (
        <div id="emailMarketingSettings">
            <div className="email-marketing-settings-container">
                <div>
                    <h3 className="header">{'Settings'}</h3>

                    {/* Name */}
                    <div className="sidebar-container-spacing">
                        <FormLabelComponent label="Name" required />
                        <FormControlComponent 
                            type="text"
                            value={formValues.name}
                            name='name'
                            onChange={(event) => setFieldValue('name', event.target.value)}
                            isInvalid={touched.name && !!errors.name}
                        />
                    </div>

                    {/* Send to */}
                    <div className="sidebar-container-spacing">
                        <FormLabelComponent label="Reply to" required />
                        <FormControlComponent 
                            type="email"
                            value={formValues.replyTo as string}
                            name='replyTo'
                            onChange={(event) => setFieldValue('replyTo', event.target.value)}
                            isInvalid={touched.replyTo && !!errors.replyTo}
                        />
                    </div>

                    {/* Subject */}
                    <div className="sidebar-container-spacing">
                        <PlaceholderAutoCompleteInput 
                            initialInputValue={formValues.subject as string}
                            options={['Event Name', 'Organization Name', 'First Name', 'Last Name']}
                            onChange={(value) => setFieldValue('subject', value)}
                            rows={2}
                            placeholder="Subject Line"
                            required
                            isInvalid={touched.subject && !!errors.subject}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
};

export default EmailMarketingSettings;