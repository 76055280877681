export enum IntegrationTypes {
    HUBSPOT = 1,
    SALES_FORCE = 2,
    RAZORPAY = 3,
    STRIPE = 4,
    LUMA = 5,
    ZOOM = 6,
    GOOGLE_CALENDAR = 7,
    EVENTBRITE = 8,
    EMAIL = 9,
    GAINSIGHT = 10,
    TOTANGO = 11,
    APOLLO_IO = 12,
    ZOOMINFO = 13,
    GOLDCAST = 14,
    SPLASHTHAT = 15,
    PIPEDRIVE = 16,
    MARKETO = 17,
    CLEARBIT = 18,
}

export enum ApiKeyIntegrationTypes {
    LUMA = 5
}

export enum HubspotContactsProperties {
    EMAIL = 1,
    FIRST_NAME = 2,
    LAST_NAME = 3,
    PHONE_NUMER = 4
}

export enum HubspotDealsProperties {
    EMAIL = 1,
    FIRST_NAME = 2,
    LAST_NAME = 3,
    PHONE_NUMER = 4
}

export enum HubspotCompaniesProperties {
    EMAIL = 1,
    FIRST_NAME = 2,
    LAST_NAME = 3,
    PHONE_NUMER = 4
}

export enum IntegrationSyncStatus {
    NONE = 1,
    IN_PROGRESS = 2,
    COMPLETED = 3
}

