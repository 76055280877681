import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import eventBus from "../../scripts/event-bus";
import APP_CONSTANTS from "../../scripts/constants";

interface IActivityLogViewerProps {
    children?: React.ReactNode;
}

const ActivityLogViewer: React.FC<IActivityLogViewerProps> = (props): React.JSX.Element => 
{

    const { children } = props;

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.CLOSE_EVENT, {
            open: false
        });
    };

    return (
        <div id="activityLogViewer">
            <div className="activity-log-view-header">
                <h3 className="heading">{'Activity'}</h3>
                <div className="drawer-close-icon">
                    <FontAwesomeIcon onClick={() => handleDrawerClose()} icon={['fal', 'xmark']} />
                </div>
            </div>
            {children}
        </div>
    )
};

export default ActivityLogViewer;