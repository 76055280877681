import React, { useEffect, useState} from 'react';
import { Grid } from '@mui/material';
import MuiChip from '../../../../common/FormComponents/MuiChip';
import CustomTooltip from '../../../../common/Tooltip';
import EventsCardHeaderComponent from '../../../../components/Events/EventComponents/EventsCardHeaderComponent';
import CardComponent from '../../../../components/Events/Registrations/CardComponent';
import { EventCheckInNotifications } from '../../../Events/interfaces/event-check-in-notifications.interface';
import './styles.scss';
import AddNotifications from './AddNotifications';
import PortalSidedrawer from '../../Components/Common/PortalSidedrawer';
import { getSponsorPortalNotifications, getSponsorPortalNotificationsCount } from '../../../../scripts/apis/sponsorPortal/sponsorPortal';
import { EventCheckInNotificationsSource } from '../../../Events/enum/event-check-in-notifications.enum';


const NotificationsPage: React.FC = (): React.JSX.Element =>
{
    const [refresh, setRefresh] = useState(false);
    const [checkInNotificationsCount, setCheckInNotificationsCount] = useState(0);
    const [checkInNotifications, setCheckInNotifications] = useState<EventCheckInNotifications| undefined>(undefined);
    const [eventCheckInNotificationsData, setEventCheckInNotificationsData] = useState<EventCheckInNotifications[]>([]);
    const [openCheckInNotificationsSidebar, setOpenCheckInNotificationsSidebar] = useState<boolean>(false);

    const fetchCount = async () =>
    {
        try
        {
            const count = await getSponsorPortalNotificationsCount(); 
            setCheckInNotificationsCount(count);
        }
        catch (error)
        {
            console.error('Error fetching count', error);
        }
    }

    const fetchData = async () =>
    {
        try 
        {
            const data = await getSponsorPortalNotifications();
            setEventCheckInNotificationsData(data);
        }
        catch (error) 
        {
            console.error('Error fetching data', error);    
            
        }
    }

    useEffect(() => 
    {   
        fetchCount();
        fetchData();
    }, []);

    useEffect(() => 
    {   
        if(refresh)
        {
            fetchCount();
            fetchData();
        }  
    }, [refresh]);

    const notificationsCardView = () => 
    {
        return (
            <div id='SponsorNotificationsCardComponent'>
            <div className='notifications-container'>
                <EventsCardHeaderComponent
                    heading='CheckIn Notifications'
                    count={checkInNotificationsCount}
                />
                <Grid container spacing={2}>
                    {eventCheckInNotificationsData && eventCheckInNotificationsData.length > 0 && eventCheckInNotificationsData.map((notification: EventCheckInNotifications, index: number) =>
                    {
                        const deviceNameComponent = (
                            <div className='device-name-container'>
                                <p>{notification.title}</p>
                            </div>
                        );

                        let filterNames = [];
                        if(notification?.source === EventCheckInNotificationsSource.TAREGET_LIST)
                        {
                            filterNames.push('Target List');
                        }
                        if (notification?.icpNames && notification.icpNames.length > 0)
                        {
                            filterNames.push(...notification.icpNames.filter(name => name !== null));
                        }
                        if (notification?.bpNames && notification.bpNames.length > 0)
                        {
                            filterNames.push(...notification.bpNames.filter(name => name !== null));
                        }

                        const displayedValues = filterNames.slice(0, 2); // Adjust this number 
                        const remainingValues = filterNames.slice(2);
                        const remainingCount = filterNames.length - displayedValues.length;

                        const NotificationsCardComponent = (
                            <div className='chip-container'>
                                {displayedValues.map((value, index) => (
                                    <MuiChip key={index} label={value} chipColor='grey' />
                                ))}
                                {remainingCount > 0 && (
                                    <CustomTooltip title={remainingValues.map((value) => value).join(', ')}>
                                         <div>
                                            <MuiChip key="more" label={`+${remainingCount}`} chipColor="grey" />
                                         </div>
                                    </CustomTooltip>
                                )}
                            </div>
                        );

                        return (
                            <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <CardComponent
                                    header={deviceNameComponent}
                                    headerBold
                                    contentHeading={NotificationsCardComponent}
                                    key={index}
                                    cardMinHeight='82px' 
                                    onClick={() =>
                                    {
                                        setOpenCheckInNotificationsSidebar(true);
                                        setCheckInNotifications(notification);
                                    }}
                                />
                            </Grid>
                        );
                    })}

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <CardComponent
                            emptyText='+ Add Notification'
                            onClick={() =>
                            {
                                setOpenCheckInNotificationsSidebar(true);
                            }}
                            emptyCardHeight='54px' 
                            cardMinHeight='54px'          
                        />
                    </Grid>
                </Grid>
            </div>
           
            {openCheckInNotificationsSidebar && (
                    <PortalSidedrawer
                        component={
                            <AddNotifications
                                setRefresh={setRefresh}
                                checkInNotifications={checkInNotifications}
                                handleDrawerClose={() => {
                                    setCheckInNotifications(undefined);
                                    setOpenCheckInNotificationsSidebar(false);
                                }}
                            />
                        }
                        handleClose={() => {
                            setCheckInNotifications(undefined);
                            setOpenCheckInNotificationsSidebar(false);
                        }}
                        heading={checkInNotifications ? `${checkInNotifications.title}` : 'Check In Notifications'}
                        open={openCheckInNotificationsSidebar}
                        containerWidth="75vw"
                    />
                )}
        </div>
        )
    }


    return notificationsCardView();
};

export default NotificationsPage;