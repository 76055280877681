import { useEffect } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import './styles.scss';
import { LocalStorage } from '../scripts/LocalStorage';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { logout, logoutWithWorkOS } from '../scripts/apis/authentication';
import { Toaster } from 'react-hot-toast';

const AuthenticatorRoute = (props) => {
    const logoutStatus = props.preventLogout;
    const pathname = useLocation().pathname;
    const user = LocalStorage.get('@UserMe');
    const isLoginRoute = pathname?.includes('login');
    const location = useLocation();

    const handleLogout = async () => {
        try {
            const res = await logoutWithWorkOS();
            if (!res) {
                console.log(res);
            }

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {

        if (!logoutStatus && !user) {
            handleLogout();
        }
    }, []);

    return (
        <div>
            {
                (isLoginRoute && user) ? <Navigate to="/" state={{
                    from: location
                }} replace /> : <Outlet />
            }
            <Toaster />
        </div>
    );
};

export default AuthenticatorRoute;
