import React, { createContext, useEffect, useState } from 'react';
import { useTablePagination } from '../TablePaginationContext';
import { deleteMarketing, getAllEventMarketing, getAllEventMarketingCount } from '../../scripts/apis/eventMarketing';
import { EventMarketing } from '../../pages/Events/interfaces';
import toast from 'react-hot-toast';
import { EventMarketingType } from '../../pages/Events/enum';
import { getEncodedParams } from '../../scripts/helpers';

interface IEventMarketingContext {
    isEmpty: boolean;
    marketingCount: number;
    setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
    setRows: React.Dispatch<React.SetStateAction<EventMarketing[]>>;
    rows: EventMarketing[];
    marketingData: EventMarketing[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    pageSize: number;
    currentPage: number;
    updateCurrentPage: (currentPage: number) => void;
    updatePageSize: (pageSize: number) => void;
    showSpinner: boolean;
    eventId: number;
    deleteMarketingDataFromTable: (marketingData: EventMarketing) => Promise<void>;
    showDeleteDialog: boolean;
    setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
    selectedMarketingData: EventMarketing | null;
    setSelectedMarketingData: React.Dispatch<React.SetStateAction<EventMarketing | null>>;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
    showCreateMarketingDialog: boolean;
    setShowCreateMarketingDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const eventMarketingMailFilterOptions =
{
    1: 'Invite',
    2: 'Approved',
    3: 'Rejected',
    4: 'Waitlist',
    5: 'Deleted',
    6: 'Confirmed'
};

export const MarketingPageContext = createContext<IEventMarketingContext>({
    isEmpty: false,
    marketingCount: 0,
    setIsEmpty: () => {},
    setRows: () => {},
    rows: [],
    marketingData: [],
    setRefresh: () => {},
    pageSize: 0,
    currentPage: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    eventId: 0,
    deleteMarketingDataFromTable: async () => {},
    showDeleteDialog: false,
    setShowDeleteDialog: () => {},
    selectedMarketingData: null,
    setSelectedMarketingData: () => {},
    setCurrentUrl: () => {},
    showCreateMarketingDialog: false,
    setShowCreateMarketingDialog: () => {}
});

const EventMarketingProvider: React.FC<{ children: React.ReactElement, eventId: number }> = ({ children, eventId }): React.JSX.Element => 
{
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [rows, setRows] = useState<EventMarketing[]>([]);
    const [marketingData, setMarketingData] = useState<EventMarketing[]>([]);
    const [marketingCount, setMarketingCount] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [selectedMarketingData, setSelectedMarketingData] = useState<EventMarketing | null>(null);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [showCreateMarketingDialog, setShowCreateMarketingDialog] = useState<boolean>(false);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();


    const fetchTotalMarketingCount = async (): Promise<void> =>
    {
        try 
        {
            const encodedParams = getEncodedParams(currentUrl);
            const count = await getAllEventMarketingCount(eventId, EventMarketingType.MARKETING, encodedParams);
            if (count) 
            {
                setMarketingCount(count);
                setIsEmpty(count == 0 && encodedParams.toString().length === 0);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    }

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            if (marketingCount)
            {
                const encodedParams = getEncodedParams(currentUrl);
                const eventMarketing: EventMarketing[] = await getAllEventMarketing(pageSize, Number(currentPage - 1), eventId, 2, encodedParams);
                if (eventMarketing) 
                {
                    setRows([...eventMarketing]);
                    setMarketingData([...eventMarketing]);
                    setRefresh(false);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }   
        finally
        {
            setShowSpinner(false);
        }
    };

    const deleteMarketingDataFromTable = async (marketingInfo: EventMarketing): Promise<void> => 
    {
        try 
        {
            const marketingDeleted: boolean = await deleteMarketing(marketingInfo.id as string, eventId);
            if (marketingDeleted) 
            {
                setShowDeleteDialog(false);
                toast.success(`${marketingInfo?.name} has been deleted successfully`);
                setSelectedMarketingData(null);
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }       
    };

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchTotalMarketingCount();
            fetchData();
        }
    }, [refresh]);

    useEffect((): void => 
    {
        fetchData();
    }, [currentPage, pageSize, marketingCount]);

    useEffect((): void => 
    {
        fetchTotalMarketingCount();
    }, [currentUrl]); 


    return (
        <MarketingPageContext.Provider 
            value={{
                isEmpty, 
                marketingCount, 
                setIsEmpty, 
                setRows, 
                rows, 
                setRefresh, 
                pageSize, 
                currentPage,
                updateCurrentPage, 
                updatePageSize, 
                showSpinner, 
                eventId, 
                marketingData,
                selectedMarketingData,
                setSelectedMarketingData,
                setShowDeleteDialog,
                showDeleteDialog,
                deleteMarketingDataFromTable,
                setCurrentUrl,
                showCreateMarketingDialog,
                setShowCreateMarketingDialog
            }}
        >
            {children}
        </MarketingPageContext.Provider>
    );
};

export default EventMarketingProvider;