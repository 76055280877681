import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { FormControlCardComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { useState } from "react";
import { EventMarketingNotificationType } from "../../../pages/Events/enum";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import EmailMarketingSteps from "./EventMarketingSteps";

import './styles.scss'

interface CreateEventMarketingTypeProps {
    handleDialogClose: () => void;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateEventMarketingType: React.FC<CreateEventMarketingTypeProps> = ({ handleDialogClose, setRefresh }): React.JSX.Element => 
{ 

    const marketingTypes =
    [
        {
            type: EventMarketingNotificationType.EMAIL,
            icon: <FontAwesomeIcon icon={['fal', 'envelope']} />,
            header: 'Email',
            helperText: 'Email Notification'
        },
        {
            type: EventMarketingNotificationType.PUSH_NOTIFICATION,
            icon: <FontAwesomeIcon icon={['fal', 'calendar']} />,
            header: 'Push Notification',
            helperText: 'App Notification'
        }
    ];

    const [selectedMarketingType, setSelectedMarketingType] = useState<EventMarketingNotificationType>(EventMarketingNotificationType.EMAIL);

    const handleNext = (): void =>
    {
        handleDialogClose();

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: `Create ${selectedMarketingType === EventMarketingNotificationType.EMAIL ? 'Email' : 'Push Notification'}`,
            component: <EmailMarketingSteps setRefresh={setRefresh} notificationType={selectedMarketingType} />,
            noSidebarPadding: true
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '70vw !important'
        });
    };

    return (
        <div id="createEventMarketingType">
            <div className="header-container">
                <div className="header-description-container">
                    <h3 className="heading">{'Create Marketing'}</h3>
                </div>
                <div className="popup-close-icon-container">
                    <CustomButton 
                        btnType="tertiary"
                        name=""
                        startIcon={<FontAwesomeIcon icon={['fal', 'xmark']} />}
                        onClick={handleDialogClose}
                    />
                </div>
            </div>

            <div className="marketing-type-container">
                {
                    marketingTypes?.map((item, index) => 
                    {
                        return (
                            <div onClick={() => setSelectedMarketingType(item.type)} key={index}>
                                <FormControlCardComponent 
                                    icon={item.icon}
                                    header={item.header}
                                    helperText={item.helperText}
                                    cardContainerStyle={{
                                        minWidth: '216px'
                                    }}
                                    cardStyle={selectedMarketingType === item.type ? 'active' : undefined}
                                />
                            </div>
                        )
                    })
                }
            </div>

            <div className="action-button-container">
                <CustomButton 
                    btnType="secondary"
                    name="Cancel"
                    onClick={handleDialogClose}
                />
                <CustomButton 
                    btnType="primary"
                    name="Next"
                    onClick={handleNext}
                />
            </div>
        </div>
    );
};

export default CreateEventMarketingType;