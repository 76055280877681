import React, { useContext, useEffect, useState } from 'react';
import TableEmptyComponent from '../../../../../../common/TableEmptyComponent';
import TanstackToolbarWithServerFilter from '../../../../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import TanstackTable from '../../../../../../common/TanstackTable/TanstackTable';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { CustomButton } from '../../../../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APP_CONSTANTS from '../../../../../../scripts/constants';
import eventBus from '../../../../../../scripts/event-bus';
import { ActionsDropdown } from '../../../../EventBudget/EventBudgetDataGrid';
import { UserContext } from '../../../../../../contexts/UserContext';
import { UserViewType } from '../../../../../Settings/enum/users.enum';
import { MinimalViewComponent } from '../../../../../../common/MinimalView/MinimalViewComponent';
import AddEventBadgeSettings from '../../../../../../components/Events/AccessControl/AddEventBadgeSettings';
import AddCardSettings from '../../../../../../components/Events/CheckinApp/AddCardSettings';
import { useSelector } from 'react-redux';
import ColumnVisibilityDropdown from '../../../../../../common/TanstackTable/ColumnVisibilityDropdown';
import { IEventsDispatch, IEventReduxValues } from '../../../../../../components/Events/interfaces/create-events_interface';
import HeaderBar from '../../../../../../common/Headerbar';
import './styles.scss';
import { EventCheckInNotifications } from '../../../../interfaces/event-check-in-notifications.interface';
import { EventAccessControlContext } from '../../../../../../contexts/EventAccessControl/EventAccessControlContext';
import AddCheckInNotifications from './AddCheckInNotifications';
import { TableActions } from '../../../../../../common/TableActions';
import DeletePopup from '../../../../../../common/DeletePopup';
import MuiChip from '../../../../../../common/FormComponents/MuiChip';
import CustomTooltip from '../../../../../../common/Tooltip';
import { EventCheckInNotificationsSource } from '../../../../enum/event-check-in-notifications.enum';
import { deleteCheckInNotifications } from '../../../../../../scripts/apis/eventCheckInNotifications';
import moment from 'moment';

const ViewAllNotificationsComponnent = () => 
  {
    const {
        isEmpty,
        eventCheckInNotificationsData,
        setRefresh,
        showSpinner,
        eventId,
        pageSize,
        currentPage,
        updatePageSize,
        updateCurrentPage,
        eventCheckInNotificationsCount,
        event,
        currentUrl,
        setCurrentUrl,
    } = useContext(EventAccessControlContext);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const { userDetails } = useContext(UserContext);
    const minimalView = userDetails?.viewType === UserViewType.MINIMAL;


    const navigate = useNavigate();
    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const columnHelper = createColumnHelper<EventCheckInNotifications>();
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [selectedRow, setSelectedRow] = useState<EventCheckInNotifications | null>(null);


    const handleEdit = (row: EventCheckInNotifications): void => 
    {
        openEventCheckInNotificationsSidedrawer(row);
    };

    const handleDeleteClick = async (): Promise<void> => 
    {
        await deleteCheckInNotifications(eventId, Number(selectedRow?.id));
        setSelectedRow(null);
        setShowDeletePopup(false);
        setRefresh(true);
    };

    const columns = [
        columnHelper.accessor('title', {
            cell: (row) => 
            {
                return (<p className='bolded-cellContent'> {row.row.original.title} </p>);
            },
            header: 'Name',
            size: 300,
            id: 'title',
        }),
        columnHelper.accessor('filters' as any, {
            cell: (row) => {
                return accessFilter(row.row.original);
            },
            header: 'Filter',
            size: 250,
            id: 'filters',
        }),
        columnHelper.accessor('audienceIds' as any, {
            cell: (row) => 
            {
                return (<p className='bolded-cellContent'> {row.row.original.registrantIds?.length} </p>);
            },
            header: 'Total Triggers',
            size: 150,
            id: 'audienceIds',
        }),
        columnHelper.accessor('userIds' as any, {
            cell: (row) => 
            {
                return (<p className='bolded-cellContent'> {row.row.original.userIds?.length} </p>);
            },
          header: 'Notification To',
          size: 150,
          id: 'userIds',
        }),
        columnHelper.accessor('modified' as any, {
            cell: (row) =>
            {
                const date = moment(row.getValue());
                const formattedDate = date.format('DD/MM/YYYY');
                return <p className="cellContent">{ formattedDate }</p>;
            },
            header: 'Last Updated',
            id: 'modified',
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) =>
            {
                return (
                    <TableActions
                        actions={ [
                            {
                                title: 'Edit',
                                onClick: (): void => 
                                {
                                    handleEdit(row.row.original);
                                }
                            },
                            {
                                title: 'Delete',
                                onClick: (): void => 
                                {
                                    setSelectedRow(row.row.original);
                                    setShowDeletePopup(true);
                                }
                            }] }
                    />
                );
            },
            header: '',
            size: 40,
            enableSorting: false,
            id: 'actions',
        }),
    ];

    const [allColumns, setAllColumns] = useState([...columns]);

    const checkInNotificationsFilterColoumns = [
        {
            header: 'Name',
            id: 'title',
            meta: {
                type: 'string',
            },
        },
    ];



    const accessFilter = (row: EventCheckInNotifications): React.ReactElement => 
    {

        let filterNames = [];
        if (row?.targetListNames && row.targetListNames.length > 0)
        {
            filterNames.push(...row.targetListNames.filter(name => name !== null));
        }
        if (row?.icpNames && row.icpNames.length > 0)
        {
            filterNames.push(...row.icpNames.filter(name => name !== null));
        }
        if (row?.bpNames && row.bpNames.length > 0)
        {
            filterNames.push(...row.bpNames.filter(name => name !== null));
        }
        if (row?.ticketNames && row.ticketNames.length > 0)
        {
            filterNames.push(...row.ticketNames.filter(name => name !== null));
        }
        if (row?.filters?.guestType && row.filters.guestType.length > 0)
        {
            filterNames.push(...row.filters.guestType.filter(name => name !== null));
        }

        const displayedValues = filterNames.slice(0, 1); // Adjust this number 
        const remainingValues = filterNames.slice(1);
        const remainingCount = filterNames.length - displayedValues.length;
        return (
            <div className='chip-container'>
                {displayedValues.map((value, index) => (
                     row.source == EventCheckInNotificationsSource.REGISTRANTS 
                     ? <MuiChip key={index} label={value} chipColor='blue' circleIcon /> 
                     : <MuiChip key={index} label={value} chipColor='green' circleIcon />
                ))}
                {remainingCount > 0 && (
                    <CustomTooltip title={remainingValues.map((value) => value).join(', ')}>
                        <div>
                            <MuiChip key="more" label={`+${remainingCount}`} chipColor="grey" />
                        </div>
                    </CustomTooltip>
                )}
            </div>
        );
    };

    

    const actions = (row: EventCheckInNotifications): React.ReactElement => 
      {
        const editClick = (): void => {
            eventBus.dispatch('selected-row-id', row.id);
            openEventCheckInNotificationsSidedrawer(row);
        };

        const deleteClick = (): void => {
            const deleteHeaderClass: React.CSSProperties = {
                padding: '12px',
                borderRadius: '50%',
                background: 'var(--Colors-Background-bg-error-secondary, #FEE4E2)',
                height: '48px',
                width: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            };

            setTimeout((): void => {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                    heading: (
                        <Box sx={deleteHeaderClass}>
                            <FontAwesomeIcon color={'#D92D20'} icon={['fal', 'trash']} />
                        </Box>
                    ),
                    component: <div>Hello</div>,
                    componentWidth: '400px',
                    componentHeight: '260px',
                });

                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                    open: true,
                });
            });
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };
    
    const handleApplyFilters = (
        filters: { selectedColumn: string; inputValue: string }[]
    ): void => 
        {
        filters.forEach((filter) => {
            if (filter.selectedColumn === 'source') {
                searchParams.append('sourcesArr', filter.inputValue);
            } else {
                searchParams.append(filter.selectedColumn, filter.inputValue);
            }
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const openEventCheckInNotificationsSidedrawer = (checkInNotifications?: EventCheckInNotifications) =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: checkInNotifications?.title ? checkInNotifications?.title : 'CheckIn Notifications',
            hideCloseButton: true,
            component: <AddCheckInNotifications eventId={eventId} setRefresh={setRefresh} checkInNotifications={checkInNotifications} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '75vw !important'
        });
    };
  
    const openEventBadgeSettingsSidedrawer = () =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Badge Settings',
            hideCloseButton: true,
            component: <AddEventBadgeSettings eventId={eventId} setRefresh={setRefresh} eventData={event} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };
  
    const openEventCardSettings = () => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'VCF Card Settings',
            hideCloseButton: true,
            component: <AddCardSettings eventId={eventId} setRefresh={setRefresh} formId={eventReduxData?.registrationFormId as string} existingVCardSettings={eventReduxData?.vcardSettings} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            // width: '50vw !important'
        });
    };



    const handleBackClick = () => 
    {
        navigate(`/events/${eventId}/check-in`);
    };

    useEffect(() => 
    {
        setAllColumns([...columns]);
    }, []);

    const buttomComponent = (
        <CustomButton
            key={1} 
            name={'Add Notification'} 
            btnType='secondary' 
            onClick={() => openEventCheckInNotificationsSidedrawer()} 
        />
    );

    const viewAllNotifications = () =>
    {
        return (
            <div id='viewAllNotifications'>
                <HeaderBar 
                  title={ 
                    <div className="title-container">
                        <div onClick={handleBackClick} className="back-arrow">
                            <FontAwesomeIcon icon={['fal', 'arrow-left']} />
                            <div>CheckIn App</div>
                            {" / "}
                        </div>
                        <span>Notifications</span>
                    </div>
                  }
                  buttons={[
                    <CustomTooltip title='VCF Card Settings' key={0}>
                        <div>
                            <CustomButton
                                key={0}
                                btnType='tertiary'
                                name=''
                                startIcon={ <FontAwesomeIcon icon={ ['fal', 'id-badge'] } /> }
                                onClick={() => openEventCardSettings()}
                            />
                        </div>
                    </CustomTooltip>,
                    <CustomTooltip title='Badge Settings' key={1}>
                        <div>
                            <CustomButton
                                key={1}
                                btnType='tertiary'
                                name=''
                                startIcon={ <FontAwesomeIcon icon={ ['fal', 'qrcode'] } /> }
                                onClick={(): void => {
                                    openEventBadgeSettingsSidedrawer();
                                }}
                            />
                        </div>
                    </CustomTooltip>,
                ]}
                />


                {isEmpty ? (
                    <TableEmptyComponent emptyImg={''} infoText={'No CheckIn Notifications Yet'} subInfoText={'Create your first notification here'}  additionalComponent={buttomComponent} />
                ) : (


                    // <div>Hello</div>
                    <div style={{ height: 'calc(100% - 128px)' }}>
                        <TanstackToolbarWithServerFilter
                            columns={columns}
                            setColumns={setAllColumns}
                            tabs={[
                                {
                                    tabName: 'All',
                                    count: eventCheckInNotificationsCount,
                                    navigation: () => {},
                                    selectedTab: true,
                                },
                            ]}
                            setCurrentUrl={setCurrentUrl}
                            filterColumns={checkInNotificationsFilterColoumns}
                            handleApplyFilters={(filters) => handleApplyFilters(filters)}
                            rightCornerButton={<CustomButton key={1} name={<FontAwesomeIcon icon={['fal', 'plus']} />} btnType='primary' onClick={() =>  openEventCheckInNotificationsSidedrawer()} />}
                        />
                        <TanstackTable
                            data={eventCheckInNotificationsData}
                            rowCount={eventCheckInNotificationsCount}
                            showSpinner={showSpinner}
                            pageSize={pageSize}
                            updatePageSize={updatePageSize}
                            updateCurrentPage={updateCurrentPage as any}
                            onRowClick={(row) => openEventCheckInNotificationsSidedrawer(row)}
                            currentPage={currentPage - 1}
                            initialColumns={allColumns}
                            // height={'calc(100% - 48px)'}
                            showBottomPadding={false}
                        />
                    </div>
                )}

                {showDeletePopup && (
                    <DeletePopup
                        acceptBtn='Delete'
                        acceptClick={handleDeleteClick}
                        cancelClick={() => {
                            setShowDeletePopup(false);
                        }}
                        modalContent={`Are you sure you want to delete the notification "${selectedRow?.title}"?`}
                        modalTitle='Delete Check In Notification'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                        modalHeaderIcon='trash'
                    />
                )}
            </div>
        );
    };

    const renderBreadCrumbs = () => {
        return (
            <div id="inside-event-module-name-section">
                <div onClick={handleBackClick} className="back-arrow">
                    <FontAwesomeIcon icon={['fal', 'arrow-left']} />
                    <div>CheckIn App</div>
                    {" / "}
                </div>
                
                <div className='header-text'>Notifications</div>
            </div>
        )
    };

    const viewAllNotificationsMinimalView = () => {
        return (
            <div id='viewAllNotificationsMinimalView'>
                <MinimalViewComponent
                    title={renderBreadCrumbs()}
                    description=""
                    componentType='table'
                    titleBarActions={[
                        <CustomButton
                            key={0}
                            btnType='tertiary'
                            name=''
                            startIcon={ <FontAwesomeIcon icon={ ['fal', 'id-badge'] } /> }
                            onClick={() => openEventCardSettings()}
                        />,
                        <CustomButton
                            key={1}
                            btnType='tertiary'
                            name=''
                            type='button'
                            startIcon={ <FontAwesomeIcon icon={ ['fal', 'qrcode'] } /> }
                            onClick={(): void => {
                                openEventBadgeSettingsSidedrawer();
                            }}
                        />,
                    ]}
                    tabData={[
                        {
                            tabName: 'All',
                            count: eventCheckInNotificationsCount,
                            navigation: () => {},
                            selectedTab: true,
                        },
                    ]}
                    actions={[

                        !isEmpty && <TanstackToolbarWithServerFilter
                            key={1}
                            columns={columns}
                            filterColumns={checkInNotificationsFilterColoumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        !isEmpty && <ColumnVisibilityDropdown 
                            key={2}
                            columns={columns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomButton
                            key={2}
                            name={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary'
                            onClick={() => openEventCheckInNotificationsSidedrawer()}
                        />,
                    ]}
                    component={
                        <div className="minimalView-table-container">
                            {isEmpty ? (
                                <TableEmptyComponent emptyImg={''} infoText={'No Users Yet'} subInfoText={''} />
                            ) : (
                                <div style={{ height: 'calc(100%)' }}>
                                    <TanstackTable
                                        data={eventCheckInNotificationsData}
                                        rowCount={eventCheckInNotificationsCount}
                                        showSpinner={showSpinner}
                                        pageSize={pageSize}
                                        updatePageSize={updatePageSize}
                                        updateCurrentPage={updateCurrentPage as any}
                                        onRowClick={(row) => openEventCheckInNotificationsSidedrawer(row)}
                                        currentPage={currentPage - 1}
                                        initialColumns={allColumns}
                                        // height={'calc(100vh - 320px)'}
                                        showBottomPadding={false}
                                    />
                                </div>
                            )}
                        </div>
                    }
                />
            </div>
        );
    };

        {showDeletePopup && (
            <DeletePopup
                acceptBtn='Delete'
                acceptClick={handleDeleteClick}
                cancelClick={() => {
                    setShowDeletePopup(false);
                }}
                modalContent={`Are you sure you want to delete the notification "${selectedRow?.title}"?`}
                modalTitle='Delete CheckIn Notifications'
                show={showDeletePopup}
                rejectBtn='Cancel'
                modalHeaderIcon='trash'
            />
        )}

    return minimalView ? viewAllNotificationsMinimalView() : viewAllNotifications();
};

export default ViewAllNotificationsComponnent;
