import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface TableEmptyComponentProps {
    emptyImg?: string;
    infoText: string;
    subInfoText?: string;
    additionalComponent?: ReactNode;
}

const TableEmptyComponent = (props: TableEmptyComponentProps) : React.JSX.Element => 
{
    const { emptyImg, infoText, subInfoText, additionalComponent } = props;

    return (
        <Box className="table-empty-container">
            <Box component="img" src={emptyImg} />
            <Box className="empty-info-container">
                <Typography className="empty-info-text">{infoText}</Typography>
                <Typography className="empty-info-sub-text">{subInfoText}</Typography>
                {additionalComponent && <Box className="additional-component-container">{additionalComponent}</Box>}
            </Box>
        </Box>
    );
};

export default TableEmptyComponent;