import React, { useContext, useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { Typography, Box, Grid } from '@mui/material';
import { CONTENT } from '../../../scripts/i18n';
import { LocalStorage } from '../../../scripts/LocalStorage';
import { checkOrgLinkAvailability, updateOrgDetails, uploadOrgCoverImage, uploadOrgImage } from '../../../scripts/apis/organisation';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { OrganisationPageContext } from '../../../contexts/Settings/OrganisationPageContext';
import { useDropzone } from 'react-dropzone';
import { isImageBuffer } from '../../../scripts/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateOrgInfo } from '../../../redux/organization/Organization';
import { FormControlComponent, FormLabelComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { Organization } from '../../../interfaces/settings/organization_interface';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import toast from 'react-hot-toast';
import { UserContext } from '../../../contexts/UserContext';
import { UserOrgRoles, UserViewType } from '../enum/users.enum';

import './styles.scss';
import { MinimalViewComponent } from '../../../common/MinimalView/MinimalViewComponent';
import HeaderBar from '../../../common/Headerbar';

/**
 * @returns 
 * Functional component to render the organisation settings page
 * Variable to store the navigation event using a react hook
 * Variable to store the redux dispatch event using a react hook
 * Variable to store the data which is shown/hidden based on the necesssary criteria
 * Variable to store the data which is shown/hidden based on the necesssary criteria
 * Variable to store the data which is shown/hidden based on the necesssary criteria
 * Variable to store the logged in user information from the local storage
 * Variable to store the logged in user's organisation information from the local storage
 * Variable to store the object which has basic organisation information
 * Variable to store the initial state of the org data
 * Variable to store the initial state of the organisation image
 * Variable to store the initial state of the initial state of the validation
 * Variable to store the initial state of the organisation name when it is accepted
 * Variable to store the initial state of the organisation name when it is rejected
 * Variable to store the initial state of the checking regular expression which is used to validate the org name
 * Variable to store the initial state of the spinner in the org link input field
 * Variable to store the initial state of the organisation billig details
 * Variable to store the initial state of the organisation link validation 
 * Variable to store the initial state of the disabled input fields
 * Variable to store the style for the input fields which are only visible for the users who is an owner of an organisation
 * Function to handle the text change and validate the organisation name input field
 * Function which handles the delete organisation button content
 * Function which handles the leave organisation button content
 * Function which handles the leave organisation button click event
 * Function which handles the delete organisation button click event
 * Function which handles the form submission event when save button is clicked
 * Variable to store the initial state of the skeleton loader
 */

const OrganizationPage: React.FC = (): React.JSX.Element => 
{

    const { 
        orgImageFile, 
        setOrgImageFile, 
        orgLinkSpinner, 
        setOrgLinkSpinner, 
        orgLinkAvailable, 
        isOrgLinkAvailable, 
        spinner, 
        setSpinner, 
        isEditing, 
        setIsEditing,
        coverImgFile,
        setCoverImgFile
    } = useContext(OrganisationPageContext);

    const {
        userDetails,
        orgDetails,
        setOrgRefresh
    } = useContext(UserContext);

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const dispatch = useDispatch();
    // const userOnline = LocalStorage.get('@UserMe');
    // const org = LocalStorage.get('@Org');

    // const [billigDetails, setBilligDetails] = useState(orgBillingDetailsObj);
    // const [validateLink, setValidateLink] = useState((orgObject.link.split('qlorganisation')[0] === '') || (orgObject.link.split('scorganisation')[0] === '') ? '' : orgObject.link);
    //     const fetchData = async () => {

    //         // const user = await userMe();
    //         // setUserOnline(user);            
    //         // const user = {
    //         //     id: 192, email: 'dharsan.seenivasan@eventhq.io', firstName: 'Dharsan', lastName: 'Seenivasan', status: 1, link: 'qluser192', additionalInfo: null, orgId: 141, orgStatus: 1, role: 3, created: '2023-02-03T08:15:29.414Z', modified: '2023-02-17T12:29:52.185Z'
    //         // };
    //         const org = await orgMe();
    //         setOrg(org);
    //         dispatch(updateOrgInfo({
    //             name: org.name,
    //             link: org.link,
    //             additionalInfo: org.additionalInfo,
    //         }));
    //         setValidateLink((org.link.split('qlorganisation')[0] === '') || (org.link.split('scorganisation')[0] === '') ? '' : org.link);
    //         organisation.name = org.name;
    //         organisation.link = org.link;
    //         organisation.additionalInfo = org.additionalInfo ? org.additionalInfo : new Object();
    //         setOrganisation(organisation);
    //         setOrgImage(organisation.additionalInfo.image);
    //         // const orgBillingDetails = await getOrgBillingDetails();
    //         // const orgBillingDetails = {
    //         //     tags: false,
    //         //     teams: true,
    //         //     users: true,
    //         //     webhooks: false,
    //         //     auditHistory: false,
    //         //     customDomain: false,
    //         //     privateLinks: true,
    //         //     cannedResponse: false,
    //         //     linkEditHistory: false,
    //         //     shareLinksTeams: true,
    //         //     publicSharingLinks: true
    //         // };
    //         // setBilligDetails(orgBillingDetails);

    //         // setDisabledInput(user.role !== 3);
    //         // eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, () => {
    //         //     navigate(0);
    //         // });
    //     }
    //     fetchData();
    // }, []);

    const { getInputProps, getRootProps } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
        },
        // disabled: isEditing,
        multiple: false,
        onDrop: (acceptedFiles): void => 
        {
            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = (): void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    setOrgImageFile(file);
                    formik.setFieldValue('orgImage', URL.createObjectURL(file));
                }
                else 
                {
                    setOrgImageFile(null);
                    toast.error(fileSizeInMB > 10 ? 'Image size should be less than 10 MB' : 'Please provide a valid image');
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        }
    });

    const { getRootProps: getCoverImgRootProps, getInputProps: getCoverImgInputProps } = useDropzone({ 
        accept: {
            'image/png': [],
            'image/jpeg': [],
        },
        // disabled: isEditing,
        multiple: false,
        onDrop: (acceptedFiles): void => 
        {
            const file = acceptedFiles[0];

            const fileSize = file.size;

            const fileSizeInMB = fileSize / (1024 * 1024);

            const reader = new FileReader();
            reader.onloadend = (): void => 
            {
                const buffer = new Uint8Array(reader.result as ArrayBufferLike);
                const isImage = isImageBuffer(buffer);

                if (isImage && fileSizeInMB < 10) 
                {
                    setCoverImgFile(file);
                    formik.setFieldValue('coverImage', URL.createObjectURL(file));
                }
                else 
                {
                    setCoverImgFile(null);
                    toast.error(fileSizeInMB > 10 ? 'Image size should be less than 10 MB' : 'Please provide a valid image');
                    return;
                }
            };

            reader.readAsArrayBuffer(file);
        }
    });

    const validationSchema = Yup.object().shape({
        orgName: Yup.string()
            .required('Organisation Name cannot be empty'),
        orgLink: Yup.string()
            .required('Organisation Link is required')
            .matches(/^[a-z0-9_\-]+$/, 'No special characters allowed'),
        gstin: Yup.string(),
        address: Yup.string(),
        website: Yup.string(),
        linkedIn: Yup.string(),
        twitter: Yup.string(),
        businessName: Yup.string(),
    });    

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnMount: false,
        initialTouched: {
            orgLink: false,
            orgName: false,
            gstin: false,
            address: false,
            website: false,
            linkedIn: false,
            twitter: false,
            businessName: false,
        },
        initialValues: {
            orgName: orgDetails?.name ? orgDetails?.name : '',
            orgLink: orgDetails?.link ? orgDetails?.link : '',
            gstin: orgDetails?.gstin ? orgDetails?.gstin : '',
            address: orgDetails?.address ? orgDetails?.address : '',
            orgImage: orgDetails?.additionalInfo?.image ? orgDetails?.additionalInfo?.image : undefined,
            website: orgDetails?.social?.website ? orgDetails?.social?.website : '',
            linkedIn: orgDetails?.social?.linkedIn ? orgDetails?.social?.linkedIn : '',
            twitter: orgDetails?.social?.twitter ? orgDetails?.social?.twitter : '',
            businessName: orgDetails?.businessName ? orgDetails?.businessName : '',
            coverImage: orgDetails?.coverImage || '',
            description: orgDetails?.description || '',
        },
        onSubmit: async (): Promise<void> => 
        {
            const hasOrganizationDetailsChanged = () => 
            {
                return (
                    orgDetails?.name !== formik.values.orgName ||
                  (orgDetails?.link !== formik.values.orgLink && orgLinkAvailable) ||
                  orgDetails?.social?.website !== formik.values.website ||
                  orgDetails?.social?.linkedIn !== formik.values.linkedIn ||
                  orgDetails?.social?.twitter !== formik.values.twitter ||
                  orgDetails.gstin !== formik.values.gstin ||
                  orgDetails.address !== formik.values.address || 
                  orgDetails?.businessName !== formik.values.businessName ||
                  orgDetails?.description !== formik.values.description
                );
            };

            if (userDetails?.role === UserOrgRoles.SUPERADMIN && orgLinkAvailable) 
            {
                setSpinner(true);
          
                let imageUpdated: string = '', coverImgUpdated: string = '', orgDetailsUpdated: Organization;
                
                if(formik.values.orgImage)
                {
                    if ((orgDetails?.additionalInfo && orgDetails.additionalInfo.image !== formik.values.orgImage) || (!orgDetails?.additionalInfo && formik.values.orgImage !== '')) 
                    {
                        const image = new FormData();
                        image.append('file', orgImageFile);
                        try 
                        {
                            imageUpdated = await uploadOrgImage(image, csrfTokenData);
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }
                    }
                }

                if(formik.values.coverImage)
                {
                    if ((orgDetails?.coverImage && orgDetails.coverImage !== formik.values.coverImage) || (!orgDetails?.coverImage && formik.values.coverImage !== '')) 
                    {
                        const coverImage = new FormData();
                        coverImage.append('file', coverImgFile);
                        try 
                        {
                            coverImgUpdated = await uploadOrgCoverImage(coverImage, csrfTokenData);
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }
                    }
                }
          
                if (hasOrganizationDetailsChanged()) 
                {
                    const details = {
                        name: formik.values.orgName,
                        link: formik.values.orgLink,
                        gstin: formik.values.gstin,
                        address: formik.values.address,
                        businessName: formik.values.businessName,
                        description: formik.values.description,
                        social: {
                            website: formik.values.website,
                            linkedIn: formik.values.linkedIn,
                            twitter: formik.values.twitter,
                        },
                    };
                    try 
                    {
                        orgDetailsUpdated = await updateOrgDetails(details);
                        if (orgDetailsUpdated) 
                        {
                            LocalStorage.set('@Org', {
                                name: orgDetailsUpdated.name,
                                link: orgDetailsUpdated.link,
                                gstin: orgDetailsUpdated.gstin,
                                address: orgDetailsUpdated.address,
                                additionalInfo: orgDetailsUpdated.additionalInfo,
                                social: orgDetailsUpdated.social,
                                businessName: orgDetailsUpdated.businessName,
                                description: orgDetailsUpdated.description,
                            });
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                    }
                    finally 
                    {
                        setSpinner(false);
                        // setIsEditing(!isEditing);
                    }
                }
                else 
                {
                    setSpinner(false);
                    // setIsEditing(!isEditing);
                }
          
                if (imageUpdated || coverImgUpdated || orgDetailsUpdated) 
                {
                    setOrgRefresh(true);
                }
            }
        }
    });
    
    useEffect((): () => void => 
    {
        const delayDebounceFn = setTimeout(async (): Promise<void> => 
        {
            if (formik.values.orgLink && formik.values.orgLink.length > 1 && !(orgDetails?.link === formik.values.orgLink.toLowerCase())) 
            {
                setOrgLinkSpinner(true);
                try 
                {
                    const linkAvailable = await checkOrgLinkAvailability(formik.values.orgLink.toLowerCase());
                    if (linkAvailable) 
                    {
                        isOrgLinkAvailable(linkAvailable === 'true');
                        setOrgLinkSpinner(false);
                    }

                }
                catch (error) 
                {
                    setOrgLinkSpinner(false);
                    console.log(error);
                }

            }
            else 
            {
                isOrgLinkAvailable(true);
            }
        }, 500);

        return (): void => 
        {
            return clearTimeout(delayDebounceFn);
        };
    }, [formik.values.orgLink, orgDetails?.link]);

    const renderOrganisationPage = (): React.JSX.Element => { 
        return (
            <Box id="organisationPage">
                <Form noValidate onSubmit={(values): void => 
                {
                    return formik.handleSubmit(values);
                }} autoComplete="off">

                    {userDetails?.viewType === UserViewType.NORMAL && <HeaderBar title={'Edit Organization Info'} showBorder />}

                    <Box padding={userDetails?.viewType === UserViewType.NORMAL ? '16px' : '16px 0'} className="organisation-form-cont">
                        <Box className="orgImage-and-coverImg-cont">
                            <Box>
                                <FormLabelComponent label="Organisation Logo" />
                                {
                                    formik.values?.orgImage ? 
                                        <Box className="org-img-cont">
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                    
                                                <img src={formik.values?.orgImage} alt="Preview" />
                                                    
                                                {formik.values?.orgImage && (
                                                    <Box className="icon-box">
                                                        <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-icon" onClick={(event): void => 
                                                        {
                                                            event.stopPropagation();
                                                            setOrgImageFile(null);
                                                            formik.setFieldValue('orgImage', null);    
                                                        }} />
                                                    </Box>
                                                )}
                                            </div>
                                        </Box> :
                                        <Box className="empty-orgImage-cont" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} />
                                        </Box>
                                }
                            </Box>

                            <Box className="sidebar-container-spacing">
                                <FormLabelComponent label="Organisation Cover Image" />
                                {
                                    formik.values?.coverImage ? 
                                        <Box className="org-coverImg-cont">
                                            <div {...getCoverImgRootProps()}>
                                                <input {...getCoverImgInputProps()} />
                                                    
                                                <img src={formik.values?.coverImage} alt="Preview" />
                                                    
                                                {formik.values?.coverImage && (
                                                    <Box className="icon-box">
                                                        <FontAwesomeIcon icon={['fal', 'xmark']} className="remove-icon" onClick={(event): void => 
                                                        {
                                                            event.stopPropagation();
                                                            setCoverImgFile(null);
                                                            formik.setFieldValue('coverImage', null);    
                                                        }} />
                                                    </Box>
                                                )}
                                            </div>
                                        </Box> :
                                        <Box className="empty-coverImg-cont" {...getCoverImgRootProps()}>
                                            <input {...getCoverImgInputProps()} />
                                            <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} />
                                        </Box>
                                }
                                <h3 className="cover-img-infoText">{'Recommended size of the cover image is 1024px X 192px.'}</h3>
                            </Box>
                        </Box>

                        <Grid container spacing={2} className="sidebar-container-spacing">
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormLabelComponent label={CONTENT.SETTINGS_PAGE.ORGANIZATION.FORMS.LABEL.ORG_NAME} required />
                                <FormControlComponent
                                    type="text"
                                    value={formik.values.orgName}
                                    // disabled={isEditing}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('orgName', event.target.value);
                                    }}
                                    placeholder={CONTENT.SETTINGS_PAGE.ORGANIZATION.FORMS.PLACEHOLDER.ORG_NAME}
                            />
                            {formik.errors.orgName && formik.touched.orgName ? <Typography className="error-msg">{formik.errors.orgName}</Typography> : null}
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormLabelComponent label='Website' />
                                <FormControlComponent
                                    type="text"
                                    value={formik.values.website}
                                    // disabled={isEditing}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('website', event.target.value);
                                    }}
                                    placeholder='Enter Website URL'
                                />
                                {formik.errors.website && formik.touched.website ? <Typography className="error-msg">{formik.errors.website}</Typography> : null}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="sidebar-container-spacing">
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormLabelComponent label={CONTENT.SETTINGS_PAGE.ORGANIZATION.FORMS.LABEL.ORG_LINK} required />
                                    <Box className="subDomain-input-container">
                                        <FormControlComponent
                                            type="text"
                                            value={formik.values.orgLink.toLowerCase()}
                                            // disabled={isEditing}
                                            onChange={(event): void => 
                                            {
                                                formik.setFieldValue('orgLink', event.target.value.toLowerCase());
                                                dispatch(updateOrgInfo({
                                                    orgLink: event.target.value.toLowerCase()
                                                }));
                                        }}
                                        required />
                                        <Box className="validation-container">
                                            {!orgLinkSpinner && formik.touched.orgLink && formik.values.orgLink.length > 1 && orgLinkAvailable ?
                                                <FontAwesomeIcon icon={['fal', 'circle-check']} className="subDomain-validate-icon" /> : ''
                                            }
                                            {orgLinkSpinner ? <Spinner size="sm" className={orgLinkSpinner ? 'subdomain-validate-spinner' : 'subdomain-validate-spinner-disable'} /> : ''}
                                        </Box>
                                    </Box>
                                {!orgLinkAvailable && formik.touched.orgLink ? <Typography className="error-msg">{'Link not available'}</Typography> :
                                    formik.errors.orgLink ? <Typography className="error-msg">{formik.errors.orgLink}</Typography> : ''}
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormLabelComponent label='LinkedIn' />
                                <FormControlComponent
                                    type="text"
                                    value={formik.values.linkedIn}
                                    // disabled={isEditing}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('linkedIn', event.target.value);
                                    }}
                                    placeholder='Enter LinkedIn URL'
                                />
                                {formik.errors.linkedIn && formik.touched.linkedIn ? <Typography className="error-msg">{formik.errors.linkedIn}</Typography> : null}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="sidebar-container-spacing">
                            {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormLabelComponent label='Tax ID' />
                                <FormControlComponent
                                    type="text"
                                    value={formik.values.gstin}
                                    disabled={isEditing}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('gstin', event.target.value?.toUpperCase());
                                    }}
                                    placeholder='Enter Tax Id'
                                />
                                {formik.errors.gstin && formik.touched.gstin ? <Typography className="error-msg">{formik.errors.gstin}</Typography> : null}
                            </Grid> */}
                            
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormLabelComponent label='Twitter' />
                                <FormControlComponent
                                    type="text"
                                    value={formik.values.twitter}
                                    // disabled={isEditing}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('twitter', event.target.value);
                                    }}
                                    placeholder='Enter Twitter URL'
                                />
                                {formik.errors.twitter && formik.touched.twitter ? <Typography className="error-msg">{formik.errors.twitter}</Typography> : null}
                            </Grid>

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormLabelComponent label='Tax ID' />
                                <FormControlComponent
                                    type="text"
                                    value={formik.values.gstin}
                                    // disabled={isEditing}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('gstin', event.target.value?.toUpperCase());
                                    }}
                                    placeholder='Enter Tax Id'
                                />
                                {formik.errors.gstin && formik.touched.gstin ? <Typography className="error-msg">{formik.errors.gstin}</Typography> : null}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="sidebar-container-spacing">
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormLabelComponent label='Address' />
                                <FormControlComponent
                                    type="text"
                                    value={formik.values.address}
                                    // disabled={isEditing}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('address', event.target.value);
                                    }}
                                    placeholder='Enter Address'
                                    as='textarea'
                                    rows={3}
                                />
                                {formik.errors.address && formik.touched.address ? <Typography className="error-msg">{formik.errors.address}</Typography> : null}
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormLabelComponent label='Business Name' />
                                <FormControlComponent
                                    type="text"
                                    value={formik.values.businessName}
                                    // disabled={isEditing}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('businessName', event.target.value);
                                    }}
                                    placeholder='Enter Business Name'
                                />
                                {formik.errors.address && formik.touched.address ? <Typography className="error-msg">{formik.errors.address}</Typography> : null}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="sidebar-container-spacing">
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormLabelComponent label='Description' />
                                <FormControlComponent
                                    type="text"
                                    value={formik.values.description}
                                    // disabled={isEditing}
                                    onChange={(event): void => 
                                    {
                                        formik.setFieldValue('description', event.target.value);
                                    }}
                                    placeholder='Enter Description'
                                    as='textarea'
                                    rows={3}
                                />
                                {formik.errors.address && formik.touched.address ? <Typography className="error-msg">{formik.errors.address}</Typography> : null}
                            </Grid>
                        </Grid>

                        <Box className="sidebar-container-spacing org-submit-button-cont">
                            <CustomButton disabled={!formik.dirty} btnType='primary' name='Save' loading={spinner} type='submit' />
                            <CustomButton disabled={!formik.dirty} btnType='secondary' name='Cancel' type='button' onClick={() => 
                                {
                                    // setIsEditing(!isEditing);
                                    formik.resetForm();
                                }} />
                        </Box>
                    </Box>
                </Form>
            </Box >
        );
    };

    const renderMinimalViewForOrganisationPage = (): React.JSX.Element => { 
        return (
            <div className="org-minimalView-container">
                <MinimalViewComponent 
                    title="Organisation"
                    // titleBarActions={[
                    //     <CustomButton btnType={isEditing ? 'secondary' : 'primary'} disabled={!isEditing} name={'Edit'} onClick={(): void => 
                    //         {
                    //             setIsEditing(!isEditing);
                    //         }} type={isEditing ? 'submit' : 'button'}  />
                    // ]}
                    description="Edit Organisation Info here."
                    component={renderOrganisationPage()}
                    componentType="list"
                />
            </div>
        );
    };

    return (
        <>
            {userDetails?.viewType === UserViewType.MINIMAL ? renderMinimalViewForOrganisationPage() : renderOrganisationPage()}
        </>
    );
};
export default OrganizationPage;