import { useEffect, useState } from "react";
import { EventMarketingAnalytics } from "../../../../pages/Events/interfaces";
import CardComponent from "../../Registrations/CardComponent";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import { BorderLinearProgress } from "../../../../common/StyledComponents/linearProgress.styled";
import _ from "lodash";
import EmailRecipients from "./EmailRecipients";
import { getEventMarketingAnalytics } from "../../../../scripts/apis/eventMarketing";

import './styles.scss';

interface EventMarketingPerformanceProps {
    marketingId: string | number;
}

const EventMarketingPerformance: React.FC<EventMarketingPerformanceProps> = (props): React.JSX.Element => {

    const { marketingId } = props;

    const [marketingAnalytics, setMarketingAnalytics] = useState<EventMarketingAnalytics>();

    const marketingTrends = [
        {
            name: 'Successful Deliveries',
            value: marketingAnalytics?.successfulDeliveries || 0,
            percentage: marketingAnalytics?.successfulDeliveriesPercentage || 0
        },
        {
            name: 'Bounces',
            value: marketingAnalytics?.bounces || 0,
            percentage: marketingAnalytics?.bouncesPercentage || 0
        },
        {
            name: 'Unsubscribes',
            value: marketingAnalytics?.unsubscribes || 0,
            percentage: marketingAnalytics?.unsubscribesPercentage || 0
        },
        {
            name: 'Spam Reports',
            value: marketingAnalytics?.spamReports || 0,
            percentage: marketingAnalytics?.spamReportsPercentage || 0
        }
    ]

    const fetchAnalyticsData = async (): Promise<void> => {
        try {
            const marketingAnalytics = await getEventMarketingAnalytics(marketingId);

            if (marketingAnalytics) {
                setMarketingAnalytics(marketingAnalytics);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchAnalyticsData();
    }, []);

    return (
        <div id="eventMarketingPerformance">
            <div className="event-marketing-performance-component">
                <h3 className="heading">{'Email Performance'}</h3>

                <div className="marketing-analytics-trends">
                    {
                        marketingTrends.map((trend, index) => {
                            const header = <h3 className="marketing-analytics-trend-header">{trend.name}</h3>;
                            const leftFooterNodes = <p className="marketing-analytics-trend-value">{trend.value}</p>;
                            const rightFooterNodes = <MuiChip label={`${trend.percentage} %`} chipColor="grey" />

                            return (
                                <CardComponent
                                    key={index}
                                    header={header}
                                    footerLeft={leftFooterNodes}
                                    footerRight={rightFooterNodes}
                                />
                            )
                        })
                    }
                </div>

                <div className="marketing-rates-card-container">
                    <MarketingRatesCard
                        data={marketingAnalytics}
                        cardType={'openRate'}
                        title={'Open Rate'}
                    />
                    <MarketingRatesCard
                        data={marketingAnalytics}
                        cardType={'clickRate'}
                        title={'Click Rate'}
                    />
                </div>
            </div>

            <EmailRecipients marketingId={marketingId} />
        </div>
    )
};

const MarketingRatesCard: React.FC<{ data?: EventMarketingAnalytics, cardType?: 'openRate' | 'clickRate', title: string }> = ({ data, cardType, title }) => {

    if (!data) return

    const renderProgressBar = (percentage: number) => {
        return (
            <div className="marketing-progress-bar">
                <BorderLinearProgress progressColor="#1570EF" value={percentage || 0} variant="determinate" />
                <p className="progress-value">{`${percentage || 0}%`}</p>
            </div>
        )
    };

    return (
        <div className="marketing-rates-card">
            <div className="marketing-rates-card-content">
                <h3>{title}</h3>

                {cardType === 'openRate' && renderProgressBar(data?.openRate?.openRatePercentage)}
                {cardType === 'clickRate' && renderProgressBar(data?.clickRate?.clickRatePercentage)}

                <>
                    {cardType === 'openRate' ? (
                        <>
                            <div className="reliable-total-data">
                                <div className="unique-label-value">
                                    <p className="unique-reliable-label">{'Unique Reliable opens'}</p>
                                    <p className="unique-reliable-value">{data?.openRate?.uniqueReliableOpens}</p>
                                </div>
                                <div className="total-label-value">
                                    <p className="total-label">{'Total opens'}</p>
                                    <p className="total-value">{data?.openRate?.totalOpens}</p>
                                </div>
                            </div>
                            <div className="screen-wise-data">
                                <div className="screen-wise-data-label-value">
                                    <p className="label">{'Desktop'}</p>
                                    <p className="value">{`${data?.openRate?.desktop || 0}%`}</p>
                                </div>
                                <div className="screen-wise-data-label-value">
                                    <p className="label">{'Mobile'}</p>
                                    <p className="value">{`${data?.openRate?.mobile || 0}%`}</p>
                                </div>
                                <div className="screen-wise-data-label-value">
                                    <p className="label">{'Other'}</p>
                                    <p className="value">{`${data?.openRate?.other || 0}%`}</p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="reliable-total-data">
                                <div className="unique-label-value">
                                    <p className="unique-reliable-label">{'Unique Reliable clicks'}</p>
                                    <p className="unique-reliable-value">{data?.clickRate?.uniqueReliableClicks}</p>
                                </div>
                                <div className="total-label-value">
                                    <p className="total-label">{'Total clicks'}</p>
                                    <p className="total-value">{data?.clickRate?.totalClicks}</p>
                                </div>
                            </div>
                            <div className="screen-wise-data">
                                <div className="screen-wise-data-label-value">
                                    <p className="label">{'Desktop'}</p>
                                    <p className="value">{`${data?.clickRate?.desktop || 0}%`}</p>
                                </div>
                                <div className="screen-wise-data-label-value">
                                    <p className="label">{'Mobile'}</p>
                                    <p className="value">{`${data?.clickRate?.mobile || 0}%`}</p>
                                </div>
                                <div className="screen-wise-data-label-value">
                                    <p className="label">{'Other'}</p>
                                    <p className="value">{`${data?.clickRate?.other || 0}%`}</p>
                                </div>
                            </div>
                        </>
                    )}
                </>
            </div>
        </div>
    )
};

export default EventMarketingPerformance;