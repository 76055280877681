import { ActivityLogResponse } from '../../interfaces/activity-log_interface';
import { EventSession, UpdateEventSession } from '../../pages/Events/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const getSessionCount = async (eventId: string | number, startTime?: number, endTime?: number): Promise<number> => 
{
    if (startTime && endTime) 
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-session/'+ eventId +  '/count?' + '&sessionStartDateTime=' + startTime + '&sessionEndDateTime=' + endTime;
        return triggerApi(API_URL, 'GET', '',);
    }
    else 
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-session/' + eventId + '/count';
        return triggerApi(API_URL, 'GET', '',);
    }
};

export const getAllSessions = async (eventId: string | number, pageSize: number, offset: number, startTime?: number, endTime?: number): Promise<EventSession[]> => 
{
    if (startTime && endTime) 
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-session/' + eventId + '?' + '&pageSize=' + pageSize + '&offset=' + offset + '&sessionStartDateTime=' + startTime + '&sessionEndDateTime=' + endTime;
        return triggerApi(API_URL, 'GET', '',);
    }
    else 
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-session/' + eventId + '?' + '&pageSize=' + pageSize + '&offset=' + offset;
        return triggerApi(API_URL, 'GET', '',);
    }
};

export const getSessionById = async (eventId: string, sessionId: string): Promise<EventSession> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-session/' + eventId + '/' + sessionId;
    return triggerApi(API_URL, 'GET', '',);
};

export const getEventSessionActivityLogs = async (eventId: string, sessionId: string): Promise<ActivityLogResponse[]> =>
{
    const query = convertJSONToGETParams({
        sessionId
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-session/' + eventId + '/activity-logs?' + query;
    return triggerApi(API_URL, 'GET', '',);
};

export const uploadSessionImage = async (eventId: string, sessionId: string, imageData: FormData, csrfToken: string): Promise<string> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-session/' + eventId + '/' + sessionId + '/upload-image';
    return triggerApi(API_URL, 'POST', imageData, csrfToken, false);
};

export const createEventSession = async (eventId: string, sessionData, csrfToken: string): Promise<EventSession> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-session/' + eventId;
    return triggerApi(API_URL, 'POST', sessionData, csrfToken);
};

export const updateEventSession = async (eventId: string, sessionId: string, sessionData: UpdateEventSession): Promise<EventSession | false> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-session/' + eventId + '/' + sessionId;
    return triggerApi(API_URL, 'PUT', sessionData,);
};

export const deleteEventSession = async (eventId: string, sessionId: string): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-session/' + eventId + '/' + sessionId;
    return triggerApi(API_URL, 'DELETE', '',);
}; 