import EventBuyerPersonaSettings from "../../../../components/Events/EventSettings/EventBuyerPersonaSettings";
import EventICPSettings from "../../../../components/Events/EventSettings/EventICPSettings";
import EventModeratorSettings from "../../../../components/Events/EventSettings/EventModeratorSettings";
import DeleteEventComponent from "../../../../components/Events/EventSettings/DeleteEventComponent";
import { useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import { UserViewType } from "../../../Settings/enum/users.enum";
import { Container } from "react-bootstrap";
import HeaderBar from "../../../../common/Headerbar";

import './styles.scss';
import IntegrationSync from "../../../../components/Events/EventSettings/IntegrationSync";

const EventSettingsPage: React.FC = (): React.JSX.Element =>
{
    
    const {
        userDetails
    } = useContext(UserContext);

    const renderEventSettings = (): React.JSX.Element =>
    {
        return (
            <div>
                <HeaderBar title="Event Settings" showBorder />
                <EventICPSettings />
                <EventBuyerPersonaSettings />
                <EventModeratorSettings />
                <IntegrationSync />
                <DeleteEventComponent />
            </div>
        );
    };

    const renderMinimalView = (): React.JSX.Element =>
    {
        return (
            <div className="event-settings-minimal">
                {renderEventSettings()}
            </div>
        )
    };

    return (
        <div id="eventSettingsPage">
            {
                userDetails?.viewType === UserViewType.MINIMAL ? renderMinimalView() : renderEventSettings()
            }
        </div>
    );
}

export default EventSettingsPage;