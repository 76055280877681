import moment from "moment";
import { ActivityLogData } from "../../common/ActivityLog/interface/activity-log-component_interface";
import { ActivityLogResponse } from "../../interfaces/activity-log_interface";
import { Event, EventStage, EventTicket, EventUtm } from "../../pages/Events/interfaces";
import { camelCaseToWords, findDifferencesBetweenObjects } from "../helpers";
import { uniqueTimezoneArr } from "../../components/Events/timezoneGenerateFunction";
import { CONTENT } from "../i18n";
import { EnableApprovalStatus, EnableInvoiceStatus, EnableTicketWaitlistStatus, EventRegistrationTypes, EventTicketPaymentIntegration, PrivateTicket, TicketType } from "../../pages/Events/enum";
import _ from "lodash";
import getSymbolCurrency from "currency-symbol-map";
import { EventRegistrantStatus } from "../../pages/Events/enum/event-registrant.enum";
import { FynoResponseStatus } from "../../enums/activity-log.enum";
import { Speaker } from "../../pages/Speakers/interfaces";
import { Sponsor } from "../../pages/Sponsors/interfaces";
import { Vendor } from "../../pages/Vendors/interfaces";
import { EventCoupon } from "../../pages/Events/interfaces/event-coupon_interface";

const getDateTimeFromTimestamp = (timestamp: number) =>
{
    let tempTimestamp = timestamp.toString().length === 13 ? Number(timestamp) : Number(timestamp) * 1000;
    return moment(tempTimestamp).format('DD MMMM, HH:mm:ss');
};

export const eventsActivityLog = (activityLog: ActivityLogResponse[], eventInfo: Event) =>
{
    const activityLogArr: ActivityLogData[] = [];
    
    activityLog?.forEach((activity) => {
        for(const data of activity?.activityLog)
        {
            if(data?.changeLog?.length === 0) { 
                activityLogArr.push({ 
                    title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Created Event: <strong>${eventInfo?.title}</strong>`, 
                    icon: 'plus',
                    modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                });
            }
            data?.changeLog?.forEach((log) => {
                switch(log?.key) {
                    case 'title':
                    case 'link':
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${eventInfo?.title}</strong>`, 
                            subtitle: `${_.capitalize(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                            icon: 'pen-to-square',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'eventStartDateTime':
                    case 'eventEndDateTime':
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${eventInfo?.title}</strong>`, 
                            subtitle: `${camelCaseToWords(log?.key)}: ${moment.unix(Number(log.previousValue)).format('DD MMMM, HH:mm')} -> ${moment.unix(Number(log.currentValue)).format('DD MMMM, HH:mm')}`,
                            icon: 'pen-to-square',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'timezone':
                        const getTimeZone = (timezone: string) =>
                        {
                            return _.filter(uniqueTimezoneArr as any, function (elem)
                            {
                                return elem.value === timezone;
                            })[0]?.name;
                        };
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${eventInfo?.title}</strong>`, 
                            subtitle: `${camelCaseToWords(log?.key)}: ${getTimeZone(String(log?.previousValue))} -> ${getTimeZone(String(log?.currentValue))}`,
                            icon: 'pen-to-square',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'type':
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${eventInfo?.title}</strong>`, 
                            subtitle: `Type: ${CONTENT.EVENTS_PAGE.EVENT_TYPE_CHIP_LABEL[log?.previousValue as keyof typeof CONTENT.EVENTS_PAGE.EVENT_TYPE_CHIP_LABEL]} -> ${CONTENT.EVENTS_PAGE.EVENT_TYPE_CHIP_LABEL[log?.currentValue as keyof typeof CONTENT.EVENTS_PAGE.EVENT_TYPE_CHIP_LABEL]}`,
                            icon: 'pen-to-square',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'locationType':
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${eventInfo?.title}</strong>`, 
                            subtitle: `Location: ${CONTENT.EVENTS_PAGE.EVENT_LOCATION_CHIP_LABEL[log?.previousValue as keyof typeof CONTENT.EVENTS_PAGE.EVENT_LOCATION_CHIP_LABEL]} -> ${CONTENT.EVENTS_PAGE.EVENT_LOCATION_CHIP_LABEL[log?.currentValue as keyof typeof CONTENT.EVENTS_PAGE.EVENT_LOCATION_CHIP_LABEL]}`,
                            icon: 'location-dot',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'registrationType':
                        const getEventRegistrationType = (type: EventRegistrationTypes) =>
                        {
                            if(type === EventRegistrationTypes.OPEN) return 'Open';
                            if(type === EventRegistrationTypes.INVITE) return 'Invite Only';
                        }
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${eventInfo?.title}</strong>`, 
                            subtitle: `Registration Type: ${getEventRegistrationType(Number(log?.previousValue))} -> ${getEventRegistrationType(Number(log?.currentValue))}`,
                            icon: 'user-check',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'status':
                        let title = '';
                        if(log?.currentValue === 2 || log?.currentValue === '2') {
                            title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Published Event: <strong>${eventInfo?.title}</strong>`;
                        }
                        activityLogArr.push({
                            title,
                            icon: 'check',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                }
            })
        }
    })  
    return activityLogArr?.reverse();
};

export const eventTicketsActivityLog = (activityLog: ActivityLogResponse[], ticketsInfo: EventTicket) =>
{
    const activityLogArr: ActivityLogData[] = [];
    
    activityLog?.forEach((activity) => {
        for(const data of activity?.activityLog)
        {
            if(data?.changeLog?.length === 0) { 
                activityLogArr.push({ 
                    title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Created Ticket: <strong>${ticketsInfo?.name}</strong>`, 
                    icon: 'plus',
                    modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                });
            }
            data?.changeLog?.forEach((log) => {
                switch(log?.key) {
                    case 'saleStartDateTime':
                    case 'saleCloseDateTime':
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${ticketsInfo?.name}</strong>`, 
                            subtitle: `${camelCaseToWords(log?.key)}: ${moment.unix(Number(log.previousValue)).format('DD MMMM, HH:mm')} -> ${moment.unix(Number(log.currentValue)).format('DD MMMM, HH:mm')}`,
                            icon: 'clock',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'name':
                    case 'totalTicketsAvailable':
                    case 'description':
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${ticketsInfo?.name}</strong>`, 
                            subtitle: `${_.capitalize(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                            icon: 'pen-to-square',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'currency':
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Currency of <strong>${ticketsInfo?.name}</strong>`, 
                            subtitle: `${_.capitalize(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                            icon: 'dollar-sign',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'ticketPrice':
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Ticket Price of <strong>${ticketsInfo?.name}</strong>`, 
                            subtitle: `Ticket Price: ${getSymbolCurrency(ticketsInfo?.currency)}${log?.previousValue} -> ${getSymbolCurrency(ticketsInfo?.currency)}${log?.currentValue}`,
                            icon: 'dollar-sign',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'privateTicket':
                        const label = (type: PrivateTicket) =>
                        {
                            return type === PrivateTicket.PRIVATE ? 'Private' : 'Public';
                        };
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Privacy of <strong>${ticketsInfo?.name}</strong>`, 
                            subtitle: `Privacy: ${label(log?.previousValue as PrivateTicket)} -> ${label(Number(log?.currentValue) as PrivateTicket)}`,
                            icon: 'eye',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'type':
                        const ticketTypeLabel = (type: TicketType) =>
                        {
                            return type === TicketType.PAID ? 'Paid' : 'Free';
                        };
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Ticket Type of <strong>${ticketsInfo?.name}</strong>`, 
                            subtitle: `Ticket Type: ${ticketTypeLabel(Number(log?.previousValue) as TicketType)} -> ${ticketTypeLabel(Number(log?.currentValue) as TicketType)}`,
                            icon: 'dollar-circle',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'enableApproval':
                        const approvalType = (type: EnableApprovalStatus) =>
                        {
                            return type === EnableApprovalStatus.ENABLE ? 'Enabled' : 'Disabled';
                        };
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Approval of <strong>${ticketsInfo?.name}</strong>`, 
                            subtitle: `Approval: ${approvalType(Number(log?.previousValue))} -> ${approvalType(Number(log?.currentValue))}`,
                            icon: 'user-check',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'enableWaitlistStatus':
                        const waitlistType = (type: EnableTicketWaitlistStatus) =>
                        {
                            return type === EnableTicketWaitlistStatus.ENABLE ? 'Enabled' : 'Disabled';
                        };
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Waitlist of <strong>${ticketsInfo?.name}</strong>`, 
                            subtitle: `Waitlist: ${waitlistType(Number(log?.previousValue))} -> ${waitlistType(Number(log?.currentValue))}`,
                            icon: 'user-clock',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'paymentIntegration':
                        const integrationType = (type: EventTicketPaymentIntegration) =>
                        {
                            return type === EventTicketPaymentIntegration.NONE ? 'None' : type === EventTicketPaymentIntegration.STRIPE ? 'Stripe' : 'Razorpay';
                        }
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Payment Integration of <strong>${ticketsInfo?.name}</strong>`, 
                            subtitle: `Payment Integration: ${integrationType(Number(log?.previousValue))} -> ${integrationType(Number(log?.currentValue))}`,
                            icon: 'money-bill',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'enableInvoice':
                        const invoiceType = (type: EnableInvoiceStatus) =>
                        {
                            return type === EnableInvoiceStatus.ENABLE ? 'Enabled' : 'Disabled';
                        };
                        activityLogArr.push({ 
                            title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Invoice of <strong>${ticketsInfo?.name}</strong>`, 
                            subtitle: `Invoice: ${invoiceType(Number(log?.previousValue))} -> ${invoiceType(Number(log?.currentValue))}`,
                            icon: 'file-invoice-dollar',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'tax':
                        const formatTax = (taxArray: any[]) => {
                            return taxArray && Array.isArray(taxArray) && taxArray?.length > 0 && taxArray.map(tax => `${tax.name}: ${tax.percentage}%`).join(', ');
                        };
                        let title = '';
                        if(log?.previousValue === '')
                        {
                            title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Added Tax of <strong>${ticketsInfo?.name}</strong>`;
                        }
                        else if(log?.currentValue === '')
                        {
                            title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Removed Tax of <strong>${ticketsInfo?.name}</strong>`;
                        }
                        else
                        {
                            title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Tax of <strong>${ticketsInfo?.name}</strong>`;
                        }

                        activityLogArr.push({ 
                            title, 
                            subtitle: `Tax: ${formatTax(log?.previousValue as any[])} -> ${formatTax(log?.currentValue as any[])}`,
                            icon: 'percent',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                }
            })
        }
    })  
    return activityLogArr?.reverse();
};

export const eventRegistrantActivityLog = (activityLog: ActivityLogResponse[]) =>
{
    const activityLogArr: ActivityLogData[] = [];
    activityLog?.forEach((activity) => {
        for(const data of activity?.activityLog) {
            if(data?.changeLog?.length === 0) { 
                activityLogArr.push({ 
                    title: `<strong>Registered</strong>`, 
                    icon: 'file',
                    modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                });
            }
            data?.changeLog?.forEach((log) => {
                switch(log?.key) {
                    case 'status':
                        activityLogArr.push({
                            title: `<strong>Status</strong> Changed: ${CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS[log?.previousValue as EventRegistrantStatus]} -> ${CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS[log?.currentValue as EventRegistrantStatus]}`,
                            icon: 'arrow-right-from-line',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'emailNotification': 
                        activityLogArr.push({
                            title: `${log?.status === FynoResponseStatus.DELIVERED ? `${CONTENT.EVENTS_MODULE.REGISTRANTS.FYNO_RESPONSE_EMAIL_STATUS[log?.currentValue as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.FYNO_RESPONSE_EMAIL_STATUS]} email sent` : `${CONTENT.EVENTS_MODULE.REGISTRANTS.FYNO_RESPONSE_EMAIL_STATUS[log?.currentValue as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.FYNO_RESPONSE_EMAIL_STATUS]} email failed to send`}`,
                            icon: 'envelope',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'firstName': 
                        activityLogArr.push({
                            title: `<strong>First Name</strong> Changed: ${log?.previousValue} -> ${log?.currentValue}`,
                            icon: 'arrow-right-from-line',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'lastName':
                        activityLogArr.push({
                            title: `<strong>Last Name</strong> Changed: ${log?.previousValue} -> ${log?.currentValue}`,
                            icon: 'arrow-right-from-line',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    case 'notes':
                        activityLogArr.push({
                            title: `Added <strong>notes</strong>: ${log?.currentValue}`,
                            icon: 'notes',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                        break;
                    // case 'additionalData':
                    //     const previousData = log?.previousValue as Record<string, any>;
                    //     const currentData = log?.currentValue as Record<string, any>;
                    //     const changes: string[] = [];
    
                    //     for (const key in previousData) {
                    //         if (previousData[key] !== currentData[key]) {
                    //             changes.push(`${key} Changed: ${previousData[key]} -> ${currentData[key]}`);
                    //         }
                    //     }
    
                    //     activityLogArr.push({
                    //         title: changes.join(', '),
                    //         icon: 'arrow-right-from-line',
                    //         modifiedTime: formattedTime,
                    //     });
                }
            })
        }
    })

    return activityLogArr?.reverse();
};

export const speakersActivityLog = (activityLog: ActivityLogResponse[], speakerInfo: Speaker) =>
{
    const activityLogArr: ActivityLogData[] = [];
    
    activityLog?.forEach((activity) => {
        for(const data of activity?.activityLog)
        {
            if(data?.changeLog?.length === 0) { 
                activityLogArr.push({ 
                    title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Created Speaker: <strong>${speakerInfo?.name}</strong>`, 
                    icon: 'plus',
                    modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                });
            }
            data?.changeLog?.forEach((log) => {
                if(log?.key === 'name' || log?.key === 'email') {
                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${_.capitalize(log?.key)} of <strong>${speakerInfo?.name}</strong>`, 
                        subtitle: `${_.capitalize(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                // else if(log?.key === 'image') {
                //     let title = '';
                //     if(log?.previousValue === '' || log?.previousValue === null) {
                //         title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Added Image of <strong>${speakerInfo?.name}</strong>`;
                //     }
                //     else {
                //         title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Image of <strong>${speakerInfo?.name}</strong>`
                //     }
                //     activityLogArr.push({ 
                //         title, 
                //         icon: 'image',
                //         modifiedTime: formattedTime,
                //     });
                // }
                else if(log?.key === 'additionalInfo') {
                    const differences = findDifferencesBetweenObjects(log?.previousValue as object, log?.currentValue as object);
                    Object.keys(differences)?.forEach((item) => {
                        let title = '';
                        if(differences[item]?.prev === '') {
                            title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Added ${_.capitalize(item)} of <strong>${speakerInfo?.name}</strong>`;
                        }
                        else {
                            title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${_.capitalize(item)} of <strong>${speakerInfo?.name}</strong>`
                        }

                        activityLogArr.push({ 
                            title, 
                            subtitle: `${_.capitalize(item)}: ${differences[item].prev} -> ${differences[item]?.current}`,
                            icon: 'pen-to-square',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                    })
                }
            })
        }
    })  

    return activityLogArr?.reverse();
};

export const sponsorsActivityLog = (activityLog: ActivityLogResponse[], sponsorInfo: Sponsor) => {
    const activityLogArr: ActivityLogData[] = [];

    activityLog?.forEach((activity) => {
        for (const data of activity?.activityLog) {
            if (data?.changeLog?.length === 0) {
                activityLogArr.push({
                    title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Created Sponsor: <strong>${sponsorInfo?.name}</strong>`,
                    icon: 'plus',
                    modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                });
            }
            data?.changeLog?.forEach((log) => {
                if (log?.key === 'name' || log?.key === 'pocName' || log?.key === 'pocEmail' || log?.key === 'websiteLink') {
                    activityLogArr.push({
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${_.capitalize(log?.key)} of <strong>${sponsorInfo?.name}</strong>`,
                        subtitle: `${_.capitalize(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                // else if (log?.key === 'logo') {
                //     let title = '';
                //     if (log?.previousValue === '' || log?.previousValue === null) {
                //         title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Added Logo of <strong>${sponsorInfo?.name}</strong>`;
                //     }
                //     else {
                //         title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Logo of <strong>${sponsorInfo?.name}</strong>`
                //     }
                //     activityLogArr.push({
                //         title,
                //         icon: 'image',
                //         modifiedTime: formattedTime,
                //     });
                // }
                else if (log?.key === 'social') {
                    const differences = findDifferencesBetweenObjects(log?.previousValue as object, log?.currentValue as object);
                    Object.keys(differences)?.forEach((item) => {
                        let title = '';
                        if (differences[item]?.prev === '') {
                            title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Added ${_.capitalize(item)} of <strong>${sponsorInfo?.name}</strong>`;
                        }
                        else {
                            title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${_.capitalize(item)} of <strong>${sponsorInfo?.name}</strong>`
                        }

                        activityLogArr.push({
                            title,
                            subtitle: `${_.capitalize(item)}: ${differences[item].prev} -> ${differences[item]?.current}`,
                            icon: 'pen-to-square',
                            modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                        });
                    })
                }
            })
        }
    })
    return activityLogArr?.reverse();
};

export const vendorsActivityLog = (activityLog: ActivityLogResponse[], vendorInfo: Vendor) =>
{
    const activityLogArr: ActivityLogData[] = [];
    
    activityLog?.forEach((activity) => {
        for(const data of activity?.activityLog)
        {
            if(data?.changeLog?.length === 0) { 
                activityLogArr.push({ 
                    title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Created Vendor: <strong>${vendorInfo?.name}</strong>`, 
                    icon: 'plus',
                    modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                });
            }
            data?.changeLog?.forEach((log) => {
                if(log?.key === 'name' || log?.key === 'email' || log?.key === 'vendorName') {
                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${_.capitalize(log?.key)} of <strong>${vendorInfo?.name}</strong>`, 
                        subtitle: `${_.capitalize(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'description') {
                    let title = '';
                    if(log?.previousValue === '' || log?.previousValue === null) {
                        title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Added Description of <strong>${vendorInfo?.name}</strong>`;
                    }
                    else {
                        title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Description of <strong>${vendorInfo?.name}</strong>`
                    }
                    activityLogArr.push({ 
                        title, 
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
            })
        }
    })  

    return activityLogArr?.reverse();
};

export const eventStageActivityLog = (activityLog: ActivityLogResponse[], stageInfo: EventStage) =>
{
    const activityLogArr: ActivityLogData[] = [];
    
    activityLog?.forEach((activity) => {
        for(const data of activity?.activityLog)
        {
            if(data?.changeLog?.length === 0) { 
                activityLogArr.push({ 
                    title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Created Stage: <strong>${stageInfo?.title}</strong>`, 
                    icon: 'plus',
                    modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                });
            }
            data?.changeLog?.forEach((log) => {
                if(log?.key === 'title' || log?.key === 'maximumParticipants') {
                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${stageInfo?.title}</strong>`, 
                        subtitle: `${camelCaseToWords(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'description') {
                    let title = '';
                    if(log?.previousValue === '' || log?.previousValue === null) {
                        title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Added Description of <strong>${stageInfo?.title}</strong>`;
                    }
                    else {
                        title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Description of <strong>${stageInfo?.title}</strong>`
                    }
                    activityLogArr.push({ 
                        title, 
                        subtitle: `${_.capitalize(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'startDateTime' || log?.key === 'endDateTime') {
                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${stageInfo?.title}</strong>`, 
                        subtitle: `${camelCaseToWords(log?.key)}: ${moment.unix(Number(log.previousValue)).format('DD MMMM, HH:mm')} -> ${moment.unix(Number(log.currentValue)).format('DD MMMM, HH:mm')}`,
                        icon: 'clock',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
            })
        }
    });

    return activityLogArr?.reverse();
};

export const eventSessionActivityLog = (activityLog: ActivityLogResponse[], sessionInfo: EventStage, eventSpeakers?: Speaker[]) =>
{
    const activityLogArr: ActivityLogData[] = [];
    
    activityLog?.forEach((activity) => {
        for(const data of activity?.activityLog)
        {
            if(data?.changeLog?.length === 0) { 
                activityLogArr.push({ 
                    title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Created Session: <strong>${sessionInfo?.title}</strong>`, 
                    icon: 'plus',
                    modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                });
            }
            data?.changeLog?.forEach((log) => {
                if(log?.key === 'title') {
                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${sessionInfo?.title}</strong>`, 
                        subtitle: `${camelCaseToWords(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'startDateTime' || log?.key === 'endDateTime') {
                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${sessionInfo?.title}</strong>`, 
                        subtitle: `${camelCaseToWords(log?.key)}: ${moment.unix(Number(log.previousValue)).format('DD MMMM, HH:mm')} -> ${moment.unix(Number(log.currentValue)).format('DD MMMM, HH:mm')}`,
                        icon: 'clock',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'type') {
                    const sessionTypes = {
                        1: 'Session',
                        2: 'Keynote',
                        3: 'Break',
                    };

                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${sessionInfo?.title}</strong>`, 
                        subtitle: `Type: ${sessionTypes[log?.previousValue as keyof typeof sessionTypes]} -> ${sessionTypes[log?.currentValue as keyof typeof sessionTypes]}`,
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'speakerIds') {
                    const prevValues = log?.previousValue as string[];
                    const currentValues = log?.currentValue as string[];

                    const pastSpeakerNames = prevValues?.map((item) => {
                        const speaker = eventSpeakers?.find(speaker => speaker.id === item);
                        return speaker?.name;
                    });
                    const currentSpeakerNames = currentValues?.map((item) => { 
                        const speaker = eventSpeakers?.find(speaker => speaker.id === item);
                        return speaker?.name;
                    });

                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Speakers of <strong>${sessionInfo?.title}</strong>`, 
                        subtitle: `Speakers: ${pastSpeakerNames?.join(', ')} -> ${currentSpeakerNames?.join(', ')}`,
                        icon: 'microphone',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
            });
        }
    });

    return activityLogArr?.reverse();
};

export const eventCouponsActivityLog = (activityLog: ActivityLogResponse[], couponInfo?: EventCoupon, eventTickets?: EventTicket[]) =>
{
    const activityLogArr: ActivityLogData[] = [];
    
    activityLog?.forEach((activity) => {
        for(const data of activity?.activityLog)
        {
            if(data?.changeLog?.length === 0) { 
                activityLogArr.push({ 
                    title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Created Coupon: <strong>${couponInfo?.name}</strong>`, 
                    icon: 'plus',
                    modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                });
            }
            data?.changeLog?.forEach((log) => { 
                if(log?.key === 'totalCouponsAvailable' || log?.key === 'name') {
                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${couponInfo?.name}</strong>`, 
                        subtitle: `${camelCaseToWords(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'description') {
                    let title = '';
                    if(log?.previousValue === '' || log?.previousValue === null) {
                        title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Added Description of <strong>${couponInfo?.name}</strong>`;
                    }
                    else {
                        title = `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Description of <strong>${couponInfo?.name}</strong>`
                    }
                    activityLogArr.push({ 
                        title, 
                        subtitle: `${_.capitalize(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'couponValue' || log?.key === 'couponPercentage') {
                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${couponInfo?.name}</strong>`, 
                        subtitle: `${camelCaseToWords(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'percent',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'currency') {
                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Currency of <strong>${couponInfo?.name}</strong>`, 
                        subtitle: `${_.capitalize(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'dollar-sign',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'couponCodeStartDateTime' || log?.key === 'couponCodeCloseDateTime') {
                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${couponInfo?.name}</strong>`, 
                        subtitle: `${camelCaseToWords(log?.key)}: ${moment.unix(Number(log.previousValue)).format('DD MMMM, HH:mm')} -> ${moment.unix(Number(log.currentValue)).format('DD MMMM, HH:mm')}`,
                        icon: 'clock',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'ticketIds') {
                    const prevValues = log?.previousValue as string[];
                    const currentValues = log?.currentValue as string[];

                    const pastTicketNames = prevValues?.map((item) => {
                        const ticket = eventTickets?.find(ticket => ticket.id === item);
                        return ticket?.name;
                    });
                    const currentTicketNames = currentValues?.map((item) => { 
                        const ticket = eventTickets?.find(ticket => ticket.id === item);
                        return ticket?.name;
                    });

                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Tickets of <strong>${couponInfo?.name}</strong>`, 
                        subtitle: `Tickets: ${pastTicketNames?.join(', ')} -> ${currentTicketNames?.join(', ')}`,
                        icon: 'ticket',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
            });
        }
    });

    return activityLogArr?.reverse();
};

export const eventUtmActivityLog = (activityLog: ActivityLogResponse[], utmInfo?: EventUtm, eventTickets?: EventTicket[], eventCoupons?: EventCoupon[]) =>
{
    const activityLogArr: ActivityLogData[] = [];

    activityLog?.forEach((activity) => {
        for (const data of activity?.activityLog) {
            if (data?.changeLog?.length === 0) {
                activityLogArr.push({
                    title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Created UTM: <strong>${utmInfo?.name}</strong>`,
                    icon: 'plus',
                    modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                });
            }

            data?.changeLog?.forEach((log) => {
                if(log?.key === 'name' || log?.key === 'utmLink') {
                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated ${camelCaseToWords(log?.key)} of <strong>${utmInfo?.name}</strong>`, 
                        subtitle: `${camelCaseToWords(log?.key)}: ${log?.previousValue} -> ${log?.currentValue}`,
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'eventTicketIds') {
                    const prevValues = log?.previousValue as string[];
                    const currentValues = log?.currentValue as string[];

                    const pastTicketNames = prevValues?.map((item) => {
                        const ticket = eventTickets?.find(ticket => ticket.id === item);
                        return ticket?.name;
                    });
                    const currentTicketNames = currentValues?.map((item) => { 
                        const ticket = eventTickets?.find(ticket => ticket.id === item);
                        return ticket?.name;
                    });

                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Tickets of <strong>${utmInfo?.name}</strong>`, 
                        subtitle: `Tickets: ${pastTicketNames?.join(', ')} -> ${currentTicketNames?.join(', ')}`,
                        icon: 'ticket',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
                else if(log?.key === 'eventCouponId') {
                    const coupon = eventCoupons?.find(coupon => String(coupon.id) === String(log?.previousValue));
                    const previousCouponName = coupon?.name;
                    const currentCoupon = eventCoupons?.find(coupon => coupon.id === log?.currentValue);
                    const currentCouponName = currentCoupon?.name;

                    activityLogArr.push({ 
                        title: `<strong>${data?.user?.firstName} ${data?.user?.lastName || ''}</strong> Updated Coupon for <strong>${utmInfo?.name}</strong>`, 
                        subtitle: `Coupon: ${previousCouponName} -> ${currentCouponName}`,
                        icon: 'pen-to-square',
                        modifiedTime: getDateTimeFromTimestamp(data?.timestamp),
                    });
                }
            })
        }
    }); 
    
    return activityLogArr?.reverse();
};
