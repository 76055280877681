import { DomainAuth } from "../../pages/Settings/interface/domain_interface";
import APP_CONSTANTS from "../constants";
import { triggerApi } from "../helpers";

export const getIntegrationsData = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/integrations';
    return triggerApi(API_URL, 'GET', '');
};

export const getIntegrationByType = async (type: number) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/integrations/integrate/' + type;
    return triggerApi(API_URL, 'GET', '');
};

export const integrateEmail = async (data: any, csrfToken: string): Promise<DomainAuth> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/integrations/integrate/email';
    return triggerApi(API_URL, 'POST', data, csrfToken);
};

export const createIntegrationWithApiKey = async (type: number, data: {apiKey: string}, csrfToken: string) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/integrations/integrate/' + type;
    return triggerApi(API_URL, 'POST', data, csrfToken);
};

export const deleteRazorpayIntegration = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/razorpay';
    return triggerApi(API_URL, 'DELETE', '');
};

export const deleteLumaIntegration = async () => { 
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/luma';
    return triggerApi(API_URL, 'DELETE', '');
};

export const deleteGoogleCalendarIntegration = async () => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/google-calendar';
    return triggerApi(API_URL, 'DELETE', '');
};
