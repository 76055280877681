/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect } from 'react';
import { Box, Drawer, Grid, Typography } from '@mui/material';
import eventBus from '../scripts/event-bus';
import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import APP_CONSTANTS from '../scripts/constants';
import { CONTENT } from '../scripts/i18n';
import NestedDrawer from './NestedDrawer';

/**
 * @returns
 * Functional component to render a dynamic side drawer
 * Variable to store the navigation event using a react hook
 * Variable to store the initial state of component to be rendered in the side drawer
 * Variable to store the initial state of heading of the side drawer
 * Variable to store the initial state of button click event in the side drawer
 * Variable to store the initial state of button name in the side drawer
 * Variable to store the initial state of the spinner component in the side drawer
 * Variable to store the initial state of event of back button click in the side drawer
 * Variable to store the state of the dynamic side drawer whether it is opened or closed
 * Variable to store the state of the button in side drawer whether it is disabled or enabled
 * Function to handle the side drawer close event
 * Function to handle the form submit event in the side drawer
 * Function to handle the back button click event in the side drawer
 */

const SideDrawer = (): React.JSX.Element => {

    const drawerBackdropStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    };

    const sidebarBottomMargin = '86px'; // this is the approx height of buttons container in the sidebar

    const [component, setComponent] = useState(<div></div>);
    const [heading, setHeading] = useState(null);
    const [buttonEvent, setButtonEvent] = useState(null);
    const [buttonName, setButtonName] = useState(null);
    const [spinner, setSpinner] = useState(false);
    const [description, setDescription] = useState('');
    // const [backButton, setBackButton] = useState(null);
    const [open, setOpen] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [customBottomBtn, setCustomBottomBtn] = useState(<div></div>);
    // const [hideCloseButton, setHideCloseButton] = useState(false);
    //const [closeEvent, setCloseEvent] = useState('');
    const [containerWidth, setContainerWidth] = useState(null);
    const [noSidebarPadding, setNoSidebarPadding] = useState(false);
    const [nestedDrawerOpen, setNestedDrawerOpen] = useState(false);
    const [nestedSidebarComponent, setNestedSidebarComponent] = useState(null);
    const [nestedDrawerRightOffset, setNestedDrawerRightOffset] = useState('');

    const handleDrawerClose = (): void => {
        eventBus.dispatch('selected-row-id', null);
        eventBus.dispatch(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.CLOSE_EVENT, {
            open: false
        });
        setOpen(false);
    };
    const handleEscapeKeyClose = (event, reason: string): void => {
        if (reason === 'escapeKeyDown') {
            handleDrawerClose();
        }
    };
    const handleSubmit = (event): void => {
        eventBus.dispatch(buttonEvent, event);
    };

    useEffect((): () => void => {
        const updateEvent = (data): void => {
            setHeading(data.heading);
            setButtonEvent(data.event);
            setButtonName(data.buttonName);
            setComponent(data.component);
            setNoSidebarPadding(data?.noSidebarPadding);
            setDescription(data?.description);
            // setBackButton(data.backButton);
            setCustomBottomBtn(data.customBottomBtn);
            // setHideCloseButton(data.hideCloseButton);
        };

        const openEvent = (data): void => {
            setOpen(data.open);
            setContainerWidth(data?.width);
        };

        const updateButton = (data): void => {
            setSpinner(data.spinner);
        };

        const closeEvent = (): void => {
            handleDrawerClose();
        };

        const disableAction = (isDisabled): void => {
            setDisabledButton(isDisabled);
        };

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, updateEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, openEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, updateButton);
        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, closeEvent);
        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.DISABLE_ACTION, disableAction);
        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, (data): void => {
            setOpen(data.open);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.OPEN_EVENT, (data): void => {
            setNestedDrawerOpen(data.open);
            setNestedDrawerRightOffset(data.right);
        });
        eventBus.on(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.CLOSE_EVENT, (data): void => {
            setNestedDrawerOpen(false);
        });
        eventBus.on(APP_CONSTANTS.EVENTS.NESTED_SIDEDRAWER.UPDATE_EVENT, (data): void => {
            setNestedSidebarComponent(data.component);
        });

        return (): void => {
            eventBus.remove(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, updateEvent);
            eventBus.remove(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, openEvent);
            eventBus.remove(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, updateButton);
            eventBus.remove(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, closeEvent);
            eventBus.remove(APP_CONSTANTS.EVENTS.SIDE_DRAWER.DISABLE_ACTION, disableAction);
        };

    }, []);
    return (
        <div>
            <Drawer 
                // hideBackdrop
                BackdropProps={{
                    style: drawerBackdropStyle
                }}
                disableScrollLock
                id="SideDrawer" 
                anchor="right" 
                open={open} 
                onClose={handleEscapeKeyClose}
                disableEnforceFocus
                // disablePortal
            >
                <Box className="box" sx={containerWidth ? {
                    width: containerWidth, 
                    height: '100%',
                    marginBottom: !noSidebarPadding && sidebarBottomMargin
                } : null}>
                    <Grid container className="heading-grid" sx={containerWidth ? {
                        width: containerWidth
                    } : null}>
                        {/* {backButton ? <Grid item xs={1}>
                            <IconButton onClick={goBack}>
                                <ChevronLeft />
                            </IconButton>
                        </Grid> : ''} */}
                        <Grid item xs={12} className="header-container">
                            <Typography className="side-drawer-heading" variant="h4" textAlign={heading == CONTENT.SETTINGS_PAGE.TEAMS.SIDE_DRAWER_SWITCH_CONTENTS.MEMBERS.HEADING ? 'center' : 'start'}>{heading}</Typography>
                            {description && <p className="sidebar-description">{description}</p>}
                            {/* {!hideCloseButton && <FontAwesomeIcon icon={['fal', 'xmark']} className="close-icon" onClick={handleDrawerClose} />} */}
                        </Grid>
                    </Grid>

                    <Box className={noSidebarPadding ? 'sidebar-component-wrapper-noPadding' : "sidebar-component-wrapper"}>{component}</Box>
                    {buttonName ? <Box>
                        <Button variant='light' size="lg" className="bottom-btn" disabled={disabledButton} onClick={handleSubmit}>
                            {spinner ?
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden"></span>
                                </Spinner> : buttonName}
                        </Button>
                    </Box> : ''}
                    {customBottomBtn}
                </Box>
            </Drawer>
            <NestedDrawer right={`${nestedDrawerRightOffset}px` || '401px'} open={nestedDrawerOpen} children={nestedSidebarComponent} />
        </div>
    );
};
export default SideDrawer;

