import { Avatar, Box, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { CONTENT } from '../../scripts/i18n';
import _ from 'lodash';
import CreateSpeaker from '../../components/Speakers/CreateSpeaker';
import { resetSpeakers } from '../../redux/speakers/Speakers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TableEmptyComponent from '../../common/TableEmptyComponent';
import DataImport from '../../common/DataImport';
import { SpeakersContext } from '../../contexts/SpeakersPageContext';
import { getSpeakerById } from '../../scripts/apis/speakers';
import SpeakersGraph from './SpeakersGraph';
import { useDispatch } from 'react-redux';
import speakerEmptyImg from '../../assets/icons/empty-state/speakerEmpty.svg';
import { createColumnHelper } from '@tanstack/react-table';
import { Speaker } from './interfaces';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import { generateSpeakerColumnsForTanstackTable } from './speakerColumn.helper';
import { TableActions } from '../../common/TableActions';
import { CustomButton } from '../../common/FormComponents/Buttons';
import DeletePopup from '../../common/DeletePopup';
import HeaderBar from '../../common/Headerbar';
import toast from 'react-hot-toast';
import CardComponent from '../../components/Events/Registrations/CardComponent';
import AddEventSpeakers from '../Events/EventDetails/People/EventSpeakers/AddEventSpeakers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EventsCardHeaderComponent from '../../components/Events/EventComponents/EventsCardHeaderComponent';
import TanstackToolbarWithServerFilter from '../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { UserContext } from '../../contexts/UserContext';
import { UserViewType } from '../Settings/enum/users.enum';
import { MinimalViewComponent } from '../../common/MinimalView/MinimalViewComponent';
import CustomSpinner from '../../common/CustomSpinner';
import { Stack } from 'react-bootstrap';
import ColumnVisibilityDropdown from '../../common/TanstackTable/ColumnVisibilityDropdown';
import CustomTooltip from '../../common/Tooltip';
import Breadcrumbs from '../../common/Breadcrumbs';
import { useSelector } from 'react-redux';
import { IEventReduxValues, IEventsDispatch } from '../../components/Events/interfaces/create-events_interface';
import { EventStatus } from '../Events/enum';

import mailIcon from '../../assets/icons/envelope-light.svg';
import twitterIcon from '../../assets/icons/twitter-icon.svg';
import linkedInIcon from '../../assets/icons/linkedin-solid.svg';

import './styles.scss';

const SpeakersPage: React.FC<{ eventId?: string | number }> = (props): React.JSX.Element => {

    const { eventId } = props;

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => {
        return state.events.value;
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const { speakerId } = useParams();
    const [chartVisibility, setChartVisibility] = useState(false);

    const {
        userDetails,
        orgDetails
    } = useContext(UserContext);

    const {
        speakerDataCount,
        isEmpty,
        speakers,
        deleteSpeakerFromTable,
        setRefresh,
        pageSize,
        updateCurrentPage,
        updatePageSize,
        showSpinner,
        isChartReady,
        speakersIcpData,
        speakerBuyerPersonaData,
        speakerRevenueData,
        currentPage,
        trendData,
        showDeletePopup,
        setShowDeletePopup,
        selectedSpeaker,
        setSelectedSpeaker,
        isTableView,
        cardView,
        setCurrentUrl,
    } = useContext(SpeakersContext);

    const leftNodes = [
        eventReduxData?.status === EventStatus.PUBLISHED && [
            <CustomTooltip title={'Speaker Portal Link'}>
                <div>
                    <CustomButton 
                        name=''
                        startIcon={<FontAwesomeIcon icon={['fal', 'copy']} />}
                        onClick={() => {
                            navigator.clipboard.writeText(
                                `${APP_CONSTANTS.APP_DOMAIN}/${orgDetails?.link}/${eventReduxData?.link}/portal`
                            );
                            toast.success('Link copied');
                        }}
                        btnType='tertiary'
                    />
                </div>
            </CustomTooltip>,
        ],
        <CustomTooltip title='Table View' key={0}>
            <div>
                <CustomButton startIcon={<FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />} name='' btnType='tertiary' onClick={() => navigate('/events/' + eventId + '/people/speakers')} />
            </div>
        </CustomTooltip>,
    ];
    

    // const [searchText, setSearchText] = useState<string>('');
    const columnHelper = createColumnHelper<Speaker>();

    const generateInitialColumns = () => [
        columnHelper.accessor(row => row?.additionalInfo?.image, {
            cell: ({ getValue, row }) => {
                const profilePicture = getValue();
                const nameSplitted = row.original.name.split(' ');
                const emptyImagesrc = nameSplitted.length >= 2 ? nameSplitted[0][0] + nameSplitted.slice(1).join(' ')[0] : row.original.name.charAt(0);
                return profilePicture ? <Avatar className="speaker-avatar" src={profilePicture} /> : <Avatar className="empty-speaker-avatar" src={emptyImagesrc}><p>{emptyImagesrc.toUpperCase()}</p></Avatar>;
            },
            header: '',
            id: 'speakerImage',
            size: 100,
            enableSorting: false,
        }),
        columnHelper.accessor('name', {
            cell: ({ row, getValue }) => (
                <Stack>
                    <p className="bolded-cellContent">{getValue()}</p>
                    {userDetails?.viewType === UserViewType.MINIMAL && <p className="cellContent">{row.original.email}</p>}
                </Stack>
            ),
            header: 'Name',
            size: 180,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name',
        }),
        ...(userDetails?.viewType === UserViewType.NORMAL ? [columnHelper.accessor('email', {
            cell: ({ getValue }) => <CustomTooltip title={<Typography variant="subtitle1">{getValue()}</Typography>}><Box className="cellContent">{getValue()}</Box></CustomTooltip>,
            header: 'Email',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'email',
        })] : []),
        columnHelper.accessor('additionalInfo.headline', {
            cell: ({ getValue }) => <CustomTooltip title={<Typography variant="subtitle1">{getValue()}</Typography>}><Box className="cellContent">{getValue()}</Box></CustomTooltip>,
            header: 'Headline',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'headline',
        }),
        columnHelper.accessor('actions' as any, {
            cell: ({ row }) => (
                <TableActions
                    actions={[
                        {
                            title: 'Edit', onClick: () => {
                                if (eventId) {
                                    editEventSpeaker(row.original, false);
                                }
                                else {
                                    editSpeaker(row.original);
                                }
                            }
                        },
                        {
                            title: 'Delete', onClick: () => {
                                setShowDeletePopup(true);
                                setSelectedSpeaker(row.original);
                            }
                        }
                    ]}
                />
            ),
            header: '',
            id: 'actions',
            size: 40,
            enableSorting: false,
        })
    ];

    const speakerColumns = [...generateInitialColumns(), ...generateSpeakerColumnsForTanstackTable()].flat();
    const [allColumns, setAllColumns] = useState(speakerColumns);

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const editSpeaker = (speakerInfo: Speaker, routeFromId?: boolean): void => {
        dispatch(resetSpeakers());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SPEAKERS_PAGE.SIDE_DRAWER.HEADING.EDIT,
            hideCloseButton: true,
            component: <CreateSpeaker createorUpdateGlobalSpeaker={true} speakerId={speakerInfo.id} speakerData={speakerInfo} speakerEdit={true} setRefresh={setRefresh} routeFromId={routeFromId} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const editEventSpeaker = (speakerInfo: Speaker, routeFromEventSpeaker?: boolean): void => {
        dispatch(resetSpeakers());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SPEAKERS_PAGE.SIDE_DRAWER.HEADING.EDIT,
            hideCloseButton: true,
            component: <CreateSpeaker isTableView={isTableView} speakerId={speakerInfo.id} speakerData={speakerInfo} speakerEdit={true} setRefresh={setRefresh} routeFromEventSpeaker={routeFromEventSpeaker} data-eventId={eventId} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const openDrawer = (createComp?: boolean): void => {
        if (createComp) {
            dispatch(resetSpeakers());
            eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
                heading: CONTENT.SPEAKERS_PAGE.SIDE_DRAWER.HEADING.CREATE,
                hideCloseButton: true,
                component: <CreateSpeaker createorUpdateGlobalSpeaker={true} setRefresh={setRefresh} />,
            });
        }
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const openEventSpeakerDrawer = (): void => {
        dispatch(resetSpeakers());

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Add Speaker',
            event: 'add-speaker',
            hideCloseButton: true,
            component: <AddEventSpeakers isTableView={(userDetails?.viewType === UserViewType.NORMAL && isTableView)} eventId={eventId} setRefresh={setRefresh} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const createEventSpeakerDrawer = (): void => {
        dispatch(resetSpeakers());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Create Speaker',
            event: 'create-speaker',
            hideCloseButton: true,
            component: <CreateSpeaker bottomBtn={false} data-eventId={eventId} create-event-speaker={true} setRefresh={setRefresh} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Email',
            id: 'email',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Headline',
            id: 'headline',
            meta: {
                type: 'string'
            }
        },
    ];

    const isDemoInstance = import.meta.env.VITE_NODE_ENV === 'demo';
    const trendDataForMinimalView = [
        {
            title: 'Speakers',
            value: `${Number(speakerDataCount) !== 0 ? speakerDataCount : '-'}`
        },
        {
            title: 'Customers',
            value: isDemoInstance && trendData?.customer ? `${trendData?.customer}` : '-',
        },
        {
            title: 'Prospects',
            value: isDemoInstance && trendData?.prospect ? `${trendData?.prospect}` : '-',
        },
        {
            title: 'Speakers in ICP',
            value: isDemoInstance && trendData?.icp ? `${trendData?.icp}` : '-',
        }
    ]

    useEffect((): void => {
        const fetchDataFromRoute = async (): Promise<void> => {
            if (speakers && speakers.length && speakerId) {
                try {
                    const speakerDataFromId = await getSpeakerById(Number(speakerId));
                    if (speakerDataFromId) {
                        editSpeaker(speakerDataFromId, true);
                    }
                    else if (!speakerDataFromId) {
                        toast.error('The speaker you\'re trying to access doesn\'t exist');
                        navigate('/speakers');
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
        };
        fetchDataFromRoute();
    }, [speakers]);

    useEffect((): void => 
    {
        if(!pageSize && cardView)
        {
            updatePageSize(25);
        }
    }, [pageSize]);   

    const renderHeader = () =>
    {
        const headerClassName = userDetails?.viewType === UserViewType.NORMAL ? 'event-speakers-header' : 'event-speakers-header-minimal';

        return (
            <div className="event-speakers-header-container">
                <div className={headerClassName}>
                    <CustomButton 
                        startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} 
                        name=''
                        btnType='tertiary'
                        onClick={() => navigate('/events/' + eventId + '/overview')}
                    />
                    <Breadcrumbs 
                        origin={['Overview']}
                        active='Speakers'
                    />
                </div>
                {
                    userDetails?.viewType === UserViewType.NORMAL &&
                    <div className="event-speakers-header-actions">
                        {
                            eventId && eventReduxData?.status === EventStatus.PUBLISHED &&
                                <CustomTooltip title={'Speaker Portal Link'}>
                                    <div>
                                        <CustomButton 
                                            name=''
                                            startIcon={<FontAwesomeIcon icon={['fal', 'copy']} />}
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    `${APP_CONSTANTS.APP_DOMAIN}/${orgDetails?.link}/${eventReduxData?.link}/portal`
                                                );
                                                toast.success('Link copied');
                                            }}
                                            btnType='tertiary'
                                        />
                                    </div>
                                </CustomTooltip>
                        }
                        <CustomTooltip key={1} title="Import CSV">
                            <div>
                                <DataImport licenseKey={import.meta.env.VITE_SPEAKER_SHEET_ID} eventId={eventId ? eventId : null} />
                            </div>
                        </CustomTooltip>
                        <CustomButton 
                            btnType='secondary'
                            name={chartVisibility ? 'Close Charts' : 'Open Charts'}
                            onClick={() => setChartVisibility(!chartVisibility)}
                        />
                    </div>
                }
            </div>
        )
    };

    const renderNormalTableViewForSpeakers = (): React.JSX.Element => {
        return (
                <div style={{ overflow: (isTableView && eventId) ? 'hidden' : '' }} id={eventId ? "eventSpeakers" : "speakersPage"}>
                {(isTableView || !cardView) &&
                    <>
                        {
                            eventId ?
                            renderHeader() :
                            <HeaderBar
                                title={<p>Speakers</p>}
                                buttons={
                                    [
                                        <CustomButton name={chartVisibility ? 'Close Charts' : 'Open Charts'} btnType='secondary' onClick={() => setChartVisibility(!chartVisibility)} />,
                                        <CustomButton name='Add Speaker' btnType='primary' onClick={() => openDrawer(true)} />
                                    ]
                                }
                            />
                        }
                        {chartVisibility && <SpeakersGraph speakerCount={speakerDataCount} isChartReady={isChartReady} speakersIcpData={speakersIcpData} speakerBuyerPersonaData={speakerBuyerPersonaData} speakerRevenueData={speakerRevenueData} trendData={trendData} />}
                        <div
                            style={{
                                height: chartVisibility ? '60%' : eventId ? `${APP_CONSTANTS.EVENTS_MODULE_STANDARD_COMPONENT_HEIGHT}` : `${APP_CONSTANTS.GLOBAL_MODULES_COMPONENT_HEIGHT}`,
                                flex: 1
                            }}
                        >
                            {
                                isEmpty ? (
                                    <TableEmptyComponent
                                        emptyImg={speakerEmptyImg}
                                        openDrawer={openDrawer}
                                        infoText={'No Speaker'}
                                        subInfoText={'Add your first Speaker'}
                                        buttonName={'Add Speaker'}
                                    />
                                ) : (
                                    <div className="h-100">
                                        <TanstackToolbarWithServerFilter 
                                            columns={speakerColumns}
                                            setColumns={setAllColumns}
                                            filterColumns={filterColumns}
                                            handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                            setCurrentUrl={setCurrentUrl}
                                            tabs={[
                                                {
                                                    tabName: 'All',
                                                    count: speakerDataCount,
                                                    navigation: () => navigate('/speakers'),
                                                    selectedTab: true
                                                }
                                            ]}
                                            // extraActionButtons={[
                                            //     <CustomTooltip key={1} title="Import CSV">
                                            //         <div>
                                            //             <DataImport iconButton={true} licenseKey={import.meta.env.VITE_SPEAKER_SHEET_ID} eventId={eventId ? eventId : null} />
                                            //         </div>
                                            //     </CustomTooltip>
                                            // ]}
                                        />
                                        <TanstackTable
                                            data={speakers}
                                            initialColumns={allColumns}
                                            rowCount={speakerDataCount}
                                            showNoRowsOverlay={speakerDataCount==0}
                                            pageSize={pageSize}
                                            currentPage={currentPage - 1}
                                            updateCurrentPage={updateCurrentPage as any}
                                            updatePageSize={updatePageSize}
                                            onRowClick={(row) => {
                                                if (eventId) {
                                                    editEventSpeaker(row as Speaker);
                                                }
                                                else {
                                                    editSpeaker(row as Speaker)
                                                }
                                            }}
                                            showSpinner={showSpinner}
                                            // height={
                                            //     chartVisibility ? 
                                            //         (eventId ? `calc(100% - 120px)` : `calc(100% - 184px)`) : 
                                            //         (eventId ? `calc(100% - 184px)` : `calc(100% - 124px)`)
                                            // }
                                            rightPinnedColumns={['actions']}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </>
                }

                
            </div>
        )
    };

    const renderCardViewForSpeakers = (): React.JSX.Element => { 
        return (
            <div id="eventSpeakers">
                <div className="speakers-container">
                    <EventsCardHeaderComponent heading='Speakers' count={speakerDataCount} leftNodes={leftNodes} />

                    <Grid container spacing={2}>
                        {speakers?.map((speaker: Speaker, index: number) => {

                            const profilePicture = speaker?.additionalInfo && speaker?.additionalInfo.image ? speaker?.additionalInfo.image : '';
                            const nameSplitted = speaker?.name.split(' ');
                            let firstNameInitial: string;
                            let lastNameInitial: string;
                            let emptyImagesrc: string;
                            let speakerSocialsArr: { icon: string, link: string }[] = [];

                            speakerSocialsArr?.unshift({
                                icon: mailIcon,
                                link: speaker?.email
                            })

                            if (speaker?.additionalInfo?.social?.twitter) {
                                speakerSocialsArr.push({
                                    icon: twitterIcon,
                                    link: speaker?.additionalInfo?.social?.twitter
                                });
                            }
                            if (speaker?.additionalInfo?.social?.linkedIn) {
                                speakerSocialsArr.push({
                                    icon: linkedInIcon,
                                    link: speaker?.additionalInfo?.social?.linkedIn
                                });
                            }

                            if (nameSplitted.length >= 2) {
                                firstNameInitial = nameSplitted[0][0];
                                const lastName = nameSplitted.slice(1).join(' ');
                                lastNameInitial = lastName[0];
                                emptyImagesrc = firstNameInitial + lastNameInitial;
                            }
                            else {
                                emptyImagesrc = speaker?.name.charAt(0);
                            }

                            const speakerImage = (<div>{profilePicture !== '' ? <Avatar className="speaker-card-avatar" src={profilePicture}></Avatar> : <Avatar className="card-empty-speaker-avatar" src={emptyImagesrc}><p>{emptyImagesrc.toUpperCase()}</p></Avatar>}</div>);
                            const speakerName = (<div className="speaker-name-container"><p>{speaker?.name}</p></div>);
                            const speakerSocials = (<div className="speaker-socials-icons">{speakerSocialsArr.map((social, index) => {
                                return <Box component={'img'} key={index} src={social.icon} height={'1rem'} width={'1rem'} onClick={(event) => {
                                    if (social.link !== speaker?.email) {
                                        window.open(social.link, '_blank');
                                        event.stopPropagation();
                                    }
                                    else {
                                        navigator.clipboard.writeText(`${speaker.email}`);
                                        toast.success('Email copied to clipboard');
                                        event.stopPropagation();
                                    }

                                }} />
                            })}</div>);

                            const footerContent = (
                                <div className="speaker-headline-social-contents">

                                    <p>{speaker?.additionalInfo?.headline}</p>

                                    {speakerSocials}
                                </div>
                            );

                            return (
                                <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <CardComponent
                                        header={speakerImage}
                                        cardMinHeight='144px'
                                        contentHeading={speakerName}
                                        contentHeadingBold
                                        footerLeft={footerContent}
                                        gapNotRequired
                                        onClick={() => { editEventSpeaker(speaker) }}
                                    />
                                </Grid>
                            )
                        })}

                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <CardComponent
                                emptyText='+ Add Speaker'
                                onClick={openEventSpeakerDrawer}
                                emptyCardHeight='116px'
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    };

    const renderMinimalViewForSpeakers = (): React.JSX.Element => { 

        const title = eventId ? renderHeader() : 'Speakers';
        const description = eventId ? '' : 'Manage your Speakers and their account permissions here.';
        const titlebarStyle = eventId && { padding: '0', borderBottom: !chartVisibility && '1px solid #F2F4F7' } as React.CSSProperties;

        return (
            <div id={eventId ? "eventSpeakers" : "speakersPageMinimalView"} style={{ padding: '0 0 16px 0' }}>
                <MinimalViewComponent
                    title={title}
                    description={description}
                    titleBarActions={[
                        eventId && eventReduxData?.status === EventStatus.PUBLISHED && [
                            <CustomTooltip title={'Speaker Portal Link'}>
                                <div>
                                    <CustomButton 
                                        name=''
                                        startIcon={<FontAwesomeIcon icon={['fal', 'copy']} />}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                `${APP_CONSTANTS.APP_DOMAIN}/${orgDetails?.link}/${eventReduxData?.link}/portal`
                                            );
                                            toast.success('Link copied');
                                        }}
                                        btnType='tertiary'
                                    />
                                </div>
                            </CustomTooltip>,
                        ],
                        <CustomButton key={1} name={chartVisibility ? 'Close Charts' : 'Open Charts'} btnType='secondary' onClick={() => setChartVisibility(!chartVisibility)} />
                    ]}
                    showCharts={chartVisibility}
                    titleBarStyle={titlebarStyle || undefined}
                    trendData={trendDataForMinimalView}
                    tabData={[
                        {
                            tabName: 'All',
                            count: speakerDataCount,
                            navigation: () => {},
                            selectedTab: true
                        }
                    ]}
                    actions={[ 
                        !isEmpty && <TanstackToolbarWithServerFilter
                            key={2}
                            columns={speakerColumns}
                            filterColumns={filterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        !isEmpty && <ColumnVisibilityDropdown 
                            key={3}
                            columns={speakerColumns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomTooltip key={1} title="Import CSV">
                            <div>
                                <DataImport iconButton={true} licenseKey={import.meta.env.VITE_SPEAKER_SHEET_ID} eventId={eventId ? eventId : null} />
                            </div>
                        </CustomTooltip>,
                        <CustomButton 
                            key={4} 
                            name=''
                            startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            btnType='primary' 
                            onClick={() => {
                                if (eventId) {
                                    openEventSpeakerDrawer()
                                }
                                else {
                                    openDrawer(true)
                                }
                            }}
                        />
                    ]}
                    component={
                        <div className="minimalView-table-container">
                            {
                                isEmpty ? 
                                <TableEmptyComponent
                                    emptyImg={speakerEmptyImg}
                                    openDrawer={openDrawer}
                                    infoText={'No Speaker'}
                                    subInfoText={'Add your first Speaker'}
                                    buttonName={'Add Speaker'}
                                /> 
                                :
                                <TanstackTable 
                                    data={speakers}
                                    initialColumns={allColumns}
                                    rowCount={speakerDataCount}
                                    showNoRowsOverlay={speakerDataCount==0}
                                    pageSize={pageSize}
                                    currentPage={currentPage - 1}
                                    updateCurrentPage={updateCurrentPage as any}
                                    updatePageSize={updatePageSize}
                                    showSpinner={false}
                                    // height={chartVisibility ? 'calc(100vh - 300px)' : 'calc(100vh - 190px)'}
                                    rightPinnedColumns={['actions']}
                                    onRowClick={(row) => {
                                        if (eventId) {
                                            editEventSpeaker(row as Speaker);
                                        }
                                        else {
                                            editSpeaker(row as Speaker)
                                        }
                                    }}
                                    showBottomPadding={false}
                                />
                            }
                        </div>
                    }
                    componentType='table'
                />
            </div>
        )
    };

    return (
        <>
            {
                (!isTableView && cardView) && renderCardViewForSpeakers()
            }

            {
                userDetails?.viewType === UserViewType.NORMAL && (isTableView || !cardView) && renderNormalTableViewForSpeakers()
            }

            {
                showSpinner ? <CustomSpinner width="100%" height="100lvh" /> : (userDetails?.viewType === UserViewType.MINIMAL && (isTableView || !cardView)) && renderMinimalViewForSpeakers()
            }

            {
                showDeletePopup &&
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={() => deleteSpeakerFromTable(selectedSpeaker as Speaker)}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                        setSelectedSpeaker(null);
                    }}
                    modalContent={`Are you sure you want to delete ${selectedSpeaker?.name}?`}
                    modalTitle='Delete Speaker'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                    modalHeaderIcon='trash'
                />
            }

        </>
        
    );
};
export default SpeakersPage;


// Needed for future *****IMP*****

// const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
//     setSearchText(event.target.value);
//     debouncedSearch(event.target.value, setSpeakers, searchSpeakers, getSpeakers, pageSize, currentPage);
// }; 

// const debouncedSearch = debounce(async (filters, setSpeakers, searchSpeakers, getSpeakers, pageSize, currentPage) => {
//     if (filters.length > 0 && filters.some(filter => filter.inputValue !== '')) {
//         try {
//             const searchValues = filters.map(filter => filter.inputValue);
//             const filteredSpeakers = await searchSpeakers(
//                 searchValues.some(value => value.includes('@')) ? undefined : searchValues,
//                 searchValues.some(value => value.includes('@')) ? searchValues : undefined
//             );
//             if (filteredSpeakers) {
//                 setSpeakers(filteredSpeakers);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     } else {
//         try {
//             const speakersData = await getSpeakers(pageSize, currentPage - 1);
//             if (speakersData) {
//                 setSpeakers(speakersData);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     }
// }, 300);