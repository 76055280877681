import { createSlice } from '@reduxjs/toolkit';
import { Integrations } from '../../pages/Settings/interface/integration_interface';

export const integrationsSlice = createSlice({
    name: 'integrations',
    initialState: {
        value: [] as Integrations[],
    },
    reducers: {
        updateIntegations: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { updateIntegations } = integrationsSlice.actions;
export default integrationsSlice.reducer;