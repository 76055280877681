import React, { createContext, useEffect, useState } from 'react';
import { IEventReduxValues } from '../../components/Events/interfaces/create-events_interface';
import moment from 'moment';
import { deleteEventStage, getAllStage, getStageCount } from '../../scripts/apis/eventStage';
import { EventSession, EventStage } from '../../pages/Events/interfaces';
import { deleteEventSession } from '../../scripts/apis/eventSession';
import { EventStageContextType } from '../Interfaces/event-stage-context_interface';
import toast from 'react-hot-toast';

export const EventStageContext = createContext<EventStageContextType>(null);

const EventStageProvider: React.FC<{children: React.ReactNode; eventId: string; eventReduxData?: IEventReduxValues }> = ({ children, eventId, eventReduxData }): React.JSX.Element => 
{

    const [stageData, setStageData] = useState<EventStage[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [daysArr, setDaysArr] = useState<string[]>([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [showDeletePopup, setShowDeletePopup] = useState<{
        stage: boolean;
        session: boolean;
    }>({
        stage: false,
        session: false
    });
    const [selectedStage, setSelectedStage] = useState<EventStage | null>(null);
    const [selectedSession, setSelectedSession] = useState<any>(null);

    const fetchStageData = async () =>
    {
        try 
        {
            const count = await getStageCount(eventId);
            if(count)
            {
                const eventStages = await getAllStage(eventId, count, 0);
                if(eventStages)
                {
                    setStageData(eventStages);
                }
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching stage data', error);
        }

    };

    const deleteSessionFromTable = async (row: EventSession): Promise<void> => 
    {
        try 
        {
            const sessionDeleted = await deleteEventSession(eventId, row?.id);
            if (sessionDeleted) 
            {
                setRefresh(true);
                toast.success('Session Deleted');
                setShowDeletePopup({ ...showDeletePopup, session: false });
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const deleteStageFromTable = async (stageData: EventStage): Promise<void> =>
    {
        try 
        {
            const stageDeleted = await deleteEventStage(eventId, stageData.id);
            if (stageDeleted) 
            {
                setRefresh(true);
                toast.success('Stage Deleted');
                setShowDeletePopup({ ...showDeletePopup, stage: false });
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchStageData();
    }, []);

    useEffect(() => {  
        if(refresh)
        {
            fetchStageData();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        const startDate = moment(moment.unix(Number(eventReduxData?.eventStartDateTime)).format('YYYY-MM-DD'));
        const endDate = moment(moment.unix(Number(eventReduxData?.eventEndDateTime)).format('YYYY-MM-DD'));
    
        const dates = [];
    
        while (startDate.isSameOrBefore(endDate)) {
            dates.push(startDate.format('DD MMM'));
            startDate.add(1, 'day');
        }
        
        if(dates?.length > 0)
        {
            setSelectedDate(dates[0]);
        }
    
        setDaysArr(dates);
    }, [eventReduxData]);    

    return (
        <EventStageContext.Provider value={{
            daysArr, 
            selectedDate, 
            setSelectedDate, 
            stageData, 
            eventId, 
            setRefresh, 
            deleteStageFromTable, 
            deleteSessionFromTable,
            showDeletePopup,
            setShowDeletePopup,
            selectedStage,
            setSelectedStage,
            selectedSession,
            setSelectedSession
        }}>
            {children}
        </EventStageContext.Provider>
    );
};

export default EventStageProvider;

