export enum EventStatus 
    {
    DRAFT = 1,
    PUBLISHED = 2,
    DELETED = 3
}

export enum EventLocationType 
    {
    ONSITE = 1,
    VIRTUAL = 2,
    HYBRID = 3
}

export enum EventTypes 
    {
    HOST = 1,
    CO_HOST = 2,
    SPONSORED = 3,
    SPEAKER = 4,
    ATTENDEE = 5
}

export enum CalendarInvitation
{
    ENABLED = 1,
    DISABLED = 2
}

export enum EventRegistrationTypes
    {
    OPEN = 1,
    INVITE = 2
}

export enum DailyOrder 
    {
    ASCENDING = 1,
    DESCENDING = 2
}

export enum EnableWaitlistStatus 
    {
    ENABLE = 1,
    DISABLE = 2
}

export enum EnableAuthenticationStatus 
    {
    ENABLE = 1,
    DISABLE = 2
}

export enum EventCategoryStatus 
    {
    ACTIVE = 1,
    INACTIVE = 2,
    DELETED = 3
}

export enum EventCategoryCountType 
    {
    WITH_EVENT_COUNT = 1,
    ALL_COUNT = 2
}

export enum LandingPageType 
    {
    BASIC = 1,
    ADVANCE = 2
}

export enum LandingPageLayout
    {
    ONE_TO_ONE = 1,
    SIXTEEN_TO_NINE = 2
}

export enum EventPrevNext
    {
    PREV = 1,
    NEXT = 2
}

export enum EventIntegrationType
{
    NONE = 1,
    LUMA = 2,
    ZOOM = 3,
    EVENTBRITE = 4,
}

export enum EventTimeline
    {
    PAST = 1,
    UPCOMING = 2
}

export enum EventRegistrationRange
    {
    ALL = 1,
    THIRTY_DAYS = 2,
    SEVEN_DAYS = 3,
    TWENTY_FOUR_HOURS = 4
}

export enum QrCodeDisplay
{
    YES = 1,
    NO = 2,
}

export enum QrCodeSize
{
    XS = 4,
    SM = 6,
    MD = 8,
}

export enum FontSize
{
    XS = 'XS',
    SM = 'SM',
    MD = 'MD',
    LG = 'LG',
    XL = 'XL',
}

export enum EventPhase
{
    ONGOING = 1,
    UPCOMING = 2,
    ENDED = 3
}

export enum EventAnalyticsType
    {
    CLOSEDWON = 1,
    OPPORTUNITIES = 2,
    LEADS = 3
}

export enum EventTicketPaymentIntegration
    {
    NONE = 1,
    RAZORPAY = 2,
    STRIPE = 3
}

export enum SyncCrm
{
    ENABLE = 1,
    DISABLE = 2
}
