import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
// import QuillEditor from '../../../../common/QuillEditor';
import { IEmailInterface, ITransactionalMarketingPayload } from '../../Marketing/IMarketingComponent';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { CloseIconComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { testMarketingEmail, updateTransactionalMarketing } from '../../../scripts/apis/eventMarketing';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { CONTENT } from '../../../scripts/i18n';
import LexicalEditor from '../../../lexical';
import PlaceholderAutoCompleteInput from '../../../common/FormComponents/PlaceholderInput';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const CreateRegisterationMail: React.FC<{data: {emailIcon: IconProp, color: string, orgName: string, eventName: string, emailType: number, emailTypeName: string, eventId: number, description: string}}> = (props: {data: {emailIcon: IconProp, color: string, orgName: string, eventName: string, emailType: number, emailTypeName: string, eventId: number, description: string, marketingContentByType: {[key:string]: IEmailInterface}, marketingId: string | number}}): React.JSX.Element => 
{
    const { emailIcon, color, orgName, eventName, emailType, emailTypeName, eventId, description, marketingContentByType, marketingId } = props.data;
    
    const [quillContent, setQuillContent] = useState('');

    const csrfTokenData = useSelector((state): string => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    const [spinner, setSpinner] = useState(false);

    const subjectHeaders = {
        1: marketingContentByType.inReview?.subject || `${orgName} | Your pass for ${eventName} is in review`,
        2: marketingContentByType.approved?.subject || `${orgName} | Your pass for ${eventName} has been Approved`,
        3: marketingContentByType.waitlist?.subject || `${orgName} | You have been waitlisted for ${eventName}`,
        4: marketingContentByType.rejected?.subject || `${orgName} | Your application to attend ${eventName} has been rejected`,
        5: marketingContentByType.confirmed?.subject || `${orgName} | Your pass for ${eventName} has been confirmed`,
    };

    const [subject, setSubject] = useState('');

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    const handleSubmit = async (): Promise<void> => 
    {
        const parser = new DOMParser();
        const htmlElement = quillContent ? parser.parseFromString(quillContent, 'text/html') : '';

        if(htmlElement)
        {
            let images = htmlElement.querySelectorAll('img');
            if(images?.length > 0)
            {
                images.forEach((image) => 
                {
                    if(image.alt?.includes('lexicalimage'))
                    {
                        image.src = `{{${image?.alt}}}`;
                    }
                });
            }
        }

        const updatedSubjects = {
            inReview: emailType === 1 ? subject : subjectHeaders[1],
            waitlist: emailType === 3 ? subject : subjectHeaders[3],
            approved: emailType === 2 ? subject : subjectHeaders[2],
            confirmed: emailType === 5 ? subject : subjectHeaders[5],
            rejected: emailType === 4 ? subject : subjectHeaders[4],
        };

        const marketingPayload: ITransactionalMarketingPayload = {
            name: 'Transactional',
            inReview: {
                'subject': updatedSubjects.inReview,
                'emailContent': emailType === 1 ? (htmlElement && htmlElement?.body?.innerHTML || quillContent) : marketingContentByType.inReview.emailContent
            },
            waitlist: {
                'subject': updatedSubjects.waitlist,
                'emailContent': emailType === 3 ? (htmlElement && htmlElement?.body?.innerHTML || quillContent) : marketingContentByType.waitlist.emailContent
            },
            approved: {
                'subject': updatedSubjects.approved,
                'emailContent': emailType === 2 ? (htmlElement && htmlElement?.body?.innerHTML || quillContent) : marketingContentByType.approved.emailContent
            },
            confirmed: {
                'subject': updatedSubjects.confirmed,
                'emailContent': emailType === 5 ? (htmlElement && htmlElement?.body?.innerHTML || quillContent) : marketingContentByType.confirmed.emailContent
            },
            rejected: {
                'subject': updatedSubjects.rejected,
                'emailContent': emailType === 4 ? (htmlElement && htmlElement?.body?.innerHTML || quillContent) : marketingContentByType.rejected.emailContent
            }
        };

        

        if (!quillContent) 
        {
            toast.error('Email Content is empty');
            return;
        }

        try 
        {
            setSpinner(true);
            const updateMarketingData = await updateTransactionalMarketing(marketingPayload, eventId, marketingId);
            if (updateMarketingData) 
            {
                setSpinner(false);
                handleDrawerClose();
                toast.success('Registration Email Updated');
                eventBus.dispatch('transactional-email-refresh', {
                    refresh: true,
                }); 
            }
        }
        catch (error) 
        {
            setSpinner(false);
            console.log(error);
            if (error.statusCode === 400) 
            {
                toast.error((error as Error).message);
            }
        }
        
    };

    const memoValue = useMemo(():void => {
        if(emailType === 1)
        {
            setQuillContent(marketingContentByType.inReview.emailContent);
        }
        else if(emailType === 2)
        {
            setQuillContent(marketingContentByType.approved.emailContent);
        }
        else if(emailType === 3)
        {
            setQuillContent(marketingContentByType.waitlist.emailContent);
        }
        else if(emailType === 4)
        {
            setQuillContent(marketingContentByType.rejected.emailContent);
        }
        else if(emailType === 5)
        {
            setQuillContent(marketingContentByType.confirmed.emailContent);
        }
    },[])

    const handleTestMarketingEmail = async (): Promise<void> => 
    { 

        const parser = new DOMParser();
        const htmlElement = quillContent ? parser.parseFromString(quillContent, 'text/html') : '';

        if(htmlElement)
        {
            let images = htmlElement.querySelectorAll('img');
            if(images?.length > 0)
            {
                images.forEach((image) => 
                {
                    if(image.alt?.includes('lexicalimage'))
                    {
                        image.src = `{{${image?.alt}}}`;
                    }
                });
            }
        }

        let emailContent;
        switch(emailType)
        {
            case 1:
                emailContent = marketingContentByType.inReview.emailContent;
                break;
            case 2:
                emailContent = marketingContentByType.approved.emailContent;
                break;
            case 3:
                emailContent = marketingContentByType.waitlist.emailContent;
                break;
            case 4:
                emailContent = marketingContentByType.rejected.emailContent;
                break;
            case 5:
                emailContent = marketingContentByType.confirmed.emailContent;
                break;
        }

        const content = {
            subject,
            emailContent
        };

        try 
        {
            const testEmailSent = await testMarketingEmail(props.data.eventId, content, csrfTokenData);

            if(testEmailSent)
            {
                toast.success('Test Email Sent');
            }
        } catch (error) 
        {
            console.log(error);
        }
    };

    const emailTypeToKeyMap = {
        1: 'inReview',
        2: 'approved',
        3: 'waitlist',
        4: 'rejected',
        5: 'confirmed',
    };

    useEffect(() => {
        const key = emailTypeToKeyMap[emailType];
        if (key && marketingContentByType[key]) {
          setSubject(marketingContentByType[key].subject);
        }
      }, [emailType]);

    return (
        <Box id="createRegMail">
            <CloseIconComponent onClick={handleDrawerClose} />

            <Box className="blockheader">
                <Box className="email-type-block">
                    <Typography className="email-type">{emailTypeName}</Typography>
                    <Box className="iconBlock">
                        <FontAwesomeIcon icon={emailIcon} className="email-icon" color={color}></FontAwesomeIcon>
                    </Box>
                </Box>
                <Box className="email-description">
                    {description}
                </Box>


            </Box>
            <Box className="w-100">
                <PlaceholderAutoCompleteInput options={['Event Name', 'Organization Name', 'First Name', 'Last Name']} initialInputValue={subject} onChange={(value) => setSubject(value)} />
            </Box>
            <Box className="email-content">

                {/* <Box className="subject-header">
                    {subjectHeaders[emailType]}
                </Box> */}
                {/* <QuillEditor height={'375px'} value={quillContent ? quillContent : marketingContent?.['emailContent']}
                    labelText={subjectHeaders[emailType]}
                    onChangeFunc={(value: string): void => 
                    {
                        setQuillContent(value);
                    }} /> */}
                {/* <CKTextEditor value={quillContent ? quillContent : null}
                    labelText={subjectHeaders[emailType]}
                    onChangeFunc={(value: string): void => 
                    {
                        setQuillContent(value);
                    }} /> */}

                    <LexicalEditor 
                        label={'Email Content'}
                        toolBarOptions={['Bold', 'Italic', 'Underline', 'Link', 'FontColor', 'InsertImage', 'TextFormat', 'InsertEmbed', 'InsertPageBreak', 'InsertPageBreak']}
                        slashCommands={['Heading 1', 'Heading 2', 'Heading 3', 'Paragraph', 'Divider', 'Quote']} 
                        value={quillContent || ''} 
                        handleChange={(html, json) => {
                            setQuillContent(html);
                        }} 
                        maxHeight='450px'
                    />
            </Box>

            <Box className="submit-btn-container" sx={{
                width: '45vw !important',
            }}>
                <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                    <CustomButton btnType='secondary' onClick={handleDrawerClose} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                    <CustomButton btnType='primary' loading={spinner} name={'Update Email'} type='submit' onClick={handleSubmit} />
                </Stack>

                <Box marginLeft={'auto'} paddingRight={'20px'}><CustomButton name='Test Marketing Email' btnType='secondary' type='button' onClick={handleTestMarketingEmail} /></Box>
            </Box>
        </Box>
    );
};

export default CreateRegisterationMail;