import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import CreateRegisterationMail from '../../../../components/Events/EventRegistrations/createRegisterationMails';
import { LocalStorage } from '../../../../scripts/LocalStorage';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './styles.scss';
import { getEventMarketingByType } from '../../../../scripts/apis/eventMarketing';
import { IEmailInterface } from '../../../../components/Marketing/IMarketingComponent';
import CardComponent from '../../../../components/Events/Registrations/CardComponent';
import { UserContext } from '../../../../contexts/UserContext';
import { UserViewType } from '../../../Settings/enum/users.enum';
// import { set } from 'lodash';

const RegistrationEmailsSection: React.FC<{eventId: number; eventName:string; registrationType:number; isEventTicketPresent : boolean; isTicketApprovalOn: boolean; isTicketPaid: boolean}> = (props: {eventId: number; eventName:string; registrationType:number; isEventTicketPresent : boolean, isTicketApprovalOn: boolean, isTicketPaid: boolean}): React.JSX.Element => 
{
    const [reviewContent, setReviewContent] = useState<IEmailInterface>({
        subject: '', emailContent: ''
    });
    const [approvedContent, setApprovedContent] = useState<IEmailInterface>({
        subject: '', emailContent: ''
    });
    const [waitlistContent, setWaitlistContent] = useState<IEmailInterface>({
        subject: '', emailContent: ''
    });
    const [rejectedContent, setRejectedContent] = useState<IEmailInterface>({
        subject: '', emailContent: ''
    });
    const [confirmedContent, setConfirmedContent] = useState<IEmailInterface>({
        subject: '', emailContent: ''
    });

    const {
        userDetails
    } = useContext(UserContext);

    const [refresh, setRefresh] = useState(true);
    const [marketingId, setMakretingId] = useState(null);
    const orgName = LocalStorage.get('@Org').name;

    const { eventId, eventName, registrationType } = props;

    const fetchEmailDetail = async (): Promise<void> => 
    {
        try 
        {
            const emailData = await getEventMarketingByType(eventId, 1);
            if (emailData) 
            {
                setMakretingId(emailData?.[0].id);
                if(emailData[0].inReview)
                {
                    setReviewContent(emailData[0].inReview);
                }
                if(emailData[0].approved)
                {
                    setApprovedContent(emailData[0].approved);
                }
                if(emailData[0].waitlist)
                {
                    setWaitlistContent(emailData[0].waitlist);
                }
                if(emailData[0].rejected)
                {
                    setRejectedContent(emailData[0].rejected);
                }
                if(emailData[0].confirmed)
                {
                    setConfirmedContent(emailData[0].confirmed);
                }
                setRefresh(false);
            }

        }
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect((): void => 
    {
        eventBus.on('transactional-email-refresh',(data): void => 
        {
            if (data.refresh) 
            {
                setRefresh(data.refresh);
            }
        });
        if(refresh)
        {
            fetchEmailDetail();
        }
        
    }, [refresh]);

    const openMarketingSideBar = (emailIcon: IconProp, color: string, orgName: string, eventName: string, emailType: number, emailTypeName: string, eventId: number, description: string): void => 
    {
        const marketingContentByType = {
            inReview: reviewContent,
            approved: approvedContent,
            waitlist: waitlistContent,
            rejected: rejectedContent,
            confirmed: confirmedContent
        };
        const propsData = {
            emailIcon,
            color,
            orgName,
            eventName,
            emailType,
            emailTypeName,
            eventId,
            description,
            marketingContentByType,
            marketingId
        };

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Registration Emails',
            component: <CreateRegisterationMail data={propsData} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '45vw !important'
        });
    };

    let EmailContent = [
        {
            name: 'Confirmed', boxClass: 'confirmed-box', icon: ['fal', 'circle-check'], color: '#175CD3', onClick: (): void => 
            {
                openMarketingSideBar(
                    ['fal', 'circle-check'],
                    '#175CD3',
                    orgName,
                    eventName,
                    5,
                    'Confirmed',
                    eventId,
                    'This email is sent when a guest registers or when you confirms a guest who is pending approval.'
                );
            }
        }
    ];

    if(registrationType === 1 && props.isTicketApprovalOn && !props.isTicketPaid)
    {
        EmailContent = [
            {
                name: 'In Review', boxClass: 'in-review-box', icon: ['fal', 'circle-check'], color: '#98A2B3', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-check'],
                        '#98A2B3',
                        orgName,
                        eventName,
                        1,
                        'In Review',
                        eventId,
                        'This email is sent when a guest registers for the event, notifying them that their registration is In Review.'
                    );
                }
            },
            {
                name: 'Waitlist', boxClass: 'waitlist-box', icon: ['fal', 'clock-three'], color: '#F79009', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'clock-three'],
                        '#F79009',
                        orgName,
                        eventName,
                        3,
                        'Waitlist',
                        eventId,
                        'This email is sent when a guest registers for the event, notifying them that they are on the waitlist.'
                    );
                }
            },
            {
                name: 'Declined', boxClass: 'declined-box', icon: ['fal', 'circle-xmark'], color: '#F04438', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-xmark'],
                        '#F04438',
                        orgName,
                        eventName,
                        4,
                        'Declined',
                        eventId,
                        'This email is sent when you decline a guest who is pending approval or on the Waitlist.'
                    );
                }
            },
            {
                name: 'Confirmed', boxClass: 'confirmed-box', icon: ['fal', 'circle-check'], color: '#175CD3', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-check'],
                        '#175CD3',
                        orgName,
                        eventName,
                        5,
                        'Confirmed',
                        eventId,
                        'This email is sent when a guest registers or when you confirms a guest who is pending approval.'
                    );
                }
            }

        ];
    } 

    if(registrationType === 1 && props.isTicketApprovalOn && props.isTicketPaid)
    {
        EmailContent = [
            {
                name: 'Approved', boxClass: 'approved-box', icon: ['fal', 'circle-check'], color: '#17B26A', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-check'],
                        '#17B26A',
                        orgName,
                        eventName,
                        2,
                        'Approved',
                        eventId,
                        'This email is sent when a guest registers or when you approve a guest who is pending approval.'
                    );
                }
            },
            {
                name: 'In Review', boxClass: 'in-review-box', icon: ['fal', 'circle-check'], color: '#98A2B3', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-check'],
                        '#98A2B3',
                        orgName,
                        eventName,
                        1,
                        'In Review',
                        eventId,
                        'This email is sent when a guest registers for the event, notifying them that their registration is In Review.'
                    );
                }
            },
            {
                name: 'Waitlist', boxClass: 'waitlist-box', icon: ['fal', 'clock-three'], color: '#F79009', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'clock-three'],
                        '#F79009',
                        orgName,
                        eventName,
                        3,
                        'Waitlist',
                        eventId,
                        'This email is sent when a guest registers for the event, notifying them that they are on the waitlist.'
                    );
                }
            },
            {
                name: 'Declined', boxClass: 'declined-box', icon: ['fal', 'circle-xmark'], color: '#F04438', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-xmark'],
                        '#F04438',
                        orgName,
                        eventName,
                        4,
                        'Declined',
                        eventId,
                        'This email is sent when you decline a guest who is pending approval or on the Waitlist.'
                    );
                }
            },
            {
                name: 'Confirmed', boxClass: 'confirmed-box', icon: ['fal', 'circle-check'], color: '#175CD3', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-check'],
                        '#175CD3',
                        orgName,
                        eventName,
                        5,
                        'Confirmed',
                        eventId,
                        'This email is sent when a guest registers or when you confirms a guest who is pending approval.'
                    );
                }
            }

        ];

    }
    if(registrationType === 2 && !props.isTicketPaid)
    {
        EmailContent = [
            {
                name: 'In Review', boxClass: 'in-review-box', icon: ['fal', 'circle-check'], color: '#98A2B3', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-check'],
                        '#98A2B3',
                        orgName,
                        eventName,
                        1,
                        'In Review',
                        eventId,
                        'This email is sent when a guest registers for the event, notifying them that their registration is In Review.'
                    );
                }
            },
            {
                name: 'Waitlist', boxClass: 'waitlist-box', icon: ['fal', 'clock-three'], color: '#F79009', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'clock-three'],
                        '#F79009',
                        orgName,
                        eventName,
                        3,
                        'Waitlist',
                        eventId,
                        'This email is sent when a guest registers for the event, notifying them that they are on the waitlist.'
                    );
                }
            },
            {
                name: 'Declined', boxClass: 'declined-box', icon: ['fal', 'circle-xmark'], color: '#F04438', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-xmark'],
                        '#F04438',
                        orgName,
                        eventName,
                        4,
                        'Declined',
                        eventId,
                        'This email is sent when you decline a guest who is pending approval or on the Waitlist.'
                    );
                }
            },
            {
                name: 'Confirmed', boxClass: 'confirmed-box', icon: ['fal', 'circle-check'], color: '#175CD3', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-check'],
                        '#175CD3',
                        orgName,
                        eventName,
                        5,
                        'Confirmed',
                        eventId,
                        'This email is sent when a guest registers or when you confirms a guest who is pending approval.'
                    );
                }
            }

        ];
    }

    if(registrationType === 2 && props.isTicketPaid)
    {
        EmailContent = [
            {
                name: 'Approved', boxClass: 'approved-box', icon: ['fal', 'circle-check'], color: '#17B26A', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-check'],
                        '#17B26A',
                        orgName,
                        eventName,
                        2,
                        'Approved',
                        eventId,
                        'This email is sent when a guest registers or when you approve a guest who is pending approval.'
                    );
                }
            },
            {
                name: 'In Review', boxClass: 'in-review-box', icon: ['fal', 'circle-check'], color: '#98A2B3', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-check'],
                        '#98A2B3',
                        orgName,
                        eventName,
                        1,
                        'In Review',
                        eventId,
                        'This email is sent when a guest registers for the event, notifying them that their registration is In Review.'
                    );
                }
            },
            {
                name: 'Waitlist', boxClass: 'waitlist-box', icon: ['fal', 'clock-three'], color: '#F79009', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'clock-three'],
                        '#F79009',
                        orgName,
                        eventName,
                        3,
                        'Waitlist',
                        eventId,
                        'This email is sent when a guest registers for the event, notifying them that they are on the waitlist.'
                    );
                }
            },
            {
                name: 'Declined', boxClass: 'declined-box', icon: ['fal', 'circle-xmark'], color: '#F04438', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-xmark'],
                        '#F04438',
                        orgName,
                        eventName,
                        4,
                        'Declined',
                        eventId,
                        'This email is sent when you decline a guest who is pending approval or on the Waitlist.'
                    );
                }
            },
            {
                name: 'Confirmed', boxClass: 'confirmed-box', icon: ['fal', 'circle-dollar'], color: '#175CD3', onClick: (): void => 
                {
                    openMarketingSideBar(
                        ['fal', 'circle-dollar'],
                        '#175CD3',
                        orgName,
                        eventName,
                        5,
                        'Confirmed',
                        eventId,
                        'This email is sent when a guest registers or when you confirms a guest who is pending approval.'
                    );
                }
            }

        ];
    }

    return (
        <Box id={userDetails?.viewType === UserViewType.NORMAL ? "registrationEmails" : "registrationEmailsMinimal"}>
            <Typography className="registration-emails-label">{'Transaction Emails'}</Typography>
            <Grid container spacing={2}>
            {
                EmailContent.map((item, index): React.ReactElement => 
                {
                    return (
                        <Grid item xl={4} lg={4} md={4} sm={4} key={index}>

                            <CardComponent 
                                header={item.name}
                                footerLeft={<FontAwesomeIcon color={item.color} icon={item.icon as IconProp} />}
                                onClick={item.onClick}
                                cardMinHeight='88px'
                            />
                        </Grid>
                    );
                })
            }
            </Grid>
        </Box>
    );
};

export default RegistrationEmailsSection;

const EmailBoxComponent = ({ name, icon, color, onClick, boxClass }): React.ReactElement => 
{
    return (
        <Box className={boxClass} onClick={onClick}>
            <Typography className="emailType">{name}</Typography>
            <Box className="iconBlock">
                <FontAwesomeIcon icon={icon} className="email-icon" color={color}></FontAwesomeIcon>
            </Box>
        </Box>
    );
};
