import { FormikErrors, FormikTouched } from "formik";
import { FormControlComponent, FormLabelComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { EmailMarketingFormData } from "../../../pages/Events/interfaces";

import './styles.scss';
import PlaceholderAutoCompleteInput from "../../../common/FormComponents/PlaceholderInput";

interface PushNotificationDesignProps {
    formValues: EmailMarketingFormData;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<EmailMarketingFormData>>;
    touched: FormikTouched<EmailMarketingFormData>;
    errors: FormikErrors<EmailMarketingFormData>;
}

const PushNotificationDesign: React.FC<PushNotificationDesignProps> = (props): React.JSX.Element =>
{

    const { formValues, setFieldValue, errors, touched } = props;

    return (
        <div id="pushNotificationDesign">
            <div className="push-notification-design-container">
                <div>

                    {/* Name */}
                    <div className="sidebar-container-spacing">
                        <FormLabelComponent label="Name" required />
                        <FormControlComponent 
                            type="text"
                            value={formValues.name}
                            name='name'
                            onChange={(event) => setFieldValue('name', event.target.value)}
                            isInvalid={touched.name && !!errors.name}
                        />
                    </div>

                    {/* Title */}
                    <div className="sidebar-container-spacing">
                        <PlaceholderAutoCompleteInput 
                            initialInputValue={formValues.title as string}
                            options={['Event Name', 'Organization Name', 'First Name', 'Last Name']}
                            isInvalid={touched.title && !!errors.title}
                            onChange={(value) => setFieldValue('title', value)}
                            rows={1}
                            placeholder="Title"
                            required
                            label="Title"
                        />
                    </div>

                    {/* Message content */}
                    <div className="sidebar-container-spacing">
                        <PlaceholderAutoCompleteInput 
                            initialInputValue={formValues.body as string}
                            options={['Event Name', 'Organization Name', 'First Name', 'Last Name']}
                            isInvalid={touched.body && !!errors.body}
                            onChange={(value) => setFieldValue('body', value)}
                            rows={6}
                            placeholder="Message Content"
                            required
                            label="Message Content"
                        />
                    </div>

                </div>
            </div>
        </div>
    )
};

export default PushNotificationDesign;
