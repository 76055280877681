import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomButton } from '../../../../../../common/FormComponents/Buttons';
import APP_CONSTANTS from '../../../../../../scripts/constants';
import eventBus from '../../../../../../scripts/event-bus';
import { refreshAccessControlPin } from '../../../../../../scripts/apis/eventAccessControl';
import { EventAccessControl } from '../../../../interfaces/event-access-control_interface';

interface AccessPinActionsComponentProps
{
    eventId: number;
    componentType: string;
    data: EventAccessControl;
}

const AccessPinActionsComponent: React.FC<AccessPinActionsComponentProps> = ({ eventId, componentType, data }) =>
{
    const [accessPin, setAccessPin] = useState<string | number>(data?.accessControlOtp || '');

    const getTitleContent = (): string =>
    {
        if (componentType === 'viewPin') return `${data?.deviceName} Pin`;
        else if (componentType === 'disableDevice') return 'Disable Device';
        return `Delete ${data?.deviceName} ?`;
    };

    const getSubTitleContent = (): string =>
    {
        if (componentType === 'viewPin')
            return 'Enter this OTP in you device to get access control to stages';
        else if (componentType === 'disableDevice') return 'Do you want disable this device?';
        return 'Are you sure you want to delete this Device? This action cannot be undone.';
    };

    const refreshAccessPin = async (data: EventAccessControl): Promise<void> =>
    {
        try
        {
            const refreshedPin = await refreshAccessControlPin(eventId, data?.id);
            if (refreshedPin)
            {
                setAccessPin(refreshedPin.accessControlOtp);
            }
        } catch (error)
        {
            console.log('Error in refreshing pin', error);
        }
    };

    useEffect(() =>
    {
        if (componentType === 'viewPin')
        {
            refreshAccessPin(data);
        }
    }, [componentType === 'viewPin']);

    return (
        <div id='accessPinAccessComponent'>
            <Box className='header-container'>
                <Typography className='title'>{getTitleContent()}</Typography>
                <Typography className='sub-title'>{getSubTitleContent()}</Typography>
            </Box>
            <Box
                paddingTop={componentType === 'viewPin' ? '24px' : '40px'}
                className='access-actions-container'
            >
                {componentType === 'viewPin' ? (
                    <Box className='verification-code-container'>
                        <Typography className='title'>{'Verification code'}</Typography>
                        <Box className='code-and-refresh'>
                            {
                                <Typography className='code'>
                                    {String(accessPin)?.split('').join('  ')}
                                </Typography>
                            }
                            <CustomButton
                                btnType='primary'
                                name=''
                                type='button'
                                startIcon={<FontAwesomeIcon icon={['fal', 'rotate']} />}
                                onClick={() =>
                                {
                                    refreshAccessPin(data);
                                }}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box className='disable-delete-container'>
                        <CustomButton
                            btnType='secondary'
                            name='Cancel'
                            type='button'
                            style={{ width: '170px', fontSize: '16px', fontWeight: 600 }}
                            onClick={() =>
                            {
                                eventBus.dispatch(
                                    APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT,
                                    true
                                );
                            }}
                        />
                        <CustomButton
                            onClick={(event) =>
                            {
                                if (componentType === 'disableDevice')
                                {
                                    // handleDisable(data);
                                } else
                                {
                                    // handleDelete(data);
                                }
                            }}
                            btnType={componentType === 'disableDevice' ? 'primary' : 'danger'}
                            style={{ width: '170px', fontSize: '16px', fontWeight: 600 }}
                            name={componentType === 'disableDevice' ? 'Disable' : 'Delete'}
                            type='button'
                        />
                    </Box>
                )}
            </Box>
        </div>
    );
};

export default AccessPinActionsComponent;